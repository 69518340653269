import {SyncDevice} from "../../../../domain/sync/SyncDevice";

export type DataChannelActions = DataChannelConnected | DataChannelDisconnected

type DataChannelConnected = {
    type: 'DATA_CHANNEL_CONNECTED',
    payload: {
        deviceUUID: SyncDevice['uuid']
    }
}

export const DATA_CHANNEL_CONNECTED = 'DATA_CHANNEL_CONNECTED';
export const dataChannelConnected = (deviceUUID: SyncDevice['uuid']): DataChannelConnected => ({
    type: DATA_CHANNEL_CONNECTED,
    payload: {
        deviceUUID
    }
})

type DataChannelDisconnected = {
    type: 'DATA_CHANNEL_DISCONNECTED',
    payload: {
        deviceUUID: SyncDevice['uuid']
    }
}

export const DATA_CHANNEL_DISCONNECTED = 'DATA_CHANNEL_DISCONNECTED';
export const dataChannelDisconnected = (deviceUUID: SyncDevice['uuid']): DataChannelDisconnected => ({
    type: DATA_CHANNEL_DISCONNECTED,
    payload: {
        deviceUUID
    }
})