import {UserAgent} from "../../environment/getUserAgent";
import {CreateDeviceRequest} from "../../../../../shared/api/devices";
import {DOLLERO_ACCOUNT_URL} from "../../../config";
import getAuthorizationHeader from "./auth/getAuthorizationHeader";

const createDevice = async (userAgentData: UserAgent) => {
    const requestBody: CreateDeviceRequest = {
        name: "",
        os_name: userAgentData.os.name || '',
        os_version: userAgentData.os.version || '',
        browser_name: userAgentData.browser.name || '',
        browser_version: userAgentData.browser.version || '',
        device_name: userAgentData.device.model,
        device_type: userAgentData.device.type,
        device_vendor: userAgentData.device.vendor,
    };

    return fetch(`${DOLLERO_ACCOUNT_URL}api/devices`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAuthorizationHeader()
        },
        body: JSON.stringify(requestBody),
        credentials: "include"
    })
        .then(response => response.json())
        .then(result => {
            console.log('Success:', result);
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

export default createDevice;