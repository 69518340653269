import React from 'react';
import Modal from '../../common/Modal';
import { useDispatch } from 'react-redux';
import {
	createAdjustmentTransaction,
	reconcileClearedTransactions,
	stopReconciling,
} from '../../store/accounts/actions';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const AdjustmentTransactionModal = (props: Props) => {
	const dispatch = useDispatch();

	const onPrimary = async () => {
		await dispatch(createAdjustmentTransaction());
		dispatch(reconcileClearedTransactions());
		dispatch(stopReconciling());
		props.onClose();
	};

	return (
		<Modal
			open={props.isOpen}
			onClose={props.onClose}
			onSecondaryClick={props.onClose}
			onPrimaryClick={onPrimary}
		>
			Your statement balance and cleared balance do not match. Would you like to created an
			adjustment transaction to cover the difference?
		</Modal>
	);
};

export default AdjustmentTransactionModal;
