import { DOLLERO_ACCOUNT_URL } from '../../../config';
import getAuthorizationHeader from '../../sync/api/auth/getAuthorizationHeader';
import { SyncDevice } from '../../../domain/sync/SyncDevice';
import handleUnauthorized from '../../api/handleUnauthorized';

const getWebsocketTicket = async (uuid: SyncDevice['uuid']) => {
	return fetch(`${DOLLERO_ACCOUNT_URL}api/auth/websocket/ticket?deviceUUID=${uuid}`, {
		headers: {
			Authorization: await getAuthorizationHeader()
		}
	})
		.then(handleUnauthorized)
		.then(response => response.json())
		.then(result => {
			console.log('Success:', result);
			return result.ticket;
		})
}

export default getWebsocketTicket;