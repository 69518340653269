import React, { useState } from 'react';
import SignUpForm from '../SignUpForm/SignUpForm';
import useIsLoggedIn from '../../store/auth/hooks/useIsLoggedIn';
import { Redirect, useHistory } from 'react-router';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import LoginForm from '../../nav/LoginForm/LoginForm';
import Button from '../../common/Button';
import './sign-up-page.less';
import useAllProfiles from '../../store/profiles/hooks/useAllPofiles';
import { Link } from 'react-router-dom';

const SignUpPage = () => {
	const history = useHistory();
	const defaultSignIn = history.location.pathname.includes('sign-in')
	const [signUpComplete, setSignUpComplete] = useState(false);
	const [signInComplete, setSignInComplete] = useState(false);
	const [mode, setMode] = useState<"sign-in"|"sign-up">(defaultSignIn ? 'sign-in' : 'sign-up');
	const profiles = useAllProfiles();
	const loggedIn = useIsLoggedIn();

	if (signInComplete && profiles.length === 0) {
		return <Redirect to={APP_LINKS.sync_profile()}></Redirect>;
	}

	if (signUpComplete) {
		return <Redirect to={APP_LINKS.add_profile()}></Redirect>;
	}

	if (loggedIn) {
		return profiles.length === 0
			? <Redirect to={APP_LINKS.sync_profile()}></Redirect>
			: <Redirect to={APP_LINKS.budget(profiles[0].uuid)}></Redirect>;
	}
	const onSignUpComplete = () => setSignUpComplete(true);
	const onSignInComplete = () => setSignInComplete(true);

	return <div className={'sign-up-page'}>
		<div className={'sign-up-page__bg'}>
			<main className={'sign-up-page__main-box'}>
				<img src={'logo-light.svg'} className={'sign-up-page__logo'} alt={'Dollero Logo'}/>
				<div className={'sign-up-page__form-container'}>
					{ mode === "sign-up" && signUp(onSignUpComplete, () => setMode('sign-in')) }
					{ mode === "sign-in" && signIn(onSignInComplete, () => setMode('sign-up')) }
				</div>
			</main>
		</div>
	</div>;
};

export default SignUpPage;

const signUp = (onSignUpSuccess: () => any, goToSignIn: () => void) => {
	return <>
		<h2>Private personal budgeting.</h2>
		<p>Create an account or <Link to={APP_LINKS.sign_in()} onClick={goToSignIn}>log in</Link></p>
		<SignUpForm onSignUpSuccess={onSignUpSuccess} />
	</>;
};

const signIn = (onSignInSuccess: () => any, goToSignUp: () => void) => {
	return <>
		<h2>Welcome back!</h2>
		<p>Log in below or <Link to={APP_LINKS.sign_up()} onClick={goToSignUp}>create an account</Link></p>
		<LoginForm onLoginSuccess={onSignInSuccess} />
		<Button form={'login'} type={'submit'} className={'sign-up-page__login-submit'}>Log In</Button>
	</>;
};
