import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    category: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string().required().notOneOf(['0']),
        })
        .required('Required'),
});

export default validationSchema;