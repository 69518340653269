import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
	createHiddenCategoryBudgetedSelector,
	createHiddenCategoryOutflowSelector,
	createHiddenCategoryBalanceSelector,
} from '../../store/budget/selectors/monthBudget';
import {Month} from "../../../domain/util/date";
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';

type Props = {
    month: Month
}

const HiddenCategoryTotals = ({ month }: Props) => {
	const budgetSelector = useMemo(() => {
		return createHiddenCategoryBudgetedSelector(month);
	}, [month]);
	const budgeted = useSelector(budgetSelector);

	const outflowsSelector = useMemo(() => {
		return createHiddenCategoryOutflowSelector(month);
	}, [month]);
	const outflows = useSelector(outflowsSelector);

	const balanceSelector = useMemo(() => {
		return createHiddenCategoryBalanceSelector(month);
	}, [month]);
	const balance = useSelector(balanceSelector);
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();

	return (
		<>
			<div className={'master-category-totals__cell'}>
				<span className={'master-category-totals__value'}>
					{formatCurrencyForDisplay(budgeted)}
				</span>
			</div>
			<div className={'master-category-totals__cell'}>
				<span className={'master-category-totals__value'}>
					{formatCurrencyForDisplay(outflows)}
				</span>
			</div>
			<div className={'master-category-totals__cell'}>
				<span className={'master-category-totals__value'}>
					{formatCurrencyForDisplay(balance)}
				</span>
			</div>
		</>
	);
};

export default HiddenCategoryTotals;
