import * as AccountActions from '../actions';
import { StoreActions } from '../../types';
import { Account } from '../../../../domain/accounts/Account';

export type ActiveAccountState = Account['uuid'] | null;
export const activeAccount = (state: ActiveAccountState = null, action: StoreActions) => {
    switch (action.type) {
        case AccountActions.SET_ACTIVE_ACCOUNT:
            return action.payload.accountUUID;
        default:
            return state;
    }
};
