import AppDB from '../index';
import { IProfileRepo } from '../../../../domain/profiles/IProfileRepo';
import { Profile } from '../../../../domain/profiles';

class ProfileRepo implements IProfileRepo {
	async getByUUID(uuid: Profile['uuid']) {
		const profile = await AppDB.profiles.where('uuid').equals(uuid).first();
		if (!profile) {
			return null;
		}

		return profile;
	}

	put(profile: Profile) {
		return AppDB.profiles.put(profile);
	}

	getAllProfiles() {
		return AppDB.profiles.toArray();
	}
}

export default new ProfileRepo();
