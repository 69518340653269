import { SubCategory } from '../categories';
import getSubCategoryBalances, { SubCategoryBalanceMap } from './getSubCategoryBalances';
import { Month } from '../util/date';
import { equal, newMoney, toLegacy } from '../util/money';
import ToastManager from '../../view/common/Toast';
import { CategoriesRepo } from '../categories/ICategoriesRepo';
import { TYPES } from '../../view/common/Toast';
import BalancePill from '../../view/budget/BudgetCardList/BalancePill';

const notifyCategoryBalanceChange = async (callable: Function, month: Month, categoryUUIDs: Array<SubCategory['uuid']>) => {
	const beforeBalances = await getSubCategoryBalances(categoryUUIDs, month);
	const result = await callable();
	const afterBalances = await getSubCategoryBalances(categoryUUIDs, month);

	changedCategories(beforeBalances, afterBalances).forEach((async (categoryUUID) => {
		const category = await CategoriesRepo.getSubCategory(categoryUUID);
		const beforeBalance = beforeBalances.get(categoryUUID) || newMoney(0);
		const afterBalance = afterBalances.get(categoryUUID) || newMoney(0);
		ToastManager.newToast(
			<span>
				{category?.name} <BalancePill numericValue={toLegacy(beforeBalance)}/> to <BalancePill numericValue={toLegacy(afterBalance)}/>
			</span>,
			{
				icon: false,
				type: TYPES.INFO,
			}
		);
	}));

	return result;
}

export default notifyCategoryBalanceChange;

const changedCategories = (beforeBalance: SubCategoryBalanceMap, afterBalances: SubCategoryBalanceMap) => {
	let changedCategories: Array<SubCategory['uuid']> = [];
	beforeBalance.forEach((beforeBalance, subCatUUID) => {
		const afterBalance = afterBalances.get(subCatUUID) || newMoney(0);
		if (!equal(beforeBalance, afterBalance)) {
			changedCategories.push(subCatUUID);
		}
	})
	return changedCategories;
}