import { addSubCategory } from '../../store/categories/actions/subCategories';
import React, { useState } from 'react';
import { uuid } from '../../../domain/util/uuid';
import { useDispatch } from 'react-redux';
import { MasterCategory } from '../../../domain/categories';
import { AdvancedModal } from '../../common/Modal';
import Button from '../../common/Button';
import { Button as AppButton } from '../../common/Button/Button';
import TextField from '../../common/Form/TextField';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { Icon } from 'semantic-ui-react';

type Props = {
	masterCategoryUUID: MasterCategory['uuid'];
	children?: React.ReactNode;
};

export const AddCategoryButton = ({ masterCategoryUUID, children }: Props) => {
	const [newCategoryName, setNewCategoryName] = useState('');
	const [isModalShowing, setIsModalShowing] = useState(false);
	const dispatch = useDispatch();
	const activeProfile = useRequiredActiveProfile();

	const onPrimaryClick = () => {
		console.log(newCategoryName);
		dispatch(
			addSubCategory({
				uuid: uuid(),
				name: newCategoryName,
				masterCategoryId: masterCategoryUUID,
				hidden: false,
				profileUUID: activeProfile.uuid,
			})
		);
		setNewCategoryName('');
		setIsModalShowing(false);
	};

	return (
		<>
			{ !!children && <Button
				onClick={() => setIsModalShowing(true)}
				compact
			>
				<Icon name={'plus'}/>
				{children}
			</Button>}
			{!children && <Button
				onClick={() => setIsModalShowing(true)}
				icon={'plus'}
				circular
				compact
			/>}
			<AdvancedModal
				open={isModalShowing}
				onClose={() => setIsModalShowing(false)}
				title={'Add Sub Category'}
				actions={
					<>
						<>
							<AppButton onClick={() => setIsModalShowing(false)}>
								<Icon name="remove" /> Cancel
							</AppButton>
							<AppButton
								type={'success'}
								onClick={onPrimaryClick}
								disabled={newCategoryName === ''}
							>
								Save
							</AppButton>
						</>
					</>
				}
			>
				<>
					<TextField
						name={'name'}
						value={newCategoryName}
						onChange={e => {
							setNewCategoryName(e.target.value);
						}}
					/>
				</>
			</AdvancedModal>
		</>
	);
};

export default AddCategoryButton;
