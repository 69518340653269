import {RootState} from "../../types";
import {HIDDEN_BELOW_WIDTH} from "../../../nav/MainNav/MainNav";

export const isSideNavShown = (state: RootState) => {
    return state.nav.sideNavShown;
};

export const isSideNavHideable = (state: RootState) => {
    const windowWidth = state.ui.windowWidth;
    return windowWidth < HIDDEN_BELOW_WIDTH;
}