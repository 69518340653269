import { Icon, Popup } from 'semantic-ui-react';
import { Redirect, useHistory } from 'react-router-dom';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import React, { useState } from 'react';
import useActiveProfile from '../../store/profiles/hooks/useActiveProfile';
import EditProfileModal from '../../profiles/EditProfileModal/EditProfileModal';
import Dropdown from '../../common/Dropdown';
import useAllProfiles from '../../store/profiles/hooks/useAllPofiles';
import NewProfileModal from '../../profiles/NewProfileModal/NewProfileModal';

const ProfilesList = () => {
	const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
	const [isNewProfileModalOpen, setIsNewProfileModalOpen] = useState(false);
	const activeProfile = useActiveProfile();
	const history = useHistory();
	const profiles = useAllProfiles();
	if (!activeProfile) {
		return <Redirect to={APP_LINKS.profiles()} />;
	}
	const profileOptions = profiles
		.filter(p => !p.deleted)
		.map(profile => ({
			key: profile.uuid,
			text: profile.name,
			value: profile.uuid,
		}));

	return (
		<div className={'main-nav__profile'}>
			<span className={'main-nav__profile-name'}>
				<Icon name={'user circle'} />
				<Dropdown
					inline
					options={profileOptions}
					defaultValue={activeProfile.uuid}
					onChange={(e, data) => {
						const selectedProfile = profiles.find(p => p.uuid === data.value);
						if (!selectedProfile) throw new Error('Error selecting profile');
						history.push(APP_LINKS.budget(selectedProfile.uuid));
					}}
					value={activeProfile.uuid}
				/>
			</span>
			<Popup
				content="Edit profile"
				trigger={
					<Icon
						name={'edit'}
						className={'main-nav__edit-profile'}
						onClick={() => setIsEditProfileModalOpen(true)}
					/>
				}
			/>
			<Popup
				content="New profile"
				trigger={
					<Icon
						name={'plus'}
						className={'main-nav__edit-profile'}
						onClick={() => setIsNewProfileModalOpen(true)}
					/>
				}
			/>
			<NewProfileModal
				isOpen={isNewProfileModalOpen}
				onClose={() => setIsNewProfileModalOpen(false)}
			/>
			<EditProfileModal
				profile={activeProfile}
				isOpen={isEditProfileModalOpen}
				onClose={() => setIsEditProfileModalOpen(false)}
			/>
		</div>
	);
};

export default ProfilesList;
