import {SerializedTransaction, Transaction} from "../Transaction";
import NaiveDate from "../../util/NaiveDate";

const fromJson = (transaction: SerializedTransaction): Transaction => {
    return {
        ...transaction,
        date: NaiveDate.fromYMD(transaction.date.year, transaction.date.month, transaction.date.date)
    }
}

export default fromJson;

