import React, { ComponentType, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import TransactionsRepo from '../../../../infrastructure/persistence/indexed-db/transactions/TransactionsRepo';
import { Profile } from '../../../../domain/profiles';
import { SingleOption } from '../../../common/Form/SelectField';
import { Transaction } from '../../../../domain/transactions/Transaction';
import { FieldInputProps } from 'formik';
import { alpha, alphaStartsWith } from '../../../../domain/util/sort';
import { VariableSizeList } from 'react-window';
import { MenuListProps } from 'react-select';

type Props = {
	profileUUID: Profile['uuid'];
	className?: string;
	label?: string;
} & FieldInputProps<Profile['uuid']>;

const PayeeField = (props: Props) => {
	const [options, setOptions] = useState<SingleOption<Transaction['payee']>[]>([]);
	const [inputValue, setInputValue] = useState<string>('');

	useEffect(() => {
		TransactionsRepo.getAllPayees(props.profileUUID).then(payees => {
			const formattedPayeeOptions = payees.sort(alpha()).map(
				(payeeName): SingleOption<Transaction['payee']> => {
					return { label: payeeName, value: payeeName };
				}
			);
			setOptions(formattedPayeeOptions);
		});
	}, [props.profileUUID]);

	useEffect(() => {
		if (inputValue.length === 0) {
			return;
		}
		const valueGetter = (opt: SingleOption<Transaction['payee']>) => opt.value;
		const filtered = options.sort(alphaStartsWith(inputValue, valueGetter));
		setOptions(filtered);
	}, [inputValue, options]);

		return (<>
			{props.label && (
				<label className="">
					{props.label}
				</label>
			)}
			<CreatableSelect<SingleOption<Transaction['payee']>>
				isClearable={true}
				onChange={newValue => {
					console.log(newValue);
					if (newValue === null || newValue === undefined) {
						props.onChange && props.onChange(null);
						return;
					}

					if (newValue.hasOwnProperty('value')) {
						const refinedValue = newValue as SingleOption<Transaction['payee']>;
						props.onChange(refinedValue.value);
					}
				}}
				onInputChange={newValue => setInputValue(newValue)}
				options={options}
				defaultValue={props.value ? { label: props.value, value: props.value } : undefined}
				value={options.find(opt => opt.value === props.value)}
				styles={getStyles()}
				className={props.className}
				menuPortalTarget={document.body}
				placeholder={'Enter a payee...'}
				menuPlacement={'auto'}
				// @ts-ignore
				components={{ MenuList: WindowedMenuList }}
				// openMenuOnClick={false}
				// menuIsOpen={inputValue.length > 0}
			/>
		</>
	);
};

export default PayeeField;

const getStyles = () => {
	return Object.assign(
		{},
		{
			control: (provided: {}) => ({
				...provided,
				borderRadius: 0,
				borderColor: '#e5e5e5',
				minHeight: 0,
				border: 0,
				background: 'none',
				boxShadow: 'none',
			}),
			indicatorSeparator: (provided: {}) => ({
				...provided,
				marginTop: '2px',
				marginBottom: '2px',
			}),
			valueContainer: (provided: {}) => ({
				...provided,
				padding: '0 4px',
			}),
			dropdownIndicator: (provided: {}) => ({
				...provided,
				padding: '5px 7px',
			}),
			clearIndicator: (provided: {}) => ({
				...provided,
				padding: '5px',
			}),
			menuPortal: (base: {}) => ({ ...base, zIndex: 9999 }),
		}
	);
};

const WindowedMenuList: ComponentType<MenuListProps<Transaction, false>> = (
	props: MenuListProps<Transaction, false>
) => {
	const numChildren = React.Children.count(props.children);

	if (numChildren === 0) {
		return null;
	}

	let height = props.maxHeight;

	if (numChildren < 9) {
		height = numChildren * 35;
	}

	const childrenArray = React.Children.toArray(props.children);

	return (
		<VariableSizeList
			width={'100%'}
			height={height}
			itemCount={numChildren}
			itemSize={index => {
				// @ts-ignore
				if (childrenArray[index]?.props.children === 'No options') {
					return 35;
				}
				// @ts-ignore
				if (childrenArray[index]?.props.data.label.length > 25) {
					return 60;
				}
				return 35;
			}}
			initialScrollOffset={0}
			estimatedItemSize={35}
		>
			{({ index, style }) => <div style={style}>{childrenArray[index]}</div>}
		</VariableSizeList>
	);
};
