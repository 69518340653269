import {Route, Switch, useLocation} from "react-router-dom";
import useActiveProfile from "../store/profiles/hooks/useActiveProfile";
import {useDispatch} from "react-redux";
import useAllProfiles from "../store/profiles/hooks/useAllPofiles";
import React, {useEffect} from "react";
import {loadActiveProfile} from "../store/profiles/actions";
import {Dimmer, Loader} from "semantic-ui-react";
import {APP_ROUTES} from "../store/router/hooks/useAppRoutes";
import SignUpPage from "../auth/SignUpPage/SignUpPage";
import ProfilesPage from "../profiles/ProfilesPage/ProfilesPage";
import TopNav from "../nav/TopNav/TopNav";
import BudgetTools from "../budget/BudgetTools/BudgetTools";
import AccountsTopNavTools from "../accounts/AccountsTopNavTools/AccountsTopNavTools";
import AccountsPage from "../accounts/AccountsPage";
import SettingsPage from "../settings/SettingsPage/SettingsPage";
import BudgetPage from "../budget/BudgetPage/BudgetPage";
import HomePage from "./HomePage";
import TransactionsTagsPage from '../settings/TransactionsTagPage/TransactionsTagsPage';
import SyncPage from '../settings/SyncPage/SyncPage';
import AppFrame from './AppFrame/AppFrame';
import ErrorBoundary from '../common/ErrorBoundary/ErrorBoundary';

const AppRoutes = () => {
    const location = useLocation();
    const activeProfile = useActiveProfile();
    const dispatch = useDispatch();
    const profiles = useAllProfiles();
    const profileRoute = isProfileRoute(location.pathname);

    useEffect(() => {
        if (profileRoute) {
            const profileUUID = getProfileUUIDFromPath(location.pathname);
            if (!activeProfile || profileUUID !== activeProfile.uuid ) {
                const profile = profiles.find(p => p.uuid === profileUUID);
                if (!profile) {
                    throw new Error('Profile not found!');
                }
                dispatch(loadActiveProfile(profile));
            }

        }
    }, [dispatch, profiles, activeProfile, profileRoute, location]);

    if (!activeProfile && profileRoute) {
        return (
            <Dimmer active inverted>
                <Loader size="huge">Loading</Loader>
            </Dimmer>
        );
    }

    return (
        <Switch>
            <Route path={[APP_ROUTES.root]} exact>
                <HomePage />
            </Route>
            <Route path={[APP_ROUTES.sign_up, APP_ROUTES.sign_in]} exact>
                <SignUpPage />
            </Route>
            <Route path={[APP_ROUTES.profiles, APP_ROUTES.add_profile, APP_ROUTES.sync_profile]} exact>
                <ProfilesPage />
            </Route>
            <Route path={APP_ROUTES.budget}>
                <AppFrame>
                    <TopNav>
                        <BudgetTools />
                    </TopNav>
                    <ErrorBoundary><BudgetPage /></ErrorBoundary>
                </AppFrame>
            </Route>
            <Route path={[APP_ROUTES.account, APP_ROUTES.accounts]}>
                <AppFrame>
                    <TopNav>
                        <AccountsTopNavTools/>
                    </TopNav>
                    <ErrorBoundary><AccountsPage /></ErrorBoundary>
                </AppFrame>
            </Route>
            <Route path={APP_ROUTES.settings_tags}>
                <AppFrame>
                    <TopNav title={'Settings'}/>
                    <ErrorBoundary><TransactionsTagsPage /></ErrorBoundary>
                </AppFrame>
            </Route>
            <Route path={APP_ROUTES.sync}>
                <AppFrame>
                    <TopNav title={'Sync'}/>
                    <ErrorBoundary><SyncPage /></ErrorBoundary>
                </AppFrame>
            </Route>
            <Route path={APP_ROUTES.settings} exact>
                <AppFrame>
                    <TopNav title={'Settings'}/>
                    <ErrorBoundary><SettingsPage /></ErrorBoundary>
                </AppFrame>
            </Route>
        </Switch>
    );
};

export default AppRoutes;

const isProfileRoute = (pathname: string): boolean => {
    const matched = pathname.match(
        /\/profiles\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/gm
    );
    return Boolean(matched);
};

// TODO: why did I do this instead of just using useParams() from react router?
const getProfileUUIDFromPath = (pathname: string) => {
    const match = pathname.match(
        /\/profiles\/([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/
    );
    if (!match) {
        throw new Error('No profile uuid in path');
    }
    return match[1];
};