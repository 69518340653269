import {combineReducers} from "redux";
import {
    devices,
    DevicesState,
    accountDevices,
    AccountDevicesState,
    firstRoundSyncComplete,
    FirstRoundSyncCompleteState,
    numItemsSynced,
    NumItemsSyncedState
} from './devices';

export type SyncState = {
    devices: DevicesState,
    accountDevices: AccountDevicesState,
    firstRoundSyncComplete: FirstRoundSyncCompleteState,
    numItemsSynced: NumItemsSyncedState,
}

export default combineReducers({
    devices,
    accountDevices,
    firstRoundSyncComplete,
    numItemsSynced
});
