import {
    DataChannelProvider,
    PeerAnswerEventType,
    PeerOfferEventType
} from "./SignallingServerSession";
import SignallingPeer from "./SignallingPeer";
import {SyncDevice} from "../../../domain/sync/SyncDevice";
import SignallingError from './SignallingError';

const SIGNALLING_TIMEOUT_MS = 6000;

export default class Initiator extends SignallingPeer implements DataChannelProvider {
    private dataChannel: RTCDataChannel;
    private signallingTimeoutID: number|undefined;

    private removeAnswerListener: (() => void | undefined) | undefined;
    private removeDataChannelOpeListener: (() => void | undefined) | undefined;

    constructor(iceServers: RTCConfiguration['iceServers']) {
        super(iceServers);
        this.dataChannel = this.peerConnection.createDataChannel("syncChannel");
    }

    public async beginSignalling(peerUUID: SyncDevice['uuid']) {
        this.socket.send({
            // @ts-ignore TODO: add to consts in SignallingServerSession
            type: 'startSignalling',
            payload: peerUUID
        })

        try {
            return await this.getDataChannel();
        } catch (err) {
            if (typeof err === "string") {
                throw new SignallingError(err, peerUUID);
            } else if (err instanceof Error) {
                throw new SignallingError(err.message, peerUUID);
            } else {
                throw new SignallingError("An unknown error occurred", peerUUID);
            }
        }
    }

    public async getDataChannel(): Promise<RTCDataChannel> {
        await this.createOffer();
        return new Promise<RTCDataChannel>((resolve, reject) => {
            this.signallingTimeoutID = window.setTimeout(() => {
                this.socket.send({
                    // @ts-ignore
                    type: 'signallingComplete',
                });
                reject('Signalling initiator timeout');
            }, SIGNALLING_TIMEOUT_MS)

            const onOpen = () => {
                console.log(`data channel open`)
                resolve(this.dataChannel);
                this.socket.send({
                    // @ts-ignore TODO: add to consts in SignallingServerSession
                    type: 'signallingComplete',
                });
            };
            this.dataChannel.addEventListener('open', onOpen);
            this.removeDataChannelOpeListener = () => this.dataChannel.removeEventListener('open', onOpen);
        });
    }

    protected afterCleanup() {
        if (this.removeAnswerListener) this.removeAnswerListener();
        if (this.removeDataChannelOpeListener) this.removeDataChannelOpeListener();
    }

    private async createOffer() {
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
        this.listenForAnswer();
        this.socket.send({
            // @ts-ignore
            type: PeerOfferEventType,
            payload: offer
        })
    }

    private listenForAnswer() {
        this.removeAnswerListener = this.socket.onMessage(async (message) => {
            // @ts-ignore
            if (message.type === PeerAnswerEventType) {
                // @ts-ignore
                const remoteDesc = new RTCSessionDescription(message.payload);
                console.log(this.peerConnection.connectionState);
                await this.peerConnection.setRemoteDescription(remoteDesc);
                this.sendLocalICECandidates();
            }
        })
    }
}