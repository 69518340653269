import React from "react";
import classNames from "classnames";
import {Draggable, Droppable} from "react-beautiful-dnd";
import MasterCategoryLabel from "../CategoryLabel/MasterCategoryLabel";
import CategoryLabel from "../CategoryLabel/CategoryLabel";
import HiddenCategoriesLabel from "../CategoryLabel/HiddenCategoriesLabel";
import {BudgetableMasterCategory} from "../../store/categories/hooks/useBudgetableCategories";
import {useSelector} from "react-redux";
import {hasHiddenCategories} from "../../store/categories/selectors";

type Props = {
    budgetableMasterCategories: BudgetableMasterCategory[]
}

const BudgetSheetCategoryRows = (props: Props) => {
    const { budgetableMasterCategories } = props;

    const hasAnyHiddenCategories = useSelector(hasHiddenCategories);

    return <div className={'budget-sheet__category-rows'}>
        {budgetableMasterCategories.map((masterCat, index) => {
            if (masterCat.hidden) {
                return null;
            }

            return (
                <Droppable droppableId={masterCat.uuid} key={masterCat.uuid} type={'MASTER_CATEGORY'}>
                    {(droppableProvided) => (
                        <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                        >
                            <DraggableMasterCategory masterCategory={masterCat} index={index}/>
                            {droppableProvided.placeholder}
                        </div>
                    )}

                </Droppable>
            );
        })}
        {hasAnyHiddenCategories && <HiddenCategoriesLabel/>}
    </div>
}

type DraggableMasterCategoryProps = {
    masterCategory: BudgetableMasterCategory
    index: number
}

const DraggableMasterCategory = (props: DraggableMasterCategoryProps) => {
    const masterCat = props.masterCategory;
    const masterCatLabelClasses = classNames({
        'budget-sheet__budget-category-row': true,
        'budget-sheet__budget-category-row--first': props.index === 0,
    });

    return <Draggable draggableId={masterCat.uuid} index={props.index}>
        {(draggableProvided) => (
            <div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
            >
                <div className={masterCatLabelClasses} {...draggableProvided.dragHandleProps}>
                    <MasterCategoryLabel masterCategory={masterCat} />
                </div>
                <DroppableSubCategoryArea masterCategory={masterCat}/>
            </div>
        )}
    </Draggable>
}

type DroppableSubCategoryProps = {
    masterCategory: BudgetableMasterCategory
}

const DroppableSubCategoryArea = (props: DroppableSubCategoryProps) => {
    const masterCat = props.masterCategory;

    return <Droppable droppableId={masterCat.uuid} type={`SUB_CATEGORY`}>
        {(innerDroppableProvided) => (
            <div
                ref={innerDroppableProvided.innerRef}
                {...innerDroppableProvided.droppableProps}
            >
                {masterCat.subCategories.map((subCategory, index) => {
                    if (subCategory.hidden) {
                        return null;
                    }
                    return (
                        <div className={'budget-categories__row'} key={subCategory.uuid}>
                            <CategoryLabel category={subCategory} index={index }/>
                        </div>
                    );
                })}
                {innerDroppableProvided.placeholder}
            </div>
        )}
    </Droppable>
}


export default BudgetSheetCategoryRows;