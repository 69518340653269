import React, {CSSProperties} from "react";

type Props = {
    style?: CSSProperties;
}

const SelectionColumn = (props: Props) => {
    return <div
        className={'add-transaction-row__main-row-cell'}
        style={props.style}
    />
}

export default SelectionColumn;