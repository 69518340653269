import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    currency: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string().required(),
        })
        .nullable()
        .required('Required'),
});

export default validationSchema;