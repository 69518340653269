import {StoreActions} from "../../types";
import * as SettingsActions from "../actions/updatePrompt";
import { Workbox } from 'workbox-window';

export type UpdatePromptIsOpenState = boolean;
export const updatePromptIsOpen = (state: UpdatePromptIsOpenState = false, action: StoreActions) => {
	switch (action.type) {
		case SettingsActions.SHOW_UPDATE_PROMPT_MODAL:
			return true;
		case SettingsActions.CLOSE_UPDATE_PROMPT_MODAL:
			return false;
		default:
			return state;
	}
};

export type WorkboxInstanceState = Workbox | null;
export const workboxInstance = (state: WorkboxInstanceState = null, action: StoreActions) => {
	switch (action.type) {
		case SettingsActions.SHOW_UPDATE_PROMPT_MODAL:
			return action.payload.workbox;
		case SettingsActions.CLOSE_UPDATE_PROMPT_MODAL:
			return null;
		default:
			return state;
	}
};