import { set, get, del } from 'idb-keyval';
import {IAppSettingsRepo, SettingsKeys} from '../../../../domain/settings/IAppSettingsRepo';

class AppSettingsRepo implements IAppSettingsRepo {
    get<T>(settingKey: SettingsKeys): Promise<T|undefined> {
        return get<T>(settingKey);
    }

    set<T>(settingKey: SettingsKeys, value: any): Promise<T> {
        return set(settingKey, value).then(() => value);
    }

    del(settingKey: SettingsKeys): Promise<void> {
        return del(settingKey);
    }
}

export default new AppSettingsRepo();
