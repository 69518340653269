import { Field, useFormikContext } from 'formik';
import {
	FormValues,
	isTransferOutOfAccount,
} from '../AddEditTransactionForm/AddEditTransactionForm';
import { useParams } from 'react-router-dom';
import useAccountOptions, { AccountOption } from '../../../store/accounts/hooks/useAccountOptions';
import SelectField, { SIZE_SMALL } from '../../../common/Form/SelectField';
import React, { CSSProperties } from 'react';
import {Account} from "../../../../domain/accounts/Account";

type Props = {
	style?: CSSProperties;
};

const TransferFromAccountColumn = (props: Props) => {
	const { setFieldValue, values } = useFormikContext<FormValues>();
	const { accountUUID } = useParams<{accountUUID: Account['uuid']}>();
	const accountOptions = useAccountOptions();

	if (!accountUUID) {
		return null;
	}

	return (
		<div className={'add-transaction-row__main-row-cell'} style={props.style}>
			<Field
				name="account"
				as={SelectField}
				options={accountOptions}
				onChange={(newValue: AccountOption) => setFieldValue('account', newValue)}
				size={SIZE_SMALL}
				placeholder={'Transfer from account...'}
				className={'add-transaction-row__select'}
				disabled={accountUUID && isTransferOutOfAccount(values, accountUUID)}
			/>
		</div>
	);
};

export default TransferFromAccountColumn;
