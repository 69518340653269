import { RefObject, useEffect, useRef } from 'react';

const useElementResizeHook = <T extends HTMLElement>(
	listener?: (width: number, height: number) => void
): RefObject<T> => {

	const ref = useRef(null);
	useEffect(() => {
		if (ref.current === null) {
			return;
		}

		if (!listener) {
			return;
		}
		// @ts-ignore
		const ro = new ResizeObserver(entries => {
			for (let entry of entries) {
				const cr = entry.contentRect;
				// console.log('Element:', entry.target);
				// console.log(`Element size: ${cr.width}px x ${cr.height}px`);
				// console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
				listener(Math.round(cr.width), Math.round(cr.height));
			}
		});

		// Observe one or multiple elements
		ro.observe(ref.current);

		return () => {
			ro.disconnect();
		}
	}, [listener]);

	return ref;
};

export default useElementResizeHook;
