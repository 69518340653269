import React, { ChangeEvent } from 'react';

export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_NUMBER = 'number';
export type INPUT_TYPES = typeof INPUT_TYPE_NUMBER | typeof INPUT_TYPE_TEXT;

type Props = {
	id?: string;
	label?: string;
	placeholder?: string;
	onChange?: (newValue: string) => void;
	className?: string;
	value?: string;
	name?: string;
	type?: INPUT_TYPES;
};

export function InputField(props: Props) {
	const propClassName = props.className || '';
	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (props.onChange) {
			props.onChange(e.target.value);
		}
	};

	const inputProps = {
		id: props.id || undefined,
		name: props.name || undefined,
		value: props.value,
		placeholder: props.placeholder || undefined,
		onChange: onChange,
		type: props.type || INPUT_TYPE_TEXT,
		className: propClassName,
	};

	return <input {...inputProps} />;
}
