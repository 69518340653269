import React, {CSSProperties, useCallback, useMemo} from 'react';
import AddEditTransactionForm, {
	FormValues,
	splitTransaction,
} from '../AddEditTransactionForm/AddEditTransactionForm';
import { ColumnInstance, Row } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import {
	COL_ID_ACCOUNT,
	COL_ID_CATEGORY,
	COL_ID_DATE,
	COL_ID_INFLOW,
	COL_ID_NOTE,
	COL_ID_OUTFLOW,
	COL_ID_PAYEE,
	COL_ID_SELECTION,
	COL_ID_STATUS, ColumnID,
} from '../../TransactionsTable/useTableColumns/useTableColumns';
import { Form, useFormikContext } from 'formik';
import useCategoryOptions from '../../../store/categories/hooks/useCategoryOptions';
import SplitRow from '../../AddTransactionRow/SplitRow';
import SplitRowFooter from '../../AddTransactionRow/SplitRowFooter';
import Button, { ButtonGroup } from '../../../common/Button';
import { useSelector } from 'react-redux';
import { getAllAccounts } from '../../../store/accounts/selectors/accounts';
import { useParams } from 'react-router-dom';
import useElementResizeHook from '../../../common/hooks/useElementResizeHook';
import SelectionColumn from './SelectionColumn';
import DateColumn from './DateColumn';
import AccountColumn from './AccountColumn';
import PayeeColumn from './PayeeColumn';
import CategoryColumn from './CategoryColumn';
import NoteColumn from './NoteColumn';
import InflowColumn from './InflowColumn';
import OutflowColumn from './OutflowColumn';
import StatusColumn from './StatusColumn';
import TransferFromAccountColumn from "./TransferFromAccountColumn";
import TransferToAccountColumn from "./TransferToAccountColumn";
import {Account} from "../../../../domain/accounts/Account";
import './add-edit-transaction-row.less';

type Props = {
	onSave: () => void;
	onCancel: () => void;
	row?: Row<Transaction>;
	columns: ColumnInstance<Transaction>[];
	style: CSSProperties;
	onHeightChange: (height: number) => void;
	isTransfer?: boolean;
};

export type ColStyle = {
	width: string | number | undefined;
	minWidth: number | undefined;
	maxWidth: number | undefined;
};

const AddEditTransactionRow = (props: Props) => {
	return (
		<AddEditTransactionForm
			onSave={props.onSave}
			onCancel={props.onCancel}
			transaction={props.row?.original}
			isTransfer={props.isTransfer}
		>
			<RowForm {...props} />
		</AddEditTransactionForm>
	);
};

export default AddEditTransactionRow;

const RowForm = ({ columns, onHeightChange, row, style, isTransfer, onCancel }: Props) => {
	const { values, setFieldValue, handleSubmit } = useFormikContext<FormValues>();
	const categoryOptions = useCategoryOptions();
	const accounts = useSelector(getAllAccounts);
	const { accountUUID } = useParams<{accountUUID: Account['uuid']}>();
	const account = accountUUID ? accounts.get(accountUUID) : null;

	const colStylesMap: Map<string, ColStyle> = useMemo(() => {
		const columnStyles = columns.map(col => [
			col.id,
			{
				width: col.width,
				minWidth: col.minWidth,
				maxWidth: col.maxWidth,
			} as ColStyle,
		]) as [[string, ColStyle]];
		return new Map<string, ColStyle>(columnStyles);
	}, [columns])

	const resizeCallback = useCallback(
		(width: number, height: number) => {
			if (height === 0) {
				return;
			}
			onHeightChange(height);
		},
		[onHeightChange]
	);
	const ref = useElementResizeHook<HTMLDivElement>(resizeCallback);
	const showCategoryCol = !accountUUID || !account?.isOffBudget;
	return (
		<Form className={'add-transaction-row'} style={style}>
			<div className={'add-transaction-row-split-wrap'} ref={ref}>
				<div className={'add-transaction-row__split-row'}>
					<SelectionColumn style={colStylesMap.get(COL_ID_SELECTION)} />
					<div style={colStylesMap.get(ColumnID.COL_ID_TAGS)} />
					<DateColumn style={colStylesMap.get(COL_ID_DATE)} />
					{!accountUUID && (
						<TransferFromAccountColumn
							style={colStylesMap.get(COL_ID_PAYEE)}
						/>
					)}
					{!accountUUID && (
						<AccountColumn
							isTransfer={!!isTransfer}
							style={colStylesMap.get(COL_ID_ACCOUNT)}
						/>
					)}
					{!isTransfer && (
						<PayeeColumn style={colStylesMap.get(COL_ID_PAYEE)} isTransfer={!!isTransfer} />
					)}
					{isTransfer && (
						<TransferToAccountColumn style={colStylesMap.get(COL_ID_PAYEE)} />
					)}
					{showCategoryCol && (
						<CategoryColumn
							style={colStylesMap.get(COL_ID_CATEGORY)}
							className={'add-transaction-row__main-row-cell'}
						/>
					)}
					<NoteColumn style={colStylesMap.get(COL_ID_NOTE)} />
					<OutflowColumn style={colStylesMap.get(COL_ID_OUTFLOW)} />
					<InflowColumn style={colStylesMap.get(COL_ID_INFLOW)} />
					<StatusColumn style={colStylesMap.get(COL_ID_STATUS)} />
					<input type="submit" hidden />
				</div>
				{values.splits.map((v, k) => (
					<SplitRow
						key={k}
						categoryOptions={categoryOptions}
						setFieldValue={setFieldValue}
						splitNum={k}
						removeSplit={splitNum => {
							if (values.splits.length <= 2) {
								setFieldValue(`splits`, []);
								return;
							}
							const newSplits = values.splits.filter((split, index) => {
								return index !== splitNum;
							});
							console.log(splitNum, values.splits, newSplits);
							setFieldValue(`splits`, [...newSplits]);
						}}
						columns={columns}
					/>
				))}
				{values.splits.length > 0 && (
					<SplitRowFooter
						transactionInflow={values.inflow}
						transactionOutflow={values.outflow}
						splits={values.splits}
						columnStyles={colStylesMap}
					/>
				)}
				<div className={'add-transaction-row__row-actions'}>
					<ButtonGroup size={'tiny'}>
						<Button
							icon="cancel"
							content="Stop"
							onClick={e => {
								onCancel();
								e.preventDefault();
							}}
						/>
						<Button
							icon="clone outline"
							content={values.splits.length === 0 ? 'Split' : 'Add Split'}
							onClick={e => {
								e.preventDefault();
								splitTransaction(values, setFieldValue);
							}}
						/>
						<Button
							icon="checkmark"
							content="Save"
							onClick={() => handleSubmit}
						/>
					</ButtonGroup>
				</div>
			</div>
		</Form>
	);
};
