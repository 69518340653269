import isEqual from "lodash/isEqual";
import papaparse from "papaparse";

export enum YnabVersion {
    VERSION_YNAB_CLASSIC = 'ynab_classic',
    VERSION_YNAB_NEW = 'ynab_new',
}

const YNAB_CLASSIC_BUDGET_HEADER_ROW = [
    'Month',
    'Category',
    'Master Category',
    'Sub Category',
    'Budgeted',
    'Outflows',
    'Category Balance',
];

const YNAB_CLASSIC_REGISTER_HEADER_ROW = [
    'Account',
    'Flag',
    'Check Number',
    'Date',
    'Payee',
    'Category',
    'Master Category',
    'Sub Category',
    'Memo',
    'Outflow',
    'Inflow',
    'Cleared',
    'Running Balance',
];

const YNAB_NEW_BUDGET_HEADER_ROW = [
    'Month',
    'Category Group/Category',
    'Category Group',
    'Category',
    'Budgeted',
    'Activity',
    'Available',
];

const YNAB_NEW_REGISTER_HEADER_ROW = [
    'Account',
    'Flag',
    'Date',
    'Payee',
    'Category Group/Category',
    'Category Group',
    'Category',
    'Memo',
    'Outflow',
    'Inflow',
    'Cleared',
];

const checkYNABVersion = (budgetCsvText: string, registerCsvText: string): YnabVersion|null => {
    // parse lines to array
    const parsedBudgetHeader = papaparse.parse(budgetCsvText, { preview: 1 });
    const parsedRegisterHeader = papaparse.parse(registerCsvText, { preview: 1 });

    if (isEqual(parsedBudgetHeader.data[0], YNAB_CLASSIC_BUDGET_HEADER_ROW)
        && isEqual(parsedRegisterHeader.data[0], YNAB_CLASSIC_REGISTER_HEADER_ROW)
    ) {
        return YnabVersion.VERSION_YNAB_CLASSIC;
    }

    if (isEqual(parsedBudgetHeader.data[0], YNAB_NEW_BUDGET_HEADER_ROW)
        && isEqual(parsedRegisterHeader.data[0], YNAB_NEW_REGISTER_HEADER_ROW)
    ) {
        return YnabVersion.VERSION_YNAB_NEW;
    }

    return null;
}

export default checkYNABVersion;