import React from 'react';

const HiddenCategoriesLabel = () => {
	return (
		<div className={'budget-categories__row'} key={'hidden'}>
			<div className={'category-label category-label--hidden-categories'}>
				<span className={'category-label__text category-label__text--hidden-categories'}>
					Hidden Categories
				</span>
			</div>
		</div>
	);
};

export default HiddenCategoriesLabel;
