import React, { useState } from 'react';
import ConfirmModal, { AdvancedModal } from '../../common/Modal';
import { Button } from '../../common/Button/Button';
import { Icon } from 'semantic-ui-react';
import { ErrorMessage, Field, Formik } from 'formik';
import { Profile } from '../../../domain/profiles';
import TextField from '../../common/Form/TextField';
import editProfile from '../../../domain/profiles/operations/editProfile';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { editProfile as editProfileAction, softDeleteProfileAction } from '../../store/profiles/actions';
import validationSchema from './validationSchema';
import { AppDispatch } from '../../store/types';
import getProfile from '../../../domain/profiles/operations/getProfile';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import useCurrencyOptions, { CurrencyOption, LoadingState } from '../../settings/hooks/useCurrencyOptions';
import SelectField from '../../common/Form/SelectField';
import './edit-profile-modal.less';

type Props = {
	profile: Profile;
	isOpen: boolean;
	onClose: () => void;
};

type FormValues = {
	name: Profile['name'];
	currency: CurrencyOption | null
};

const EditProfileModal = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();
	const [isDeleteConfirmShowing, setIsDeleteConfirmShowing] = useState(false);
	const [loadingCurrencies, currencyOptions] = useCurrencyOptions(props.isOpen);

	return (
		<Formik
			enableReinitialize={true}
			validationSchema={validationSchema}
			initialValues={getInitialValues(props.profile, currencyOptions)}
			onSubmit={(values, formik) => {
				console.log(values)
				const updatedProfile = editProfile(props.profile, getUpdatedProfileFromForm(values));
				dispatch(editProfileAction(updatedProfile));
				props.onClose();
			}}
		>
			{({ isValid, handleSubmit, setFieldValue }) => (
				<>
					<AdvancedModal
						open={props.isOpen}
						actions={
							<>
								<Button
									onClick={() => setIsDeleteConfirmShowing(true)}
									type={'warning'}
								>
									Delete
								</Button>
								<Button onClick={() => props.onClose()}>
									<Icon name="remove" /> Cancel
								</Button>
								<Button type={'success'} onClick={handleSubmit} disabled={!isValid}>
									<Icon name="checkmark" /> Save
								</Button>
							</>
						}
						title={'Edit Profile'}
						onClose={props.onClose}
					>
						<Field name="name" as={TextField} label={'Name'} />
						<ErrorMessage name={'name'}/>
						<Field name="currency"
							   className={'edit-profile-modal__currency-selector'}
							   as={SelectField}
							   label={'Default Currency'}
							   options={currencyOptions}
							   onChange={(newOption: CurrencyOption) =>
								   setFieldValue('currency', newOption)
							   }
							   disabled={loadingCurrencies !== LoadingState.LOADED}
							   placeholder={loadingCurrencies !== LoadingState.LOADED ? "Loading currencies..." : "Select a currency"}
						/>
						<ErrorMessage name={'currency'}/>
					</AdvancedModal>
					<ConfirmModal
						title={'Delete Profile'}
						open={isDeleteConfirmShowing}
						onSecondaryClick={() => setIsDeleteConfirmShowing(false)}
						onPrimaryClick={() => {
							dispatch(softDeleteProfileAction(props.profile.uuid)).then(async () => {
								// TODO: Handle deleting your only profile
								const profile = await getProfile();
								setIsDeleteConfirmShowing(false);
								props.onClose();
								history.push(APP_LINKS.budget(profile.uuid));
							})
						}}
						onClose={() => setIsDeleteConfirmShowing(false)}
					>
						Are you sure you want to delete {props.profile.name}?
					</ConfirmModal>
				</>
			)}
		</Formik>
	);
};

export default EditProfileModal;

const getInitialValues = (profile: Profile, currencyOptions: CurrencyOption[]) => {
	return {
		name: profile.name,
		currency: currencyOptions.find(opt => opt.value === profile.currency) || null,
	};
};

const getUpdatedProfileFromForm = (formValues: FormValues) => {
	return Object.assign(formValues, { currency: formValues.currency?.value })
}

