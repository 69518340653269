import { useSelector } from 'react-redux';
import { legacyToMoney } from '../../../../../domain/util/money';
import useRequiredActiveProfile from '../../../profiles/hooks/useRequiredActiveProfile';
import { getStatementBalance } from '../../selectors/reconciling';
import useAccountBeingReconciled from './useAccountBeingReconciled';

const useStatementBalance = () => {
	const profile = useRequiredActiveProfile();
	const accountBeingReconciled = useAccountBeingReconciled();
	if (!accountBeingReconciled) {
		throw new Error("Cannot get reconciliation statement balance when a reconciliation is not in progress");
	}
	const statementBalance = useSelector(getStatementBalance) || 0;
	return legacyToMoney(statementBalance, profile.currency)
}

export default useStatementBalance;