import deleteDeviceApi from '../../infrastructure/sync/api/deleteDevice';
import {SyncDevice} from "./SyncDevice";
import handleDeletedDevice from "./handleDeletedDevice";

const deleteDevice = async (deviceUUID: SyncDevice['uuid']) => {
    const deleted = await deleteDeviceApi(deviceUUID)
    if (!deleted) {
        throw new Error(`Unable to delete device ${deviceUUID}`);
    }

    await handleDeletedDevice(deviceUUID);
}

export default deleteDevice;