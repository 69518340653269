import {Profile} from "../../profiles";
import CategoriesRepo from "../../../infrastructure/persistence/indexed-db/categories/CategoriesRepo";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";

const resetAllCategoryOrder = async (profileUUID: Profile['uuid']) => {
    const masterCategories = await CategoriesRepo.getAllMasterCategories(profileUUID);
    const subCategories = await CategoriesRepo.getAllSubCategories(profileUUID);

    const masterCatOrder = sortBy(masterCategories, ['name']).map((masterCat) => masterCat.uuid);
    await CategoriesRepo.setMasterCategoryOrder(masterCatOrder);

    const groupedSubCats = groupBy(subCategories, 'masterCategoryId');
    for (const masterCatUUID in groupedSubCats) {
        const subCategories = groupedSubCats[masterCatUUID];
        const subCatOrder = sortBy(subCategories, ['name']).map((subCat) => subCat.uuid);
        await CategoriesRepo.setSubCategoryOrder(masterCatUUID, subCatOrder);
    }
}

export default resetAllCategoryOrder;