import React from 'react';
import { Icon } from 'semantic-ui-react';
import Button from '../../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { showSideNav } from '../../store/nav/actions';
import { isSideNavHideable } from '../../store/nav/selectors/sideNav';
import './top-nav.less';

type Props = {
	children?: React.ReactNode
	title?: string
}

const TopNav = (props: Props) => {
	const dispatch = useDispatch();
	const sideNavHideable = useSelector(isSideNavHideable);

	if (!sideNavHideable) {
		return null;
	}

	return (
		<nav className={'top-nav'}>
			<Button icon size={'big'} onClick={() => dispatch(showSideNav())} primary>
				<Icon name="bars" />
			</Button>
			<div className={'top-nav__content'}>
				<Title title={props.title}/>
				{ props.children }
			</div>
		</nav>
	);
};

const Title = (props: {title: string|undefined}) => {
	if (props.title) {
		return <h1 className={'top-nav__title'}>
			<span>{props.title}</span>
		</h1>
	}

	return null;
}

export default TopNav;
