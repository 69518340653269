type SortReturn = 0 | 1 | -1;
type SortObject = string | number | {[key: string]: string}

type SortableValue = string | number;
type AccessorFn<R = SortableValue> = (v: any) => R;
const defaultAccessor = <R = SortableValue>(v: R):R => v;

export const alpha = (accessor: AccessorFn = defaultAccessor) => {
    return (a: SortObject, b: SortObject): SortReturn => {
        if (accessor(a) < accessor(b)) {
            return -1;
        }

        if (accessor(a) > accessor(b)) {
            return 1;
        }

        return 0;
    }
}

export const alphaStartsWith = (startsWith: string, accessor: AccessorFn<string> = defaultAccessor) => {
    const alphaSorter = alpha(accessor);
    return (a: SortObject, b: SortObject) => {
        const aLowCase = accessor(a).toLowerCase();
        const bLowCase = accessor(b).toLowerCase();
        const startsWithLowCase = startsWith.toLowerCase();

        if ((!aLowCase.toLowerCase().startsWith(startsWithLowCase) && !bLowCase.startsWith(startsWithLowCase))
            || (aLowCase.toLowerCase().startsWith(startsWithLowCase) && bLowCase.startsWith(startsWithLowCase))
        ) {
            return alphaSorter(a, b)
        }

        if (aLowCase.toLowerCase().startsWith(startsWithLowCase) && !bLowCase.startsWith(startsWithLowCase)) {
            return -1;
        }

        if (!aLowCase.startsWith(startsWithLowCase) && bLowCase.startsWith(startsWithLowCase)) {
            return 1;
        }

        return 0;
    }
}