import { RootState } from '../../types';
import {
	INCOME_SUB_CATEGORY,
	TRANSFER_SUB_CATEGORY,
	INCOME_NEXT_MONTH_SUB_CATEGORY,
	SubCategory,
} from '../../../../domain/categories';
import {CategoryBudget} from "../../../../domain/budget";
import { requireActiveProfile } from "../../profiles/selectors";

export const getAllUserCategories = (state: RootState) => {
	return state.categories.subCategories;
};

// Includes built in categories
export const getAllCategories = (state: RootState): Map<SubCategory['uuid'], SubCategory> => {
	const userCategories = new Map(state.categories.subCategories.entries());
	userCategories.set(TRANSFER_SUB_CATEGORY.uuid, TRANSFER_SUB_CATEGORY);
	userCategories.set(INCOME_SUB_CATEGORY.uuid, INCOME_SUB_CATEGORY);
	userCategories.set(INCOME_NEXT_MONTH_SUB_CATEGORY.uuid, INCOME_NEXT_MONTH_SUB_CATEGORY);
	return userCategories;
};


export const getHiddenCategories = (state: RootState) => {
	const profile = requireActiveProfile(state);
	return Array.from(state.categories.subCategories.values()).filter(
		category => category.hidden && category.profileUUID === profile.uuid
	);
};

export const hasHiddenCategories = (state: RootState) => {
	return getHiddenCategories(state).length > 0;
}

export const getCustomCategories = (state: RootState) => {
	return state.categories.subCategories;
};

export const getAllMasterCategories = (state: RootState) => {
	return state.categories.masterCategories;
};

export const getOrderedSubCategoryAssignments = (state: RootState) => {
	return state.categories.orderedSubCategoryAssignments;
};

export const getMasterCategoryOrder = (state: RootState) => {
	return state.categories.orderedMasterCategories;
};

export const createBudgetSelector = (
	year: number,
	month: number,
	categoryId: SubCategory['uuid']
): (state: RootState) => CategoryBudget | undefined => {
	return (state: RootState): CategoryBudget | undefined => {
		return state.categories.categoryBudgets.get(`${year}-${month}:${categoryId}`);
	};
};

export const isAddMasterCategoryModalOpen = (state: RootState) => state.categories.addMasterCategoryModalOpen;

export const hasCreatedAnyCategories = (state: RootState) => state.categories.masterCategories.size !== 0;
