import {
	ADD_MASTER_CATEGORY,
	LOAD_MASTER_CATEGORY,
	SET_MASTER_CATEGORY_ORDER,
	UPDATE_MASTER_CATEGORY,
} from '../actions/masterCategories';
import { StoreActions } from '../../types';
import { MasterCategory } from '../../../../domain/categories';
import { RESET_ALL_CATEGORIES } from '../actions';

export type MasterCategoriesState = Map<MasterCategory['uuid'], MasterCategory>;
export const masterCategories = (
	state: MasterCategoriesState = new Map(),
	action: StoreActions
) => {
	switch (action.type) {
		case RESET_ALL_CATEGORIES:
			const all: Array<[MasterCategory['uuid'], MasterCategory]> = action.payload.masterCategories.map(cat => [cat.uuid, cat])
			return new Map(all);

		case LOAD_MASTER_CATEGORY:
			state.set(action.payload.masterCategory.uuid, action.payload.masterCategory);
			return new Map(state.entries());

		case ADD_MASTER_CATEGORY:
			const masterCategory = action.payload.masterCategory;
			if (state.get(masterCategory.uuid) !== undefined) {
				throw new Error(`Master category with uuid:${masterCategory.uuid} already exists`);
			}
			state.set(masterCategory.uuid, masterCategory);
			return new Map(state.entries());

		case UPDATE_MASTER_CATEGORY:
			state.set(action.payload.masterCategory.uuid, action.payload.masterCategory);
			return new Map(state.entries());

		default:
			return state;
	}
};

export type OrderedMasterCategoriesState = Array<MasterCategory['uuid']>;
export const orderedMasterCategories = (
	state: OrderedMasterCategoriesState = [],
	action: StoreActions
): OrderedMasterCategoriesState => {
	switch (action.type) {
		case RESET_ALL_CATEGORIES:
			return action.payload.masterCategoryOrder;

		case SET_MASTER_CATEGORY_ORDER:
			return action.payload.order;

		default:
			return state;
	}
};
