import { MasterCategoryNameMap, isIgnoredCategory } from './masterCategories';
import { SubCategoryNameMap } from './subCategories';
import { CategoryBudget } from '../../../budget';
import { getLastMonth, Month } from '../../../util/date';
import { SubCategory } from '../../../categories';
import setCarryover from "../../../categories/operations/setCarryover";
import {Profile} from "../../../profiles";
import {BudgetRow} from "./index";
import { getCategoryFromBudgetRow } from './util';

const LONG_MONTHS = {
	January: 1,
	February: 2,
	March: 3,
	April: 4,
	May: 5,
	June: 6,
	July: 7,
	August: 8,
	September: 9,
	October: 10,
	November: 11,
	December: 12,
} as { [monthName: string]: number };

const MONTHS = {
	Jan: 1,
	Feb: 2,
	Mar: 3,
	Apr: 4,
	May: 5,
	Jun: 6,
	Jul: 7,
	Aug: 8,
	Sep: 9,
	Oct: 10,
	Nov: 11,
	Dec: 12,
} as { [monthName: string]: number };


export const getCategoryBudgets = (
	budgetRows: BudgetRow[],
	masterCategories: MasterCategoryNameMap,
	subCategories: SubCategoryNameMap,
	profile: Profile
): CategoryBudget[] => {
	const budgetRowsMap = createBudgetRowsMap(budgetRows, subCategories);
	const initialMap: Map<string, CategoryBudget> = new Map();
	const categoryBudgets = budgetRows.reduce((acc, row) => {
		if (isIgnoredCategory(row)) {
			return acc;
		}

		const month = getMonthFromBudgetRow(row);
		const subCategory = getSubcategoryFromRow(row, subCategories);

		if (didLastMonthsBalanceCarryover(row, subCategory.uuid, budgetRowsMap)) {
			acc = setLastMonthsCategoryBudgetCarryover(month, subCategory.uuid, acc);
		}

		const categoryBudget: CategoryBudget = {
			profileUUID: profile.uuid,
			year: month.year,
			month: month.month,
			amount: row.budgeted,
			categoryId: subCategory.uuid,
			carryover: false
		};

		acc.set(`${month.year}-${month.month}:${subCategory.uuid}`, categoryBudget);
		return acc;
	}, initialMap)

	return Array.from(categoryBudgets.values());
};

const getSubCategoryName = (row: BudgetRow) => {
	const [, subCategoryName] = getCategoryFromBudgetRow(row);
	return subCategoryName;
};

const createBudgetRowsMap = (budgetRows: BudgetRow[], subCategories: SubCategoryNameMap) => {
	const initial: Map<string, BudgetRow> = new Map();
	return budgetRows.reduce((acc, row) => {
		if (isIgnoredCategory(row)) {
			return acc;
		}
		const month = getMonthFromBudgetRow(row);
		const subCategory = getSubcategoryFromRow(row, subCategories);

		acc.set(`${month.year}-${month.month}:${subCategory.uuid}`, row);
		return acc;
	}, initial);
};

const getMonthFromBudgetRow = (budgetRow: BudgetRow): Month => {
	const [monthName, stringYear] = budgetRow.month.includes('-') ? budgetRow.month.split('-') : budgetRow.month.split(' ');
	const month = LONG_MONTHS[monthName] || MONTHS[monthName];
	const longYear =  stringYear.length === 2 ? `20${stringYear}` : stringYear
	const year = Number(longYear);
	return { year, month };
};

const getSubcategoryFromRow = (
	budgetRow: BudgetRow,
	subCategories: SubCategoryNameMap
): SubCategory => {
	const subCategoryName = getSubCategoryName(budgetRow);
	const subcategory = subCategoryName ? subCategories.get(subCategoryName) : null;

	if (!subcategory) {
		throw new Error(`Can't find sub category for category budget`);
	}

	return subcategory;
};

const getLastMonthsBudgetRow = (
	thisMonth: Month,
	subCategoryUUID: SubCategory['uuid'],
	budgetRowsMap: Map<string, BudgetRow>
) => {
	const lastMonth = getLastMonth(thisMonth);
	return budgetRowsMap.get(`${lastMonth.year}-${lastMonth.month}:${subCategoryUUID}`);
};

const didLastMonthsBalanceCarryover = (
	budgetRow: BudgetRow,
	subCategoryUUID: SubCategory['uuid'],
	budgetRowsMap: Map<string, BudgetRow>
): boolean => {
	const thisMonth = getMonthFromBudgetRow(budgetRow);
	const lastMonthsBudgetRow = getLastMonthsBudgetRow(thisMonth, subCategoryUUID, budgetRowsMap);
	if (!lastMonthsBudgetRow) {
		return false;
	}

	const lastMonthsBalance = lastMonthsBudgetRow.categoryBalance;

	if (lastMonthsBalance >= 0) {
		return false;
	}

	const thisMonthsBudgeted = budgetRow.budgeted;
	const thisMonthsOutflows = budgetRow.outflows;
	const thisMonthsBalance = budgetRow.categoryBalance;

	// TODO: Floating point issues cause this to be false when it shouldn't be
	// return (lastMonthsBalance + thisMonthsBudgeted - thisMonthsOutflows) === thisMonthsBalance;

	const expectedCarryOverBalance = lastMonthsBalance + thisMonthsBudgeted - thisMonthsOutflows;
	// Will round as a temp fix instead
	return (Math.round((expectedCarryOverBalance + Number.EPSILON) * 100) / 100) === thisMonthsBalance;
};

const setLastMonthsCategoryBudgetCarryover = (thisMonth: Month, subCategoryUUID: SubCategory['uuid'], categoryBudgets: Map<string, CategoryBudget>) => {
	const lastMonth = getLastMonth(thisMonth);
	const lastMonthCategoryBudget = categoryBudgets.get(`${lastMonth.year}-${lastMonth.month}:${subCategoryUUID}`);
	if (!lastMonthCategoryBudget) {
		throw new Error(`Can't find last months category budget for year:${lastMonth.year} month:${lastMonth.month}`);
	}
	const updatedCategoryBudget = setCarryover(lastMonthCategoryBudget, true);
	categoryBudgets.set(`${lastMonth.year}-${lastMonth.month}:${subCategoryUUID}`, updatedCategoryBudget);
	return categoryBudgets;
}
