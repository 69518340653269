import React, { CSSProperties } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FormValues } from '../AddEditTransactionForm/AddEditTransactionForm';
import CurrencyInput from "../../../common/Form/CurrencyInput";

type Props = {
	style?: CSSProperties;
};

const OutflowColumn = (props: Props) => {
	const { setFieldValue } = useFormikContext<FormValues>();
	return (
		<div className={'add-transaction-row__main-row-cell'} style={props.style}>
			<Field name="outflow">
				{(props: FieldProps) => (
					<CurrencyInput
						{...props.field}
						name={'outflow'}
						className={'add-transaction-row__input'}
						onChange={() => {
							setFieldValue('inflow', 0);
						}}
						onBlur={(newValue: number) => {
							newValue !== 0 && setFieldValue('inflow', 0);
							setFieldValue('outflow', newValue);
						}}
					/>
				)}
			</Field>
		</div>
	);
};

export default OutflowColumn;
