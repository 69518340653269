import { useSelector } from 'react-redux';
import { getAllUserCategories, getAllMasterCategories, getOrderedSubCategoryAssignments } from '../selectors';
import {
    INCOME_CATEGORY_ID_THIS_MONTH,
    INCOME_CATEGORY_ID_NEXT_MONTH,
    SubCategory,
} from '../../../../domain/categories';
import { useMemo } from 'react';

export type FullCategoryOption = {
    label: string;
    value: SubCategory['uuid'][];
    isMasterCategory: boolean
};

const builtInOptions: FullCategoryOption[] = [
    { label: 'Income', value: [INCOME_CATEGORY_ID_THIS_MONTH, INCOME_CATEGORY_ID_NEXT_MONTH], isMasterCategory: true},
    { label: 'Income this month', value: [INCOME_CATEGORY_ID_THIS_MONTH], isMasterCategory: false },
    { label: 'Income next month', value: [INCOME_CATEGORY_ID_NEXT_MONTH], isMasterCategory: false },
];

const useFullCategoryOptions = (): Array<FullCategoryOption> => {
    const allCategories = useSelector(getAllUserCategories) as Map<SubCategory['uuid'], SubCategory>;
    const allMasterCategories = useSelector(getAllMasterCategories);
    const assignments = useSelector(getOrderedSubCategoryAssignments);

    return useMemo((): FullCategoryOption[] => {
        const customCategories = Array.from(assignments.entries())
            .map(masterCatAssignments => {
                const [masterCategoryId, subCategoryIds] = masterCatAssignments;
                const masterCat = allMasterCategories.get(masterCategoryId);
                if (!masterCat || masterCat.hidden) {
                    return null;
                }

                return [
                    {
                        label: masterCat ? masterCat.name : '',
                        value: subCategoryIds,
                        isMasterCategory: true
                    },
                    ...getSubCategoryOptionsFromIds(subCategoryIds, allCategories)
                ]
            }, [])
            .flat()
            .filter(option => option !== null) as FullCategoryOption[];

        return [...builtInOptions, ...customCategories];
    }, [allCategories, allMasterCategories, assignments]);
};

export default useFullCategoryOptions;

const getSubCategoryOptionsFromIds = (
    subCategoryIds: Array<SubCategory['uuid']>,
    allCategories: Map<SubCategory['uuid'], SubCategory>
): Array<FullCategoryOption> => {
    return subCategoryIds
        .map(categoryId => {
            const cat = allCategories.get(categoryId);
            if (!cat) {
                throw new Error(`Category id:${categoryId} does not exist`);
            }

            if (cat.hidden) {
                return null;
            }

            return {
                label: cat.name,
                value: [cat.uuid],
                isMasterCategory: false
            };
        })
        .filter(option => option !== null) as Array<FullCategoryOption>;
};
