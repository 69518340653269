import {Icon} from "semantic-ui-react";
import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import './sync-bar.less'
import useIsLoggedIn from "../../store/auth/hooks/useIsLoggedIn";
import {useDispatch} from "react-redux";
import {openLoginModal} from "../../store/auth/actions";
import useSyncDevices, { SyncDeviceStatus } from '../../store/app/hooks/useSyncDevices';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import { NavLink } from 'react-router-dom';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import useHasInternetConnection from '../../common/hooks/useHasInternetConnection';

const SyncBar = () => {
    const loggedIn = useIsLoggedIn();
    const dispatch = useDispatch();
    const activeProfile = useRequiredActiveProfile();
    const hasInternet = useHasInternetConnection();
    const syncDeviceStatuses = useSyncDevices();
    const numConnected = syncDeviceStatuses.filter(isConnected).length
    const classes = classNames({
        'sync-bar': true,
        'sync-bar--warning': !loggedIn || !hasInternet,
        'sync-bar--success': loggedIn && numConnected > 0
    })

    const onClick: MouseEventHandler = (e) => {
        if (!loggedIn) {
            e.preventDefault();
            dispatch(openLoginModal());
        }
    }

    return <>
        <NavLink
            to={APP_LINKS.sync(activeProfile.uuid)}
            className={classes}
            onClick={onClick}
        >
            {hasInternet && <Icon
                name={'sync alternate'}
            />}
            {getMessage(loggedIn, hasInternet, syncDeviceStatuses)}
        </NavLink>
    </>
}

export default SyncBar;

const getMessage = (loggedIn: boolean, hasInternet: boolean, syncDeviceStatuses: SyncDeviceStatus[]) => {
    const numOnline = syncDeviceStatuses.filter(isOnline).length
    const numConnected = syncDeviceStatuses.filter(isConnected).length
    const anySyncing = syncDeviceStatuses.filter(isCurrentlySyncing).length > 0

    if (!hasInternet) {
        return "No internet connection";
    }

    if (!loggedIn) {
        return "Sign in to sync data to your other devices";
    }

    if (anySyncing) {
        return `Syncing with ${numConnected} devices`;
    }

    if (numConnected) {
        return `Connected to ${numConnected} devices`;
    }

    return `${numOnline} devices are online`;
}


const isOnline = (syncDevice: SyncDeviceStatus) => {
    return syncDevice.online;
}

const isConnected = (syncDevice: SyncDeviceStatus) => {
    return isOnline(syncDevice) && syncDevice.dataChannelConnected;
}

const isCurrentlySyncing = (syncDevice: SyncDeviceStatus) => {
    return syncDevice.syncStatus === "SYNCING" && syncDevice.dataChannelConnected;
}