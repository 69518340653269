import React from 'react';
import MainNav from '../MainNav/MainNav';
import { useSelector } from 'react-redux';
import { isSideNavShown } from '../../store/nav/selectors/sideNav';
import { CSSTransition } from 'react-transition-group';
import useSideNav from "../../store/nav/hooks/useSideNav";
import './side-bar.less';

const SideBar = () => {
	const isShown = useSelector(isSideNavShown);
	const {hideSideNav} = useSideNav()

	return (<>
		<CSSTransition in={isShown} timeout={500} classNames="slide-animation">
			<aside className={'side-bar'}>
				<MainNav />
			</aside>
		</CSSTransition>
		<CSSTransition in={isShown} timeout={250} classNames="fade-animation">
			<div className={"side-bar__background"} onClick={hideSideNav} />
		</CSSTransition>
	</>);
};

export default SideBar;
