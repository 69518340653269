import {
	getMasterCategoriesMap,
	hideHiddenMasterCategories,
	MasterCategoryNameMap,
} from './masterCategories';
import { getSubCategoriesMap, SubCategoryNameMap } from './subCategories';
import { getCategoryBudgets } from './categoryBudgets';
import { CategoryBudget } from '../../../budget';
import { Profile } from '../../../profiles';
import { BudgetRow } from './index';

export const importBudget = (
	budgetRows: BudgetRow[],
	profile: Profile
): [CategoryBudget[], SubCategoryNameMap, MasterCategoryNameMap] => {
	const masterCategoriesMap = getMasterCategoriesMap(budgetRows, profile);
	const subCategoriesMap = getSubCategoriesMap(budgetRows, masterCategoriesMap, profile);
	const updatedMasterCategories = hideHiddenMasterCategories(
		masterCategoriesMap,
		subCategoriesMap
	);
	const categoryBudgets = getCategoryBudgets(
		budgetRows,
		updatedMasterCategories,
		subCategoriesMap,
		profile
	);
	return [categoryBudgets, subCategoriesMap, updatedMasterCategories];
};
