import { ListenerData, PubSubInterface } from '../../../../infrastructure/pub-sub';
import { recalculateBudget } from '../actions/monthBudget';
import { SET_CATEGORY_BUDGET, SetCategoryBudget } from '../../categories/actions/budgets';

const updateCategoryBudgetListener = (
	actionType: SetCategoryBudget['type'],
	data: ListenerData<SetCategoryBudget>
) => {
	const { dispatch, action } = data;
	const year = action.payload.categoryBudget.year;
	const month = action.payload.categoryBudget.month;
    dispatch(recalculateBudget({ year, month}));
};

const registerListeners = (pubSub: PubSubInterface) => {
	pubSub.subscribe<SetCategoryBudget>(SET_CATEGORY_BUDGET, updateCategoryBudgetListener);
};

export default registerListeners;
