import {AppSettingsRepo, SettingsKeys} from "../../../../domain/settings/IAppSettingsRepo";
import {AppDispatch} from "../../types";

export type SettingsActions = SetSettingsAction;

export type SetSettingsAction = {
    type: 'SET_SETTINGS',
    payload: {
        settingsMap: Map<SettingsKeys, any>
    }
};

export const SET_SETTINGS = 'SET_SETTINGS';
export const setSettings = (settingsMap: Map<SettingsKeys, any>): SetSettingsAction => ({
    type: SET_SETTINGS,
    payload: {
        settingsMap
    }
})

export const loadSettings = () => {
    return async (dispatch: AppDispatch) => {
        const settingsKeyValues: Array<[SettingsKeys, any]> = [];
        for (let key in SettingsKeys) {
            // @ts-ignore
            const settingValue = await AppSettingsRepo.get<any>(key);
            settingsKeyValues.push([key as SettingsKeys, settingValue])
        }

        const allSettings = new Map<SettingsKeys, any>(settingsKeyValues);
        dispatch(setSettings(allSettings));
    }
}