import DolleroWebSocket from "../web-socket/DolleroWebSocket";
import newDeviceHandler from "./web-socket-message-handlers/newDeviceHandler";
import deviceConnectedHandler from "./web-socket-message-handlers/deviceConnectedHandler";
import deviceDeletedHandler from "./web-socket-message-handlers/deviceDeletedHandler";
import { AppDispatch } from '../../view/store/types';
import deviceDisconnectedHandler from './web-socket-message-handlers/deviceDisconnectedHandler';

type MessageListener = Parameters<(typeof DolleroWebSocket)['onMessage']>[0]

const handleSocketMessageProvider = (dispatch: AppDispatch) => {
    const handleSocketMessage: MessageListener = async (message) => {
        console.log('handleSocketMessage', message)
        // TODO: Share these types with the backend somehow
        switch (message.type) {
            case "NEW_DEVICE":
                await newDeviceHandler(dispatch);
                break;
            case "DEVICE_CONNECTED":
                await deviceConnectedHandler(dispatch, message.deviceUUID);
                break;
            case "DEVICE_DISCONNECTED":
                await deviceDisconnectedHandler(dispatch, message.deviceUUID);
                break;
            case "DEVICE_DELETED":
                await deviceDeletedHandler(dispatch, message.deviceUUID);
                break;
        }
    }
    return handleSocketMessage;
}

export default handleSocketMessageProvider;