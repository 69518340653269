import { Transaction } from '../Transaction';
import { SubCategory } from '../../categories';
import isTransfer from '../util/isTransfer';

const assignCategory = (
	transactions: Transaction[],
	subCategoryUUID: SubCategory['uuid']
): Transaction[] => {
	return transactions.map(t => {
		// Can't reassign category for transfers
		if (isTransfer(t)) {
			return t;
		}

		// set category and clear splits
		return Object.assign({}, t, {
			categoryId: [subCategoryUUID],
			splits: [],
		});
	});
};

export default assignCategory;
