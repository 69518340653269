export const SHOW_TRANSACTION_FILTERS = 'SHOW_TRANSACTION_FILTERS';
export const HIDE_TRANSACTION_FILTERS = 'HIDE_TRANSACTION_FILTERS';
export const TOGGLE_TRANSACTION_FILTERS = 'TOGGLE_TRANSACTION_FILTERS';

export type FiltersActions = ShowTransactionFilters | HideTransactionFilters | ToggleTransactionFilters;

export type ShowTransactionFilters = {
    type: typeof SHOW_TRANSACTION_FILTERS,
    payload: {}
}

export const showTransactionFilters = (): ShowTransactionFilters => ({
    type: SHOW_TRANSACTION_FILTERS,
    payload: {}
})

export type HideTransactionFilters = {
    type: typeof HIDE_TRANSACTION_FILTERS,
    payload: {}
}

export const hideTransactionFilters = (): HideTransactionFilters => ({
    type: HIDE_TRANSACTION_FILTERS,
    payload: {}
})

export type ToggleTransactionFilters = {
    type: typeof TOGGLE_TRANSACTION_FILTERS,
    payload: {}
}

export const toggleTransactionFilters = (): ToggleTransactionFilters => ({
    type: TOGGLE_TRANSACTION_FILTERS,
    payload: {}
})