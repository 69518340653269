import { ColumnInstance, FilterProps } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import React from 'react';
import SelectField, { SingleOption, SelectOptions } from '../../../common/Form/SelectField';
import isEqual from 'lodash/isEqual'
import './select-filter.less';
import { StylesConfig } from 'react-select';

type Props<T> = {
	selectOptions: SelectOptions<T>;
	defaultOption: SingleOption<T>;
	label: string;
	column: ColumnInstance<Transaction>;
	valueStylesFn?: StylesConfig<SingleOption<T>>['singleValue'];
	optionsStylesFn?: StylesConfig<SingleOption<T>>['option'];
	onChange?: (newValue: SingleOption<T>|null) => void
} & FilterProps<Transaction>;

function SelectFilter<T = string>(props: Props<T>) {
	const flattenedOptions = [...props.selectOptions].reduce((acc: SingleOption<T>[], opt) => {
		if (opt.hasOwnProperty('options')) {
			// @ts-ignore
			return [...acc, ...opt.options];
		}

		return [...acc, opt];
	}, []);

	const selected = flattenedOptions.find(subCat => {
		return isEqual(subCat.value, props.column.filterValue);
	});
	return (
		<div className={'select-filter'}>
			<label className={'select-filter__label'}>{props.label}</label>
			<SelectField<T>
				// @ts-ignore
				value={selected || props.defaultOption}
				onChange={newValue => {
					props.onChange && props.onChange(newValue)
				}}
				name={'selectFilter'}
				options={props.selectOptions}
				clearable={true}
				className={'select-filter__input'}
				valueStylesFn={props.valueStylesFn}
				optionsStylesFn={props.optionsStylesFn}
			/>
		</div>
	);
}

export default SelectFilter;
