import { Month } from '../../../../domain/util/date';
import { SubCategory } from '../../../../domain/categories';
import { useMemo } from 'react';
import { createBudgetSelector } from '../../categories/selectors';
import { useSelector } from 'react-redux';
import createNewCategoryBudget from '../../../../domain/categories/operations/createNewCategoryBudget';
import useRequiredActiveProfile from '../../profiles/hooks/useRequiredActiveProfile';

const useCategoryBudget = (month: Month, uuid: SubCategory['uuid']) => {
	const activeProfile = useRequiredActiveProfile();
	const budgetSelector = useMemo(() => {
		return createBudgetSelector(month.year, month.month, uuid);
	}, [month.year, month.month, uuid]);
	return useSelector(budgetSelector) ||
		createNewCategoryBudget(month, activeProfile.uuid, uuid);
};

export default useCategoryBudget;