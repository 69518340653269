import uniq from 'lodash/uniq';
import { Account, ACCOUNT_CASH, AccountType } from '../../../accounts/Account';
import { getUUID } from '../../../index';
import { Profile } from '../../../profiles';
import { RegisterRow } from './index';

export type AccountNameMap = Map<Account['name'], Account>;

export const getAccountsMap = (registerRows: RegisterRow[], profile: Profile): AccountNameMap => {
	const allAccountNames = registerRows.map(row => row.account);
	const uniqueAccountNames = uniq(allAccountNames) as string[];
	const accounts: Account[] = uniqueAccountNames.map(accountName => ({
		uuid: getUUID(),
		name: accountName,
		type: AccountType.ASSET_ACCOUNT,
		category: ACCOUNT_CASH,
		closed: false,
		profileUUID: profile.uuid,
		isOffBudget: isOffBudgetAccount(registerRows, accountName),
	}));
	return accounts.reduce((acc, account) => {
		acc.set(account.name, account);
		return acc;
	}, new Map());
};

const isOffBudgetAccount = (registerRows: RegisterRow[], accountName: string): boolean => {
	const acctTransactions = registerRows.filter(r => r.account === accountName);
	const transactionWithCategory = acctTransactions.find(r => r.category !== '');
	return transactionWithCategory === undefined;
};
