import { AppDispatch, AppThunkAction } from '../../types';
import { getAllCategories } from '../../categories/selectors';
import {Transaction} from "../../../../domain/transactions/Transaction";
import {Account} from "../../../../domain/accounts/Account";
import {
	getReconcileAccountUUID,
	getReconcileDate,
	isReconciling,
} from '../selectors/reconciling';
import { loadReconcileClearedBalance, loadReconcileTransactions } from './reconciling';
import {Profile} from "../../../../domain/profiles";
import {TransactionsRepo} from "../../../../domain/transactions/ITransactionsRepo";

export type TransactionActions = AddTransaction | EditTransaction | LoadTransactions | DeleteTransactions | EditTransactions;

export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export type AddTransaction = {
	type: typeof ADD_TRANSACTION;
	payload: {
		transaction: Transaction;
	};
};

export const addTransactionActionCreator = (
	transaction: Transaction,
): AddTransaction => ({
	type: ADD_TRANSACTION,
	payload: {
		transaction,
	},
});

export const addTransaction = (transaction: Transaction): AppThunkAction<Promise<void>> => {
	return (dispatch, getState) => {
		const state = getState();
		const categories = getAllCategories(state);
		// TODO: check all category ids
		if (transaction.categoryId.length) {
			const category = categories.get(transaction.categoryId[0]);
			if (!category) {
				throw new Error(`Transaction categoryId:${transaction.categoryId} does not exist`);
			}
		}

		return TransactionsRepo.put(transaction).then(() => {
			dispatch(addTransactionActionCreator(transaction));
		});
	};
};

export const EDIT_TRANSACTION = 'EDIT_TRANSACTION';
export type EditTransaction = {
	type: typeof EDIT_TRANSACTION;
	payload: {
		transaction: Transaction;
		oldTransaction: Transaction|null;
	};
};

export const editTransactionActionCreator = (
	transaction: Transaction,
	oldTransaction: Transaction|null
): EditTransaction => ({
	type: EDIT_TRANSACTION,
	payload: {
		transaction,
		oldTransaction
	},
});

export const editTransaction = (transaction: Transaction, oldTransaction: Transaction|null): AppThunkAction<Promise<void>> => {
	return (dispatch, getState) => {
		return TransactionsRepo.put(transaction).then(() => {
			dispatch(editTransactionActionCreator(transaction, oldTransaction));
			const state = getState();
			if (isReconciling(state)) {
				dispatch(loadReconcileClearedBalance());
			}
		});
	};
};

export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export type LoadTransactions = {
	type: typeof LOAD_TRANSACTIONS;
	payload: {
		transactions: Array<Transaction>;
	};
};

export const loadTransactionsActionCreator = (
	transactions: Array<Transaction>
): LoadTransactions => ({
	type: LOAD_TRANSACTIONS,
	payload: {
		transactions,
	},
});

export const loadTransactions = (profileUUID: Profile['uuid'], accountUUID?: Account['uuid']): AppThunkAction<Promise<void>> => {
	return (dispatch: AppDispatch, getState) => {
		const state = getState();
		const reconcileDate = getReconcileDate(state);
		const reconciledAccountUUID = getReconcileAccountUUID(state);

		if (isReconciling(state) && reconcileDate && reconciledAccountUUID) {
			return dispatch(loadReconcileTransactions(reconciledAccountUUID, reconcileDate));
		}

		return TransactionsRepo.getTransactions(profileUUID, accountUUID).then(transactions => {
			dispatch(loadTransactionsActionCreator(transactions));
		});
	};
};

export const DELETE_TRANSACTIONS = 'DELETE_TRANSACTIONS';
export type DeleteTransactions = {
	type: typeof DELETE_TRANSACTIONS;
	payload: {
		UUIDs: Transaction['uuid'][];
		deletedTransactions: Array<Transaction>;
	};
};

export const deleteTransactionsActionCreator = (
	UUIDs: Transaction['uuid'][],
	deletedTransactions: Array<Transaction>
): DeleteTransactions => ({
	type: DELETE_TRANSACTIONS,
	payload: {
		UUIDs,
		deletedTransactions
	},
});

export const deleteTransactions = (UUIDs: Transaction['uuid'][]): AppThunkAction<Promise<void>> => {
	return async (dispatch, getState) => {
		const deletedTransactions = await TransactionsRepo.getByUUIDs(UUIDs)
		return TransactionsRepo.deleteMany(UUIDs).then(() => {
			dispatch(deleteTransactionsActionCreator(UUIDs, deletedTransactions));
		});
	};
};


export const EDIT_TRANSACTIONS = 'EDIT_TRANSACTIONS';
export type EditTransactions = {
	type: typeof EDIT_TRANSACTIONS;
	payload: {
		transactions: Transaction[];
	};
};

export const editTransactionsActionCreator = (
	transactions: Transaction[]
): EditTransactions => ({
	type: EDIT_TRANSACTIONS,
	payload: {
		transactions
	},
});

export const editTransactions = (transactions: Transaction[]): AppThunkAction<Promise<void>> => {
	return (dispatch, getState) => {
		dispatch(editTransactionsActionCreator(transactions));
		return TransactionsRepo.putMany(transactions)
			.then(() => {})
			.catch((err) => console.error(err));
	};
};

