import { UUID } from '../index';
import NaiveDate, {SerializedNaiveDate} from '../util/NaiveDate';
import { SubCategory } from '../categories';
import { Account } from '../accounts/Account';
import { Profile } from '../profiles';
import { TransactionTag } from './TransactionTag';

export const STATUS_UNCLEARED = 'uncleared';
export const STATUS_CLEARED = 'cleared';
export const STATUS_RECONCILED = 'reconciled';
export type TransactionStatus =
	| typeof STATUS_UNCLEARED
	| typeof STATUS_CLEARED
	| typeof STATUS_RECONCILED;

export type Transaction = {
	uuid: UUID;
	date: NaiveDate;
	categoryId: Array<SubCategory['uuid']>;
	accountUUID: Account['uuid'];
	transferToAccountUUID: Account['uuid'] | null;
	transferToAccountStatus: TransactionStatus | null;
	profileUUID: Profile['uuid'];
	note: string;
	inflow: number;
	outflow: number;
	payee: string;
	splits: Array<TransactionSplit>;
	status: TransactionStatus;
	tags: Array<TransactionTag['uuid']>;
};

export type TransactionSplit = {
	categoryUUID: SubCategory['uuid'];
	note: string;
	inflow: number;
	outflow: number;
};

export type SerializedTransaction = {
	uuid: UUID;
	date: SerializedNaiveDate;
	categoryId: Array<SubCategory['uuid']>;
	accountUUID: Account['uuid'];
	transferToAccountUUID: Account['uuid'] | null;
	transferToAccountStatus: TransactionStatus | null;
	profileUUID: Profile['uuid'];
	note: string;
	inflow: number;
	outflow: number;
	payee: string;
	splits: Array<TransactionSplit>;
	status: TransactionStatus;
	tags: Array<TransactionTag['uuid']>;
}
