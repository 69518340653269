
export type NavActions =
    | ShowSideNav
    | HideSideNav;

export const SHOW_SIDE_NAV = 'SHOW_SIDE_NAV';
export type ShowSideNav = {
    type: typeof SHOW_SIDE_NAV;
};

export const showSideNav = (): ShowSideNav => ({
    type: SHOW_SIDE_NAV
});

export const HIDE_SIDE_NAV = 'HIDE_SIDE_NAV';
export type HideSideNav = {
    type: typeof HIDE_SIDE_NAV;
};

export const hideSideNav = (): HideSideNav => {
    return {
        type: HIDE_SIDE_NAV
    }
};
