import SelectFilter from '../SelectFilter';
import React from 'react';
import {FilterProps, UseFiltersColumnProps} from 'react-table';
import { Transaction } from '../../../../../domain/transactions/Transaction';
import {Account} from "../../../../../domain/accounts/Account";
import useAccountOptions, {DEFAULT_CHOOSE_ACCOUNT} from "../../../../store/accounts/hooks/useAccountOptions";

const AccountFilter = (
    props: FilterProps<Transaction> & {
        column: UseFiltersColumnProps<Transaction>;
    }
) => {
    const accountOptions = useAccountOptions();

    return <SelectFilter<Account['uuid']>
        {...props}
        selectOptions={accountOptions}
        defaultOption={DEFAULT_CHOOSE_ACCOUNT}
        label={'Account'}
    />
};

export default AccountFilter;
