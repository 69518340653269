import { RegisterRow } from '../YNAB';
import papaparse from 'papaparse';
import { NewRegisterRowRaw } from './index';
import camelCase from 'lodash/camelCase';
import { getCurrencyAmount } from '../YNAB/util';
import take from 'lodash/fp/take';
import {guessDateFormatParser} from "../../../util/dateFormat";

const parseConfig = {
	header: true,
	skipEmptyLines: true,
	transformHeader: camelCase,
};

const parseRegisterRows = (registerCsvText: string): RegisterRow[] => {
	const parsedBudgetData = papaparse.parse<NewRegisterRowRaw>(registerCsvText, parseConfig);
	const dateParser = guessDateParser(parsedBudgetData.data);
	return parsedBudgetData.data.map(row => {
		return {
			account: row.account,
			flag: row.flag,
			checkNumber: '',
			date: dateParser(row.date),
			payee: row.payee,
			category: row.categoryGroupCategory,
			masterCategory: row.categoryGroup,
			subCategory: row.category,
			memo: row.memo,
			outflow: getCurrencyAmount(row.outflow),
			inflow: getCurrencyAmount(row.inflow),
			cleared: row.cleared,
			runningBalance: '',
		};
	});
};

export default parseRegisterRows;

const guessDateParser = (rows: NewRegisterRowRaw[]) => {
	const testBatch = take(1000, rows).map(r => r.date);
	return guessDateFormatParser(testBatch);
}