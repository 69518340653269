import React from 'react';
import { Header, Modal as SemanticModal, Transition} from 'semantic-ui-react';
import { Size, SIZE_SMALL } from './index';
import classNames from "classnames";
import './advanced-modal.less';

type Props = {
	open: boolean;
	onClose?: () => void;
	children?: React.ReactNode;
	title?: string;
	footer?: React.ReactNode;
	size?: Size;
	actions: React.ReactNode;
	contentPadding?: boolean
};

const AdvancedModal = (props: Props) => {
	const size = props.size || SIZE_SMALL;
	const noContentPadding = props.contentPadding === false;
	return (
		<Transition animation={'fade down'} duration={300} visible={props.open} unmountOnHide={true}>
			<SemanticModal
				open={true}
				size={size}
				closeIcon
				onClose={() => props.onClose && props.onClose()}
				className={classNames({
					'advanced-modal': true,
					'advanced-modal--no-content-padding': noContentPadding,
				})}
			>
				<Header content={props.title || ''} />
				<SemanticModal.Content>{props.children}</SemanticModal.Content>
				<SemanticModal.Actions>{props.actions}</SemanticModal.Actions>
			</SemanticModal>
		</Transition>
	);
};

export default AdvancedModal;
