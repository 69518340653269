import { Profile } from '../../../../domain/profiles';
import { Account } from '../../../../domain/accounts/Account';
import { DateFilterType } from '../../../accounts/TransactionsTable/TableFilters/DateRangeFilter';
import NaiveDate from '../../../../domain/util/NaiveDate';
import {
	DATE_FILTER_QUERY,
	DATE_FROM_QUERY,
	DATE_TO_QUERY,
} from '../../../accounts/TransactionsTable/TableFilters/useDateRangeQueryFilters';
import { SubCategory } from '../../../../domain/categories';
import { CATEGORY_UUID_QUERY } from '../../../accounts/TransactionsTable/TableFilters/useCategoryFilters';

const ROOT_ROUTE = 'root';
const PROFILES_ROUTE = 'profiles';
const ADD_PROFILE_ROUTE = 'add_profile';
const SYNC_PROFILE_ROUTE = 'sync_profile';
const BUDGET_ROUTE = 'budget';
const ALL_ACCOUNTS_ROUTE = 'accounts';
const ACCOUNT_ROUTE = 'account';
const SETTINGS_ROUTE = 'settings';
const TRANSACTION_TAGS_ROUTE = 'settings_tags';
const SYNC_ROUTE = 'sync';
const SIGN_UP_ROUTE = 'sign_up';
const SIGN_IN_ROUTE = 'sign_in';

export const APP_ROUTES = {
	[ROOT_ROUTE]: `/`,
	[SIGN_UP_ROUTE]: `/sign-up`,
	[SIGN_IN_ROUTE]: `/sign-in`,
	[PROFILES_ROUTE]: `/profiles`,
	[ADD_PROFILE_ROUTE]: `/profiles/add`,
	[SYNC_PROFILE_ROUTE]: `/profiles/sync`,
	[BUDGET_ROUTE]: `/profiles/:profileUUID/budget`,
	[ALL_ACCOUNTS_ROUTE]: `/profiles/:profileUUID/accounts`,
	[SETTINGS_ROUTE]: `/profiles/:profileUUID/settings`,
	[TRANSACTION_TAGS_ROUTE]: `/profiles/:profileUUID/transaction/tags`,
	[SYNC_ROUTE]: `/profiles/:profileUUID/sync`,
	[ACCOUNT_ROUTE]: `/profiles/:profileUUID/accounts/:accountUUID`,
};

export const APP_LINKS = {
	[ROOT_ROUTE]: () => `/`,
	[SIGN_UP_ROUTE]: () => `/sign-up`,
	[SIGN_IN_ROUTE]: () => `/sign-in`,
	[PROFILES_ROUTE]: () => `/profiles`,
	[ADD_PROFILE_ROUTE]: () => `/profiles/add`,
	[SYNC_PROFILE_ROUTE]: () => `/profiles/sync`,
	[BUDGET_ROUTE]: (profileUUID: Profile['uuid']) => `/profiles/${profileUUID}/budget`,
	[SETTINGS_ROUTE]: (profileUUID: Profile['uuid']) => `/profiles/${profileUUID}/settings`,
	[TRANSACTION_TAGS_ROUTE]: (profileUUID: Profile['uuid']) => `/profiles/${profileUUID}/transaction/tags`,
	[SYNC_ROUTE]: (profileUUID: Profile['uuid']) => `/profiles/${profileUUID}/sync`,
	[ALL_ACCOUNTS_ROUTE]: (
		profileUUID: Profile['uuid'],
		dateFilter: DateFilterType | undefined = undefined,
		dateFrom: NaiveDate | undefined = undefined,
		dateTo: NaiveDate | undefined = undefined,
		categoryUUID: SubCategory['uuid'][] | undefined = undefined
	) => {
		const queryParams = new URLSearchParams();
		if (dateFilter) queryParams.set(DATE_FILTER_QUERY, dateFilter.toString());
		if (dateFrom) queryParams.set(DATE_FROM_QUERY, dateFrom.toString());
		if (dateTo) queryParams.set(DATE_TO_QUERY, dateTo.toString());
		if (categoryUUID) queryParams.set(CATEGORY_UUID_QUERY, categoryUUID?.join(','));
		return `/profiles/${profileUUID}/accounts?` + String(queryParams);
	},
	[ACCOUNT_ROUTE]: (profileUUID: Profile['uuid'], accountUUID: Account['uuid']) =>
		`/profiles/${profileUUID}/accounts/${accountUUID}`,
};
