import persistRemoteChanges from './persistRemoteChanges';
import reactToChanges from './reactToChanges';
import {SyncDevice} from "../../domain/sync/SyncDevice";
import deflate from '../compression/deflate';
import inflate from '../compression/inflate';
import { dispatch } from '../../view/store/dispatch';
import { firstRoundSyncComplete, incrementItemsSynced } from '../../view/store/sync/actions/devices';
import { IDatabaseChange } from 'dexie-observable/api';

export default class IncomingSyncDataChannel {
	private peerDeviceUUID: SyncDevice['uuid'];
	private removeMessageListener: undefined|Function;

	constructor(private dataChannel: RTCDataChannel, peerDeviceUUID: SyncDevice['uuid']) {
		this.peerDeviceUUID = peerDeviceUUID;
		this.dataChannel.addEventListener('close', () => {
			console.log('Data channel closed');
			this.removeMessageListener && this.removeMessageListener()
		});

		this.listenForMessages();
	}

	private listenForMessages() {
		this.dataChannel.addEventListener('message', this.onChanges);
		this.removeMessageListener = () => this.dataChannel.removeEventListener('message', this.onChanges);
	}

	private onChanges = async (message: MessageEvent) => {
		const messageData = await inflate(message.data);
		console.log(messageData)
		if (messageData.role === 'receiver') {
			// This message was sent by the peer operating as a receiver.
			// Here we are only interested in messages from the peer operating as the initiator.
			return;
		}
		if (messageData.type === 'changes') {
			const filteredChanges = messageData.changes.filter((chg: IDatabaseChange) => chg.table !== 'monthBudgets');
			console.log('IncomingSyncDataChannel received remote changes', messageData.changes, filteredChanges);
			await persistRemoteChanges(filteredChanges, this.peerDeviceUUID);
			console.log(`Sending ack as receiver for request id: ${messageData.requestId}`)
			this.sendMessage({
				role: 'receiver',
				type: 'ack',
				requestId: messageData.requestId,
			});
			// TODO: Is this necessary?
			this.sendMessage({
				role: 'receiver',
				type: 'changes',
				changes: [],
				partial: false,
				baseRevision: 1, // TODO: figure out what this should be?
				requestId: 1,
			});
			reactToChanges([]);
			dispatch(incrementItemsSynced(this.peerDeviceUUID, filteredChanges.length))
			if (messageData.partial === false) {
				dispatch(firstRoundSyncComplete(this.peerDeviceUUID))
			}
		}
	}

	private sendMessage(data: any) {
		this.dataChannel.send(deflate(data));
	}
}
