import { AppThunkAction } from '../../types';
import TransactionsRepo from '../../../../infrastructure/persistence/indexed-db/transactions/TransactionsRepo';
import { SubCategory } from '../../../../domain/categories';
import { getLatestVisibleMonth } from '../selectors/monthsVisible';
import { MonthBudget } from '../../../../domain/budget';
import { getLastMonth, getMonthsBetween, getNextMonth, Month } from '../../../../domain/util/date';
import { getCustomCategories, getOrderedSubCategoryAssignments } from '../../categories/selectors';
import calculateMonthBudget from '../../../../domain/budget/calculations/calculateMonthBudget';
import { getMonthBudget } from '../selectors/monthBudget';
import { requireActiveProfile } from '../../profiles/selectors';
import MonthBudgetsRepo from '../../../../infrastructure/persistence/indexed-db/budgets/MonthBudgetsRepo';
import calculatingMonthBudgets from './calculatingMonthBudgets';

export const SET_MONTH_BUDGET = 'SET_MONTH_BUDGET';
export type SetMonthBudget = {
	type: typeof SET_MONTH_BUDGET;
	payload: {
		monthBudget: MonthBudget;
	};
};

const setMonthBudget = (monthBudget: MonthBudget): SetMonthBudget => ({
	type: SET_MONTH_BUDGET,
	payload: {
		monthBudget,
	},
});

/**
 *
 * @param month The month to start recalculating at
 */
export const recalculateBudget = (month: Month | null = null): AppThunkAction => {
	return async (dispatch, getState) => {
		const state = getState();
		const activeProfile = requireActiveProfile(state);
		let lastMonthBudget = undefined;
		if (!month) {
			const oldestTransaction = await TransactionsRepo.getOldestTransaction(
				activeProfile.uuid
			);
			if (!oldestTransaction) {
				return;
			}

			month = {
				year: oldestTransaction.date.getYear(),
				month: oldestTransaction.date.getMonth(),
			};
		} else {
			lastMonthBudget = getMonthBudget(state, getLastMonth(month));
		}

		const categories: Array<SubCategory> = Array.from(getCustomCategories(state).values());
		const categoryAssignments = getOrderedSubCategoryAssignments(state);
		const latestMonth = getLatestVisibleMonth(state) || month;

		const monthsToCalculate = getMonthsBetween(month, latestMonth);
		dispatch(calculatingMonthBudgets(monthsToCalculate));

		for (const monthToCalculate of monthsToCalculate) {
			let monthBudget: MonthBudget = await calculateMonthBudget(
				monthToCalculate,
				activeProfile,
				categories,
				categoryAssignments,
				lastMonthBudget
			);
			await MonthBudgetsRepo.put(monthBudget);
			dispatch(setMonthBudget(monthBudget));
			month = getNextMonth(month);
			lastMonthBudget = monthBudget;
		}
	};
};
