
export type SocketActions = SetSocketStateAction;

export type SetSocketStateAction = {
    type: 'SET_SOCKET_STATE',
    payload: {
        state: WebSocket['readyState']
    }
};

export const SET_SOCKET_STATE = 'SET_SOCKET_STATE';
export const setSocketState = (state: WebSocket['readyState']): SetSocketStateAction => ({
    type: SET_SOCKET_STATE,
    payload: {
        state
    }
})