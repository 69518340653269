import {SyncDevice} from "../../../domain/sync/SyncDevice";
import handleDeletedDevice from "../../../domain/sync/handleDeletedDevice";
import { AppDispatch } from '../../../view/store/types';
import { fetchAccountDevices } from '../../../view/store/sync/actions/devices';

const deviceDeletedHandler = async (dispatch: AppDispatch, deviceUUID: SyncDevice['uuid']) => {
    await handleDeletedDevice(deviceUUID);
    await dispatch(fetchAccountDevices())
}

export default deviceDeletedHandler;