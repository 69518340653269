import { Month } from '../../../domain/util/date';
import { MasterCategory } from '../../../domain/categories';
import useMasterCategoryBudgeted from '../../store/budget/hooks/useMasterCategoryBudgeted';
import useMasterCategoryBalance from '../../store/budget/hooks/useMasterCategoryBalance';
import React, { useState } from 'react';
import useMasterCategoryOutflows from '../../store/budget/hooks/useMasterCategoryOutflows';
import './master-category-card.less';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';
import { Icon } from 'semantic-ui-react';
import EditCategoryModal from '../EditCategoryButton/EditCategoryModal';

type Props = {
	month: Month;
	masterCategory: MasterCategory,
}

const MasterCategoryCard = (props: Props) => {
	const {
		month,
		masterCategory
	} = props;
	const budgeted = useMasterCategoryBudgeted(month, masterCategory.uuid);
	const balance = useMasterCategoryBalance(month, masterCategory.uuid);
	const outflows = useMasterCategoryOutflows(month, masterCategory.uuid);
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return <div className={'master-category-card'}>
		<h3 className={'master-category-card__title'} onClick={() => setIsEditModalOpen(true)}>
			<span>{ props.masterCategory.name }</span>
			<Icon name={'edit'} className={'master-category-card__edit'}/>
		</h3>
		<div className={'master-category-card__cells'}>
			<div className={'master-category-card__cell'}>
				<span className={'master-category-card__label'}>Budgeted</span>
				<span className={'master-category-card__value'}>{ formatCurrencyForDisplay(budgeted)}</span>
			</div>
			<div className={'master-category-card__cell'}>
				<span className={'master-category-card__label'}>Outflows</span>
				<span className={'master-category-card__value'}>{ formatCurrencyForDisplay(outflows)}</span>
			</div>
			<div className={'master-category-card__cell'}>
				<span className={'master-category-card__label'}>Balance</span>
				<span className={'master-category-card__value'}>{ formatCurrencyForDisplay(balance)}</span>
			</div>
		</div>
		<EditCategoryModal
			category={masterCategory}
			isModalOpen={isEditModalOpen}
			onClose={() => setIsEditModalOpen(false)}
		/>
	</div>
}

export default MasterCategoryCard;