import AppDB from "../persistence/indexed-db";
import deleteSyncDevice from "./deleteSyncDevice";
import disconnectSync from "./disconnectSync";

const deleteAllSyncDevices = async () => {
    const syncUrls = await AppDB.syncable.list();
    const deletes = syncUrls.map(async (url) => {
        await disconnectSync(url)
        deleteSyncDevice(url)
    });
    return await Promise.all(deletes)
}

export default deleteAllSyncDevices;