import { TableInstance } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import { formatCurrencyForInput } from '../../../../domain/util/currency';
import { getCategoriesOutflows } from '../../../../domain/transactions/util/getCategoryTotal';
import React from 'react';
import { ColumnID } from './useTableColumns';
import useAccountsMap from '../../../store/accounts/hooks/useAccountsMap';
import useIsSmallScreen from '../../../store/ui/hooks/useIsSmallScreen';
import useLegacyMoneyFormatter from '../../../common/hooks/useLegacyMoneyFormatter';

type Props = {
	cell: TableInstance<Transaction>['cell'],
	columns: TableInstance<Transaction>['columns'],
}

const OutflowCell = (props: Props) => {
	const { cell, columns } = props;
	const accounts = useAccountsMap();
	const isSmallScreen = useIsSmallScreen();
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const categoryColumn = columns.find(c => c.id === ColumnID.COL_ID_CATEGORY);
	const formatFunction = isSmallScreen ? formatCurrencyForDisplay : formatCurrencyForInput;

	if (categoryColumn && categoryColumn.filterValue) {
		const transactionAccount = accounts.get(cell.row.original.accountUUID);
		if (!transactionAccount) return <>{formatFunction(cell.value)}</>

		const filteredAmount = getCategoriesOutflows(cell.row.original, categoryColumn.filterValue, transactionAccount)
		if (filteredAmount !== cell.value) {
			return <>{formatFunction(filteredAmount)} <span style={{opacity: 0.65}}>/{formatFunction(cell.value)}</span></>
		}
	}

	return <>{formatFunction(cell.value)}</>;
}

export default OutflowCell;