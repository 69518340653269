import React, { useEffect, useState } from 'react';
import 'semantic-ui-less/semantic.less';
import './App.less';
import { useDispatch } from 'react-redux';
import { bootstrapApp } from './store/app/actions/bootstrap';
import debounce from 'lodash/debounce';
import { setDimensions } from './store/ui/actions/window';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store';
import { AppDispatch } from './store/types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import Troubleshooter from "./settings/Troubleshooter/Troubleshooter";
import AppRoutes from "./structure/AppRoutes";
import 'react-toastify/dist/ReactToastify.css';
import PromptForUpdateModal from './settings/PromptForUpdateModal/PromptForUpdateModal';

// const whyDidYouRender = require('@welldone-software/why-did-you-render');
// whyDidYouRender(React);

function App() {
	const dispatch = useDispatch<AppDispatch>();
	const [isAppBootstrapped, setIsAppBootstrapped] = useState(false);
	useEffect(() => {
		if (!isAppBootstrapped) {
			dispatch(bootstrapApp()).finally(() => {
				setIsAppBootstrapped(true);
			});
		}
	});

	useEffect(() => {
		const handleResize = () => {
			console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
			dispatch(setDimensions(window.innerWidth, window.innerHeight));
		};

		window.addEventListener('resize', debounce(handleResize, 100));
	}, [dispatch]);

	if (!isAppBootstrapped) {
		return (
			<Dimmer active inverted>
				<Loader size="massive">Loading</Loader>
			</Dimmer>
		);
	}

	return (
		<>
			<ConnectedRouter history={history}>
				<AppRoutes />
			</ConnectedRouter>
			<Troubleshooter/>
			<PromptForUpdateModal/>
			<ToastContainer/>
		</>
	);
}

export default App;

