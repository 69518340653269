import * as AccountActions from '../actions/accounts';
import { StoreActions } from '../../types';
import { Account } from '../../../../domain/accounts/Account';

export type AccountUnclearedBalancesState = Map<Account['uuid'], number>;
export const accountUnclearedBalances = (state: AccountUnclearedBalancesState = new Map(), action: StoreActions) => {
    switch (action.type) {
        case AccountActions.LOAD_UNCLEARED_ACCOUNT_BALANCE:
            state.set(action.payload.accountUUID, action.payload.unclearedBalance);
            return new Map(state.entries());

        default:
            return state;
    }
};