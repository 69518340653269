import { Icon, Segment, Header } from 'semantic-ui-react';
import './sync-devices-empty-state.less'

const SyncDevicesEmptyState = () => {
	return <Segment placeholder className={'sync-devices-empty-state'}>
		<Header icon>
			<Icon name='mobile alternate' className={'sync-devices-empty-state__icon'} />
			<Icon name='arrows alternate horizontal' className={'sync-devices-empty-state__icon'} />
			<Icon name='laptop' className={'sync-devices-empty-state__icon'} />
			<span className={'sync-devices-empty-state__title'}>You have no synchronized devices.</span>
		</Header>
		<p className={'sync-devices-empty-state__subtitle'}>Sign into Dollero on another device to begin synchronizing your budget.</p>
	</Segment>
};

export default SyncDevicesEmptyState;