import React from 'react';
import './text-input.less';

type Props = {
    value: string;
    name: string;
    id?: string;
    type?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    placeholder?: string;
    label?: string;
};

const TextInput = (props: Props) => {
    return (
        <input
            className={'text-input'}
            id={props.id}
            type={props.type || 'text'}
            name={props.name}
            placeholder={props.placeholder}
            value={props.value}
            onChange={e => props.onChange && props.onChange(e)}
            disabled={props.disabled}
            autoComplete={'off'}
        />
    );
};

export default TextInput;
