import React from 'react';
import Button from '../../../common/Button';
import './table-footer.less';
import { Icon, Label } from 'semantic-ui-react';
import { Row } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import { Account } from '../../../../domain/accounts/Account';
import { useSelector } from 'react-redux';
import { isSmallScreen } from '../../../store/ui/selectors/window';
import AddEditTransactionModal from '../../AddEditTransaction/AddEditTransactionModal/AddEditTransactionModal';
import useSearchTotal from './useSearchTotal';
import useLegacyMoneyFormatter from '../../../common/hooks/useLegacyMoneyFormatter';

type Props = {
	isAddingTransfer: boolean;
	isAddingTransaction: boolean;
	onAddTransaction: () => void;
	onAddTransfer: () => void;
	onSave: () => void;
	currentAccountUUID: Account['uuid'] | undefined;
	rows: Row<Transaction>[];
};

const TableFooter = (props: Props) => {
	const smallScreen = useSelector(isSmallScreen);
	const searchTotals = useSearchTotal(props.rows, props.currentAccountUUID);
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const addTransactionButtonSize = smallScreen ? 'large' : 'medium';

	return (
		<div className={'transactions-table-footer'}>
			<div className={'transactions-table-footer__left'}>
				<Button.Group fluid={smallScreen}>
					{!props.isAddingTransaction && !props.isAddingTransfer && (
						<Button icon labelPosition="left" onClick={props.onAddTransaction} size={addTransactionButtonSize}>
							<Icon name="plus square" />
							Add Transaction
						</Button>
					)}
					{!smallScreen && !props.isAddingTransfer && !props.isAddingTransaction && (
						<Button icon labelPosition="left" onClick={props.onAddTransfer}>
							<Icon name="exchange" />
							Add Transfer
						</Button>
					)}
				</Button.Group>
			</div>
			{!smallScreen && (
				<div className={'transactions-table-footer__right'}>
					<div className={'transactions-table-footer__totals'}>
						<Label size={'large'}>
							Search Total
							<Label.Detail>{formatCurrencyForDisplay(searchTotals.total)}</Label.Detail>
						</Label>
					</div>
				</div>
			)}
			{smallScreen && (
				<AddEditTransactionModal
					isOpen={props.isAddingTransaction}
					onClose={() => props.onSave()}
				/>
			)}
		</div>
	);
};

export default TableFooter;
