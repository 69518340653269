import {MasterCategory} from "../../../../domain/categories";
import {useMemo} from "react";
import {createMasterCategoryBudgetedSelector} from "../selectors/monthBudget";
import {useSelector} from "react-redux";
import {Month} from "../../../../domain/util/date";

const useMasterCategoryBudgeted = (month: Month, uuid: MasterCategory['uuid']) => {
    const budgetSelector = useMemo(() => {
        return createMasterCategoryBudgetedSelector(month.year, month.month, uuid);
    }, [month.year, month.month, uuid]);
    return useSelector(budgetSelector);
}

export default useMasterCategoryBudgeted;