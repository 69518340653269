import {SyncDevice} from "../../../domain/sync/SyncDevice";
import {DOLLERO_ACCOUNT_URL} from "../../../config";
import getAuthorizationHeader from "./auth/getAuthorizationHeader";
import handleUnauthorized from '../../api/handleUnauthorized';

const isDeviceConnected = async (uuid: SyncDevice['uuid']): Promise<boolean> => {
    return fetch(`${DOLLERO_ACCOUNT_URL}api/devices/${uuid}/connection`, {
        credentials: "include",
        headers: {
            Authorization: await getAuthorizationHeader()
        }
    })
        .then(handleUnauthorized)
        .then(response => response.json())
        .then(results => {
            console.log('Success:', results);
            return results.status;
        })
        .catch(error => {
            throw new Error(error);
        });
}

export default isDeviceConnected;