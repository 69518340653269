import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { Button } from '../../common/Button/Button';
import './accounts-empty-state.less';

type Props = {
	onPrimaryClick: () => void;
};

const AccountsEmptyState = (props: Props) => {
	return (
		<div className={'accounts-empty-state'}>
			<Header icon inverted>
				<Icon name="university" />
				Add an account to get started
			</Header>
			<br />
			<Button type={'success'} onClick={props.onPrimaryClick}>
				Add Account
			</Button>
		</div>
	);
};

export default AccountsEmptyState;
