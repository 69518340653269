import { getLastMonth, Month } from '../../../domain/util/date';
import useBudgetableCategories from '../../store/categories/hooks/useBudgetableCategories';
import { useSelector } from 'react-redux';
import { hasCreatedAnyCategories } from '../../store/categories/selectors';
import BudgetSheetEmptyState from '../BudgetSheetEmptyState/BudgetSheetEmptyState';
import React from 'react';
import MasterCategoryCard from './MasterCategoryCard';
import SubCategoryCard from './SubCategoryCard';
import useMonthBudget from '../../store/budget/hooks/useMonthBudget';
import MonthTotalsCard from './MonthTotalsCard';
import './budget-card-list.less';
import AddCategoryButton from '../AddCategoryBudgetButton/AddCategoryButton';

type Props = {
	month: Month;
};

const BudgetCardList = (props: Props) => {
	const budgetableMasterCategories = useBudgetableCategories();
	const hasCreatedCategories = useSelector(hasCreatedAnyCategories);
	const monthBudget = useMonthBudget(props.month);
	const lastMonthBudget = useMonthBudget(getLastMonth(props.month));

	if (!hasCreatedCategories) {
		return <BudgetSheetEmptyState />;
	}

	if (!monthBudget) {
		return <span>No budget this month</span>;
	}

	return (
		<div className={'budget-card-list'}>
			<MonthTotalsCard month={props.month}/>
			{budgetableMasterCategories.map(masterCat => {
				if (masterCat.hidden) return null;
				return <>
					<MasterCategoryCard
						month={props.month}
						masterCategory={masterCat}
					/>
					{masterCat.subCategories.map(subCat => {
						if (subCat.hidden) return null;
						return <SubCategoryCard month={props.month} subCategory={subCat} monthBudget={monthBudget} lastMonthBudget={lastMonthBudget} />;
					})}
					<div className={'budget-card-list__add-category'}>
						<AddCategoryButton masterCategoryUUID={masterCat.uuid}>Add Category</AddCategoryButton>
					</div>
				</>;
			})}
		</div>
	);
};

export default BudgetCardList;

