import React, { useMemo } from 'react';
import { Cell, CellProps, Column, FilterProps, Row, UseFiltersColumnProps } from 'react-table';
import {
	Transaction,
} from '../../../../domain/transactions/Transaction';
import DateRangeFilter, { dateRangeFilterFn } from '../TableFilters/DateRangeFilter';
import { Account } from '../../../../domain/accounts/Account';
import TextFilter from '../TableFilters/TextFilter';
import { SubCategory } from '../../../../domain/categories';
import { HeaderContentProps } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { getAllAccounts } from '../../../store/accounts/selectors/accounts';
import TransactionStatus from '../../TransactionStatus';
import CategoryFilter from '../TableFilters/CategoryFilter/CategoryFilter';
import { CategoryFilterValue } from '../TableFilters/useCategoryFilters';
import useAllCategories from "../../../store/categories/hooks/useAllCategories";
import AccountFilter from "../TableFilters/AccountFilter/AccountFilter";
import TagsCell from '../../../transactions/TagsCell/TagsCell';
import TransactionTagFilter from '../TableFilters/TransactionTagFilter/TransactionTagFilter';
import OutflowCell from './OutflowCell';
import InflowCell from './InflowCell';
import usePayeeFilters from '../TableFilters/PayeeFilter/usePayeeFilter';
import useNotesFilters from '../TableFilters/NotesFilter/useNotesFilter';

export const COL_ID_SELECTION = 'selection';
export const COL_ID_DATE = 'date';
export const COL_ID_ACCOUNT = 'accountUUID';
export const COL_ID_PAYEE = 'payee';
export const COL_ID_CATEGORY = 'categoryID';
export const COL_ID_NOTE = 'note';
export const COL_ID_INFLOW = 'inflow';
export const COL_ID_OUTFLOW = 'outflow';
export const COL_ID_STATUS = 'status';

export enum ColumnID {
	COL_ID_SELECTION = 'selection',
	COL_ID_TAGS = 'tags',
	COL_ID_DATE = 'date',
	COL_ID_ACCOUNT = 'accountUUID',
	COL_ID_PAYEE = 'payee',
	COL_ID_CATEGORY = 'categoryID',
	COL_ID_NOTE = 'note',
	COL_ID_INFLOW = 'inflow',
	COL_ID_OUTFLOW = 'outflow',
	COL_ID_STATUS = 'status',
}

export const useTableColumns = (accountUUID: Account['uuid'] | undefined) => {
	const accounts = useSelector(getAllAccounts);
	const account = useMemo(() => (accountUUID ? accounts.get(accountUUID) : undefined), [
		accounts,
		accountUUID,
	]);
	const categories = useAllCategories();
	const dateTimeSort = useMemo(() => {
		return (a: Row<Transaction>, b: Row<Transaction>) => {
			if (a.original.date > b.original.date) {
				return 1;
			}

			if (a.original.date < b.original.date) {
				return -1;
			}

			return a.original.uuid > b.original.uuid ? 1 : -1;
		};
	}, []);

	return useMemo(() => {
		return [
			{
				id: COL_ID_SELECTION,
				maxWidth: 50,
				width: 50,
				minWidth: 30,
				// Make this column a groupByBoundary. This ensures that groupBy columns
				// are placed after it
				groupByBoundary: true,
				// The header can use the table's getToggleA,lRowsSelectedProps method
				// to render a checkbox
				Header: ({ getToggleAllRowsSelectedProps }: HeaderContentProps) => (
					<div>
						<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
					</div>
				),
				// The cell can use the individual row's getToggleRowSelectedProps method
				// to the render a checkbox
				// @ts-ignore
				Cell: ({ row }: CellProps<Transaction>) => (<div><IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /></div>
				)
			},
			{
				Header: 'Tags',
				id: ColumnID.COL_ID_TAGS,
				maxWidth: 50,
				width: 50,
				minWidth: 50,
				accessor: 'tags',
				Filter: TransactionTagFilter,
				Cell: ({ cell }: { cell: Cell<Transaction> }) => {
					return <TagsCell transaction={cell.row.original}/>
				}
			},
			{
				Header: 'Date',
				id: COL_ID_DATE,
				accessor: (originalRow: Row<Transaction>['original']) =>
					originalRow.date.toJSDate(),
				Cell: ({ cell }: { cell: Cell<Transaction> }) => cell.row.original.date.toString(),
				defaultCanSort: true,
				maxWidth: 135,
				width: 125,
				minWidth: 100,
				disableFilters: false,
				Filter: DateRangeFilter,
				filter: dateRangeFilterFn,
				sortType: dateTimeSort,
			},
			!account
				? {
						Header: 'Account',
						id: COL_ID_ACCOUNT,
						accessor: 'accountUUID',
						Cell: ({ cell }: { cell: Cell<Transaction> }) => {
							const cellAcct = accounts.get(cell.value);
							return cellAcct ? cellAcct.name : '';
						},
						Filter: AccountFilter,
						filter: (
							rows: Row<Transaction>[],
							id: string,
							filterValue: Account['uuid']
						) => {
							return rows.filter(row => {
								return (
									row.values.accountUUID === filterValue ||
									row.values.transferToAccountUUID === filterValue
								);
							});
						},
				  }
				: null,
			{
				Header: 'Payee',
				id: COL_ID_PAYEE,
				accessor: 'payee',
				Cell: ({ cell }: { cell: Cell<Transaction> }) => {
					if (cell.row.original.transferToAccountUUID === accountUUID) {
						const account = accounts.get(cell.row.original.accountUUID);
						const accountName = account ? account.name : '';
						return `Transfer from: ${accountName}`;
					}

					if (cell.row.original.transferToAccountUUID) {
						const account = accounts.get(cell.row.original.transferToAccountUUID);
						const accountName = account ? account.name : '';
						return `Transfer to: ${accountName}`;
					}
					return cell.value;
				},
				Filter: (
					props: FilterProps<Transaction> & { column: UseFiltersColumnProps<Transaction> }
				) => {
					const payeeFilter = usePayeeFilters(props.column)
					return <TextFilter label={'Payee'} filter={payeeFilter} />;
				},
			},
			!account?.isOffBudget ? {
				Header: 'Category',
				id: COL_ID_CATEGORY,
				accessor: 'categoryId',
				Cell: ({ cell }: { cell: Cell<Transaction> }) => {
					const hasSplits = cell.value.length > 1;
					const category = categories.get(cell.value[0]);
					const categoryName = category ? category.name : '';
					return hasSplits ? 'Split...' : categoryName;
				},
				disableFilters: false,
				Filter: CategoryFilter,
				filter: (
					rows: Row<Transaction>[],
					id: string,
					filterValue: CategoryFilterValue
				) => {
					if (!filterValue) {
						return rows;
					}

					return rows.filter(row => {
						return (
							row.values[
								COL_ID_CATEGORY
							].filter((transCatUUID: SubCategory['uuid']) =>
								filterValue.includes(transCatUUID)
							).length > 0
						);
					});
				},
			} : null,
			{
				Header: 'Note',
				id: COL_ID_NOTE,
				accessor: 'note',
				Filter: (
					props: FilterProps<Transaction> & { column: UseFiltersColumnProps<Transaction> }
				) => {
					const noteFilter = useNotesFilters(props.column)
					return <TextFilter label={'Notes'} filter={noteFilter} />;
				},
			},
			{
				Header: 'Outflow',
				id: COL_ID_OUTFLOW,
				accessor: (originalRow: Transaction) => {
					return accountUUID && originalRow.transferToAccountUUID === accountUUID
						? originalRow.inflow
						: originalRow.outflow;
				},
				Cell: OutflowCell,
				maxWidth: 100,
				width: 90,
				minWidth: 75,
				disableFilters: true,
			},
			{
				Header: 'Inflow',
				id: COL_ID_INFLOW,
				accessor: (originalRow: Transaction) => {
					return accountUUID && originalRow.transferToAccountUUID === accountUUID
						? originalRow.outflow
						: originalRow.inflow;
				},
				Cell: InflowCell,
				maxWidth: 100,
				width: 90,
				minWidth: 75,
				disableFilters: true,
			},
			{
				Header: 'Cleared',
				id: COL_ID_STATUS,
				accessor: 'status',
				maxWidth: 60,
				width: 60,
				minWidth: 50,
				disableFilters: true,
				isEditable: false,
				Cell: ({ cell }: { cell: Cell<Transaction> }) => {
					const status =
						cell.row.original.transferToAccountUUID === accountUUID
							? cell.row.original.transferToAccountStatus
							: cell.value;
					return <TransactionStatus transactionStatus={status} size={'large'} />;
				},
			},
		].filter(h => h !== null) as Column<Transaction>[];
	}, [dateTimeSort, accountUUID, account, accounts, categories]);
};

export const SelectionColumn = {
    id: COL_ID_SELECTION,
    maxWidth: 50,
    width: 50,
    minWidth: 30,
    // Make this column a groupByBoundary. This ensures that groupBy columns
    // are placed after it
    groupByBoundary: true,
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }: HeaderContentProps) => (
        <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        </div>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    // @ts-ignore
    Cell: ({ row }: CellProps<Transaction>) => (<div><IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /></div>
    )
};

const IndeterminateCheckbox = React.forwardRef(
	// @ts-ignore
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			// @ts-ignore
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

        // @ts-ignore
        return (<><input type="checkbox" ref={resolvedRef} {...rest} /></>);
    }
);
