import React from 'react';
import { FormSplit } from './SplitRow';
import './split-row-footer.less';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';
import { ColumnID } from '../TransactionsTable/useTableColumns/useTableColumns';
import { ColStyle } from '../AddEditTransaction/AddEditTransactionRow/AddEditTransactionRow';

type Props = {
	splits: FormSplit[];
	transactionOutflow: number;
	transactionInflow: number;
	columnStyles?: Map<string, ColStyle>;
};

const SplitRowFooter = (props: Props) => {
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const totalSplitsOutflow = props.splits.reduce((acc, split) => acc + split.outflow, 0);
	const totalSplitsInflow = props.splits.reduce((acc, split) => acc + split.inflow, 0);
	const remainingOutflow = props.transactionOutflow - totalSplitsOutflow;
	const remainingInflow = props.transactionInflow - totalSplitsInflow;
	const remainingOutflowDisplay =
		remainingInflow < 0 ? remainingOutflow + remainingInflow * -1 : remainingOutflow;
	const remainingInflowDisplay =
		remainingOutflow < 0 ? remainingInflow + remainingOutflow * -1 : remainingInflow;

	if (props.columnStyles) {
		return <RowLayout remainingOutflow={remainingOutflowDisplay} remainingInflow={remainingInflowDisplay} columnStyles={props.columnStyles}/>
	}

	return (
		<div className={'split-row-footer'}>
			{`Amount remaining to assign: ${formatCurrencyForDisplay(
				remainingInflowDisplay < 0 ? 0 : remainingInflowDisplay
			)} ${formatCurrencyForDisplay(
				remainingOutflowDisplay < 0 ? 0 : remainingOutflowDisplay
			)}`}
		</div>
	);
};

export default SplitRowFooter;

type RowLayoutProps = {
	remainingOutflow: number;
	remainingInflow: number;
	columnStyles: Map<string, ColStyle>;
}
const RowLayout = (props: RowLayoutProps) => {
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const selectionColStyles = props.columnStyles.get(ColumnID.COL_ID_SELECTION);
	const dateColStyles = props.columnStyles.get(ColumnID.COL_ID_DATE);
	const payeeColStyles = props.columnStyles.get(ColumnID.COL_ID_PAYEE);
	const categoryColStyles = props.columnStyles.get(ColumnID.COL_ID_CATEGORY);
	const noteColStyles = props.columnStyles.get(ColumnID.COL_ID_NOTE);
	const outflowColStyles = props.columnStyles.get(ColumnID.COL_ID_OUTFLOW);
	const inflowColStyles = props.columnStyles.get(ColumnID.COL_ID_INFLOW);
	const statusColStyles = props.columnStyles.get(ColumnID.COL_ID_STATUS);

	return <div className={'split-row-footer__row-layout'}>
		<div style={selectionColStyles} className={'split-row-footer__row-layout-cell'}/>
		<div style={dateColStyles} className={'split-row-footer__row-layout-cell'}/>
		<div style={payeeColStyles} className={'split-row-footer__row-layout-cell'}/>
		<div style={categoryColStyles} className={'split-row-footer__row-layout-cell'}/>
		<div style={noteColStyles} className={'split-row-footer__row-layout-cell split-row-footer__row-layout-cell--description'}>Amounts remaining to assign:</div>
		<div style={outflowColStyles} className={'split-row-footer__row-layout-cell'}>
			{formatCurrencyForDisplay(props.remainingInflow < 0 ? 0 : props.remainingOutflow)}
		</div>
		<div style={inflowColStyles} className={'split-row-footer__row-layout-cell'}>
			{formatCurrencyForDisplay(props.remainingOutflow < 0 ? 0 : props.remainingInflow)}
		</div>
		<div style={statusColStyles} className={'split-row-footer__row-layout-cell'}/>
	</div>
}
