export type Month = {
	year: number;
	month: number;
};

export const getDateTimeNow = () => {
	return new Date();
};

export const getCurrentMonthYear = () => {
	const now = getDateTimeNow();
	const year = now.getFullYear();
	const month = now.getMonth();
	return { year, month };
};

export const getCurrentMonth = (): Month => {
	const monthYear = getCurrentMonthYear()
	return { year: monthYear.year, month: monthYear.month + 1 }
}

export const getNextMonths = (currentMonth: Month, numberOfMonths: number) => {
	let yearIterator = currentMonth.year;
	let monthIterator = currentMonth.month;
	let months = [];
	for (let i = 0; i < numberOfMonths; i++) {
		if (monthIterator === 12) {
			monthIterator = 0;
			yearIterator++;
		}

		monthIterator++;
		months.push({ year: yearIterator, month: monthIterator });
	}

	return months;
};

export const getPastMonths = (currentMonth: Month, numberOfPastMonths: number) => {
	let yearIterator = currentMonth.year;
	let monthIterator = currentMonth.month;
	let months = [];
	for (let i = 0; i < numberOfPastMonths; i++) {
		monthIterator--;
		if (monthIterator === 0) {
			monthIterator = 12;
			yearIterator--;
		}
		months.unshift({ year: yearIterator, month: monthIterator });
	}

	return months;
};

export const getLastMonth = (month: Month): Month => {
	let yearNum = month.year;
	let monthNum = month.month;
	if (monthNum === 1) {
		monthNum = 12;
		yearNum = yearNum - 1;
	} else {
		monthNum = monthNum - 1;
	}
	return {
		year: yearNum,
		month: monthNum,
	};
};

export const getNextMonth = (month: Month) => {
	let yearNum = month.year;
	let monthNum = month.month;
	if (monthNum === 12) {
		monthNum = 1;
		yearNum = yearNum + 1;
	} else {
		monthNum = monthNum + 1;
	}
	return {
		year: yearNum,
		month: monthNum,
	};
};

export const getCurrentMonths = (numMonths: number) => {
	const current = getCurrentMonthYear();
	return getNextMonths(current, numMonths);
};

export const isCurrentMonth = (month: Month) => {
	const now = getDateTimeNow();
	return month.year === now.getFullYear() && now.getMonth() + 1 === month.month;
};

export const isPastMonth = (month: Month) => {
	const now = getDateTimeNow();
	return (
		month.year < now.getFullYear() ||
		(month.year === now.getFullYear() && now.getMonth() + 1 > month.month)
	);
};

/**
 * Is monthA less than monthB
 *
 * @param monthA
 * @param monthB
 * @param orEqual
 */
export const isMonthBefore = (monthA: Month, monthB: Month, orEqual: boolean = false): boolean => {
	if (monthA.year === monthB.year && monthA.month === monthB.month && !orEqual) {
		return false;
	}

	if (monthA.year < monthB.year) {
		return true;
	}

	if (monthA.year === monthB.year && monthA.month <= monthB.month) {
		return true;
	}

	return false;
}

export const getMonthsBetween = (startMonth: Month, endMonth:Month): Month[] => {
	if (!isMonthBefore(startMonth, endMonth, true)) {
		throw new Error('startMonth must be before endMonth')
	}

	let monthIterator = startMonth;
	let months = [startMonth];
	while (
		endMonth.year > monthIterator.year ||
		(endMonth.year === monthIterator.year && endMonth.month > monthIterator.month)
	) {
		monthIterator = getNextMonth(monthIterator);
		months.push(monthIterator);
	}

	return months;
}

export const toMonthKey = (month: Month) => `${month.year}-${month.month}`;

export const getMonthFromKey = (monthKey: string): Month => {
	const [yearStr, monthStr] = monthKey.split('-');
	return {
		year: Number(yearStr),
		month: Number(monthStr),
	}
}

export const getMonthName = (month: Month) => {
	const date = new Date(`${month.year}/${month.month}/01 00:00:00`);
	return date.toLocaleString('default', {
		month: 'long',
	});
};

export const getShortMonthName = (month: Month) => {
	const date = new Date(`${month.year}/${month.month}/01 00:00:00`);
	return date.toLocaleString('default', {
		month: 'short',
	});
};

export const getShortMonthYear = (month: Month) => {
	const date = new Date(`${month.year}/${month.month}/01 00:00:00`);
	return date.toLocaleString('default', {
		month: 'short',
		year: 'numeric',
	});
};