import { AppDispatch } from '../../../view/store/types';
import { fetchAccountDevices, loadSyncDevices, syncStatusChanged } from '../../../view/store/sync/actions/devices';
import registerNewDevices from '../registerNewDevices';
import handleDeletedDevices from '../handleDeletedDevices';
import { SyncDevice } from '../../../domain/sync/SyncDevice';
import getSyncStatuses from '../getSyncStatuses';
import appDb from '../../persistence/indexed-db';
import Dexie from 'dexie';

const setupAccountSyncDevices = async (syncUUID: SyncDevice['uuid'], dispatch: AppDispatch) => {
	// fetch all devices and load into state
	const devices = await dispatch(fetchAccountDevices())

	// if any devices are not yet registered and are online then register them
	const registrations = registerNewDevices(devices, syncUUID);
	const deletions = handleDeletedDevices(devices);

	// load dexie sync states into state for each device
	const syncStatus = await getSyncStatuses();
	dispatch(loadSyncDevices(syncStatus));

	// listen to any sync status changes
	appDb.syncable.on('statusChanged', (status, url) => {
		dispatch(syncStatusChanged(url, Dexie.Syncable.StatusTexts[status]));
	});

	return Promise.all([registrations, deletions]).then(() => {
		return devices;
	})
}

export default setupAccountSyncDevices;