import {SyncDevice} from "../../domain/sync/SyncDevice";
import appDb from "../persistence/indexed-db";
import DataChannelManager from "../web-rtc/data-channel/DataChannelManager";

const disconnectSync = async (peerDeviceUUID?: SyncDevice['uuid']) => {
    if (peerDeviceUUID) return disconnectPeer(peerDeviceUUID);

    const syncUrls = await appDb.syncable.list();
    syncUrls.forEach(syncUrl => {
        disconnectPeer(syncUrl)
    })
}

const disconnectPeer = async (peerDeviceUUID: SyncDevice['uuid']) => {
    await appDb.syncable.disconnect(peerDeviceUUID);
    const dataChannel = DataChannelManager.getDataChannel(peerDeviceUUID);
    return dataChannel?.close();
}

export default disconnectSync;