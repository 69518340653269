import { SyncDevice } from '../../domain/sync/SyncDevice';
import { isAndroid, isIOS, isLinux, isMac, isWindows } from '../../domain/sync/operatingSystem';
import { Icon } from 'semantic-ui-react';
import { isChrome, isEdge, isFirefox, isIE, isSafari } from '../../domain/sync/webBrowsers';
import React from 'react';

type Props = {
	syncDevice: SyncDevice
}

const SyncDeviceIcon = (props: Props) => {
	return <>
		{getBrowserIcon(props.syncDevice)}
		{getOsIcon(props.syncDevice)}
	</>
}

export default SyncDeviceIcon;

const getOsIcon = (syncDevice: SyncDevice) => {
	if (isWindows(syncDevice)) return <Icon name={'windows'}/>;
	if (isMac(syncDevice)) return <Icon name={'apple'}/>;
	if (isLinux(syncDevice)) return <Icon name={'linux'}/>;
	if (isAndroid(syncDevice)) return <Icon name={'android'}/>;
	if (isIOS(syncDevice)) return <Icon name={'apple'}/>;
	return <Icon name={'computer'}/>;
}

const getBrowserIcon = (syncDevice: SyncDevice) => {
	if (isChrome(syncDevice)) return <Icon name={'chrome'}/>;
	if (isSafari(syncDevice)) return <Icon name={'safari'}/>;
	if (isFirefox(syncDevice)) return <Icon name={'firefox'}/>;
	if (isEdge(syncDevice)) return <Icon name={'edge'}/>;
	if (isIE(syncDevice)) return <Icon name={'internet explorer'}/>;
	return '';
}