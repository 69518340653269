import React from 'react';
import { Account } from '../../../domain/accounts/Account';
import './account-header.less';
import { getReconcileDate, isReconciling } from '../../store/accounts/selectors/reconciling';
import { useSelector } from 'react-redux';
import ReconcileSummary from '../ReconcileSummary/ReconcileSummary';
import { isSmallScreen } from '../../store/ui/selectors/window';
import AccountTools from '../AccountTools/AccountTools';
import AccountSummary from '../AccountSummary/AccountSummary';
import NoticeMessage from '../../common/Message/NoticeMessage';

type Props = {
	account: Account;
};

const AccountHeader = (props: Props) => {
	const reconciling = useSelector(isReconciling);
	const smallScreen = useSelector(isSmallScreen);
	const reconcileDate = useSelector(getReconcileDate);

	const title = !smallScreen && (
		<div className={'account-header__heading-container'}>
			<h2 className={'account-header__heading'}>{props.account.name}</h2>
			{reconciling && (
				<NoticeMessage>
					{`You are currently reconciling this account to ${reconcileDate?.toString()}`}
				</NoticeMessage>
			)}
		</div>
	);

	return (
		<section className={'account-header'}>
			{title}
			{!reconciling && <AccountSummary />}
			{!reconciling && !smallScreen && <AccountTools account={props.account} />}
			{reconciling && <ReconcileSummary />}
		</section>
	);
};

export default AccountHeader;
