import React, { useState } from 'react';
import AddMasterCategoryModal from '../AddMasterCategoryButton/AddMasterCategoryModal';
import UnhideCategoriesModal from '../UnhideCategoriesModal/UnhideCategoriesModal';
import { Button, Dropdown } from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import { isSmallScreen } from '../../store/ui/selectors/window';
import './budget-tools.less';
import {isAddMasterCategoryModalOpen} from "../../store/categories/selectors";
import {hideAddMasterCategoryModal, showAddMasterCategoryModal} from "../../store/categories/actions/addCategoryModal";

export const MIN_WIDTH = 270;

const BudgetTools = () => {
	const dispatch = useDispatch();
	const [unhideModalOpen, setUnhideModalOpen] = useState<boolean>(false);
	const masterCategoryModalOpen = useSelector(isAddMasterCategoryModalOpen);
	const smallScreen = useSelector(isSmallScreen);

	const modals = (
		<>
			<AddMasterCategoryModal
				modalOpen={masterCategoryModalOpen}
				onModalClose={() => dispatch(hideAddMasterCategoryModal())}
			/>
			<UnhideCategoriesModal
				modalOpen={unhideModalOpen}
				onModalClose={() => setUnhideModalOpen(false)}
			/>
		</>
	);

	if (smallScreen) {
		return (
			<>
				<Dropdown
					icon="ellipsis vertical"
					floating
					button
					className="top-nav__kabob icon"
					M
					direction="left"
				>
					<Dropdown.Menu>
						<Dropdown.Item
							icon="plus"
							text="Add Master Category"
							onClick={() => dispatch(showAddMasterCategoryModal())}
						/>
						<Dropdown.Item
							icon="plus"
							text="View Hidden Categories"
							onClick={() => setUnhideModalOpen(true)}
						/>
					</Dropdown.Menu>
				</Dropdown>
				{modals}
			</>
		);
	}

	return (
		<div className={'budget-tools'} style={{ minWidth: `${MIN_WIDTH}px` }}>
			<div className={'budget-tools__top'}>
				<Button.Group
					vertical
					labeled
					icon
					compact
					className={'budget-tools__button-group'}
				>
					<Button
						icon="eye"
						content="View Hidden Categories"
						onClick={() => setUnhideModalOpen(true)}
						className={'budget-tools__button'}
					/>
					<Button
						icon="plus"
						content="Add Master Category"
						onClick={() => dispatch(showAddMasterCategoryModal())}
						className={'budget-tools__button'}
					/>
				</Button.Group>
				{modals}
			</div>
		</div>
	);
};

export default BudgetTools;
