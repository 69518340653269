import { AppThunkAction } from '../../types';
import CategoriesRepo from '../../../../infrastructure/persistence/indexed-db/categories/CategoriesRepo';
import { MasterCategory } from '../../../../domain/categories';
import { getAllMasterCategories, getMasterCategoryOrder } from '../selectors';
import { hideAllSubCategories } from './subCategories';
import { OrderedMasterCategoriesState } from '../reducers/masterCategories';

export type MasterCategoryActions =
	| LoadMasterCategory
	| AddMasterCategory
	| UpdateMasterCategory
	| SetMasterCategoryOrder;

export const ADD_MASTER_CATEGORY = 'ADD_MASTER_CATEGORY';
export type AddMasterCategory = {
	type: typeof ADD_MASTER_CATEGORY;
	payload: {
		masterCategory: MasterCategory;
	};
};
export const addMasterCategoryCreator = (masterCategory: MasterCategory): AddMasterCategory => {
	return {
		type: ADD_MASTER_CATEGORY,
		payload: {
			masterCategory,
		},
	};
};

export const addMasterCategory = (masterCategory: MasterCategory): AppThunkAction => {
	return (dispatch, getState) => {
		return CategoriesRepo.putMasterCategory(masterCategory).then(() => {
			dispatch(addMasterCategoryCreator(masterCategory));
			const masterCategoryOrder = getMasterCategoryOrder(getState());
			if (!masterCategoryOrder.includes(masterCategory.uuid)) {
				dispatch(setMasterCategoryOrder([...masterCategoryOrder, masterCategory.uuid]));
			}
		});
	};
};


export const LOAD_MASTER_CATEGORY = 'LOAD_MASTER_CATEGORY';
export type LoadMasterCategory = {
	type: typeof LOAD_MASTER_CATEGORY;
	payload: {
		masterCategory: MasterCategory;
	};
};

export const loadMasterCategoryCreator = (masterCategory: MasterCategory): LoadMasterCategory => {
	return {
		type: LOAD_MASTER_CATEGORY,
		payload: {
			masterCategory,
		},
	};
};


export const UPDATE_MASTER_CATEGORY = 'UPDATE_MASTER_CATEGORY';
export type UpdateMasterCategory = {
	type: typeof UPDATE_MASTER_CATEGORY;
	payload: {
		masterCategory: MasterCategory;
	};
};
export const updateMasterCategoryCreator = (
	masterCategory: MasterCategory
): UpdateMasterCategory => {
	return {
		type: UPDATE_MASTER_CATEGORY,
		payload: {
			masterCategory,
		},
	};
};

export const updateMasterCategory = (masterCategory: MasterCategory): AppThunkAction => {
	return (dispatch, getState) => {
		const state = getState();
		const currentMasterCategory = getAllMasterCategories(state).get(masterCategory.uuid);
		if (!currentMasterCategory) {
			throw new Error('Cannot update master category that does not exist');
		}

		if (masterCategory.hidden && !currentMasterCategory.hidden) {
			// hide all sub categories
		}

		return CategoriesRepo.putMasterCategory(masterCategory).then(() => {
			dispatch(updateMasterCategoryCreator(masterCategory));
		});
	};
};

export const hideMasterCategory = (masterCategoryUUID: MasterCategory['uuid']): AppThunkAction => {
	return (dispatch, getState) => {
		const state = getState();
		const masterCategory = getAllMasterCategories(state).get(masterCategoryUUID);
		if (!masterCategory) {
			throw new Error(`Can't find master category id: ${masterCategoryUUID}`);
		}
		masterCategory.hidden = true;

		return Promise.all([
			dispatch(updateMasterCategory(masterCategory)),
			dispatch(hideAllSubCategories(masterCategoryUUID)),
		]);
	};
};

export const unHideMasterCategory = (
	masterCategoryUUID: MasterCategory['uuid']
): AppThunkAction => {
	return (dispatch, getState) => {
		const state = getState();
		const masterCategory = getAllMasterCategories(state).get(masterCategoryUUID);
		if (!masterCategory) {
			throw new Error(`Can't find master category id: ${masterCategoryUUID}`);
		}
		masterCategory.hidden = false;

		return dispatch(updateMasterCategory(masterCategory));
	};
};

export const SET_MASTER_CATEGORY_ORDER = 'SET_MASTER_CATEGORY_ORDER';
export type SetMasterCategoryOrder = {
	type: typeof SET_MASTER_CATEGORY_ORDER;
	payload: {
		order: MasterCategory['uuid'][];
	};
};

export const setMasterCategoryOrder = (newOrder: OrderedMasterCategoriesState): AppThunkAction => {
	return async (dispatch) => {
		dispatch({
			type: SET_MASTER_CATEGORY_ORDER,
			payload: {
				order: newOrder,
			},
		});
		return CategoriesRepo.setMasterCategoryOrder(newOrder);
	};
};

export const reOrderMasterCategory = (
	masterCategoryUUID: MasterCategory['uuid'],
	oldIndex: number,
	newIndex: number
): AppThunkAction => {
	return async (dispatch, getState) => {
		const state = getState();
		const masterCategoryOrder = getMasterCategoryOrder(state);
		masterCategoryOrder.splice(oldIndex, 1);
		masterCategoryOrder.splice(newIndex, 0, masterCategoryUUID);
		const newOrder = Array.from(masterCategoryOrder.values());
		return dispatch(setMasterCategoryOrder(newOrder));
	};
};
