import React from "react";
import NaiveDate from "../../../domain/util/NaiveDate";
import './native-date-picker.less';
import classNames from "classnames";
import supportsDateInput from "../util/supportsDateInput";
import DatePickerField from "./DatePickerField";

type Props = {
    name?: string,
    onChange?: (date: NaiveDate | undefined) => void
    value?: NaiveDate,
    className?: string,
    label?: string,
    disabled?: boolean,
}

const NativeDatePicker = (props: Props) => {
    if (!supportsDateInput()) {
        return <DatePickerField {...props} />
    }

    const classes = classNames({
        'native-date-picker': true,
        [props.className || '']: props.className
    })

    return <div>
        {props.label && (
            <label className="native-date-picker__label">
                {props.label}
            </label>
        )}
        <input
            type={'date'}
            name={props.name || undefined}
            value={props.value ? props.value.toString() : undefined}
            onChange={(e) => {
                const strDate = e.target.value || undefined;
                const date = strDate ? new NaiveDate(strDate) : undefined;
                (props.onChange && props.onChange(date));
            }}
            className={classes}
        />
    </div>
}

export default NativeDatePicker;