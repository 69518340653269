import { INCOME_CATEGORY_IDS, SubCategory } from '../categories';
import { Month } from '../util/date';
import { MonthBudgetsRepo } from './IMonthBudgetsRepo';
import { legacyToMoney, Money } from '../util/money';
import { CategoriesRepo } from '../categories/ICategoriesRepo';

export type SubCategoryBalanceMap = Map<SubCategory['uuid'], Money>;

const getSubCategoryBalances = async (
	categoryUUIDs: Array<SubCategory['uuid']>,
	month: Month
): Promise<SubCategoryBalanceMap> => {
	/**
	 * Income categories are special and don't really have balances so we exclude them.
	 * Could update this one day to calculate income for the month or something if that's useful.
	 */
	const nonIncomeSubCategories = categoryUUIDs.filter(c => !INCOME_CATEGORY_IDS.includes(c));
	if (!nonIncomeSubCategories.length) return new Map();

	// Get the category so we can get the profile it belongs to
	const category = await CategoriesRepo.getSubCategory(nonIncomeSubCategories[0]);
	if (!category) {
		throw new Error(`Cannot find category ${nonIncomeSubCategories[0]}`);
	}
	const monthBudget = await MonthBudgetsRepo.getMonthBudget(category.profileUUID, month);
	if (!monthBudget) {
		// TODO: in this scenario possible calculate the month budget if we dont have one
		return emptyResponse(nonIncomeSubCategories);
	}

	const values: Array<[SubCategory['uuid'], Money]> = nonIncomeSubCategories.map(subCatUUID => {
		const categoryBalance = monthBudget.categoryBalances.get(subCatUUID);
		const balance = categoryBalance ? categoryBalance.balance : 0;
		return [subCatUUID, legacyToMoney(balance)];
	})
	return new Map(values);
}

export default getSubCategoryBalances;

const emptyResponse = (categoryUUIDs: Array<SubCategory['uuid']>): Map<SubCategory['uuid'], Money> => {
	return new Map(categoryUUIDs.map(subCatUUID => [subCatUUID, legacyToMoney(0)]));
}