
const readFileFromInput = (blob: Blob): Promise<string> => {
    return new Promise((success, reject) => {
        const fileReader = new FileReader();

        fileReader.onerror = (e) => {
            reject(e);
        };

        fileReader.onloadend = () => {
            const csvText = fileReader.result as string;
            success(csvText);
        };

        fileReader.readAsText(blob);
    });
}

export default readFileFromInput;