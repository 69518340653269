import React from 'react';
import { Month } from '../../../domain/util/date';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';
import useMonthTotalBudgeted from '../../store/budget/hooks/useMonthTotalBudgeted';
import useMonthTotalOutflows from '../../store/budget/hooks/useMonthTotalOutflows';
import useMonthTotalBalance from '../../store/budget/hooks/useMonthTotalBalance';
import './month-totals-card.less';

type Props = {
	month: Month
}

const MonthTotalsCard = ({ month }: Props) => {
	const budgeted = useMonthTotalBudgeted(month);
	const outflows = useMonthTotalOutflows(month);
	const balance = useMonthTotalBalance(month);
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();

	return (
		<div className={'month-totals-card'}>
			<div className={'month-totals-card__cell'}>
				<div className={'month-totals-card__column-label'}>{'Budgeted'}</div>
				<span className={'month-totals-card__value'}>
					{formatCurrencyForDisplay(budgeted)}
				</span>
			</div>
			<div className={'month-totals-card__cell'}>
				<div className={'month-totals-card__column-label'}>{'Outflows'}</div>
				<span className={'month-totals-card__value'}>
					{formatCurrencyForDisplay(outflows)}
				</span>
			</div>
			<div className={'month-totals-card__cell'}>
				<div className={'month-totals-card__column-label'}>{'Balance'}</div>
				<span className={'month-totals-card__value'}>
					{formatCurrencyForDisplay(balance)}
				</span>
			</div>
		</div>
	);
};

export default MonthTotalsCard;
