import React, { useState } from 'react';
import Button from '../../../common/Button';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { isSmallScreen } from '../../../store/ui/selectors/window';
import AddEditTransactionModal from '../AddEditTransactionModal/AddEditTransactionModal';

type Props = {
	onModalClose?: () => void,
	onButtonClick?: () => void,
}

const AddEditTransactionButton = (props: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const smallScreen = useSelector(isSmallScreen);
	const addTransactionButtonSize = smallScreen ? 'large' : 'medium';

	const onClick = () => {
		setIsModalOpen(true)
		props.onButtonClick && props.onButtonClick();
	}

	const onClose = () => {
		setIsModalOpen(false);
		props.onModalClose && props.onModalClose()
	}

	return <>
		<Button icon labelPosition="left" onClick={onClick} size={addTransactionButtonSize} fluid={smallScreen}>
			<Icon name="plus square" />
			Add Transaction
		</Button>
		<AddEditTransactionModal
			isOpen={isModalOpen}
			onClose={onClose}
		/>
	</>
}

export default AddEditTransactionButton;