import { Store } from 'redux';
import { AppDispatch, RootState } from './types';

let dispatch: AppDispatch
let getState: () => RootState;

export const injectStore = (_store: Store) => {
	dispatch = _store.dispatch
	getState = _store.getState
}

export { dispatch, getState }
