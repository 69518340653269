import { AppDispatch, RootState } from '../../../view/store/types';
import connectToOnlineDevices from "../conntectToOnlineDevices";
import isLoggedIn from "../../../domain/auth/isLoggedIn";
import setupWebSocket from './setupWebSocket';
import setupSyncDevice from './setupSyncDevice';
import setupAccountSyncDevices from './setupAccountSyncDevices';
import setupDataChannels from './setupDataChannels';
import { Store } from 'redux';

const setup = async (dispatch: AppDispatch, getState: Store<RootState>['getState']) => {
    const loggedIn = await isLoggedIn();
    if (!loggedIn) {
        console.log("Not logged in");
        return;
    }

    // check if this device has a sync uuid set
    const syncUUID = await setupSyncDevice(dispatch);

    setupWebSocket(syncUUID, dispatch);
    setupDataChannels(dispatch, getState);

    await setupAccountSyncDevices(syncUUID, dispatch);

    // if any devices are online but don't have a data channel yet call AppDB.syncable.connect
    setTimeout(connectToOnlineDevices, 3000);
}

export default setup;
