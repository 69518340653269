import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSmallScreen } from '../../store/ui/selectors/window';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import { openReconcileModal, reOpenAccount } from '../../store/accounts/actions';
import { Account } from '../../../domain/accounts/Account';
import EditAccountModal from '../EditAccountModal/EditAccountModal';
import CloseAccountModal from '../CloseAccountModal/CloseAccountModal';

type Props = {
	account: Account;
};

const AccountTools = (props: Props) => {
	const dispatch = useDispatch();
	const [isEditingModal, setIsEditingModal] = useState(false);
	const [isCloseAccountModalOpen, setIsCloseAccountModalOpen] = useState(false);
	const smallScreen = useSelector(isSmallScreen);

	if (smallScreen) {
		return (
			<>
				<Dropdown
					icon='ellipsis vertical'
					floating
					button
					className='top-nav__kabob icon'
					direction='left'
				>
					<Dropdown.Menu>
						<Dropdown.Item
							icon='calculator'
							text='Reconcile Account'
							onClick={() => dispatch(openReconcileModal())}
						/>
						<Dropdown.Item
							icon='edit'
							text='Edit Account'
							onClick={() => setIsEditingModal(true)}
						/>
						{!props.account.closed && <Dropdown.Item icon='window close' text='Close Account'
									   onClick={() => setIsCloseAccountModalOpen(true)} />}
						{props.account.closed && <Dropdown.Item icon='reply' text='Reopen Account'
																 onClick={() => dispatch(reOpenAccount(props.account))} />}
					</Dropdown.Menu>
				</Dropdown>
				<EditAccountModal
					account={props.account}
					open={isEditingModal}
					onClose={() => setIsEditingModal(false)}
				/>
				<CloseAccountModal isOpen={isCloseAccountModalOpen} account={props.account}
								   onClose={() => setIsCloseAccountModalOpen(false)} />
			</>
		);
	}

	return (
		<>
			<Menu compact icon='labeled'>
				<Menu.Item name='edit' onClick={() => setIsEditingModal(true)}>
					<Icon name='edit' />
					Edit Account
				</Menu.Item>

				<Menu.Item name='reconcile' onClick={() => dispatch(openReconcileModal())}>
					<Icon name='calculator' />
					Reconcile Account
				</Menu.Item>
				{!props.account.closed && <Menu.Item
					name='close'
					onClick={() => setIsCloseAccountModalOpen(true)}
				>
					<Icon name='window close' />
					Close Account
				</Menu.Item>}
				{props.account.closed && <ReOpenAccountButton account={props.account} />}
			</Menu>
			<CloseAccountModal
				isOpen={isCloseAccountModalOpen}
				account={props.account}
				onClose={() => setIsCloseAccountModalOpen(false)}
			/>
			<EditAccountModal
				account={props.account}
				open={isEditingModal}
				onClose={() => setIsEditingModal(false)}
			/>
		</>
	);
};

export default AccountTools;

const ReOpenAccountButton = ({ account }: {
	account: Account;
}) => {
	const dispatch = useDispatch();
	const reOpen = () => {
		dispatch(reOpenAccount(account));
	};

	return (
		<Menu.Item
			name='close'
			onClick={reOpen}
		>
			<Icon name='reply' />
			Reopen Account
		</Menu.Item>
	);
};
