import { useSelector } from 'react-redux';
import {
	getAllUserCategories,
	getAllMasterCategories,
	getOrderedSubCategoryAssignments,
	getMasterCategoryOrder
} from '../selectors';
import {
	INCOME_CATEGORY_ID_THIS_MONTH,
	INCOME_CATEGORY_ID_NEXT_MONTH,
	SubCategory,
} from '../../../../domain/categories';
import {ReactNode, useMemo} from 'react';

export type MasterCategoryGroup = {
	label: string;
	options: Array<CategoryOption>;
};

export type CategoryOption = {
	label: ReactNode;
	value: SubCategory['uuid'];
};

const builtInOptions = [
	{
		label: 'Income',
		options: [
			{ label: 'Income this month', value: INCOME_CATEGORY_ID_THIS_MONTH },
			{ label: 'Income next month', value: INCOME_CATEGORY_ID_NEXT_MONTH },
		],
	},
];

const useCategoryOptions = (extraOptions: CategoryOption[] = []): Array<MasterCategoryGroup> => {
	const allCategories = useSelector(getAllUserCategories) as Map<SubCategory['uuid'], SubCategory>;
	const allMasterCategories = useSelector(getAllMasterCategories);
	const assignments = useSelector(getOrderedSubCategoryAssignments);
	const masterCategoryOrder = useSelector(getMasterCategoryOrder);

	return useMemo(() => {
		const extra: MasterCategoryGroup[] = extraOptions.length ? [{ label: '', options: extraOptions}] : [];
		const orderedAssignments: Array<[string, string[]]> = masterCategoryOrder.map((masterCategoryUUID) => {
			return [
				masterCategoryUUID,
				assignments.get(masterCategoryUUID) || []
			];
		})
		const customCategories = orderedAssignments
			.map(masterCatAssignments => {
				const [masterCategoryId, subCategoryIds] = masterCatAssignments;
				const masterCat = allMasterCategories.get(masterCategoryId);
				if (!masterCat || masterCat.hidden) {
					return null;
				}

				return {
					label: masterCat ? masterCat.name : '',
					options: getSubCategoryOptionsFromIds(subCategoryIds, allCategories),
				};
			}, [])
			.filter(option => option !== null) as Array<MasterCategoryGroup>;

		return [...extra, ...builtInOptions, ...customCategories];
	}, [extraOptions, allCategories, allMasterCategories, assignments, masterCategoryOrder]);
};

export default useCategoryOptions;

export const DEFAULT_CHOOSE_CATEGORY = { label: 'Choose a category...', value: '0' };

export const findOptionByValue = (
	value: CategoryOption['value'],
	options: Array<MasterCategoryGroup>
) => {
	const subCategories = options.reduce((acc: Array<CategoryOption>, group) => {
		return [...acc, ...group.options];
	}, []);

	return subCategories.find(subCat => {
		return subCat.value === value;
	});
};

const getSubCategoryOptionsFromIds = (
	subCategoryIds: Array<SubCategory['uuid']>,
	allCategories: Map<SubCategory['uuid'], SubCategory>
): Array<CategoryOption> => {
	return subCategoryIds
		.map(categoryId => {
			const cat = allCategories.get(categoryId);
			if (!cat) {
				throw new Error(`Category id:${categoryId} does not exist`);
			}

			if (cat.hidden) {
				return null;
			}

			return {
				label: cat.name,
				value: cat.uuid,
			};
		})
		.filter(option => option !== null) as Array<CategoryOption>;
};
