import React, {CSSProperties} from "react";
import {Field, useFormikContext} from "formik";
import SelectField, {SIZE_SMALL} from "../../../common/Form/SelectField";
import useAccountOptions, {AccountOption} from "../../../store/accounts/hooks/useAccountOptions";
import {FormValues} from "../AddEditTransactionForm/AddEditTransactionForm";

type Props = {
    style?: CSSProperties;
    isTransfer: boolean
}

const AccountColumn = (props: Props) => {
    const { setFieldValue, errors } = useFormikContext<FormValues>();
    const accountOptions = useAccountOptions();

    return <div
        className={'add-transaction-row__main-row-cell'}
        style={props.style}
    >
        <Field
            type="number"
            name="account"
            as={SelectField}
            options={accountOptions}
            placeholder={props.isTransfer ? 'Transfer from account...' : 'Select an account...'}
            onChange={(newValue: AccountOption) =>
                setFieldValue('account', newValue, true)
            }
            size={SIZE_SMALL}
            className={'add-transaction-row__select'}
        />
        {!!errors.account && <small className={'add-transaction-row__error-message'}>Account is required</small>}
    </div>
}

export default AccountColumn;