import { useEffect, useState } from 'react';
import { Currency } from '../../../domain/util/money';

export enum LoadingState {
	NOT_LOADED,
	LOADING,
	LOADED
}

type CurrencyDetails = {
	"name": string,
	"demonym": string,
	"majorSingle": string,
	"majorPlural": string,
	"ISOnum": number|null,
	"symbol": string,
	"symbolNative": string,
}

type CurrencyMap = {
	[currency_code: string]: CurrencyDetails
}

export type CurrencyOption = {
	label: string;
	value: Currency['code'];
};

const useCurrencyOptions = (shouldLoadCurrencies: boolean = true): [LoadingState, CurrencyOption[]] => {
	const [currencies, setCurrencies] = useState<CurrencyOption[]>([]);
	const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NOT_LOADED);

	useEffect(() => {
		if (shouldLoadCurrencies && loadingState === LoadingState.NOT_LOADED) {
			setLoadingState(LoadingState.LOADING)
			import('../../../domain/settings/currencies/currencies.json')
				.then((imported) => {
					setCurrencies(mapOptions(imported.default))
					setLoadingState(LoadingState.LOADED)
				})
		}
	}, [loadingState, shouldLoadCurrencies])

	return [loadingState, currencies]
}

export default useCurrencyOptions;

const mapOptions = (currencies: CurrencyMap): CurrencyOption[] => {
	return Object.keys(currencies).map(currencyCode => {
		return {
			label: `${currencies[currencyCode].name} (${currencies[currencyCode].symbol})`,
			value: currencyCode
		}
	})
}
