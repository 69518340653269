import {FormValues} from "./AddEditTransactionForm";
import {AccountsMap} from "../../../store/accounts/selectors/accounts";

export const getSelectedAccount = (formValues: FormValues, accounts: AccountsMap) => {
    if (!formValues.account) {
        return null;
    }

    return accounts.get(formValues.account.value)
}

export const getTransferAccount = (formValues: FormValues, accounts: AccountsMap) => {
    if (!formValues.transferToAccount) {
        return null;
    }

    return accounts.get(formValues.transferToAccount.value)
}

