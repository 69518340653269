import React from "react";
import {SyncDeviceStatus} from "../../store/app/hooks/useSyncDevices";
import Button, {ButtonGroup} from "../../common/Button";
import Card from "../../common/Card";
import Dexie from "dexie";
import appDB from "../../../infrastructure/persistence/indexed-db";
import disconnectSync from "../../../infrastructure/sync/disconnectSync";
import {AppDispatch} from "../../store/types";
import deleteDevice from "../../../domain/sync/deleteDevice";
import {fetchAccountDevices, fetchSyncDevices} from "../../store/sync/actions/devices";
import {useDispatch} from "react-redux";

type Props = { syncDevice: SyncDeviceStatus }

const SyncDeviceCardFooter = ({ syncDevice }: Props) => {
    const dispatch = useDispatch();

    if (syncDevice.deletedAt) return null;

    return <Card.Content extra>
        <ButtonGroup basic size={'small'}>
            {syncNowButton(syncDevice)}
            {disconnectButton(syncDevice)}
        </ButtonGroup>
        {deleteButton(syncDevice, dispatch)}
    </Card.Content>
}

export default SyncDeviceCardFooter;

const syncNowButton = (syncDevice: SyncDeviceStatus) => {
    // @ts-ignore
    if (syncDevice.syncStatus !== Dexie.Syncable.StatusTexts[Dexie.Syncable.OFFLINE]) {
        return (
            <Button
                basic
                size={'tiny'}
                onClick={() => {
                    if (syncDevice.syncStatus === "ERROR") {
                        appDB.syncable.disconnect(syncDevice.syncUrl);
                    }
                    appDB.syncable.connect('webrtc', syncDevice.syncUrl);
                }}
            >
                Sync Now
            </Button>
        );
    }

    return null;
};

const disconnectButton = (syncDevice: SyncDeviceStatus) => {
    return (
        <Button
            basic
            size={'tiny'}
            onClick={async () => {
                await disconnectSync(syncDevice.syncUrl)
            }}
        >
            Disconnect
        </Button>
    );
};

const deleteButton = (syncDevice: SyncDeviceStatus, dispatch: AppDispatch) => {
    return <Button basic floated={'right'} size={'small'} onClick={async () => {
        await deleteDevice(syncDevice.uuid);
        dispatch(fetchSyncDevices());
        await dispatch(fetchAccountDevices())
    }}>
        Delete
    </Button>
}