import { Month } from '../../util/date';
import { MasterCategory, SubCategory } from '../../categories';
import { MonthBudget } from '../index';
import TransactionsRepo from '../../../infrastructure/persistence/indexed-db/transactions/TransactionsRepo';
import CategoryBudgetsRepo from '../../../infrastructure/persistence/indexed-db/categories/CategoryBudgetsRepo';
import calculateAllCategoryMonthTotals from './subCategory/calculateAllCategoryMonthTotals';
import getAllMasterCategoryBalances from './masterCategory/getAllMasterCategoryBalances';
import getAllMasterCategoryBudgeted from './masterCategory/getAllMasterCategoryBudgeted';
import getAllMasterCategoryOutflows from './masterCategory/getAllMasterCategoryOutflows';
import getMonthBalance from './month/getMonthBalance';
import getHiddenCategoryTotals from './subCategory/getHiddenCategoryTotals';
import { Profile } from '../../profiles';
import { uuid } from '../../util/uuid';
import MonthBudgetsRepo from '../../../infrastructure/persistence/indexed-db/budgets/MonthBudgetsRepo';
import AccountRepo from '../../../infrastructure/persistence/indexed-db/accounts/AccountRepo';
import { Account } from '../../accounts/Account';

const calculateMonthBudget = async (
	month: Month,
	profile: Profile,
	categories: Array<SubCategory>,
	categoryAssignments: Map<MasterCategory['uuid'], Array<SubCategory['uuid']>>,
	lastMonthsBudget?: MonthBudget
): Promise<MonthBudget> => {
	const accounts: Map<Account['uuid'], Account> = await AccountRepo.getAll(profile.uuid).then(
		accounts => {
			return new Map(accounts.map(a => [a.uuid, a]));
		}
	);

	// get month income
	const monthIncome = await TransactionsRepo.getTotalIncomeForMonth(month, profile.uuid, accounts);
	// get month budgeted
	const monthBudgeted = await CategoryBudgetsRepo.getTotalBudgetedForMonth(month, profile.uuid);

	// get balance carryover to next month
	const lastMonthsBalances = lastMonthsBudget ? lastMonthsBudget.categoryBalances : undefined;

	const allCategoryBalances = await calculateAllCategoryMonthTotals(
		profile.uuid,
		categories,
		month,
		accounts,
		lastMonthsBalances
	);

	const hiddenCategoryTotals = getHiddenCategoryTotals(categories, allCategoryBalances);
	const monthBalance = getMonthBalance(allCategoryBalances);
	const lastMonthsBalance = lastMonthsBudget ? lastMonthsBudget.monthBalance : 0;
	const lastMonthAvailableToBudget = lastMonthsBudget ? lastMonthsBudget.availableToBudget : 0;
	const availableToBudget =
		lastMonthAvailableToBudget + lastMonthsBalance + monthIncome - monthBudgeted;

	const alreadyPersistedMonth = await MonthBudgetsRepo.getMonthBudget(profile.uuid, month);

	return {
		uuid: alreadyPersistedMonth?.uuid || uuid(),
		month,
		profileUUID: profile.uuid,
		totalIncome: monthIncome,
		totalBudgeted: monthBudgeted,
		monthBalance: monthBalance,
		lastMonthsBalance: lastMonthsBudget ? lastMonthsBudget.monthBalance : 0,
		availableToBudget: availableToBudget,
		notBudgetedLastMonth: lastMonthsBudget ? lastMonthsBudget.availableToBudget : 0,
		categoryBalances: allCategoryBalances.categoryBalances,
		categoryOutflows: allCategoryBalances.categoryOutflows,
		categoryBudgeted: allCategoryBalances.categoryBudgeted,
		masterCategoryBalances: getAllMasterCategoryBalances(
			categoryAssignments,
			allCategoryBalances
		),
		masterCategoryBudgeted: getAllMasterCategoryBudgeted(
			categoryAssignments,
			allCategoryBalances
		),
		masterCategoryOutflows: getAllMasterCategoryOutflows(
			categoryAssignments,
			allCategoryBalances
		),
		hiddenCategoryTotals: hiddenCategoryTotals,
	};
};

export default calculateMonthBudget;
