import { CategoryBudget } from '../../budget';
import { SubCategory } from '../index';
import { Month } from '../../util/date';
import {Profile} from "../../profiles";

const createNewCategoryBudget = (
	month: Month,
	profileUUID: Profile['uuid'],
	subCategoryUUID: SubCategory['uuid'],
	amount = 0,
	carryover = false
) => {
	const newCategoryBudget: CategoryBudget = {
		profileUUID: profileUUID,
		year: month.year,
		month: month.month,
		categoryId: subCategoryUUID,
		amount,
		carryover,
	};

	return newCategoryBudget;
};

export default createNewCategoryBudget;
