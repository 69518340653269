import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { useLiveQuery } from 'dexie-react-hooks';
import { TransactionTagsRepo } from '../../../domain/transactions/ITransactionTagsRepo';

const useTransactionTags = () => {
	const profile = useRequiredActiveProfile();
	const result = useLiveQuery(() => TransactionTagsRepo.getAll(profile.uuid));
	if (!result) return [];
	return result;
}

export default useTransactionTags;