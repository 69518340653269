import React from 'react';
import {Formik, FormikHelpers} from 'formik';
import {addTransaction, editTransaction} from '../../../store/accounts/actions/transactions';
import NaiveDate from '../../../../domain/util/NaiveDate';
import useAccountOptions, {
	AccountOption,
} from '../../../store/accounts/hooks/useAccountOptions';
import {STATUS_UNCLEARED, Transaction} from '../../../../domain/transactions/Transaction';
import useCategoryOptions, {
	CategoryOption,
	DEFAULT_CHOOSE_CATEGORY,
	MasterCategoryGroup,
} from '../../../store/categories/hooks/useCategoryOptions';
import {FormSplit} from '../../AddTransactionRow/SplitRow';
import {Profile} from '../../../../domain/profiles';
import {Account} from '../../../../domain/accounts/Account';
import {getUUID} from '../../../../domain';
import useRequiredActiveProfile from '../../../store/profiles/hooks/useRequiredActiveProfile';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch} from '../../../store/types';
import {useParams} from 'react-router-dom';
import createTransaction from '../../../../domain/transactions/operations/createTransaction';
import {SubCategory, TRANSFER_CATEGORY_ID} from '../../../../domain/categories';
import validationSchema from './validationSchema';
import {getAllAccounts} from "../../../store/accounts/selectors/accounts";
import isBudgetedAccountTransfer from "../../../../domain/transactions/util/isBudgetedAccountTransfer";
import {requiredGet} from "../../../../domain/util/map";
import { TransactionTag } from '../../../../domain/transactions/TransactionTag';
import getInitialValues from './getInitialValues';

export type FormValues = {
	uuid: Transaction['uuid']|null;
	date: NaiveDate;
	category: CategoryOption | null;
	account: AccountOption | null;
	transferToAccount: AccountOption | null;
	profileUUID: Profile['uuid'];
	note: string;
	inflow: number;
	outflow: number;
	payee: string;
	splits: Array<FormSplit>;
	status: Transaction['status'];
	isTransfer: boolean
	tags: Array<TransactionTag['uuid']>
};

type Props = {
	onSave: () => void;
	onCancel: () => void;
	transaction?: Transaction;
	isTransfer?: boolean,
	children: React.ReactNode;
};

const AddEditTransactionForm = (props: Props) => {
	const categoryOptions = useCategoryOptions();
	const accountOptions = useAccountOptions(true);
	const accounts = useSelector(getAllAccounts);
	const activeProfile = useRequiredActiveProfile();
	const dispatch = useDispatch<AppDispatch>();
	const { accountUUID } = useParams<{accountUUID: Account['uuid']}>();
	const initialValues = getInitialValues(
		categoryOptions,
		accountOptions,
		activeProfile.uuid,
		props.transaction,
		accountUUID,
		props.isTransfer
	);
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema(accounts)}
			validateOnMount={false}
			validateOnChange={false}
			enableReinitialize={true}
			onSubmit={(values, formProps) => {
				console.log('form values', values)
				const transactionToSave = getTransactionFromForm(values, accounts);
				if (props.transaction) {
					return dispatch(editTransaction(transactionToSave, props.transaction)).then(() => {
						formProps.resetForm();
						props.onSave();
					});
				}
				return dispatch(addTransaction(transactionToSave)).then(() => {
					formProps.resetForm();
					props.onSave();
				});
			}}
		>
			{props.children}
		</Formik>
	);
};

export default AddEditTransactionForm;

const createEmptySplit = (): FormSplit => ({
	category: DEFAULT_CHOOSE_CATEGORY,
	note: '',
	inflow: 0,
	outflow: 0,
});

export const splitTransaction = (values: FormValues, setFieldValue: FormikHelpers<FormValues>['setFieldValue']) => {
	const newNumSplits = values.splits.length === 0 ? 2 : 1;
	const newSplits = new Array(newNumSplits).fill(createEmptySplit());
	setFieldValue(`splits`, [...values.splits, ...newSplits]);
}

export const getSplitAmountsRemaining = (totalOutflow: number, totalInflow: number, splits: FormSplit[]) => {
	const totalSplitsOutflow = splits.reduce((acc, split) => acc + split.outflow, 0);
	const totalSplitsInflow = splits.reduce((acc, split) => acc + split.inflow, 0);
	const remainingOutflow = totalOutflow - totalSplitsOutflow;
	const remainingInflow = totalInflow - totalSplitsInflow;
	return {
		remainingInflow: remainingInflow < 0 ? remainingOutflow + remainingInflow * -1 : remainingOutflow,
		remainingOutflow: remainingOutflow < 0 ? remainingInflow + remainingOutflow * -1 : remainingInflow
	}
}

export const clearSplits = (setFieldValue: FormikHelpers<FormValues>['setFieldValue']) => {
	setFieldValue('splits', []);
	setFieldValue('category', null);
}

export const removeSplit = (splitNum: number, splits: FormSplit[]): FormSplit[] => {
	if (splits.length <= 2) {
		return [];
	}
	const newSplits = splits.filter((split, index) => {
		return index !== splitNum;
	});
	return [...newSplits];
}

export const isTransfer = (values: FormValues) => {
	return values.isTransfer
}

export const isTransferIntoAccount = (values: FormValues, accountUUID: Account['uuid']): boolean => {
	if (!isTransfer(values)) {
		return false;
	}

	return values.transferToAccount?.value === accountUUID
}

export const isTransferOutOfAccount = (values: FormValues, accountUUID: Account['uuid']): boolean => {
	if (!isTransfer(values)) {
		return false;
	}

	return values.account?.value === accountUUID;
}

export const isNewTransaction = (values: FormValues) => {
	return values.uuid === null;
}

export const hasSplits = (values: FormValues) => {
	return values.splits.length !== 0;
}

const getCategoryUUIDsFromForm = (formValues: FormValues, accounts: Map<Account['uuid'], Account>): SubCategory['uuid'][] | null => {
	if (formValues.isTransfer && formValues.account && formValues.transferToAccount) {
		const sourceAccount = requiredGet(accounts, formValues.account.value);
		const targetAccount = requiredGet(accounts, formValues.transferToAccount.value);
		if (isBudgetedAccountTransfer(sourceAccount, targetAccount)) {
			return [TRANSFER_CATEGORY_ID];
		}
	}

	if (formValues.account) {
		if (requiredGet(accounts, formValues.account.value).isOffBudget) return [];
	}

	if (formValues.splits.length > 0) {
		return formValues.splits.map(split => split.category.value)
	}

	if (formValues.category) {
		return [formValues.category.value];
	}

	return null;
}

const getTransactionFromForm = (formValues: FormValues, accounts: Map<Account['uuid'], Account>): Transaction => {

	const categories = getCategoryUUIDsFromForm(formValues, accounts);
	if (categories === null) {
		throw new Error(`Can't create transaction without a category`);
	}

	if (!formValues.account) {
		throw new Error(`Can't create transaction without an account selected`);
	}

	return createTransaction({
		uuid: formValues.uuid || getUUID(),
		date: formValues.date,
		categoryId: categories,
		accountUUID: formValues.account.value,
		transferToAccountUUID: formValues.transferToAccount ? formValues.transferToAccount.value : null,
		transferToAccountStatus: formValues.transferToAccount ? STATUS_UNCLEARED : null,
		profileUUID: formValues.profileUUID,
		note: formValues.note,
		inflow: formValues.inflow,
		outflow: formValues.outflow,
		payee: formValues.payee,
		status: formValues.status,
		splits: formValues.splits.map(split => {
			return {
				categoryUUID: split.category.value,
				note: split.note,
				inflow: split.inflow,
				outflow: split.outflow,
			};
		}),
		tags: formValues.tags
	});
};

export const getSelectedCategory = (
	categoryOptions: Array<MasterCategoryGroup>,
	transactionCategories: Array<SubCategory['uuid']>
): CategoryOption => {
	if (transactionCategories.length > 1) {
		return DEFAULT_CHOOSE_CATEGORY;
	}

	const categoryUUID = transactionCategories[0];

	const subCategories = categoryOptions.reduce((acc: Array<CategoryOption>, group) => {
		return [...acc, ...group.options];
	}, []);

	const cat = subCategories.find(subCat => {
		return subCat.value === categoryUUID;
	});

	if (cat) {
		return cat;
	}

	return DEFAULT_CHOOSE_CATEGORY;
};

export const getSelectedAccount = (
	accountOptions: Array<AccountOption>,
	transactionAccountUUID: Account['uuid'] | null
): AccountOption|null => {
	const found = accountOptions.find(acctOpt => {
		return acctOpt.value === transactionAccountUUID;
	});

	return found || null;
};