import React, { useMemo } from 'react';
import CurrencyCell from '../CurrencyCell/CurrencyCell';
import { useDispatch, useSelector } from 'react-redux';
import { createBudgetSelector } from '../../store/categories/selectors';
import * as categoryBudgetActions from '../../store/categories/actions/budgets';
import { dangerouslyRound, formatCurrencyForInput } from '../../../domain/util/currency';
import {
	createCategoryBalanceSelector,
	createCategoryOutflowSelector,
} from '../../store/budget/selectors/monthBudget';
import './month-category-cell.less';
import { isPastMonth, Month } from '../../../domain/util/date';
import { SubCategory } from '../../../domain/categories';
import classNames from 'classnames';
import setAmount from '../../../domain/categories/operations/setAmount';
import createNewCategoryBudget from '../../../domain/categories/operations/createNewCategoryBudget';
import setCarryover from '../../../domain/categories/operations/setCarryover';
import { Icon } from 'semantic-ui-react';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { Link } from 'react-router-dom';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import { Profile } from '../../../domain/profiles';
import { DateFilterType } from '../../accounts/TransactionsTable/TableFilters/DateRangeFilter';
import NaiveDate from '../../../domain/util/NaiveDate';
import CellValue from "../CellValue/CellValue";
import * as money from '../../../domain/util/money';
import { legacyToMoney } from '../../../domain/util/money';

type Props = {
	month: Month;
	category: SubCategory;
	isLoading: boolean
};

const MonthCategoryCells = ({ month, category, isLoading }: Props) => {
	const activeProfile = useRequiredActiveProfile();
	const budgetSelector = useMemo(() => {
		return createBudgetSelector(month.year, month.month, category.uuid);
	}, [month.year, month.month, category.uuid]);
	const categoryBudget =
		useSelector(budgetSelector) ||
		createNewCategoryBudget(month, activeProfile.uuid, category.uuid);

	const balanceSelector = useMemo(() => {
		return createCategoryBalanceSelector(month.year, month.month, category.uuid);
	}, [month.year, month.month, category.uuid]);
	const balance = useSelector(balanceSelector);

	const outflowsSelector = useMemo(() => {
		return createCategoryOutflowSelector(month.year, month.month, category.uuid);
	}, [month.year, month.month, category.uuid]);
	const outflows = useSelector(outflowsSelector);

	const dispatch = useDispatch();

	const onBlur = (newValue: string) => {
		const newAmount = money.stringToMoney(newValue, activeProfile.currency);
		const oldAmount = legacyToMoney(categoryBudget.amount);

		// value hasn't changed
		if (money.equal(newAmount, oldAmount)) {
			return;
		}

		dispatch(
			categoryBudgetActions.updateCategoryBudget(setAmount(categoryBudget, money.toLegacy(newAmount)))
		);
	};

	const onNegativeBalanceClick = () => {
		if (balance >= 0) {
			return;
		}

		dispatch(
			categoryBudgetActions.updateCategoryBudget(
				setCarryover(categoryBudget, !categoryBudget.carryover)
			)
		);
	};

	const roundedBalance = dangerouslyRound(balance);

	const budgetCellClasses = classNames({
		'month-category-cell': true,
		'month-category-cell--past-budgeted': isPastMonth(month),
		'month-category-cell--budgeted': !isPastMonth(month),
	});

	const balanceClasses = classNames({
		'month-category-cell__cell-value': true,
		'month-category-cell__cell-value--negative': roundedBalance < 0,
	});

	return (
		<>
			<div className={budgetCellClasses}>
				{!isLoading && <CurrencyCell value={categoryBudget.amount} onBlur={onBlur} />}
			</div>
			<div className={'month-category-cell'}>
				{!isLoading && <span className={'month-category-cell__cell-value'}>
					{getOutflowCell(outflows, activeProfile, month, category)}
				</span>}
			</div>
			<div className={'month-category-cell'}>
				{!isLoading && <span className={balanceClasses} onClick={onNegativeBalanceClick}>
					<CellValue numericValue={roundedBalance}>
						{categoryBudget.carryover && balance < 0 ? <Icon name={'arrow right'} /> : null}
					</CellValue>
				</span>}
			</div>
		</>
	);
};

export default MonthCategoryCells;

const getOutflowCell = (outflows: number, activeProfile: Profile, month: Month, category: SubCategory) => {
	if (dangerouslyRound(outflows) === 0) {
		return formatCurrencyForInput(outflows);
	}

	return (
		<Link
			to={APP_LINKS.accounts(
				activeProfile.uuid,
				DateFilterType.CustomRange,
				NaiveDate.getStartOfMonth(month),
				NaiveDate.getEndOfMonth(month),
				[category.uuid]
			)}
			className={'month-category-cell__outflows-link'}
			tabIndex={1}
		>
			{formatCurrencyForInput(outflows)}
		</Link>
	);
};
