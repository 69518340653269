import { MasterCategory, SubCategory } from '../../../categories';
import { getUUID } from '../../../index';
import { SubCategoryNameMap } from './subCategories';
import {Profile} from "../../../profiles";
import {BudgetRow} from "./index";

export type MasterCategoryNameMap = Map<MasterCategory['name'], MasterCategory>;

export const MASTER_CATEGORY_HIDDEN = 'Hidden Categories';
export const MASTER_CATEGORY_UNCATEGORIZED = 'Uncategorized Transactions';
export const MASTER_CATEGORY_CC_PAYMENTS = 'Credit Card Payments';

export const IGNORED_MASTER_CATEGORIES = [MASTER_CATEGORY_UNCATEGORIZED, MASTER_CATEGORY_CC_PAYMENTS]

export const getMasterCategoriesMap = (budgetRows: BudgetRow[], profile: Profile): MasterCategoryNameMap => {
	return budgetRows.reduce((acc: MasterCategoryNameMap, row) => {
		const masterCategoryName = getMasterCategoryNameFromRow(row);
		if (isIgnoredCategory(row)) {
			return acc;
		}
		const masterCategory: MasterCategory = {
			uuid: getUUID(),
			name: masterCategoryName,
			hidden: false,
			profileUUID: profile.uuid
		};
		acc.set(masterCategoryName, masterCategory);
		return acc;
	}, new Map());
};

export const isIgnoredCategory = (row: BudgetRow) => {
	return IGNORED_MASTER_CATEGORIES.includes(row.masterCategory)
}

const getMasterCategoryNameFromRow = (row: BudgetRow): string => {
	if (row.category === '') {
		return row.masterCategory;
	}

	if (row.masterCategory === MASTER_CATEGORY_HIDDEN) {
		const [masterCategoryName] = row.category.split(':');
		return masterCategoryName;
	}

	return row.masterCategory.trim();
};

/**
 * If all subcategories of a master category are hidden then the master category should be hidden
 * as well.
 *
 * @param masterCategoryMap
 * @param subCategoryMap
 */
export const hideHiddenMasterCategories = (
	masterCategoryMap: MasterCategoryNameMap,
	subCategoryMap: SubCategoryNameMap
): MasterCategoryNameMap => {
	const categoriesByMasterUUID = Array.from(subCategoryMap.values()).reduce(
		(acc, subCategory) => {
			const masterCatSubCategories = acc.get(subCategory.masterCategoryId);
			if (!masterCatSubCategories) {
				acc.set(subCategory.masterCategoryId, [subCategory]);
			} else {
				acc.set(subCategory.masterCategoryId, [subCategory, ...masterCatSubCategories]);
			}
			return acc;
		},
		new Map()
	);

	return Array.from(masterCategoryMap.values()).reduce((acc, masterCategory) => {
		if (shouldMasterCategoryBeHidden(masterCategory, categoriesByMasterUUID)) {
		    masterCategory.hidden = true;
        }
        acc.set(masterCategory.name, masterCategory);
        return acc;
	}, new Map());
};

const shouldMasterCategoryBeHidden = (
	masterCategory: MasterCategory,
	categoriesByMasterUUID: Map<MasterCategory['uuid'], SubCategory[]>
) => {
	const subCategories = categoriesByMasterUUID.get(masterCategory.uuid) || [];
	const shouldNotBeHidden = subCategories.find((subCategory) => {
	    return !subCategory.hidden;
    })

    return !Boolean(shouldNotBeHidden);
};
