import React from 'react';
import { AdvancedModal } from '../../../../common/Modal';
import { Button } from '../../../../common/Button/Button';
import { Button as SemanticUIButton } from 'semantic-ui-react';
import SelectField, { SIZE_SMALL } from '../../../../common/Form/SelectField';
import useCategoryOptions, {
	CategoryOption,
} from '../../../../store/categories/hooks/useCategoryOptions';
import { Field, useFormikContext } from 'formik';
import {
	clearSplits,
	FormValues,
	removeSplit,
	splitTransaction,
} from '../../AddEditTransactionForm/AddEditTransactionForm';
import './splits-modal.less';
import OutflowInflowField, { AmountType, Size } from '../../OutflowInflowFIeld/OutflowInflowField';
import SplitRowFooter from '../../../AddTransactionRow/SplitRowFooter';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const SplitsModal = (props: Props) => {
	const categoryOptions = useCategoryOptions();
	const { values, errors, setFieldValue } = useFormikContext<FormValues>();
	const actions = (
		<>
			<Button
				onClick={() => {
					clearSplits(setFieldValue);
					props.onClose();
				}}
				label={'Clear Splits'}
				type={'warning'}
			/>
			<Button onClick={() => splitTransaction(values, setFieldValue)} label={'Add Split'} />
			<Button
				disabled={!!errors?.splits}
				onClick={props.onClose}
				label={'Ok'}
				type={'success'}
			/>
		</>
	);

	return (
		<AdvancedModal
			open={props.isOpen}
			actions={actions}
			title={'Split Categories'}
			onClose={props.onClose}
		>
			{values.splits.map((split, splitIndex) => {
				return (
					<div className={'splits-modal__each-split'}>
						<div className={'splits-modal__split-amount'}>
							<OutflowInflowField
								size={Size.SMALL}
								initialType={
									split.inflow !== 0 ? AmountType.INFLOW : AmountType.OUTFLOW
								}
								initialAmount={split.outflow || split.inflow}
								onChange={(newAmount, type) => {
									if (type === AmountType.OUTFLOW) {
										setFieldValue(`splits[${splitIndex}]`, Object.assign(
											values.splits[splitIndex],
											{
												outflow: newAmount,
												inflow: 0
											})
										);
										return;
									}

									setFieldValue(`splits[${splitIndex}]`, Object.assign(
										values.splits[splitIndex],
										{
											outflow: 0,
											inflow: newAmount
										})
									);
								}}
							/>
						</div>
						<div className={'splits-modal__split-category'}>
							{splitIndex > 1 && (
								<SemanticUIButton
									onClick={() => {
										const newSplits = removeSplit(splitIndex, values.splits);
										setFieldValue(`splits`, newSplits);
									}}
									icon={'close'}
								/>
							)}
							<div className={'splits-modal__input'}>
								<Field
									type="number"
									name={`splits[${splitIndex}].category`}
									as={SelectField}
									options={categoryOptions}
									onChange={(newValue: CategoryOption) => {
										setFieldValue(`splits[${splitIndex}].category`, newValue);
									}}
									isDisabled={values.splits.length > 0}
									size={SIZE_SMALL}
									placeholder={'Choose a category...'}
								/>
							</div>
						</div>
					</div>
				);
			})}
			{values.splits.length > 0 && (
				<SplitRowFooter
					transactionInflow={values.inflow}
					transactionOutflow={values.outflow}
					splits={values.splits}
				/>
			)}
		</AdvancedModal>
	);
};

export default SplitsModal;
