export enum SignallingRole {
    'initiator'= 'initiator',
    'receiver' = 'receiver'
}

export const RoleAssignmentEventType = 'role';
export type RoleAssignmentEvent = {
    type: typeof RoleAssignmentEventType,
    payload: SignallingRole
}

export const PeerAnswerEventType = 'answer';
export type PeerAnswerEvent = {
    type: typeof PeerAnswerEventType,
    payload: RTCSessionDescriptionInit
}

export const PeerOfferEventType = 'offer';
export type PeerOfferEvent = {
    type: typeof PeerOfferEventType,
    payload: RTCSessionDescriptionInit
}

export const StartICEEventType = 'startICE';
export type StartICEEvent = {
    type: typeof StartICEEventType,
    payload: null
}

export const ICECandidateEventType = 'ICECandidate';
export type ICECandidateEvent = {
    type: typeof ICECandidateEventType,
    payload: RTCIceCandidate
}
// TODO: Use these?
// type SignallingServerEvents = RoleAssignmentEvent | PeerAnswerEvent | PeerOfferEvent | StartICEEvent | ICECandidateEvent;

export interface DataChannelProvider {
    getDataChannel(): Promise<RTCDataChannel>;
}
