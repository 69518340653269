import React, { useRef, useState } from 'react';
import { AdvancedModal } from '../../common/Modal';
import { Button } from '../../common/Button/Button';
import { Dimmer, Icon, Loader } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react';
import importFromBackup from '../../../domain/settings/import/Dollero/importFromBackup';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import { Profile } from '../../../domain/profiles';

type Props = {
	open: boolean;
	onClose: () => void;
};

const DolleroImportModal = (props: Props) => {
	const [isImporting, setIsImporting] = useState(false);
	const [importedProfile, setImportedProfile] = useState<Profile | null>(null);
	const inputRef = useRef(null);

	const doImport = () => {
		if (!inputRef || !inputRef.current) {
			console.error('File input refs missing');
			return;
		}

		setIsImporting(true);

		const fileReader = new FileReader();
		fileReader.onloadend = () => {
			const jsonString = fileReader.result as string;
			const jsonData = JSON.parse(jsonString);
			importFromBackup(jsonData).then(profile => {
				setImportedProfile(profile);
			});
		};
		// @ts-ignore
		fileReader.readAsText(inputRef.current.files[0]);
	};

	if (importedProfile) {
		window.location.replace(APP_LINKS.budget(importedProfile.uuid));
	}

	return (
		<AdvancedModal
			title={'Import from Dollero Backup'}
			open={props.open}
			onClose={props.onClose}
			actions={
				<>
					<Button onClick={props.onClose}>
						<Icon name="remove" /> Cancel
					</Button>
					<Button type={'success'} onClick={doImport}>
						<Icon name="download" /> Import
					</Button>
				</>
			}
		>
			<Dimmer active={isImporting} inverted>
				<Loader active={isImporting} disabled={!isImporting} inverted>
					Importing
				</Loader>
			</Dimmer>

			{!isImporting && (
				<Form>
					<Form.Field>
						<label>{'Backup file (.json)'}</label>
						<input
							type="file"
							id="input"
							ref={inputRef}
							accept=".json, application/json"
						/>
					</Form.Field>
				</Form>
			)}
		</AdvancedModal>
	);
};

export default DolleroImportModal;
