import React from 'react';

type Props = {
    name: string;
    value: boolean,
    id?: string;
    onChange?: (value: boolean) => void;
    disabled?: boolean;
    label?: string;
    required?: boolean
};

const CheckboxField = (props: Props) => {
    const htmlFor = props.id ? props.id : '';
    return (
        <div>
            {props.label && (
                <label className="text-field__label" htmlFor={htmlFor}>
                    {props.label}
                </label>
            )}
            <input
                type={'checkbox'}
                name={props.name}
                checked={props.value}
                onChange={e => props.onChange && props.onChange(e.target.checked)}
                disabled={props.disabled}
                required={props.required}
            />
        </div>
    );
};

export default CheckboxField;
