import React, { CSSProperties } from 'react';
import { Field } from 'formik';

type Props = {
	style?: CSSProperties;
};

const NoteColumn = (props: Props) => {
	return (
		<div className={'add-transaction-row__main-row-cell'} style={props.style}>
			<Field
				type="text"
				name="note"
				placeholder={'Notes...'}
				className={'add-transaction-row__input'}
			/>
		</div>
	);
};

export default NoteColumn;
