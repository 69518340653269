import React from 'react';
import { useField } from 'formik';
import { Form, Input, Label } from 'semantic-ui-react';

type Props = {
	label?: string;
	value: string;
	name: string;
	id?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	placeholder?: string;
};

const CurrencyInputField = (props: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(props);
	return (
		<Form.Field>
			{props.label && (
				<label className="text-field__label" htmlFor={props.id ? props.id : undefined}>
					{props.label}
				</label>
			)}
			<Input
				labelPosition="left"
				className={'currency-input__input'}
				type={'number'}
				name={props.name}
				placeholder={props.placeholder}
				value={props.value}
				onChange={e => props.onChange && props.onChange(e)}
				disabled={props.disabled}
			>
				<Label basic>$</Label>
				<input />
			</Input>
			{meta.error && (
				<Label basic color="red" pointing>
					{meta.error}
				</Label>
			)}
		</Form.Field>
	);
};

export default CurrencyInputField;
