import { getTransactions } from './transactions';
import { AccountNameMap, getAccountsMap } from './accounts';
import { SubCategoryNameMap } from './subCategories';
import { Transaction } from '../../../transactions/Transaction';
import { Profile } from '../../../profiles';
import { RegisterRow } from './index';

export const importRegister = (
	registerRows: RegisterRow[],
	subcategoriesMap: SubCategoryNameMap,
	profile: Profile
): [AccountNameMap, Transaction[]] => {
	const accountsMap = getAccountsMap(registerRows, profile);
	const transactions = getTransactions(registerRows, subcategoriesMap, accountsMap, profile);
	return [accountsMap, transactions];
};
