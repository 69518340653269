import { TransactionStatus, Transaction, STATUS_RECONCILED } from '../Transaction';
import { Account } from '../../accounts/Account';
import isTransfer from '../util/isTransfer';

/**
 * Will correctly set the status of a transaction (including transfers).
 * By default it will not change the status of a transaction from reconciled unless `overrideReconciled` is true.
 */
const setStatus = (
	transactions: Transaction[],
	status: TransactionStatus,
	accountUUIDContext: Account['uuid']|null = null,
	overrideReconciled: boolean = false,
): Transaction[] => {
	return transactions.map(t => {
		const accountUUID = accountUUIDContext || t.accountUUID;
		if (isTransfer(t)) {
			return setTransferStatus(t, status, accountUUID, overrideReconciled);
		}

		if (t.status === STATUS_RECONCILED && !overrideReconciled) {
			return t;
		}

		return Object.assign({}, t, {
			status: status,
		});
	});
};

export default setStatus;

const setTransferStatus = (transaction: Transaction, newStatus: TransactionStatus, accountUUIDContext: Account['uuid'], overrideReconciled: boolean) => {
	if (accountUUIDContext === transaction.accountUUID) {
		return setTransferStatusInSourceAccount(transaction, newStatus, overrideReconciled);
	} else if (accountUUIDContext === transaction.transferToAccountUUID) {
		return setTransferStatusInDestinationAccount(transaction, newStatus, overrideReconciled);
	}

	return transaction;
};

const setTransferStatusInSourceAccount = (transfer: Transaction, newStatus: TransactionStatus, overrideReconciled: boolean) => {
	const existingStatus = transfer.status;
	if (existingStatus === STATUS_RECONCILED && newStatus !== STATUS_RECONCILED && !overrideReconciled) {
		return transfer;
	} else {
		return Object.assign({}, transfer, {
			status: newStatus,
		});
	}
};

const setTransferStatusInDestinationAccount = (transfer: Transaction, newStatus: TransactionStatus, overrideReconciled: boolean) => {
	const existingStatus = transfer.transferToAccountStatus;
	if (existingStatus === STATUS_RECONCILED && newStatus !== STATUS_RECONCILED && !overrideReconciled) {
		return transfer;
	} else {
		return Object.assign({}, transfer, {
			transferToAccountStatus: newStatus,
		});
	}
};
