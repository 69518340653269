import React from "react";
import Card from "../../common/Card";
import {SyncDeviceStatus} from "../../store/app/hooks/useSyncDevices";
import Pill from "../../common/Pill/Pill";

type Props = {
    syncDevice: SyncDeviceStatus
}

const SyncDeviceContent = ({ syncDevice }: Props) => {
    return <Card.Content>
        {getSyncStatusPill(syncDevice)}
        {getDescription(syncDevice)}
    </Card.Content>
}

export default SyncDeviceContent;

const getDescription = (syncDevice: SyncDeviceStatus) => {
    if (syncDevice.deletedAt) {
        return `This device was deleted on ${syncDevice.deletedAt}`
    }
}

const getSyncStatusPill = (syncDevice: SyncDeviceStatus) => {
    if (syncDevice.deletedAt) {
        return <Pill value={'deleted'} theme={'warning'}/>
    }

    if (syncDevice.dataChannelConnected) {
        return <Pill value={'syncing'} theme={'success'}/>
    }

    if (syncDevice.syncStatus === "ERROR" && syncDevice.online) {
        return <Pill value={'errored'} theme={'warning'}/>
    }

    return <Pill value={'disconnected'} theme={'muted'}/>
}