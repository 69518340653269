import React, { useEffect, useState } from 'react';
import './currency-input.less';
import classnames, { Argument as ClassNames } from 'classnames';
import { formatCurrencyForInput } from 'domain/util/currency';

export type Props = {
	value: number;
	name: string;
	id?: string;
	onChange?: (newValue: number) => void;
	onBlur?: (newValue: number) => void;
	disabled?: boolean;
	placeholder?: string;
	className?: ClassNames;
	autoFocus?: boolean;
};

const CurrencyInput = (props: Props) => {
	const [inputValue, setInputValue] = useState<string>(formatCurrencyForInput(props.value));
	useEffect(() => {
		setInputValue(formatCurrencyForInput(props.value));
	}, [props.value]);
	return (
		<input
			inputMode="decimal"
			autoFocus={props.autoFocus}
			type={'number'}
			value={inputValue}
			aria-label="currency-input"
			onChange={e => {
				setInputValue(e.target.value);
				props.onChange && props.onChange(Number(e.target.value));
			}}
			onBlur={e => {
				setInputValue(formatCurrencyForInput(Number(e.target.value)));
				props.onBlur && props.onBlur(Number(e.target.value));
			}}
			onFocus={e => {
				e.target.select();
			}}
			className={classnames(props.className)}
			onKeyPress={e => {
				if (e.charCode === 13) {
					props.onBlur && props.onBlur(Number(inputValue));
				}
			}}
			autoComplete={'off'}
			disabled={props.disabled}
			maxLength={9}
			readOnly={!props.onBlur}
		/>
	);
};

export default CurrencyInput;
