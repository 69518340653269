import {Account} from "../../accounts/Account";
import {STATUS_CLEARED, STATUS_RECONCILED, Transaction} from "../Transaction";
import setStatus from "./setStatus";

const reconcileClearedAccountTransactions = (accountUUID: Account['uuid'], transactions: Transaction[]): Transaction[] => {
    const cleared = transactions.filter((t) => {
        if (t.transferToAccountUUID === accountUUID && t.transferToAccountStatus === STATUS_CLEARED) {
            return true;
        }

        return t.accountUUID === accountUUID && t.status === STATUS_CLEARED;
    });

    return setStatus(cleared, STATUS_RECONCILED, accountUUID);
}

export default reconcileClearedAccountTransactions;