import {dangerouslyRound} from "../../../domain/util/currency";
import Pill, {THEME_DEFAULT, THEME_WARNING} from "../../common/Pill/Pill";
import React from "react";
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';

const BalancePill = ({ balance }: { balance: number }) => {
    const rounded = dangerouslyRound(balance);
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
    const theme = rounded < 0 ? THEME_WARNING : THEME_DEFAULT;
    return (
        <span className={'accounts-nav__balance'}>
			<Pill theme={theme} value={formatCurrencyForDisplay(rounded, true)} />
		</span>
    );
};

export default BalancePill;