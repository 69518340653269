import { useEffect, useMemo } from 'react';
import { createMonthBudgetSelector } from '../selectors/monthBudget';
import { useDispatch, useSelector } from 'react-redux';
import { Month } from '../../../../domain/util/date';
import { recalculateBudget } from '../actions/monthBudget';

const useMonthBudget = (month: Month) => {
	const dispatch = useDispatch();
	const monthBudgetSelector = useMemo(() => {
		return createMonthBudgetSelector(month);
	}, [month]);
	const monthBudget =  useSelector(monthBudgetSelector);
	useEffect(() => {
		if (!monthBudget) {
			dispatch(recalculateBudget(month));
		}
	}, [monthBudget, dispatch, month])

	return monthBudget;
}

export default useMonthBudget;