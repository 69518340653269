import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAllAccounts } from '../../store/accounts/selectors/accounts';
import {
	Account,
} from '../../../domain/accounts/Account';
import AddAccountFormModal from '../../accounts/AddAccountForm/AddAccountFormModal';
import Button from '../../common/Button';
import { Icon } from 'semantic-ui-react';
import './accounts-nav.less';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import AccountsEmptyState from '../AccountsEmptyState/AccountsEmptyState';
import ClosedAccountsList from "./ClosedAccountsList";
import OffBudgetAccountsList from "./OffBudgetAccountsList";
import AccountLink from "./AccountLink";

const AccountsNav = () => {
	const accounts = useSelector(getAllAccounts);
	const [showAccountModal, setShowAccountModal] = useState<boolean>(false);

	const activeProfile = useRequiredActiveProfile();
	if (!activeProfile) {
		return <Redirect to={APP_LINKS.profiles()} />;
	}

	const activeAccounts = Array.from(accounts.values())
		.filter(ac => !ac.closed)
		.filter(ac => !ac.isOffBudget)
		.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}

			if (a.name > b.name) {
				return 1;
			}

			return 0;
		});


	return (
		<section className={'accounts-nav'}>
			<div className={'accounts-nav__top'}>
				<h3 className={'accounts-nav__heading'}>Accounts</h3>
				{activeAccounts.length !== 0 && (
					<Button
						size={'mini'}
						onClick={() => setShowAccountModal(true)}
						icon
						compact
						inverted
						className={'accounts-nav__add-acct-btn'}
					>
						<Icon name="plus" /> Add Account
					</Button>
				)}
			</div>
			<div className={'accounts-nav__section-container'}>
				<nav className={'accounts-nav__section'}>
					{activeAccounts.length === 0 && (
						<AccountsEmptyState onPrimaryClick={() => setShowAccountModal(true)} />
					)}
					<ul className={'accounts-nav__accounts-list'}>
						{activeAccounts.map((account: Account) => (
							<li className={'accounts-nav__accounts-list-item'} key={account.uuid}>
								<AccountLink account={account}/>
							</li>
						))}
					</ul>
				</nav>
				<OffBudgetAccountsList/>
				<ClosedAccountsList/>
			</div>
			<AddAccountFormModal
				modalOpen={showAccountModal}
				onModalClose={() => setShowAccountModal(false)}
			/>
		</section>
	);
};

export default AccountsNav;
