import {UUID} from "../../domain";
import { DOLLERO_ACCOUNT_URL } from "../../config";
import {AllEvents} from "../../../../shared/web-socket-events";
import getWebsocketTicket from '../auth/api/getWebsocketTicket';
import { UnauthorizedError } from '../api/errors';

export type OnMessageFn<MessageTypes> = (data: MessageTypes) => void
type ListenerRemover = () => void;

export interface AppWebSocket<MessageTypes> {
    send(data: MessageTypes): void
    onMessage(callback: OnMessageFn<MessageTypes>): ListenerRemover
}

export class WebSocketMessage extends Event {
    public messageData: Object;

    constructor(messageData: Object) {
        super('message');
        this.messageData = messageData;
    }

}

export class WebSocketOpen extends Event {
    constructor() {
        super('open');
    }
}

export class WebSocketClose extends Event {
    constructor() {
        super('close');
    }
}

type WebSocketErrors = "general" | "unauthorized";
export class WebSocketError extends Event {
    public message: string;
    public errorType: WebSocketErrors

    constructor(message: string, errorType: WebSocketErrors = "general") {
        super('error');
        this.errorType = errorType;
        this.message = message;
    }
}

const url = new URL(DOLLERO_ACCOUNT_URL);
const DOLLERO_SOCKET_HOSTNAME = DOLLERO_ACCOUNT_URL.replace(url.protocol, 'wss:');

class DolleroWebSocket<MessageTypes> extends EventTarget implements AppWebSocket<MessageTypes> {

    protected ws: WebSocket|undefined;

    protected pongTimeout: number|undefined;
    protected pingPongInterval: number|undefined;

    connect(uuid: UUID) {
        if (this.ws && this.ws.readyState === WebSocket.CLOSED) {
            this.ws.removeEventListener('message', this.handleMessage);
            this.ws.removeEventListener('open', this.handleOpen);
            this.ws.removeEventListener('close', this.handleClose);
            this.ws.removeEventListener('error', this.handleError);
            this.ws = undefined;
        }

        if (!this.ws) {
            getWebsocketTicket(uuid).then((ticket) => {
                this.ws = new WebSocket(`${DOLLERO_SOCKET_HOSTNAME}ws?ticket=${ticket}`);
                this.ws.addEventListener('message', this.handleMessage);
                this.ws.addEventListener('open', this.handleOpen);
                this.ws.addEventListener('close', this.handleClose);
                this.ws.addEventListener('error', this.handleError);
            }).catch((err) => {
                let errorType: WebSocketErrors = "general";
                if (err instanceof UnauthorizedError) {
                    errorType = "unauthorized";
                }
                this.dispatchErrorEvent(err.message, errorType)
            })
        }
        return this;
    }
    // TODO: remove a bunch of event listeners when closing websocket
    disconnect() {
        this?.ws?.close();
        this.ws = undefined;
    }

    send(data: MessageTypes) {
        this.requireConnection().send(JSON.stringify(data));
        return this;
    }

    onOpen(listener: () => void) {
        this.addEventListener('open', listener);
        return this;
    }

    onClose(listener: () => void) {
        this.addEventListener('close', listener);
        return this;
    }

    onError(listener: (errorEvent: WebSocketError) => void) {
        // @ts-ignore
        this.addEventListener('error', listener);
        return this;
    }

    onMessage(callback: OnMessageFn<MessageTypes>): ListenerRemover {
        const listener = (webSocketMessage: WebSocketMessage) => {
            const message: MessageTypes = webSocketMessage.messageData as MessageTypes;
            callback(message);
        };

        // @ts-ignore
        this.addEventListener('message', listener);

        return () => {
            // @ts-ignore
            this.removeEventListener('message', listener)
        }
    }

    isConnected() {
        return this.ws && this.ws.readyState === WebSocket.OPEN;
    }

    private requireConnection() {
        if (!this.ws) {
            throw new Error('Websocket connection does not exist');
        }

        if (this.ws.readyState !== WebSocket.OPEN) {
            throw new Error('Websocket connection is not readystate OPEN');
        }

        return this.ws;
    }

    private handleMessage = (messageEvent: MessageEvent) => {
        const messageData = JSON.parse(messageEvent.data);
        if (messageData.type === 'pong') {
            return this.handlePong();
        }
        const newEvent = new WebSocketMessage(messageData);
        this.dispatchEvent(newEvent);
    }

    private handleOpen = () => {
        this.startPingPong();
        this.dispatchEvent(new WebSocketOpen());
    }

    private handleClose = () => {
        this.stopPingPong();
        this.dispatchEvent(new WebSocketClose());
    }

    private handleError = () => {
        this.dispatchEvent(new WebSocketError("An error occurred"));
        this.dispatchErrorEvent()
    }

    private dispatchErrorEvent = (message = "An error occurred", errorType: WebSocketErrors = "general") => {
        this.dispatchEvent(new WebSocketError(message, errorType));
    }

    private startPingPong = () => {
        this.playPingPong();
        this.pingPongInterval = window.setInterval(this.playPingPong, 20000);
    }

    private stopPingPong = () => {
        if (this.pongTimeout) clearTimeout(this.pongTimeout);
        if (this.pingPongInterval) clearInterval(this.pingPongInterval);
    }

    private playPingPong = () => {
        const timeout = () => {
            console.log('Failed to receive pong :-(');
            this.ws?.close();
        }
        this.pongTimeout =  window.setTimeout(timeout, 15000);
        setTimeout(() => {
            try {
                // @ts-ignore TODO: Remove this when proper web socket types in place
                this.send({ type: 'ping' })
            } catch (e) {
                console.log("An error ocurred sending ping");
                console.error(e)
                this.stopPingPong();
            }

        }, 500)
    }

    private handlePong = () => {
        if (this.pongTimeout) clearTimeout(this.pongTimeout);
    }
}

export default new DolleroWebSocket<AllEvents>();