import React from 'react';
import { Route } from 'react-router-dom';
import { APP_ROUTES } from '../../store/router/hooks/useAppRoutes';
import AddProfilePage from '../AddProfilePage/AddProfilePage';
import ProfilesListPage from '../ProfilesListPage';
import './profiles-page.less';
import SyncProfilePage from '../SyncProfilePage/SyncProfilePage';

const ProfilesPage = () => {
	return (
		<div className={'profiles-page__bg'}>
			<main className={'profiles-page__main-box'}>
				<h1 className={'profiles-page__title'}>
					<img src="/logoV1-min.svg" className={'profiles-page__logo'} alt={'Dollero Logo'} />
				</h1>
				<Route path={APP_ROUTES.add_profile}>
					<AddProfilePage />
				</Route>
				<Route path={APP_ROUTES.sync_profile}>
					<SyncProfilePage />
				</Route>
				<Route exact path={[APP_ROUTES.root, APP_ROUTES.profiles]}>
					<ProfilesListPage />
				</Route>
			</main>
		</div>
	);
};

export default ProfilesPage;
