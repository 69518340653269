import useQueryParams from '../../../../common/hooks/useQueryParams';
import { useCallback, useMemo } from 'react';
import { ColumnInstance } from 'react-table';
import { Transaction } from '../../../../../domain/transactions/Transaction';

export const NOTES_QUERY = 'notes';

export type PayeeFilterValue = Transaction['note'] | null | undefined;

type NoteQueryFilters = {
    filterValue: PayeeFilterValue;
    setFilterValue: (payee: Transaction['note'] | undefined | null) => void;
    isShown: boolean;
    show: () => void;
    hide: () => void;
};

const useNotesFilters = (notesColumn: ColumnInstance<Transaction>): NoteQueryFilters => {
    const query = useQueryParams();
    const filterValue = useNotesFilterValue();

    const setFilterValue = useCallback(
        (notes: Transaction['note'] | undefined | null = undefined) => {
            if (!notesColumn) {
                return;
            }
            notesColumn.setFilter(notes);
            if (notes === undefined) {
                query.removeParam(NOTES_QUERY);
            } else {
                query.setParam(NOTES_QUERY, notes || '');
            }
        },
        [notesColumn, query]
    );

    return useMemo(
        () => ({
            filterValue: filterValue,
            setFilterValue: setFilterValue,
            isShown: filterValue !== undefined,
            show: () => {
                if (filterValue === undefined) setFilterValue(null)
            },
            hide: () => {
                setFilterValue(undefined)
            }
        }),
        [setFilterValue, filterValue]
    );
};

export const useNotesFilterValue = () => {
    const query = useQueryParams();
    const notes = query.params.get(NOTES_QUERY);
    const notesSet = query.params.has(NOTES_QUERY);
    return useMemo(() => {
        if (!notesSet) {
            return undefined;
        }

        if (notes === '') {
            return null;
        }
        return notes
    }, [notes, notesSet]);
}

export default useNotesFilters;
