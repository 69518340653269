import React from 'react';
import { AdvancedModal } from '../../common/Modal';
import { Button } from '../../common/Button/Button';
import { Icon } from 'semantic-ui-react';
import { ErrorMessage, Field, Formik } from 'formik';
import TextField from '../../common/Form/TextField';
import { useDispatch } from 'react-redux';
import { createProfile } from '../../store/profiles/actions';
import validationSchema from './validationSchema';
import { useHistory } from 'react-router-dom';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import { AppDispatch } from '../../store/types';
import createNewProfile from '../../../domain/profiles/operations/createNewProfile';
import SelectField from '../../common/Form/SelectField';
import useCurrencyOptions, { CurrencyOption, LoadingState } from '../../settings/hooks/useCurrencyOptions';
import { Profile } from '../../../domain/profiles';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

type FormValues = {
	name: Profile['name'];
	currency: CurrencyOption|null
};

const NewProfileModal = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();
	const [loadingCurrencies, currencyOptions] = useCurrencyOptions(props.isOpen);

	return (
		<Formik<FormValues>
			enableReinitialize={true}
			validationSchema={validationSchema}
			initialValues={{
				name: '',
				currency: null
			}}
			onSubmit={async (values, formik) => {
				// @ts-ignore
				const newProfile = createNewProfile(values.name, values.currency.value);
				await dispatch(createProfile(newProfile));
				props.onClose();
				history.push(APP_LINKS.budget(newProfile.uuid));
			}}
		>
			{({ isValid, handleSubmit, setFieldValue }) => (
				<>
					<AdvancedModal
						open={props.isOpen}
						actions={
							<>
								<Button onClick={() => props.onClose()}>
									<Icon name="remove" /> Cancel
								</Button>
								<Button type={'success'} onClick={handleSubmit} disabled={!isValid}>
									<Icon name="checkmark" /> Save
								</Button>
							</>
						}
						title={'New Profile'}
						onClose={props.onClose}
					>
						<Field name="name" as={TextField} label={'Name'} />
						<Field name="currency"
							   className={'edit-profile-modal__currency-selector'}
							   as={SelectField}
							   label={'Default Currency'}
							   options={currencyOptions}
							   onChange={(newOption: CurrencyOption) =>
								   setFieldValue('currency', newOption)
							   }
							   disabled={loadingCurrencies !== LoadingState.LOADED}
							   placeholder={loadingCurrencies !== LoadingState.LOADED ? "Loading currencies..." : "Select a currency"}
						/>
						<ErrorMessage name={'currency'}/>
					</AdvancedModal>
				</>
			)}
		</Formik>
	);
};

export default NewProfileModal;
