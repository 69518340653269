import React, { useState } from 'react';
import { MasterCategory, SubCategory } from '../../../domain/categories';
import { useDispatch } from 'react-redux';
import { AdvancedModal } from '../../common/Modal';
import TextField from '../../common/Form/TextField';
import { isMasterCategory } from '../../../domain/categories/util/isMasterCategory';
import { hideCategory, updateSubCategory } from '../../store/categories/actions/subCategories';
import {hideMasterCategory, updateMasterCategory} from '../../store/categories/actions/masterCategories';
import { Button } from '../../common/Button/Button';
import { Icon } from 'semantic-ui-react';
import editCategory from '../../../domain/categories/operations/editCategory';

type Props = {
	category: MasterCategory | SubCategory;
	isModalOpen: boolean;
	onClose: () => void;
};

const EditCategoryModal = (props: Props) => {
	const dispatch = useDispatch();
	const [newCategoryName, setNewCategoryName] = useState(props.category.name);
	const onSave = () => {
		const updatedCategory = editCategory(props.category, {
			name: newCategoryName,
		})
		if (isMasterCategory(updatedCategory)) {
			dispatch(updateMasterCategory(updatedCategory as MasterCategory));
		} else {
			dispatch(updateSubCategory(updatedCategory as SubCategory));
		}
		props.onClose();
	};
	return (
		<AdvancedModal
			open={props.isModalOpen}
			onClose={() => props.onClose()}
			title={'Edit Category'}
			actions={
				<>
					<Button
						type={'warning'}
						onClick={() => {
							if (isMasterCategory(props.category)) {
								dispatch(hideMasterCategory(props.category.uuid));
							} else {
								dispatch(hideCategory(props.category.uuid));
							}

							props.onClose();
						}}
					>
						<Icon name="eye slash" /> Hide Category
					</Button>
					<Button onClick={() => props.onClose()}>
						<Icon name="remove" /> Cancel
					</Button>
					<Button type={'success'} onClick={onSave} disabled={newCategoryName === ''}>
						<Icon name="checkmark" /> Save
					</Button>
				</>
			}
		>
			<>
				<TextField
					name={'name'}
					value={newCategoryName}
					onChange={e => {
						setNewCategoryName(e.target.value);
					}}
				/>
			</>
		</AdvancedModal>
	);
};

export default EditCategoryModal;
