import * as actions from '../actions';
import { StoreActions } from '../../types';
import { SET_ACTIVE_PROFILE } from '../../profiles/actions';

export type SideNavShownState = boolean;
export const sideNavShown = (state: SideNavShownState = false, action: StoreActions) => {
	switch (action.type) {
		case actions.SHOW_SIDE_NAV:
			return true;

		case actions.HIDE_SIDE_NAV:
			return false;

		case SET_ACTIVE_PROFILE:
			return false;

		default:
			return state;
	}
};
