import getStunOnlySetting from '../../../infrastructure/web-rtc/stun-turn/getStunOnlySetting';
import { useState } from 'react';
import toggleStunOnlySetting from '../../../infrastructure/web-rtc/stun-turn/toggleStunOnlySetting';

type Hook = [boolean, () => any]

const useStunOnlySetting = (): Hook => {
	const [stunOnly, setStunOnly] = useState(false);
	getStunOnlySetting().then(stunOnly => {
		setStunOnly(stunOnly);
	});
	const toggleSetting = () => {
		toggleStunOnlySetting().then(newSetting => setStunOnly(newSetting))
	}
	return [stunOnly, toggleSetting]
}

export default useStunOnlySetting;