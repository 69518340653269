import { getMonthName, Month } from '../util/date';
import { CategoryBudgetsRepo } from '../categories/ICategoryBudgetsRepo';
import { Profile } from '../profiles';

const resyncMonthBudget = async (profileUUID: Profile['uuid'], month: Month) => {
	const categoryBudgetsForMonth = await CategoryBudgetsRepo.getCategoryBudgetsForMonth(month, profileUUID);
	console.log(`Attempting to resync ${categoryBudgetsForMonth.length} budget amounts for ${getMonthName(month)}`)
	const updated = categoryBudgetsForMonth.map((categoryBudget) => {
		return { ...categoryBudget, updatedAt: new Date() }
	})
	await CategoryBudgetsRepo.putMany(updated);
}

export default resyncMonthBudget;