import React, { useEffect, useState } from 'react';
import { AdvancedModal } from '../../common/Modal';
import TextField from '../../common/Form/TextField';
import { Button } from '../../common/Button/Button';
import { Icon } from 'semantic-ui-react';
import { TransactionTagsRepo } from '../../../domain/transactions/ITransactionTagsRepo';
import createNewTransactionTag from '../../../domain/transactions/operations/createNewTransactionTag';
import { Profile } from '../../../domain/profiles';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { TransactionTag } from '../../../domain/transactions/TransactionTag';
import updateTransactionTag from '../../../domain/transactions/operations/updateTransactionTag';

type Props = {
	isOpen: boolean,
	onClose: () => void,
	tag?: TransactionTag
}

const AddTransactionTagModal = (props: Props) => {
	const [label, setLabel] = useState(props.tag?.label || '');
	const profile = useRequiredActiveProfile();
	useEffect(() => {
		setLabel(props.tag?.label || '')
	}, [props.tag])

	const actions = <>
		<Button onClick={() => props.onClose()}>
			<Icon name="remove" /> Cancel
		</Button>
		<Button type={'success'} onClick={() => {
			console.log('submit', label)
			if (props.tag) return updateTag(label, props.tag).then(() => {
				props.onClose();
			});

			return saveNewTag(label, profile.uuid).then(() => {
				props.onClose();
			})
		}}>
			<Icon name="checkmark" /> Save
		</Button>
	</>

	return <AdvancedModal open={props.isOpen} actions={actions} title={'Add New Tag'} onClose={props.onClose}>
		<TextField value={label} name={'label'} onChange={e => {
			setLabel(e.target.value);
		}}
		/>
	</AdvancedModal>
}

export default AddTransactionTagModal;

const saveNewTag = (label: string, profileUUID: Profile['uuid']) => {
	return TransactionTagsRepo.put(createNewTransactionTag(label, profileUUID));
}

const updateTag = (label: string, tag: TransactionTag) => {
	return TransactionTagsRepo.put(updateTransactionTag({ label }, tag));
}