import { Transaction } from '../Transaction';
import { isMonthBefore, Month } from '../../util/date';

const getEarliestMonth = (transactions: Array<Transaction>): Month => {
    if (transactions.length === 0) {
        throw new Error(`No transactions provided`);
    }

	return transactions.reduce((month, t) => {
		if (isMonthBefore(t.date.toMonth(), month)) {
			return t.date.toMonth();
		}

		return month;
	}, transactions[0].date.toMonth());
};

export default getEarliestMonth;
