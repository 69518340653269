import { RootState } from '../../types';

export const isReconcileModalOpen = (state: RootState) => state.accounts.reconcileModalOpen;

export const isReconciling = (state: RootState) => state.accounts.reconciling !== null;

export const getReconcileAccountUUID = (state: RootState) =>
	state.accounts.reconciling !== null ? state.accounts.reconciling.accountUUID : null;

export const getReconcileDate = (state: RootState) =>
	state.accounts.reconciling !== null ? state.accounts.reconciling.reconcileDate : null;

export const getStatementBalance = (state: RootState) =>
	state.accounts.reconcilingBalance.statementBalance;

export const getClearedBalance = (state: RootState) =>
	state.accounts.reconcilingBalance.clearedBalance;

