import { useSelector } from 'react-redux';
import { getClearedBalance } from '../../selectors/reconciling';
import { legacyToMoney } from '../../../../../domain/util/money';
import useRequiredActiveProfile from '../../../profiles/hooks/useRequiredActiveProfile';
import useAccountBeingReconciled from './useAccountBeingReconciled';

const useClearedBalance = () => {
	const profile = useRequiredActiveProfile();
	const account = useAccountBeingReconciled();
	if (!account) {
		throw new Error("Cannot get reconciliation cleared balance when a reconciliation is not in progress");
	}
	const clearedBalance = useSelector(getClearedBalance) || 0;
	return legacyToMoney(clearedBalance, profile.currency);
}

export default useClearedBalance;