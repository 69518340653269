import {BackupData} from "../../backup/collectData";
import { AccountRepo } from '../../../accounts/IAccountRepo';
import { MonthBudgetsRepo } from '../../../budget/IMonthBudgetsRepo';
import { CategoryBudgetsRepo } from '../../../categories/ICategoryBudgetsRepo';
import { CategoriesRepo } from '../../../categories/ICategoriesRepo';
import { ProfileRepo } from "../../../profiles/IProfileRepo";
import { TransactionsRepo } from "../../../transactions/ITransactionsRepo";
import {SubCategory} from "../../../categories";
import fromJson from "../../../transactions/util/fromJson";

const importFromBackup = async (backupData: BackupData) => {
    await AccountRepo.putMany(backupData.accounts);
    await MonthBudgetsRepo.putMany(backupData.monthBudgets);
    await CategoriesRepo.putManyMasterCategories(backupData.categories.masterCategories);
    await CategoriesRepo.putManySubCategories(backupData.categories.subCategories);
    await CategoriesRepo.setMasterCategoryOrder(backupData.categories.masterCategoryOrder);
    const subCatOrderPromises: Promise<SubCategory['uuid'][]>[] = [];
    new Map(backupData.categories.subCategoryOrder).forEach((subCatOrder, masterCatUUID ) => {
        subCatOrderPromises.push(CategoriesRepo.setSubCategoryOrder(masterCatUUID, subCatOrder));
    })
    await Promise.all(subCatOrderPromises);
    await CategoryBudgetsRepo.putMany(backupData.categoryBudgets);
    await ProfileRepo.put(backupData.profile);
    await TransactionsRepo.putMany(backupData.transactions.map(fromJson));

    return backupData.profile;
}

export default importFromBackup;