import {SyncDevice} from "../../domain/sync/SyncDevice";
import isSyncDeviceRegistered from "../../domain/sync/isSyncDeviceRegistered";
import deleteDevice from "../../domain/sync/deleteDevice";

const handleDeletedDevices = async (syncDevices: SyncDevice[]) => {
    const promises = syncDevices.map(async (device) => {
        if (device.deletedAt && await isSyncDeviceRegistered(device.uuid)) {
            await deleteDevice(device.uuid);
        }
    })
    return Promise.all(promises);
}

export default handleDeletedDevices;