import { useSelector } from 'react-redux';
import { getCustomCategories } from '../selectors';
import {
	INCOME_NEXT_MONTH_SUB_CATEGORY,
	INCOME_SUB_CATEGORY,
	TRANSFER_SUB_CATEGORY,
} from '../../../../domain/categories';
import { useMemo } from 'react';

const useAllCategories = () => {
	const userCategories = useSelector(getCustomCategories);

	return useMemo(() => {
		const allCategories = new Map(userCategories.entries());
		allCategories.set(TRANSFER_SUB_CATEGORY.uuid, TRANSFER_SUB_CATEGORY);
		allCategories.set(INCOME_SUB_CATEGORY.uuid, INCOME_SUB_CATEGORY);
		allCategories.set(INCOME_NEXT_MONTH_SUB_CATEGORY.uuid, INCOME_NEXT_MONTH_SUB_CATEGORY);
		return allCategories;
	}, [userCategories]);
};

export default useAllCategories;
