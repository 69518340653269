import { Transaction } from '../Transaction';
import isTransfer from '../util/isTransfer';
import {Account} from "../../accounts/Account";

const assignAccount = (
    transactions: Transaction[],
    accountUUID: Account['uuid']
): Transaction[] => {
    return transactions.map(t => {
        // Can't reassign account for transfers
        if (isTransfer(t)) {
            return t;
        }

        // set account
        return Object.assign({}, t, {
            accountUUID: accountUUID,
        });
    });
};

export default assignAccount;
