import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ColumnInstance, Row } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import './table-tools.less';
import Dropdown from '../../../common/Dropdown';
import EditTransactionsMenu from './EditTransactionsMenu';
import {
	COL_ID_CATEGORY,
	COL_ID_DATE,
	COL_ID_NOTE,
	COL_ID_PAYEE, ColumnID,
} from '../useTableColumns/useTableColumns';
import { isReconciling } from '../../../store/accounts/selectors/reconciling';
import useCategoryFilters from '../TableFilters/useCategoryFilters';
import usePayeeFilters from '../TableFilters/PayeeFilter/usePayeeFilter';
import useNotesFilters from '../TableFilters/NotesFilter/useNotesFilter';
import AllFilters from './AllFilters';
import { isSmallScreen } from '../../../store/ui/selectors/window';
import FiltersModal from "./FiltersModal";
import {isFiltersModalOpen} from "../../../store/accounts/selectors/filters";
import {hideTransactionFilters} from "../../../store/accounts/actions/filters";
import TransactionsSummary from '../TransactionsSummary/TransactionsSummary';
import useTransactionTagFilters from '../TableFilters/useTransactionTagFilters';

type Props = {
	heading: string;
	columns: ColumnInstance<Transaction>[];
	selectedTransactions: Transaction[];
	rows: Row<Transaction>[];
};

const TableTools = (props: Props) => {
	const dispatch = useDispatch();
	const smallScreen = useSelector(isSmallScreen);
	const categoryCol = props.columns.find(col => col.id === COL_ID_CATEGORY);
	const payeeCol = props.columns.find(col => col.id === COL_ID_PAYEE);
	const notesCol = props.columns.find(col => col.id === COL_ID_NOTE);
	const dateCol = props.columns.find(col => col.id === COL_ID_DATE);
	const tagCol = props.columns.find(col => col.id === ColumnID.COL_ID_TAGS);

	if (!payeeCol || !notesCol || !tagCol) {
		throw new Error('Cannot find table column for filters');
	}

	const categoryFilters = useCategoryFilters(categoryCol);
	const payeeFilters = usePayeeFilters(payeeCol);
	const notesFilters = useNotesFilters(notesCol);
	const tagFilters = useTransactionTagFilters(tagCol);
	const reconciling = useSelector(isReconciling);
	const filtersModalOpen = useSelector(isFiltersModalOpen);
	const filteringAnything = categoryFilters.filterValue || payeeFilters.filterValue || notesFilters.filterValue;

	if (smallScreen) {
		return (
			<div className={'table-tools'}>
				<div className={'table-tools__header'}>
					{filteringAnything && <TransactionsSummary rows={props.rows}/>}
					{!reconciling && (
						<div className="table-tools__each-filter">
							{dateCol && dateCol.render('Filter')}
						</div>
					)}
				</div>
				<FiltersModal
					isOpen={filtersModalOpen}
					onClose={() => dispatch(hideTransactionFilters())}
					columns={props.columns}
				/>
			</div>
		);
	}

	return (
		<div className={'table-tools'}>
			<div className={'table-tools__header'}>
				{!reconciling && (
					<div className="table-tools__date-filter">
						{dateCol && dateCol.render('Filter')}
					</div>
				)}
				<div className={'table-tools__toolbar-buttons'}>
					<EditTransactionsMenu selectedTransactions={props.selectedTransactions} />
					<Dropdown text="Filter" icon="filter" floating labeled button className="icon">
						<Dropdown.Menu>
							<Dropdown.Item
								text="Payee"
								onClick={() => {
									payeeFilters.filterValue === undefined
										? payeeFilters.setFilterValue(null)
										: payeeFilters.setFilterValue(undefined);
								}}
							/>
							{ categoryCol && <Dropdown.Item
								text="Category"
								onClick={() => {
									categoryFilters && categoryFilters.filterValue === undefined
										? categoryFilters.setFilterValue(null)
										: categoryFilters &&
										  categoryFilters.setFilterValue(undefined);
								}}
							/>}
							<Dropdown.Item
								text="Notes"
								onClick={() => {
									notesFilters.filterValue === undefined
										? notesFilters.setFilterValue(null)
										: notesFilters.setFilterValue(undefined);
								}}
							/>
							<Dropdown.Item
								text="Tags"
								onClick={() => {
									tagFilters.filterValue === undefined
										? tagFilters.setFilterValue(null)
										: tagFilters.setFilterValue(undefined);
								}}
							/>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
			<div className="table-tools__filters">
				<div className={'table-tools__filter-items'}>
					<AllFilters columns={props.columns} />
				</div>
			</div>
		</div>
	);
};

export default TableTools;
