export const SHOW_ADD_MASTER_CATEGORY_MODAL = 'SHOW_ADD_MASTER_CATEGORY_MODAL';
export const HIDE_ADD_MASTER_CATEGORY_MODAL = 'HIDE_ADD_MASTER_CATEGORY_MODAL';

export type AddCategoryModalActions = ShowAddMasterCategoryModal | HideAddMasterCategoryModal;

export type ShowAddMasterCategoryModal = {
    type: typeof SHOW_ADD_MASTER_CATEGORY_MODAL,
    payload: {}
}

export const showAddMasterCategoryModal = (): ShowAddMasterCategoryModal => ({
    type: SHOW_ADD_MASTER_CATEGORY_MODAL,
    payload: {}
})

export type HideAddMasterCategoryModal = {
    type: typeof HIDE_ADD_MASTER_CATEGORY_MODAL,
    payload: {}
}

export const hideAddMasterCategoryModal = (): HideAddMasterCategoryModal => ({
    type: HIDE_ADD_MASTER_CATEGORY_MODAL,
    payload: {}
})