import React from "react";
import {SyncDeviceStatus} from "../../store/app/hooks/useSyncDevices";
import Card from "../../common/Card";
import Pill from "../../common/Pill/Pill";
import getDeviceName from '../../../domain/sync/getDeviceName';
import SyncDeviceIcon from '../SyncDeviceIcon';

type Props = { syncDevice: SyncDeviceStatus }

const SyncDeviceCardHeader = ({ syncDevice }: Props) => {
    return <Card.Content>
        <Card.Header>
            <SyncDeviceIcon syncDevice={syncDevice}/>
            {getDeviceName(syncDevice)}
            <div className={'sync-device-card__dollero-status'}>
                {getDolleroStatusPill(syncDevice)}
            </div>
        </Card.Header>
        <Card.Meta>uuid: {syncDevice.uuid}</Card.Meta>
    </Card.Content>
}

export default SyncDeviceCardHeader;

const getDolleroStatusPill = (syncDevice: SyncDeviceStatus) => {
    if(syncDevice.deletedAt) return null;
    if (syncDevice.online) {
        return <Pill value={'online'} theme={'success'}/>
    }

    return <Pill value={'offline'} theme={'muted'}/>
}