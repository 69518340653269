import {combineReducers} from "redux";
import {SocketState, socketState} from "./socketState";
import {SettingsState, settings} from "./settings";
import {DataChannelState, dataChannels} from "./dataChannels";
import {updatePromptIsOpen, UpdatePromptIsOpenState} from "./updatePrompt";
import {workboxInstance, WorkboxInstanceState} from "./updatePrompt";

export type AppState = {
    socketState: SocketState,
    settings: SettingsState,
    dataChannels: DataChannelState,
    updatePromptIsOpen: UpdatePromptIsOpenState,
    workboxInstance: WorkboxInstanceState
}

export default combineReducers({
    socketState,
    settings,
    dataChannels,
    updatePromptIsOpen,
    workboxInstance,
});
