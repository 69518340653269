import { Profile } from '../../../../domain/profiles';
import { AppThunkAction } from '../../types';
import ProfileRepo from '../../../../infrastructure/persistence/indexed-db/profiles/ProfileRepo';
import { loadAllCategories } from '../../categories/actions';
import { loadAccounts } from '../../accounts/actions';
import { loadMonthBudgets } from '../../budget/actions/loadMonthBudgets';
import softDeleteProfile from '../../../../domain/profiles/operations/softDeleteProfile';

export type ProfileActions =
	| LoadProfiles
	| AddProfile
	| SetActiveProfile
	| LoadActiveProfile
	| EditProfile
	| DeleteProfile;

export const LOAD_PROFILES = 'LOAD_PROFILES';
export type LoadProfiles = {
	type: typeof LOAD_PROFILES;
	payload: {
		profiles: Profile[];
	};
};

export const loadAllProfiles = (): AppThunkAction<Promise<void>> => {
	return async dispatch => {
		return ProfileRepo.getAllProfiles().then(profiles => {
			dispatch({
				type: LOAD_PROFILES,
				payload: {
					profiles,
				},
			});
		});
	};
};

export const ADD_PROFILE = 'ADD_PROFILE';
export type AddProfile = {
	type: typeof ADD_PROFILE;
	payload: {
		profile: Profile;
	};
};

export const createProfile = (profile: Profile): AppThunkAction<Promise<void>> => {
	return async dispatch => {
		return ProfileRepo.put(profile).then(() => {
			dispatch({
				type: ADD_PROFILE,
				payload: {
					profile,
				},
			});
		});
	};
};

export const SET_ACTIVE_PROFILE = 'SET_ACTIVE_PROFILE';
export type SetActiveProfile = {
	type: typeof SET_ACTIVE_PROFILE;
	payload: {
		profile: Profile;
	};
};

export const setActiveProfile = (profile: Profile): SetActiveProfile => ({
	type: SET_ACTIVE_PROFILE,
	payload: {
		profile,
	},
});

export const LOAD_ACTIVE_PROFILE = 'LOAD_ACTIVE_PROFILE';
export type LoadActiveProfile = {
	type: typeof LOAD_ACTIVE_PROFILE;
	payload: {
		profile: Profile;
	};
};

export const loadActiveProfile = (profile: Profile): AppThunkAction<Promise<void>> => {
	return async dispatch => {
		const loadProfileAction: LoadActiveProfile = {
			type: LOAD_ACTIVE_PROFILE,
			payload: {
				profile,
			},
		};
		dispatch(loadProfileAction);
		return Promise.all([
			dispatch(loadAllCategories(profile.uuid)),
			dispatch(loadAccounts(profile.uuid)),
			dispatch(loadMonthBudgets(profile.uuid)),
		]).then(() => {
			dispatch(setActiveProfile(profile));
		});
	};
};

export const EDIT_PROFILE = 'EDIT_PROFILE';
export type EditProfile = {
	type: typeof EDIT_PROFILE;
	payload: {
		updatedProfile: Profile;
	};
};

export const editProfile = (updatedProfile: Profile): AppThunkAction<Promise<void>> => {
	return async dispatch => {
		return ProfileRepo.put(updatedProfile).then(() => {
			dispatch({
				type: EDIT_PROFILE,
				payload: {
					updatedProfile,
				},
			});
		});
	};
};

export const DELETE_PROFILE = 'DELETE_PROFILE';
export type DeleteProfile = {
	type: typeof DELETE_PROFILE;
	payload: {
		deletedProfile: Profile;
	};
};

export const softDeleteProfileAction = (
	profileUUID: Profile['uuid']
): AppThunkAction<Promise<void>> => {
	return async dispatch => {
		const deletedProfile = await softDeleteProfile(profileUUID);
		const deleteAction: DeleteProfile = {
			type: DELETE_PROFILE,
			payload: {
				deletedProfile,
			},
		};
		dispatch(deleteAction);
	};
};
