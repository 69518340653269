import {combineReducers} from "redux";
import {isLoggedIn} from "./login";
import {loginModalOpen} from "./loginModalOpen";

export type AuthState = {
    isLoggedIn: boolean,
    loginModalOpen: boolean
}

export default combineReducers({
    isLoggedIn,
    loginModalOpen
});
