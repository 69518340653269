import classNames from 'classnames';
import React from 'react';
import './balance-pill.less';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';

type Props = {
	numericValue: number
}

const BalancePill = (props: Props) => {
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const classes = classNames({
		'balance-pill': true,
		'balance-pill--negative': props.numericValue < 0,
		'balance-pill--positive': props.numericValue > 0,
	});

	return (
		<span className={classes}>
			{formatCurrencyForDisplay(props.numericValue)}
		</span>
	);
}

export default BalancePill;