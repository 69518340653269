import { AppDispatch, AppThunkAction } from '../../types';
import { loadAllProfiles } from '../../profiles/actions';
import setupSync from "../../../../infrastructure/sync/setup";
import {loadSettings} from "./settings";
import {loadLoggedIn} from "../../auth/actions";

export const bootstrapApp = (): AppThunkAction<Promise<void>> => {
	return async (dispatch: AppDispatch, getState): Promise<void> => {
		try {
			await setupSync(dispatch, getState);
		} catch (e) {
			console.error(e);
		}

		try {
			await dispatch(loadSettings())
		} catch (e) {
			console.error(e);
		}

		dispatch(loadLoggedIn())
		return dispatch(loadAllProfiles());
	};
};
