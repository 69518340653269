import Header from '../../common/Header';
import { Segment } from 'semantic-ui-react';
import React from 'react';

const ContactSection = () => {
	return <section className={'settings-page__section'}>
		<Header>Contact</Header>
		<Segment>
			<p>Report a bug or contact us for any other reason at: <a href={'mailto:hello@dollero.app'}>hello@dollero.app</a></p>
		</Segment>
	</section>
}

export default ContactSection;