import React from 'react';
import { Form as FormikForm } from 'formik';

export const LAYOUT_STACKED = 'LAYOUT_STACKED';
export const LAYOUT_HORIZONTAL = 'LAYOUT_HORIZONTAL';
export type FormLayouts = typeof LAYOUT_STACKED | typeof LAYOUT_HORIZONTAL;

type Props = {
	name: string;
	layout?: FormLayouts;
	children: React.ReactNode;
};

type FormContextType = {
	layout: FormLayouts;
};

const defaultFormContext = {
	layout: LAYOUT_STACKED,
} as FormContextType;

export const FormContext = React.createContext(defaultFormContext);

const Form = (props: Props) => {
	const contextValue = {
		layout: props.layout || LAYOUT_STACKED,
	};

	const formClass =
		props.layout && props.layout === LAYOUT_HORIZONTAL
			? 'uk-form-horizontal'
			: 'uk-form-stacked';

	return (
		<FormikForm className={formClass}>
			<FormContext.Provider value={contextValue}>{props.children}</FormContext.Provider>
		</FormikForm>
	);
};

export default Form;
