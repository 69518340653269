import { Month } from '../../../../domain/util/date';
import { useMemo } from 'react';
import { createMonthTotalBudgetedSelector } from '../selectors/monthBudget';
import { useSelector } from 'react-redux';

const useMonthTotalBudgeted = (month: Month) => {
	const budgetSelector = useMemo(() => {
		return createMonthTotalBudgetedSelector(month.year, month.month);
	}, [month.year, month.month]);
	return useSelector(budgetSelector);
}

export default useMonthTotalBudgeted;