import {Profile} from "../index";
import ProfileRepo from "../../../infrastructure/persistence/indexed-db/profiles/ProfileRepo";
import editProfile from "./editProfile";

const softDeleteProfile = async (profileUUID: Profile['uuid']): Promise<Profile> => {
    const profile = await ProfileRepo.getByUUID(profileUUID);
    if (!profile) {
        throw new Error(`Cannot delete profile [${profileUUID}] because no profile with this uuid exists`);
    }

    const deletedProfile = editProfile(profile, { deleted: true });
    await ProfileRepo.put(deletedProfile);
    return deletedProfile;
}

export default softDeleteProfile;
