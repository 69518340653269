
import {StoreActions} from "../../types";
import * as SocketActions from "../actions/socket";

export type SocketState = WebSocket['readyState']|null;
export const socketState = (state: SocketState = null, action: StoreActions) => {
    switch (action.type) {
        case SocketActions.SET_SOCKET_STATE:
            return action.payload.state;
        default:
            return state;
    }
};