import React, { useState } from 'react';
import './install-prompt.less';
import Button from '../../common/Button';
import useInstallPromptEvent from '../../store/ui/hooks/useInstallPromptEvent';
import { AppSettingsRepo, SettingsKeys } from '../../../domain/settings/IAppSettingsRepo';
import useIsSmallScreen from '../../store/ui/hooks/useIsSmallScreen';

const InstallPrompt = () => {
	const [showOverride, setShowOverride] = useState(true);
	const installPromptEvent = useInstallPromptEvent();
	const shouldPrompt = useShouldPromptForInstall();
	const isSmallScreen = useIsSmallScreen();

	const shouldBeDisplayed = showOverride && shouldPrompt && installPromptEvent && isSmallScreen;

	if (!shouldBeDisplayed) return null;

	const onClick = () => {
		// @ts-ignore
		installPromptEvent.prompt();
		// @ts-ignore
		installPromptEvent.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === 'accepted') {
				console.log('User accepted the A2HS prompt');
				return;
			}

			setShowOverride(false);
			declineInstallPrompt();
		});
	};

	const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		setShowOverride(false);
		declineInstallPrompt();
	};

	return <div className={'install-prompt'}>
		<img src={'/favicon.png'} className={'install-prompt__img'} onClick={onClick} alt={"Dollero logo"} />
		<span className={'install-prompt__message'} onClick={onClick}>
			Add Dollero to home screen
		</span>
		<Button icon={'close'} className={'install-prompt__close'} onClick={onClose} />
	</div>;
};

export default InstallPrompt;

const declineInstallPrompt = async () => {
	await AppSettingsRepo.set(SettingsKeys.lastInstallPromptDate, new Date());
};

const DAY = 1000 * 60 * 60 * 24;
const FIVE_DAYS = DAY * 5;
const shouldPromptForInstall = async () => {
	const lastPromptDate: Date | undefined = await AppSettingsRepo.get(SettingsKeys.lastInstallPromptDate);
	if (!lastPromptDate) return true;

	const diff = (new Date()).getTime() - lastPromptDate.getTime();
	console.log(`Last prompted for install ${diff / DAY} days ago.`);
	return diff > FIVE_DAYS;
};

const useShouldPromptForInstall = () => {
	const [shouldPrompt, setShouldPrompt] = useState(false);
	shouldPromptForInstall().then((shouldPrompt) => setShouldPrompt(shouldPrompt));
	return shouldPrompt;
};