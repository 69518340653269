import {combineReducers} from 'redux';
import {StoreActions} from '../../types';
import {Profile} from '../../../../domain/profiles';
import {
	ADD_PROFILE,
	DELETE_PROFILE,
	EDIT_PROFILE,
	LOAD_ACTIVE_PROFILE,
	LOAD_PROFILES,
	SET_ACTIVE_PROFILE,
} from '../actions';

export type ByUUIDState = Map<Profile['uuid'], Profile>;

export const byUUID = (state: ByUUIDState = new Map(), action: StoreActions) => {
	switch (action.type) {
		case ADD_PROFILE:
			state.set(action.payload.profile.uuid, action.payload.profile);
			return new Map(state.entries());

		case EDIT_PROFILE:
			state.set(action.payload.updatedProfile.uuid, action.payload.updatedProfile);
			return new Map(state.entries());

		case DELETE_PROFILE:
			state.set(action.payload.deletedProfile.uuid, action.payload.deletedProfile);
			return new Map(state.entries());

		case LOAD_PROFILES:
			return new Map(
				action.payload.profiles.map(profile => {
					return [profile.uuid, profile];
				})
			);

		default:
			return state;
	}
};

export type ActiveProfileState = Profile['uuid'] | null;
export const activeProfile = (state: ActiveProfileState = null, action: StoreActions) => {
	switch (action.type) {
		case SET_ACTIVE_PROFILE:
			return action.payload.profile.uuid;

		default:
			return state;
	}
};

export type IsLoadingProfileState = boolean;
export const isLoadingProfile = (state: IsLoadingProfileState = false, action: StoreActions) => {
	switch (action.type) {
		case LOAD_ACTIVE_PROFILE:
			return true;

		case SET_ACTIVE_PROFILE:
			return false;

		default:
			return state;
	}
}

export type ProfilesState = {
	byUUID: ByUUIDState;
	activeProfile: ActiveProfileState;
	isLoadingProfile: IsLoadingProfileState;
};

export default combineReducers({
	byUUID,
	activeProfile,
	isLoadingProfile
});
