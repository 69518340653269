import { Profile } from '../../profiles';
import { MonthBudgetsRepo } from '../IMonthBudgetsRepo';
import { AppDispatch } from '../../../view/store/types';
import { recalculateBudget } from '../../../view/store/budget/actions/monthBudget';

const recalculateEntireBudget = async (profileUUID: Profile['uuid'], dispatch: AppDispatch) => {
	await MonthBudgetsRepo.deleteAll(profileUUID);
	await dispatch(recalculateBudget());
}

export default recalculateEntireBudget;