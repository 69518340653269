import { uuid } from '../../util/uuid';
import { Profile } from '../index';
import { Currency } from '../../util/money';

const createNewProfile = (name: string, currency: Currency['code']): Profile => {
	return {
		uuid: uuid(),
		name,
		currency: currency,
		deleted: false
	};
};

export default createNewProfile;
