import { SyncDevice } from '../../../domain/sync/SyncDevice';
import useAccountDevices from '../../store/sync/hooks/useAccountDevices';
import { useSelector } from 'react-redux';
import { getSyncUUID } from '../../store/app/selectors/settings';

const useCurrentSyncDevice = (): SyncDevice|undefined => {
	const accountDevices = useAccountDevices();
	const currentSyncDeviceUUID =  useSelector(getSyncUUID);

	return Array.from(accountDevices.values()).find(d => d.uuid === currentSyncDeviceUUID);
}

export default useCurrentSyncDevice;