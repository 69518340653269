import {ApplyRemoteChangesFunction, IPersistedContext} from "dexie-syncable/api";
import {OnChangesAccepted, OnSuccess, OnError} from "../persistence/WebRTCSyncProtocol";
import {IDatabaseChange} from "dexie-observable/api";
import {AckMessage, ChangesMessage, SyncMessages} from "./syncMessages";
import reactToChanges from "./reactToChanges";
import getDeviceConnection from './api/getDeviceConnection';
import { SyncDevice } from '../../domain/sync/SyncDevice';
import { MESSAGE_TIMEOUT_MS, RETRY_CONNECTION_MS } from './constants';
import deflate from '../compression/deflate';
import inflate from '../compression/inflate';

export default class SyncDataChannel {
    private peerDeviceUUID: SyncDevice['uuid']|undefined;
    private requestId: number = 0;
    private acceptCallbacks: {[requestId: string]: () => void} = {};
    private isFirstRound = false;

    private applyRemoteChanges: ApplyRemoteChangesFunction|undefined;
    private onSuccess: OnSuccess|undefined;
    private onError: OnError|undefined;
    private removeWebsocketListener: (() => void) | undefined;

    constructor(private dataChannel: RTCDataChannel) {
        this.dataChannel.addEventListener("close", () => {
            console.log('data channel closed');
            if (this.removeWebsocketListener) {
                this.removeWebsocketListener();
            }
        }, {once: true})
    }

    public beginSync(
        peerDeviceUUID: SyncDevice['uuid'],
        context: IPersistedContext,
        baseRevision: number,
        syncedRevision: number,
        changes: IDatabaseChange[],
        partial: boolean,
        applyRemoteChanges: ApplyRemoteChangesFunction,
        onChangesAccepted: OnChangesAccepted,
        onSuccess: OnSuccess,
        onError: OnError
    ) {
        console.log('Begin data channel sync as initiator');
        this.peerDeviceUUID = peerDeviceUUID;
        this.isFirstRound = true;
        this.applyRemoteChanges = applyRemoteChanges;
        this.onSuccess = onSuccess;
        this.onError = onError;

        // May not be needed?
        this.sendMessage({
            role: "initiator",
            type: "clientIdentity",
            clientIdentity: context.clientIdentity || null
        })

        this.sendChanges({
            role: "initiator",
            type: 'changes',
            changes: changes,
            partial: partial,
            baseRevision: baseRevision,
        }, onChangesAccepted)

        // May not be needed?
        this.sendMessage({
            role: "initiator",
            type: "subscribe",
            syncedRevision: syncedRevision
        })

        if (!this.removeWebsocketListener) {
            this.removeWebsocketListener = () => this.dataChannel.removeEventListener('message', this.handleWebSocketMessage);
            this.dataChannel.addEventListener('message', this.handleWebSocketMessage)
        }
    }

    public isClosed() {
        return this.dataChannel.readyState === "closing" || this.dataChannel.readyState === "closed";
    }

    private handleWebSocketMessage = async (message: MessageEvent) => {
        const messageData = await inflate(message.data);
        if (messageData.role === 'initiator') {
            return;
        }
        console.log('Received data channel message as Initiator', messageData);
        this.onRemoteMessage(messageData);
    }

    private sendMessage(data: SyncMessages) {
        this.dataChannel.send(deflate(data));
    }

    private sendChanges(changesMessage: Partial<ChangesMessage>, onChangesAccepted: OnChangesAccepted) {
        this.requestId += 1;
        const cancelTimeout = this.getMessageTimeout(this.requestId);
        this.acceptCallbacks[this.requestId.toString()] = () => {
            cancelTimeout();
            onChangesAccepted();
        };

        const updatedMessage = Object.assign(
            {},
            changesMessage,
            { changes: changesMessage.changes, requestId: this.requestId }
        )
        console.log(`Sending changes from initiator`, updatedMessage);
        // @ts-ignore TODO: fix types here
        this.sendMessage(updatedMessage);
    }

    private onRemoteMessage(message: SyncMessages) {
        switch (message.type) {
            case "clientIdentity":
                return;
            case "subscribe":
                return
            case "changes":
                return this.onRemoteChanges(message);
            case "ack":
                return this.onAck(message);
            case "error":
                return this.handleError(message.toString());
        }
    }

    private handleError(error: Error|string, tryAgainMs?: number ) {
        this.onError && this.onError(error, tryAgainMs);
    }

    private onAck(messageData: AckMessage) {
        console.log(`onAck`, messageData, this.acceptCallbacks)
        var requestId = messageData.requestId;
        var acceptCallback = this.acceptCallbacks[requestId.toString()];
        acceptCallback(); // Tell framework that server has acknowledged the changes sent.
        delete this.acceptCallbacks[requestId.toString()];
    }

    private onRemoteChanges(changesMessage: ChangesMessage) {
        if (!this.applyRemoteChanges || !this.onSuccess) {
            throw new Error("Missing applyRemoteChanges or onSuccess");
        }
        console.log("Remote changes", changesMessage.changes);

        this.applyRemoteChanges(changesMessage.changes, changesMessage.baseRevision, changesMessage.partial)
            .then(() => {
                this.sendMessage({
                    role: "initiator",
                    type: "ack",
                    requestId: changesMessage.requestId
                })
                if (changesMessage.changes.length !== 0) reactToChanges(changesMessage.changes);
            })

        if (this.isFirstRound && !changesMessage.partial) {
            // Since this is the first sync round and server says we've got all changes - now is the time to call onsuccess()
            this.onSuccess({
                // Specify a react function that will react on additional client changes
                react: (changes, baseRevision, partial, onChangesAccepted) => {
                    if (this.dataChannel.readyState !== 'open') {
                        this.handleError("The data channel has been closed.", 5000);
                        return;
                    }
                    this.sendChanges({
                        role: "initiator",
                        type: 'changes',
                        changes: changes,
                        baseRevision,
                        partial,
                    }, onChangesAccepted);
                },
                // Specify a disconnect function that will close our socket so that we dont continue to monitor changes.
                disconnect: function () {
                    // todo close data channel
                }
            });
            this.isFirstRound = false;
        }
    }

    private getMessageTimeout = (requestId: number) => {
        const timeOutId = setTimeout(async () => {
            console.log(`Failed to receive ack for ${requestId}`);
            this.dataChannel.close();
            if (!this.peerDeviceUUID) {
                throw new Error(`Unknown peerDeviceUUID while handling timeout.`)
            }
            const isDeviceConnected = await getDeviceConnection(this.peerDeviceUUID)
            const retryMs = isDeviceConnected ? RETRY_CONNECTION_MS : undefined;
            console.log(`Peer connection: ${isDeviceConnected}. Retry ms: ${retryMs}`)
            this.onError && this.onError(new Error("Timeout out sending changes to peer"), retryMs)
        }, MESSAGE_TIMEOUT_MS)

        return () => clearTimeout(timeOutId);
    }
}