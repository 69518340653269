import React from "react";
import TextField from "../../common/Form/TextField";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {createProfile, loadActiveProfile} from "../../store/profiles/actions";
import createNewProfile from "../../../domain/profiles/operations/createNewProfile";
import Button from "../../common/Button";
import {AppDispatch} from "../../store/types";
import {APP_LINKS} from "../../store/router/hooks/useAppRoutes";
import './add-profile-page.less';
import validationSchema from '../NewProfileModal/validationSchema';
import { ErrorMessage, Field, Formik } from 'formik';
import { Profile } from '../../../domain/profiles';
import useCurrencyOptions, { CurrencyOption, LoadingState } from '../../settings/hooks/useCurrencyOptions';
import SelectField from '../../common/Form/SelectField';

type FormValues = {
    name: Profile['name'];
    currency: CurrencyOption|null
};

const AddProfilePage = () => {
    const history = useHistory();
    const dispatch = useDispatch<AppDispatch>();
    const [loadingCurrencies, currencyOptions] = useCurrencyOptions();

    return <div className={'add-profile-page'}>
        <Formik<FormValues>
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={{
                name: '',
                currency: null
            }}
            onSubmit={async (values, formik) => {
                // @ts-ignore
                const newProfile = createNewProfile(values.name, values.currency.value);
                await dispatch(createProfile(newProfile));
                await dispatch(loadActiveProfile(newProfile));
                history.push(APP_LINKS.budget(newProfile.uuid))
            }}
        >
            {({ isValid, handleSubmit, setFieldValue }) => (
                <>
                    <p>Choose a name for your budgeting profile. You can rename this or add another one later.</p>
                    <Field name="name" as={TextField} label={'Profile name'} className={'add-profile-page__profile-name'} />
                    <ErrorMessage name={'name'}/>
                    <Field name="currency"
                           className={'add-profile-page__currency-selector'}
                           as={SelectField}
                           label={'Default currency'}
                           options={currencyOptions}
                           onChange={(newOption: CurrencyOption) =>
                               setFieldValue('currency', newOption)
                           }
                           disabled={loadingCurrencies !== LoadingState.LOADED}
                           placeholder={loadingCurrencies !== LoadingState.LOADED ? "Loading currencies..." : "Select a currency"}
                    />
                    <ErrorMessage name={'currency'}/>
                    <div className={'add-profile-page__actions'}>
                        <Button
                            onClick={() => handleSubmit()}
                            positive
                            disabled={!isValid}
                        >
                            Submit
                        </Button>
                    </div>
                </>
            )}
        </Formik>

    </div>

}

export default AddProfilePage;