import {RootState} from "../../types";

const MOBILE_WIDTH = 640;

export const width = (state: RootState) => state.ui.windowWidth;
export const height = (state: RootState) => state.ui.windowHeight;

export const isSmallScreen = (state: RootState) => state.ui.windowWidth < MOBILE_WIDTH;

export const getInstallPromptEvent = (state: RootState) => state.ui.installPromptEvent;
