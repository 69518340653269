import React from 'react';
import { Grid as SemanticGrid } from 'semantic-ui-react';

type Props = {
	children: React.ReactNode;
	className?: string;
	padded?: boolean;
};
const Grid = (props: Props) => {
	return (
		<SemanticGrid className={props.className} padded={props.padded}>
			{props.children}
		</SemanticGrid>
	);
};

export default Grid;
