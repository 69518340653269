import React from 'react';
import {
	STATUS_CLEARED,
	STATUS_RECONCILED,
	STATUS_UNCLEARED,
	Transaction,
} from '../../../domain/transactions/Transaction';
import { Icon } from 'semantic-ui-react';
import { IconSizeProp } from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import './transaction-status.less';

type Props = {
	transactionStatus: Transaction['status'];
	size?: IconSizeProp;
};

const TransactionStatus = (props: Props) => {
	switch (props.transactionStatus) {
		case STATUS_UNCLEARED:
			return (
				<Icon
					name="check circle"
					size={props.size}
                    className={'transaction-status transaction-status--uncleared'}
                />
			);
		case STATUS_CLEARED:
			return (
				<Icon
					name="check circle"
					size={props.size}
                    className={'transaction-status transaction-status--cleared'}
				/>
			);
		case STATUS_RECONCILED:
			return (
				<Icon
					name="lock"
					size={props.size}
					className={'transaction-status transaction-status--reconciled'}
				/>
			);
		default:
			throw new Error('Invalid transaction status');
	}
};

export default TransactionStatus;
