import {SubCategory} from "../../../categories";
import {Month} from "../../../util/date";
import {AllCategoryTotals, CategoryBalances, CategoryMonthTotals} from "../../index";
import calculateCategoryMonthTotals from "./calculateCategoryMonthTotals";
import {Profile} from "../../../profiles";
import {Account} from "../../../accounts/Account";

const calculateAllCategoryMonthTotals = async (
    profileUUID: Profile['uuid'],
    categories: Array<SubCategory>,
    thisMonth: Month,
    accounts: Map<Account['uuid'], Account>,
    lastCategoryBalances?: CategoryBalances,
): Promise<AllCategoryTotals> => {
    // calculate each category balance
    const categoryBalancePromises = categories.map((subCategory: SubCategory) => {
        return calculateCategoryMonthTotals(profileUUID, subCategory, thisMonth, accounts, lastCategoryBalances);
    });

    const categoryBalances = await Promise.all(categoryBalancePromises);
    const initial = {
        categoryBalances: new Map(),
        categoryOutflows: new Map(),
        categoryBudgeted: new Map(),
    };

    return categoryBalances.reduce((acc: AllCategoryTotals, categoryValues: CategoryMonthTotals) => {
        const { id, balance, outflows, budgeted, balanceCarriesOver } = categoryValues;
        acc.categoryBalances.set(id, {balance, isCarriedOver: balanceCarriesOver });
        acc.categoryOutflows.set(id, outflows);
        acc.categoryBudgeted.set(id, budgeted);
        return acc;
    }, initial);
};

export default calculateAllCategoryMonthTotals;