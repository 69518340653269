import { FieldInputProps } from 'formik';
import React from 'react';
import Select from 'react-select';
import useTransactionTagOptions from '../../../transactions/hooks/useTransactionTagOptions';
import { TransactionTag } from '../../../../domain/transactions/TransactionTag';

type Props = FieldInputProps<TransactionTag['uuid'][]>;

const TagField = (props: Props) => {
	const options = useTransactionTagOptions();
	const defaultOptions = options.filter(o => props.value.includes(o.value))

	return <Select
		defaultValue={defaultOptions}
		isMulti
		options={options}
		isClearable={true}
		onChange={newValue => {
			if (newValue === null || newValue === undefined) {
				props.onChange && props.onChange(null);
				return;
			}

			props.onChange(newValue.map(option => option.value));
		}}
		value={options.filter(o => props.value.includes(o.value))}
		styles={getStyles()}
		placeholder={'Tags...'}
	/>
}

export default TagField;

const getStyles = () => {
	return Object.assign(
		{},
		{
			control: (provided: {}) => ({
				...provided,
				borderRadius: 0,
				borderColor: '#e5e5e5',
				minHeight: 0,
				border: 0,
				background: 'none',
				boxShadow: 'none',
			}),
			indicatorSeparator: (provided: {}) => ({
				...provided,
				marginTop: '2px',
				marginBottom: '2px',
			}),
			valueContainer: (provided: {}) => ({
				...provided,
				padding: '0 4px',
			}),
			dropdownIndicator: (provided: {}) => ({
				...provided,
				padding: '5px 7px',
			}),
			clearIndicator: (provided: {}) => ({
				...provided,
				padding: '5px',
			}),
			menuPortal: (base: {}) => ({ ...base, zIndex: 9999 }),
		}
	);
};