import React from 'react';
import { Header, Icon, Modal as SemanticModal, Transition } from 'semantic-ui-react';
import { Button } from '../Button/Button';
import { SIZE_SMALL } from './index';
import { Size } from './index';

type Props = {
	open: boolean;
	onClose?: () => void;
	onPrimaryClick?: () => void;
	onSecondaryClick?: () => void;
	children?: React.ReactNode;
	title?: string;
	footer?: React.ReactNode;
	size?: Size;
};

const ConfirmModal = (props: Props) => {
	const size = props.size || SIZE_SMALL;
	return (
		<Transition.Group animation={'fade down'} duration={300}>
			{ props.open && <SemanticModal
				open={props.open}
				size={size}
				closeIcon
				onClose={() => props.onClose && props.onClose()}
			>
				<Header content={props.title || ''} />
				<SemanticModal.Content>{props.children}</SemanticModal.Content>
				<SemanticModal.Actions>
					<Button
						onClick={() => {
							if (props.onSecondaryClick) {
								return props.onSecondaryClick();
							}

							props.onClose && props.onClose();
						}}
					>
						<Icon name="remove" /> Cancel
					</Button>
					<Button
						type={'success'}
						onClick={() => props.onPrimaryClick && props.onPrimaryClick()}
					>
						<Icon name="checkmark" /> Save
					</Button>
				</SemanticModal.Actions>
			</SemanticModal>}
		</Transition.Group>
	);
};

export default ConfirmModal;
