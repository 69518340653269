import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import classNames from 'classnames';
import './app-button.less';
import {SemanticSIZES} from "semantic-ui-react";

type Type = 'success' | 'primary' | 'secondary' | 'warning' | 'default' | 'link';

type Props = {
	label?: string;
	onClick: () => void;
	size?: SemanticSIZES;
	children?: React.ReactNode;
	type?: Type;
	disabled?: boolean,
	loading?: boolean,
	className?: string
};

export const Button = (props: Props) => {
	const classes = classNames({
		'app-button': true,
		'app-button--primary': props.type === 'primary',
		'app-button--success': props.type === 'success',
		'app-button--warning': props.type === 'warning',
		[props.className || '']: !!props.className
	});

	return (
		<SemanticButton onClick={props.onClick} className={classes} disabled={props.disabled} size={props.size} loading={props.loading}>
			{props.label || props.children}
		</SemanticButton>
	);
};

export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
export const SIZE_LARGE = 'large';

export type SIZES = typeof SIZE_SMALL | typeof SIZE_MEDIUM | typeof SIZE_LARGE;
