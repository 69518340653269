import React from "react";
import { Message as SemanticUiMessage } from "semantic-ui-react";
import classNames from "classnames";
import './notice-message.less';

export enum Theme {
    LIGHT= 'light',
    DARK= 'dark'
}

export enum Type {
    ERROR= 'red',
    WARNING= 'yellow'
}

type Props = {
    header?: string
    children?: React.ReactNode
    theme?: Theme,
    className?: string,
    type?: Type
}

const NoticeMessage = (props: Props) => {
    const theme = props.theme || Theme.LIGHT;
    const customClass = props.className || '';

    const classes = classNames({
        [customClass]: true,
        'notice-message': true,
        'notice-message--dark': theme === Theme.DARK
    });

    return <SemanticUiMessage
        header={props.header}
        content={
            props.children
        }
        className={classes}
        color={props.type}
    />
}

export default NoticeMessage;

