import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TransactionsTable from '../TransactionsTable/TransactionsTable';
import { getTransactions } from '../../store/accounts/selectors/transactions';
import { loadTransactions } from '../../store/accounts/actions/transactions';

import './accounts-page.less';
import { getAllAccounts } from '../../store/accounts/selectors/accounts';
import {
	getClearedAccountBalance,
	getUnclearedAccountBalance,
	setActiveAccount,
	stopReconciling,
} from '../../store/accounts/actions';
import AccountHeader from '../AccountHeader/AccountHeader';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { Loader } from 'semantic-ui-react';
import { AppDispatch } from '../../store/types';
import ReconcileModal from '../ReconcileModal/ReconcileModal';
import { isReconcileModalOpen } from '../../store/accounts/selectors/reconciling';
import {Account} from "../../../domain/accounts/Account";

const AccountsPage = () => {
	const dispatch = useDispatch<AppDispatch>();
	const activeProfile = useRequiredActiveProfile();
	const { accountUUID } = useParams<{accountUUID: Account['uuid']}>();
	const transactions = useSelector(getTransactions);
	const allAccounts = useSelector(getAllAccounts);
	const account = allAccounts.get(accountUUID || '');
	const [loadingTransactions, setLoadingTransactions] = useState(false);
	const reconcileModalOpen = useSelector(isReconcileModalOpen);

	useEffect(() => {
		if (accountUUID) {
			dispatch(setActiveAccount(accountUUID));
			dispatch(getClearedAccountBalance(accountUUID));
			dispatch(getUnclearedAccountBalance(accountUUID));
			dispatch(stopReconciling());
		}

		setLoadingTransactions(true);
		dispatch(loadTransactions(activeProfile.uuid, accountUUID)).then(() => {
			setLoadingTransactions(false);
		});
	}, [dispatch, accountUUID, activeProfile]);

	return (
		<div className={'accounts-page'}>
			{account && <AccountHeader account={account} />}
			<Loader active={loadingTransactions} size={'large'} />
			{!loadingTransactions && (
				<TransactionsTable transactions={transactions} account={account} />
			)}
			{reconcileModalOpen && <ReconcileModal accountUUID={accountUUID} />}
		</div>
	);
};

AccountsPage.whyDidYouRender = true;

export default AccountsPage;
