import AppDB from '../index';
import { ICategoryBudgetsRepo } from '../../../../domain/categories/ICategoryBudgetsRepo';
import Dexie from 'dexie';
import { SubCategory } from '../../../../domain/categories';
import {CategoryBudget} from "../../../../domain/budget";
import {Month} from "../../../../domain/util/date";
import {Profile} from "../../../../domain/profiles";
import { IDBCategoryBudget } from './types';
import { getDate } from '../../../time';

class CategoryBudgetsRepo implements ICategoryBudgetsRepo {
	put(categoryBudget: CategoryBudget) {
		return AppDB.categoryBudgets.put(mapForStorage(categoryBudget));
	}

	putMany(categoryBudgets: CategoryBudget[]) {
		return AppDB.categoryBudgets.bulkPut(categoryBudgets.map(mapForStorage));
	}

	getCategoryBudgetsForMonth(
		month: Month,
		profileUUID: Profile['uuid'],
		subCategoryIds?: Array<SubCategory['uuid']>
	): Promise<Array<CategoryBudget>> {
		if (subCategoryIds) {
			const keys = subCategoryIds.map(subCatId => {
				return [profileUUID, month.year, month.month, subCatId];
			});
			return (
				AppDB.categoryBudgets
					.where('[profileUUID+year+month+categoryId]')
					// @ts-ignore
					.anyOf(keys)
					.toArray()
					.then(mapAllFromStorage)
			);
		}

		return AppDB.categoryBudgets
			.where('[profileUUID+year+month+categoryId]')
			.between(
				[profileUUID, month.year, month.month, Dexie.minKey],
				[profileUUID, month.year, month.month, Dexie.maxKey]
			)
			.toArray()
			.then(mapAllFromStorage)
	}

	getTotalBudgetedForMonth(month: Month, profileUUID: Profile['uuid']): Promise<number> {
		return AppDB.categoryBudgets
			.where('[profileUUID+year+month+categoryId]')
			.between(
				[profileUUID, month.year, month.month, Dexie.minKey],
				[profileUUID, month.year, month.month, Dexie.maxKey]
			)
			.toArray()
			.then(mapAllFromStorage)
			.then(sumBudgets);
	}

	getAll(profileUUID: Profile['uuid']) {
		return AppDB.categoryBudgets
			.where('profileUUID')
			.equals(profileUUID)
			.toArray()
			.then(mapAllFromStorage)
	}
}

export default new CategoryBudgetsRepo();

const sumBudgets = (categoryBudgets: Array<CategoryBudget>) => {
	return categoryBudgets.reduce(
		(acc, categoryBudget) => acc + categoryBudget.amount,
		0
	);
};

const mapAllFromStorage = (categoryBudgets: IDBCategoryBudget[]) => categoryBudgets.map(mapFromStorage)

const mapForStorage = (categoryBudget: CategoryBudget): IDBCategoryBudget => {
	return {
		...categoryBudget,
		updatedAt: getDate().toISOString()
	}
}

const mapFromStorage = (dbCategoryBudget: IDBCategoryBudget): CategoryBudget => {
	return {
		...dbCategoryBudget,
		updatedAt: dbCategoryBudget.updatedAt ? new Date(dbCategoryBudget.updatedAt) : null
	}
}
