import * as AccountActions from '../actions';
import { StoreActions } from '../../types';

export type ReconcileModalState = boolean;

export const reconcileModalOpen = (state: ReconcileModalState = false, action: StoreActions) => {
    switch (action.type) {
        case AccountActions.OPEN_RECONCILE_MODAL:
            return true;
        case AccountActions.CLOSE_RECONCILE_MODAL:
            return false;
        default:
            return state;
    }
};
