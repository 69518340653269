import AppDB from '../index';
import { ITransactionsRepo } from '../../../../domain/transactions/ITransactionsRepo';
import { IDBTransaction } from './types';
import {
	INCOME_CATEGORY_ID_NEXT_MONTH,
	INCOME_CATEGORY_ID_THIS_MONTH,
	SubCategory,
} from '../../../../domain/categories';
import Dexie from 'dexie';
import NaiveDate from '../../../../domain/util/NaiveDate';
import { Account } from '../../../../domain/accounts/Account';
import {
	STATUS_CLEARED, STATUS_RECONCILED,
	STATUS_UNCLEARED,
	Transaction,
} from '../../../../domain/transactions/Transaction';
import { getLastMonth, Month } from '../../../../domain/util/date';
import getTransactionMonth from '../../../../domain/transactions/util/getTransactionMonth';
import { Profile } from '../../../../domain/profiles';
import getCategoryTotal from "../../../../domain/transactions/util/getCategoryTotal";
import { TransactionTag } from '../../../../domain/transactions/TransactionTag';

class TransactionsRepo implements ITransactionsRepo {
	put(transaction: Transaction) {
		return AppDB.transactions.put(mapForStorage(transaction));
	}

	putMany(transactions: Transaction[]) {
		return AppDB.transactions.bulkPut(transactions.map(mapForStorage));
	}

	async getByUUIDs (uuids: Transaction['uuid'][]) {
		const transactions = await AppDB.transactions.where('uuid').anyOf(uuids).toArray()
		return transactions.map(mapFromStorage);
	}

	getCategoryOutflow(
		month: Month,
		profileUUID: Profile['uuid'],
		accounts: Map<Account['uuid'], Account>,
		subCategoryId: SubCategory['uuid']
	): Promise<number> {
		const monthCategoryKey = getMonthCategoryKey(profileUUID, month, subCategoryId);
		return AppDB.transactions
			.where('monthCategories')
			.equals(monthCategoryKey)
			.toArray()
			.then(subCategoryTotal.bind(null, subCategoryId, accounts));
	}

	getTotalIncomeForMonth(
		month: Month,
		profileUUID: Profile['uuid'],
		accounts: Map<Account['uuid'], Account>
	): Promise<number> {
		const lastMonth = getLastMonth(month);
		const monthIncome = this.getCategoryOutflow(month, profileUUID, accounts, INCOME_CATEGORY_ID_THIS_MONTH);
		const lastMonthIncome = this.getCategoryOutflow(lastMonth, profileUUID, accounts, INCOME_CATEGORY_ID_NEXT_MONTH);

		return Promise.all([monthIncome, lastMonthIncome]).then(
			([monthIncome, lastMonthIncome]) => {
				return monthIncome + lastMonthIncome;
			}
		);
	}

	async getOldestTransaction(profileUUID: Profile['uuid'], subCategoryId?: SubCategory['uuid']) {
		if (!subCategoryId) {
			return AppDB.transactions
				.where('profileUUID')
				.equals(profileUUID)
				.toArray()
				.then(transactions => {
					if (!transactions.length) {
						return;
					}
					const oldest = transactions.reduce((acc, t) => {
						// wtf was I doing here, could I have not made this more readable? smh
						if (
							!acc ||
							t.date.year < acc.date.year ||
							(t.date.year === acc.date.year && t.date.month < acc.date.month) ||
							(t.date.year === acc.date.year &&
								t.date.month === acc.date.month &&
								t.date.date < acc.date.date)
						) {
							return t;
						}

						return acc;
					});

					if (!oldest) {
						return;
					}

					return mapFromStorage(oldest);
				});
		}

		return AppDB.transactions
			.where('[profileUUID+date.year+date.month+categoryId]')
			.between(
				[profileUUID, Dexie.minKey, Dexie.minKey, subCategoryId],
				[profileUUID, Dexie.maxKey, Dexie.maxKey, subCategoryId]
			)
			.limit(1)
			.toArray()
			.then(transactions => {
				if (!transactions.length) {
					return;
				}
				return mapFromStorage(transactions[0]);
			});
	}

	getTransactions(profileUUID: Profile['uuid'], accountUUID?: Account['uuid']) {
		if (!accountUUID) {
			return AppDB.transactions
				.where('profileUUID')
				.equals(profileUUID)
				.toArray()
				.then(transactions => transactions.map(mapFromStorage));
		}

		return AppDB.transactions
			.where('accountUUID')
			.equals(accountUUID)
			.or('transferToAccountUUID')
			.equals(accountUUID)
			.toArray()
			.then(transactions => transactions.map(mapFromStorage));
	}

	deleteMany(UUIDs: Transaction['uuid'][]): Promise<void> {
		return AppDB.transactions.bulkDelete(UUIDs);
	}

	getTransactionsToReconcile(
		accountUUID: Account['uuid'],
		reconcileDate: NaiveDate
	): Promise<Transaction[]> {
		return (
			AppDB.transactions
				.where('[accountUUID+status]')
				// @ts-ignore
				.anyOf([
					[accountUUID, STATUS_UNCLEARED],
					[accountUUID, STATUS_CLEARED],
				])
				.or('transferToAccountUUID')
				.equals(accountUUID)
				.and((t: IDBTransaction) => {
					// if transfer to this account
					if (t.transferToAccountUUID === accountUUID && t.transferToAccountStatus === STATUS_RECONCILED) {
						return false;
					}

					return (
						NaiveDate.fromYMD(t.date.year, t.date.month, t.date.date).toString() <=
						reconcileDate.toString()
					);
				})
				.toArray()
				.then((transactions: IDBTransaction[]) => transactions.map(mapFromStorage))
		);
	}

	getTotalTransactionCount() {
		return AppDB.transactions.count();
	}

	getAllPayees(profileUUID: Profile['uuid']) {
		return AppDB.transactions
			.where('profileUUID')
			.equals(profileUUID)
			.toArray()
			.then(results => {
				const payeeSet: Set<Transaction['payee']> = new Set();
				const uniquePayees = results.reduce((acc, t) => {
					if (t.payee) {
						acc.add(t.payee);
					}
					return acc;
				}, payeeSet);

				return Array.from(uniquePayees.values());
			});
	}

	searchPayees(profileUUID: Profile['uuid'], searchValue: string) {
		const lCase = searchValue.toLowerCase();
		return AppDB.transactions
			.filter(t => {
				return t.profileUUID === profileUUID && t.payee.toLowerCase().includes(lCase);
			})
			.toArray()
			.then(results => {
				const payeeSet: Set<Transaction['payee']> = new Set();
				const uniquePayees = results.reduce((acc, t) => {
					if (t.payee) {
						acc.add(t.payee);
					}
					return acc;
				}, payeeSet);

				return Array.from(uniquePayees.values());
			});
	}

	getTagged(tagUUID: TransactionTag['uuid']) {
		return AppDB.transactions
			.where('tags')
			.equals(tagUUID)
			.toArray()
			.then((transactions: IDBTransaction[]) => transactions.map(mapFromStorage))

	}
}

const mapForStorage = (transaction: Transaction): IDBTransaction => ({
	uuid: transaction.uuid,
	// Replace with transaction.date.toJson() at some point
	date: {
		year: transaction.date.getYear(),
		month: transaction.date.getMonth(),
		date: transaction.date.getDay(),
	},
	accountUUID: transaction.accountUUID,
	transferToAccountUUID: transaction.transferToAccountUUID,
	transferToAccountStatus: transaction.transferToAccountStatus,
	categoryId: transaction.categoryId,
	profileUUID: transaction.profileUUID,
	monthCategories: transaction.categoryId.map(categoryUUID =>
		getMonthCategoryKey(transaction.profileUUID, getTransactionMonth(transaction), categoryUUID)
	),
	note: transaction.note,
	inflow: transaction.inflow,
	outflow: transaction.outflow,
	payee: transaction.payee,
	splits: transaction.splits,
	status: transaction.status,
	tags: transaction.tags || [],
});

const mapFromStorage = (transaction: IDBTransaction): Transaction => ({
	uuid: transaction.uuid,
	date: NaiveDate.fromYMD(transaction.date.year, transaction.date.month, transaction.date.date),
	accountUUID: transaction.accountUUID,
	transferToAccountUUID: transaction.transferToAccountUUID,
	transferToAccountStatus: transaction.transferToAccountStatus,
	categoryId: transaction.categoryId,
	profileUUID: transaction.profileUUID,
	note: transaction.note,
	inflow: transaction.inflow,
	outflow: transaction.outflow,
	payee: transaction.payee,
	splits: transaction.splits,
	status: transaction.status,
	tags: transaction.tags || [],
});

/** WARNING: This can't change in production without a migration on existing data **/
const getMonthCategoryKey = (
	profileUUID: Profile['uuid'],
	date: Month,
	categoryUUID: SubCategory['uuid']
) => {
	return `${profileUUID}:${date.year}-${date.month}:${categoryUUID}`;
};

const subCategoryTotal = (
	subCategoryUUID: SubCategory['uuid'],
	accounts: Map<Account['uuid'], Account>,
	transactions: IDBTransaction[]
) => {
	return transactions.reduce((acc, transaction) => {
		const transactionAccount = accounts.get(transaction.accountUUID);
		if (!transactionAccount) {
			throw new Error(`Cannot find account for transaction. Account UUID: ${transaction.accountUUID}`);
		}
		return acc + getCategoryTotal(mapFromStorage(transaction), subCategoryUUID, transactionAccount);
	}, 0);
}

export default new TransactionsRepo();
