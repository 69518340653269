import { AllCategoryTotals, HiddenCategoriesTotals } from '../../index';
import { SubCategory } from '../../../categories';

const getHiddenCategoryTotals = (
	categories: SubCategory[],
	allCategoryTotals: AllCategoryTotals
): HiddenCategoriesTotals => {
	const initial: HiddenCategoriesTotals = {
		balance: 0,
		outflows: 0,
		budgeted: 0,
	};
	return categories
		.filter(subCat => subCat.hidden)
		.reduce((acc, subCat) => {
			const categoryBalance = allCategoryTotals.categoryBalances.get(subCat.uuid) || {
				balance: 0,
				isCarriedOver: false,
			};
			const categoryBudgeted = allCategoryTotals.categoryBudgeted.get(subCat.uuid) || 0;
			const categoryOutflow = allCategoryTotals.categoryOutflows.get(subCat.uuid) || 0;
			return {
				balance: acc.balance + categoryBalance.balance,
				outflows: acc.outflows + categoryOutflow,
				budgeted: acc.budgeted + categoryBudgeted,
			};
		}, initial);
};

export default getHiddenCategoryTotals;
