import React from 'react';
import useSearchTotal from '../TableFooter/useSearchTotal';
import { Row } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import useLegacyMoneyFormatter from '../../../common/hooks/useLegacyMoneyFormatter';

type Props = {
	rows: Row<Transaction>[];
}

const TransactionsSummary = (props: Props) => {
	const searchTotal = useSearchTotal(props.rows)
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();

	return (
		<div className={'account-summary'}>
			<div className={'account-summary__third'}>
				<span className={'account-summary__value'}>
					{formatCurrencyForDisplay(searchTotal.inflows)}
				</span>
				<span className={'account-summary__label'}>Inflows</span>
			</div>
			<div className={'account-summary__third'}>
				<span className={'account-summary__value'}>
					{formatCurrencyForDisplay(searchTotal.outflows)}
				</span>
				<span className={'account-summary__label'}>Outflows</span>

			</div>
			<div className={'account-summary__third'}>
				<span className={'account-summary__value'}>
					{formatCurrencyForDisplay(searchTotal.total)}
				</span>
				<span className={'account-summary__label'}>Total</span>
			</div>
		</div>
	);
};

export default TransactionsSummary;
