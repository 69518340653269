import {CategoryOption, MasterCategoryGroup} from "../../store/categories/hooks/useCategoryOptions";
import {Column} from "react-table";
import {Transaction} from "../../../domain/transactions/Transaction";
import { Field, useFormikContext } from 'formik';
import SelectField, {SIZE_SMALL} from "../../common/Form/SelectField";
import React from "react";
import {ColStyle} from "../AddEditTransaction/AddEditTransactionRow/AddEditTransactionRow";
import Button from "../../common/Button";
import {
    COL_ID_CATEGORY,
    COL_ID_DATE,
    COL_ID_INFLOW,
    COL_ID_NOTE,
    COL_ID_OUTFLOW, COL_ID_PAYEE, COL_ID_SELECTION,
    COL_ID_STATUS
} from "../TransactionsTable/useTableColumns/useTableColumns";
import CurrencyInput from "../../common/Form/CurrencyInput";
import { FormValues } from '../AddEditTransaction/AddEditTransactionForm/AddEditTransactionForm';

export type FormSplit = {
    category: CategoryOption;
    note: string;
    inflow: number;
    outflow: number;
};

type SplitRowProps = {
    splitNum: number;
    categoryOptions: Array<MasterCategoryGroup>;
    // TODO: remove prop and just use the formik hook
    setFieldValue: (fieldName: string, newValue: any) => void;
    removeSplit: (splitNum: number) => void;
    columns: Column<Transaction>[]
};

const SplitRow = (props: SplitRowProps) => {
    const columnStyles = props.columns.map(col => [
        col.id, {
            width: col.width,
            minWidth: col.minWidth,
            maxWidth: col.maxWidth,
        } as ColStyle
    ]) as [[string, ColStyle]]
    const colStylesMap = new Map<string, ColStyle>(columnStyles);
    const { values } = useFormikContext<FormValues>();

    return (
        <div className={'add-transaction-row__split-row'}>
            <div className={'add-transaction-row__split-row-cell'} style={colStylesMap.get(COL_ID_SELECTION)} />
            <div className={'add-transaction-row__split-row-cell'} style={colStylesMap.get(COL_ID_DATE)} />
            <div className={'add-transaction-row__split-row-cell add-transaction-row__split-row-cell--split-actions'}
                 style={colStylesMap.get(COL_ID_PAYEE)}
            >
                <Button icon='close' onClick={(e) =>  {
                    props.removeSplit(props.splitNum)
                    e.preventDefault();
                }} size={'small'}/>
            </div>
            <div className={'add-transaction-row__split-row-cell'} style={colStylesMap.get(COL_ID_CATEGORY)}>
                <Field
                    type="number"
                    name={`splits[${props.splitNum}].category`}
                    as={SelectField}
                    options={ props.categoryOptions}
                    onChange={(newValue: CategoryOption) =>
                        props.setFieldValue(`splits[${props.splitNum}].category`, newValue)
                    }
                    size={SIZE_SMALL}
                    className={'add-transaction-row__select'}
                />
            </div>
            <div className={'add-transaction-row__split-row-cell'} style={colStylesMap.get(COL_ID_NOTE)}>
                <Field
                    type="text"
                    name={`splits[${props.splitNum}].note`}
                    className={'add-transaction-row__input'}
                />
            </div>
            <div className={'add-transaction-row__split-row-cell'} style={colStylesMap.get(COL_ID_OUTFLOW)}>
                <Field
                    as={CurrencyInput}
                    name={`splits[${props.splitNum}].outflow`}
                    className={'add-transaction-row__input'}
                    onBlur={(newValue: number) => {
                        if (newValue === values.splits[props.splitNum].outflow) return;

                        props.setFieldValue(`splits[${props.splitNum}]`, Object.assign(
                            values.splits[props.splitNum],
                            {
                                inflow: 0,
                                outflow: newValue
                            })
                        );
                    }}
                    onChange={() => {}}
                    autocomplete="off"
                />
            </div>
            <div className={'add-transaction-row__split-row-cell'} style={colStylesMap.get(COL_ID_INFLOW)}>
                <Field
                    as={CurrencyInput}
                    name={`splits[${props.splitNum}].inflow`}
                    onBlur={(newValue: number) => {
                        if (newValue === values.splits[props.splitNum].inflow) return;

                        props.setFieldValue(`splits[${props.splitNum}]`, Object.assign(
                            values.splits[props.splitNum],
                            {
                                inflow: newValue,
                                outflow: 0
                            })
                        );
                    }}
                    onChange={() => {}}
                    autocomplete="off"
                    className={'add-transaction-row__input'}
                />
            </div>
            <div className={'add-transaction-row__split-row-cell'} style={colStylesMap.get(COL_ID_STATUS)} />
        </div>
    );
};

export default SplitRow;