import {ICategoriesRepo} from "../../../../domain/categories/ICategoriesRepo";
import { MasterCategory, SubCategory} from "../../../../domain/categories";
import AppDB from "../index";
import {SubCategorySortOrder} from "./types";
import {Profile} from "../../../../domain/profiles";

class CategoriesRepo implements ICategoriesRepo {
    getSubCategory(categoryUUID: SubCategory['uuid']) {
        return AppDB.subCategories.get(categoryUUID);
    }

    putSubCategory(category: SubCategory) {
        return AppDB.subCategories.put(category);
    }

    putManySubCategories(categories: SubCategory[]) {
        return AppDB.subCategories.bulkPut(categories);
    }

    putMasterCategory(category: MasterCategory) {
        return AppDB.masterCategories.put(category);
    }

    putManyMasterCategories(categories: MasterCategory[]) {
        return AppDB.masterCategories.bulkPut(categories);
    }

    getAllMasterCategories(profileUUID: Profile['uuid']) {
        return AppDB.masterCategories.where('profileUUID').equals(profileUUID).toArray();
    }

    getAllSubCategories(profileUUID: Profile['uuid']) {
        return AppDB.subCategories.where('profileUUID').equals(profileUUID).toArray();
    }

    async setMasterCategoryOrder(newOrder: MasterCategory['uuid'][]) {
        const mappedToSave = newOrder.map((uuid, index) => ({
            uuid,
            order: index
        }));
        await AppDB.masterCategoryOrder.bulkPut(mappedToSave);
        return newOrder;
    }

    async getMasterCategoryOrder(profileUUID: Profile['uuid']) {
        const mCats = await this.getAllMasterCategories(profileUUID)
        const order = await AppDB.masterCategoryOrder
            .where('uuid').anyOf(mCats.map(m => m.uuid))
            .toArray();
        return order.sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            // a must be equal to b
            return 0;
        }).map((cOrder) => {
            return cOrder.uuid;
        })
    }

    async setSubCategoryOrder(
        masterCategoryUUID: MasterCategory['uuid'],
        newOrder: SubCategory['uuid'][]
    ) {
        const toSave: SubCategorySortOrder = {
            uuid: masterCategoryUUID,
            subCategoryOrder: newOrder
        };

        await AppDB.transaction('rw', AppDB.subCategoryOrder, async () => {
            await AppDB.subCategoryOrder.delete(masterCategoryUUID);
            await AppDB.subCategoryOrder.put(toSave);
        })

        return newOrder;
    }

    async getSubCategoryOrder(
        masterCategoryUUID?: MasterCategory['uuid'],
    ): Promise<Map<MasterCategory['uuid'], SubCategory['uuid'][]>> {
        if (!masterCategoryUUID) {
            const all = await AppDB.subCategoryOrder.toArray()
            return all.reduce((acc, order) => {
                acc.set(order.uuid, order.subCategoryOrder);
                return acc;
            }, new Map())
        }

        const order = await AppDB.subCategoryOrder.get(masterCategoryUUID);
        if (!order) {
            return new Map();
        }
        return new Map([[masterCategoryUUID, order.subCategoryOrder]]);
    }
}

export default new CategoriesRepo();