import { FormValues } from './AddEditTransactionForm';
import { AccountsMap } from '../../../store/accounts/selectors/accounts';
import { getSelectedAccount, getTransferAccount } from './formValues';

const isCategoryFieldDisabled = (values: FormValues, accounts: AccountsMap) => {
	if (values.splits.length > 0) {
		return true;
	}

	const selectedAccount = getSelectedAccount(values, accounts);
	const targetAccount = getTransferAccount(values, accounts);

	if (!values.isTransfer && selectedAccount?.isOffBudget) {
		return true;
	}

	if (values.isTransfer && !selectedAccount?.isOffBudget && !targetAccount?.isOffBudget) {
		return true;
	}

	return false;
}

export default isCategoryFieldDisabled;