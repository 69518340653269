import ConfirmModal from '../../common/Modal';
import { TransactionTag } from '../../../domain/transactions/TransactionTag';
import React, { useEffect, useState } from 'react';
import { TransactionTagsRepo } from '../../../domain/transactions/ITransactionTagsRepo';
import deleteTransactionTag from '../../../domain/transactions/operations/deleteTransactionTag';

type Props = {
	tagToDelete: TransactionTag | undefined,
	onDelete: () => void,
	onCancel: () => void,
}

const DeleteTransactionTagConfirmModal = (props: Props) => {
	const [transactionCount, setTransactionCount] = useState(0);
	useEffect(() => {
		if (!props.tagToDelete) return;
		TransactionTagsRepo.getTransactionCount(props.tagToDelete.uuid).then((count) => {
			setTransactionCount(count);
		})
	}, [props.tagToDelete, setTransactionCount])

	if (!props.tagToDelete) return null;

	const onDeleteClick = async () => {
		if (!props.tagToDelete) return null;
		await deleteTransactionTag(props.tagToDelete.uuid)
		props.onDelete();
	}

	return <ConfirmModal title={'Delete Tag'} open={!!props.tagToDelete} onPrimaryClick={onDeleteClick} onSecondaryClick={props.onCancel}>
		<p>{transactionCount ? `This tag is assigned to ${transactionCount} transactions. The tag will be removed from these transactions.` : null}</p>
		<p>{`Are you sure you want to delete the "${props.tagToDelete.label}" tag?`}</p>
	</ConfirmModal>
}

export default DeleteTransactionTagConfirmModal;