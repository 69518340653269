import { StoreActions } from '../../types';
import {SET_CLEARED_BALANCE, START_RECONCILING, STOP_RECONCILING} from "../actions";

export type ReconcilingBalance = {
    clearedBalance: number|null
    statementBalance: number|null
}

const initialState = {
    clearedBalance: null,
    statementBalance: null
}

export const reconcilingBalance = (state: ReconcilingBalance = initialState, action: StoreActions) => {
    switch (action.type) {
        case START_RECONCILING:
            const clearedBalance = state ? state.clearedBalance : null;
            return Object.assign({}, {
                statementBalance: action.payload.reconcileBalance,
                clearedBalance
            })
        case SET_CLEARED_BALANCE:
            return Object.assign({}, {
                statementBalance: state ? state.statementBalance : null,
                clearedBalance: action.payload.clearedBalance,
            })
        case STOP_RECONCILING:
            return {
                statementBalance: null,
                clearedBalance: null
            }
        default:
            return state;
    }
};
