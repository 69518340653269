import {Account} from "../../../domain/accounts/Account";
import React from "react";
import AccountLink from "./AccountLink";
import useOffBudgetAccounts from '../../accounts/hooks/useOffBudgetAccounts';

const OffBudgetAccountsList = () => {
    const accounts = useOffBudgetAccounts()
    if (accounts.length === 0) {
        return null;
    }
    const offBudgetAccounts = Array.from(accounts.values()).filter(a => a.isOffBudget)

    return <nav className={'accounts-nav__section'}>
        <div className={'accounts-nav__top'}>
            <h3 className={'accounts-nav__heading'}>Off-Budget Accounts</h3>
        </div>

        <nav className={'accounts-nav__section'}>
            <ul className={'accounts-nav__accounts-list'}>
                {offBudgetAccounts.map((account: Account) => (
                    <li className={'accounts-nav__accounts-list-item'} key={account.uuid}>
                        <AccountLink account={account}/>
                    </li>
                ))}
            </ul>
        </nav>
    </nav>
}

export default OffBudgetAccountsList;