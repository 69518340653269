import { combineReducers } from "redux";
import { monthsVisible, MonthsVisibleState } from "./monthsVisible";
import { monthBudget, MonthBudgetState } from "./monthBudget";
import { monthsBudgetsLoading, MonthsBudgetsLoadingState } from "./monthBudgetsLoading";
import withProfileReset from "../../profiles/withProfileReset";

export type BudgetState = {
    monthBudget: MonthBudgetState,
    monthsVisible: MonthsVisibleState,
    monthsBudgetsLoading: MonthsBudgetsLoadingState,
}
export default withProfileReset(combineReducers({
    monthBudget,
    monthsVisible,
    monthsBudgetsLoading
}));