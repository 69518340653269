import useCategoryFilters from '../useCategoryFilters';
import { SubCategory } from '../../../../../domain/categories';
import SelectFilter from '../SelectFilter';
import React, {CSSProperties} from 'react';
import { FilterProps } from 'react-table';
import { Transaction } from '../../../../../domain/transactions/Transaction';
import useFullCategoryOptions, { FullCategoryOption } from '../../../../store/categories/hooks/useFullCategoryOptions';
import { StylesConfig } from 'react-select';

export const DEFAULT_CHOOSE_CATEGORY = { label: '', value: [] };

const CategoryFilter = (
	props: FilterProps<Transaction>
) => {
	console.log('col filter val', props.column.filterValue)
	const queryParams = useCategoryFilters(props.column);
	const categoryOptions = useFullCategoryOptions();

	if (queryParams === null) {
		return null;
	}

	const styles: StylesConfig<FullCategoryOption>['option'] = (provided, state) => {
		let styles: CSSProperties = {};
		if (state.data.isMasterCategory) {
			styles.fontWeight = 'bold';
		} else {
			styles.paddingLeft = '20px'
		}
		return { ...provided, ...styles}
	}

	return (
		<SelectFilter<SubCategory['uuid'][]>
			{...props}
			selectOptions={categoryOptions}
			defaultOption={DEFAULT_CHOOSE_CATEGORY}
			label={'Category'}
			// @ts-ignore TODO: fix type
			optionsStylesFn={styles}
			onChange={(newValue) => {
				queryParams.setFilterValue(newValue?.value || null);
			}}
		/>
	);
};

export default CategoryFilter;
