import ConfirmModal from "./ConfirmModal";
import AdvancedModal from "./AdvancedModal";

export const SIZE_MINI = 'mini';
export const SIZE_TINY = 'tiny';
export const SIZE_SMALL = 'small';
export const SIZE_LARGE = 'large';
export const SIZE_FULL = 'fullscreen';

export type Size = typeof SIZE_MINI | typeof SIZE_TINY | typeof SIZE_SMALL | typeof SIZE_LARGE | typeof SIZE_FULL;

export default ConfirmModal;
export {
	AdvancedModal
}