/**
 * This file is deprecated.
 *
 * Use money.ts instead. Trying to move over to dinero.js everywhere
 * since it properly handles money calculations without floating point errors.
 */

export const formatCurrencyForInput = (amount: number, showZero = false) => {
	if (!showZero && amount === 0) {
		return '';
	}

	return amount.toFixed(2);
};

export const dangerouslyRound = (amount: number) => {
	return Math.round((amount + Number.EPSILON) * 100) / 100;
}

export const numberStringToCurrencyAmount = (input: string) => {
	if (input.length === 0) {
		return '0.00';
	}

	let currencyAmount = input;

	const charsToAdd = input.length < 3 ? 3 - input.length : 0;
	for (let i = 0; i < charsToAdd; i++) {
		currencyAmount = '0' + currencyAmount;
	}

	return currencyAmount.slice(0, -2) + '.' + currencyAmount.slice(-2);
}
