import getCurrentSyncDeviceUUID from '../../../domain/sync/getCurrentSyncDeviceUUID';
import registerNewDevice from '../../../domain/sync/registerNewDevice';
import { loadSettings } from '../../../view/store/app/actions/settings';
import { AppDispatch } from '../../../view/store/types';

const setupSyncDevice = async (dispatch: AppDispatch) => {
	let syncUUID = await getCurrentSyncDeviceUUID()
	if (!syncUUID) {
		// if not create one
		syncUUID = await registerNewDevice();
		await dispatch(loadSettings())
	}
	return syncUUID;
}

export default setupSyncDevice;