import { AppDispatch, RootState } from '../../../view/store/types';
import { dataChannelConnected, dataChannelDisconnected } from '../../../view/store/app/actions/dataChannels';
import ToastManager, { TYPES } from '../../../view/common/Toast';
import DataChannelManager from '../../web-rtc/data-channel/DataChannelManager';
import { Store } from 'redux';
import { getAccountDevices } from '../../../view/store/sync/selectors/devices';
import getDeviceName from '../../../domain/sync/getDeviceName';

const setupDataChannels = (dispatch: AppDispatch, getState: Store<RootState>['getState']) => {
	DataChannelManager.addEventListener('data-channel-connected', (event) => {
		dispatch(dataChannelConnected(event.deviceUUID));
		const device = getAccountDevices(getState()).get(event.deviceUUID)
		if (!device) return;
		ToastManager.updateToast(event.deviceUUID, `Connected to ${getDeviceName(device)}`, {
			type: TYPES.SUCCESS
		});
	})

	DataChannelManager.addEventListener('data-channel-disconnected', (event) => {
		dispatch(dataChannelDisconnected(event.deviceUUID))
	})
}

export default setupDataChannels;