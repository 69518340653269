import React, {useCallback} from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import AccountsNav from '../AccountsNav/AccountsNav';
import { useDispatch, useSelector } from 'react-redux';
import { isSideNavHideable } from '../../store/nav/selectors/sideNav';
import './main-nav.less';
import { hideSideNav } from '../../store/nav/actions';
import { Icon } from 'semantic-ui-react';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import useActiveProfile from '../../store/profiles/hooks/useActiveProfile';
import Button from '../../common/Button';
import LoginButton from "../LoginButton/LoginButton";
import SyncBar from "../SyncBar/SyncBar";
import ProfilesList from "../ProfilesList/ProfilesList";

export const HIDDEN_BELOW_WIDTH = 1024;

const MainNav = () => {
	const dispatch = useDispatch();
	const hideable = useSelector(isSideNavHideable);
	const onNavigate = useCallback(() => {
		if (hideable) {
			dispatch(hideSideNav())
		}
	}, [dispatch, hideable])

	const activeProfile = useActiveProfile();
	if (!activeProfile) {
		console.log('Main nav redirect');
		return <Redirect to={APP_LINKS.profiles()} />;
	}

	return (
		<nav className={'main-nav'}>
			<section className={'main-nav__top'}>
				<img src="/logoV1-min.svg" className={'main-nav__logo'} alt={'Dollero Logo'} />
				<div className={'main-nav__top-actions'}>
					<LoginButton/>
					{hideable && (
						<Button icon size={'large'} onClick={() => dispatch(hideSideNav())} primary>
							<Icon name="bars" />
						</Button>
					)}
				</div>
			</section>
			<section className={'main-nav__section'}>
				<ProfilesList/>
			</section>
			<section className={'main-nav__section'}>
				<nav>
					<ul className={'main-nav__list'}>
						<li className={'main-nav__list-item'}>
							<NavLink
								to={APP_LINKS.budget(activeProfile.uuid)}
								className={'main-nav__list-item-link'}
								activeClassName={'main-nav__list-item-link--active'}
								onClick={onNavigate}
							>
								<Icon name={'table'} />
								{'Budget'}
							</NavLink>
						</li>
						<li className={'main-nav__list-item'}>
							<NavLink
								to={APP_LINKS.accounts(activeProfile.uuid)}
								className={'main-nav__list-item-link'}
								activeClassName={'main-nav__list-item-link--active'}
								onClick={onNavigate}
							>
								<Icon name={'exchange'} />
								{'All Transactions'}
							</NavLink>
						</li>
						<li className={'main-nav__list-item'}>
							<NavLink
								to={APP_LINKS.settings_tags(activeProfile.uuid)}
								className={'main-nav__list-item-link'}
								activeClassName={'main-nav__list-item-link--active'}
								onClick={onNavigate}
							>
								<Icon name={'tag'} />
								{'Transaction Tags'}
							</NavLink>
						</li>
						<li className={'main-nav__list-item'}>
							<NavLink
								to={APP_LINKS.sync(activeProfile.uuid)}
								className={'main-nav__list-item-link'}
								activeClassName={'main-nav__list-item-link--active'}
								onClick={onNavigate}
							>
								<Icon name={'sync alternate'} />
								{'Sync Devices'}
							</NavLink>
						</li>
						<li className={'main-nav__list-item'}>
							<NavLink
								to={APP_LINKS.settings(activeProfile.uuid)}
								className={'main-nav__list-item-link'}
								activeClassName={'main-nav__list-item-link--active'}
								onClick={onNavigate}
							>
								<Icon name={'setting'} />
								{'Settings'}
							</NavLink>
						</li>
					</ul>
				</nav>
			</section>
			<AccountsNav />
			<SyncBar />
		</nav>
	);
};

export default MainNav;
