import React, { CSSProperties } from 'react';
import { useFormikContext } from 'formik';
import { STATUS_CLEARED, STATUS_UNCLEARED } from '../../../../domain/transactions/Transaction';
import TransactionStatus from '../../TransactionStatus';
import { FormValues } from '../AddEditTransactionForm/AddEditTransactionForm';

type Props = {
	style?: CSSProperties;
};

const StatusColumn = (props: Props) => {
	const { setFieldValue, values } = useFormikContext<FormValues>();
	return (
		<div
			className={
				'add-transaction-row__main-row-cell add-transaction-row__main-row-cell--status'
			}
			style={props.style}
			onClick={() => {
				if (values.status === STATUS_UNCLEARED) {
					setFieldValue('status', STATUS_CLEARED);
				}
				if (values.status === STATUS_CLEARED) {
					setFieldValue('status', STATUS_UNCLEARED);
				}
			}}
		>
			<TransactionStatus transactionStatus={values.status} size={'large'} />
		</div>
	);
};

export default StatusColumn;
