import {useDispatch} from "react-redux";
import {AppDispatch} from "../../types";
import {showSideNav as showSideNavAction, hideSideNav as hideSideNavAction} from "../actions";

const useSideNav = () => {

    const dispatch = useDispatch<AppDispatch>()
    const showSideNav = () => dispatch(showSideNavAction())
    const hideSideNav = () => dispatch(hideSideNavAction())

    return {showSideNav, hideSideNav}
}

export default useSideNav;