import * as calculatingMonthBudgets from "../actions/calculatingMonthBudgets";
import {StoreActions} from "../../types";
import {Month} from "../../../../domain/util/date";
import {SET_MONTH_BUDGET} from "../actions/monthBudget";
import isEqual from 'lodash/isEqual';

export type MonthsBudgetsLoadingState = Array<Month>;
export const monthsBudgetsLoading = (state: Array<Month> = [], action: StoreActions) => {
    switch (action.type) {
        case calculatingMonthBudgets.CALCULATING_MONTH_BUDGETS:
            return action.payload.months;

        case SET_MONTH_BUDGET:
            return state.filter((m) => !isEqual(m, action.payload.monthBudget.month))

        default:
            return state;
    }
};