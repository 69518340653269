import persistJWT from "./persistJWT";
import persistAuthUser from "./persistAuthUser";
import {AppSettingsRepo, SettingsKeys} from "../settings/IAppSettingsRepo";
import deleteAllSyncDevices from "../../infrastructure/sync/deleteAllSyncDevices";

const logInUser = async (user: any, token: string) => {
    await persistJWT(token)
    await persistAuthUser(user)
    const syncDevice = await AppSettingsRepo.get(SettingsKeys.syncDevice) as any; // TODO proper type here
    if (syncDevice?.account?.uuid !== user?.account?.uuid) {
        // the device is logging in to a new account, clear out the old sync data
        await AppSettingsRepo.del(SettingsKeys.syncUUID);
        await AppSettingsRepo.del(SettingsKeys.syncDevice);
        await deleteAllSyncDevices();
    }
}

export default logInUser;