import { SIZE_SMALL, SIZE_MEDIUM, SIZE_LARGE } from "./Button";
import SimpleButton from "./SimpleButton/SimpleButton";
import { Button as SemanticButton } from "semantic-ui-react";

const ButtonGroup = SemanticButton.Group;
const Button = SemanticButton;

export default Button;
export {
    SimpleButton,
    ButtonGroup,
    SIZE_SMALL,
    SIZE_MEDIUM,
    SIZE_LARGE,
}