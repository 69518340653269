import { Account } from '../../../../domain/accounts/Account';
import NaiveDate from '../../../../domain/util/NaiveDate';
import {
	Transaction,
} from '../../../../domain/transactions/Transaction';
import { AppDispatch, AppThunkAction } from '../../types';
import TransactionsRepo from '../../../../infrastructure/persistence/indexed-db/transactions/TransactionsRepo';
import { getAllAccountTransactions } from '../selectors/transactions';
import { addTransaction, editTransactions, loadTransactions } from './transactions';
import {
	getClearedBalance,
	getReconcileAccountUUID,
	getReconcileDate,
	getStatementBalance,
} from '../selectors/reconciling';
import AccountRepo from '../../../../infrastructure/persistence/indexed-db/accounts/AccountRepo';
import { createAdjustmentTransaction as createAdjTrans } from '../../../../domain/transactions/operations/createAdjustmentTransaction';
import { requireActiveProfile } from '../../profiles/selectors';
import reconcileClearedAccountTransactions from '../../../../domain/transactions/operations/reconcileClearedAccountTransactions';

export type ReconcilingActions =
	| OpenReconcileModal
	| StartReconciling
	| StopReconciling
	| CloseReconcileModal
	| LoadReconcileTransactions
	| SetClearedBalance;

export const OPEN_RECONCILE_MODAL = 'OPEN_RECONCILE_MODAL';
export type OpenReconcileModal = {
	type: typeof OPEN_RECONCILE_MODAL;
};

export const openReconcileModal = (): OpenReconcileModal => ({
	type: OPEN_RECONCILE_MODAL,
});

export const CLOSE_RECONCILE_MODAL = 'CLOSE_RECONCILE_MODAL';
export type CloseReconcileModal = {
	type: typeof CLOSE_RECONCILE_MODAL;
};

export const closeReconcileModal = (): CloseReconcileModal => ({
	type: CLOSE_RECONCILE_MODAL,
});

export const START_RECONCILING = 'START_RECONCILING';
export type StartReconciling = {
	type: typeof START_RECONCILING;
	payload: {
		accountUUID: Account['uuid'];
		reconcileDate: NaiveDate;
		reconcileBalance: number;
	};
};

export const startReconcilingActionCreator = (
	accountUUID: Account['uuid'],
	reconcileDate: NaiveDate,
	reconcileBalance: number
): StartReconciling => ({
	type: START_RECONCILING,
	payload: {
		accountUUID,
		reconcileDate,
		reconcileBalance,
	},
});

export const startReconciling = (
	accountUUID: Account['uuid'],
	reconcileDate: NaiveDate,
	reconcileBalance: number
): AppThunkAction => {
	return (dispatch: AppDispatch) => {
		return Promise.all([
			dispatch(loadReconcileTransactions(accountUUID, reconcileDate)),
			dispatch(loadReconcileClearedBalance(accountUUID, reconcileDate)),
		]).then(() => {
			dispatch(startReconcilingActionCreator(accountUUID, reconcileDate, reconcileBalance));
		});
	};
};

export const LOAD_RECONCILE_TRANSACTIONS = 'LOAD_RECONCILE_TRANSACTIONS';
export type LoadReconcileTransactions = {
	type: typeof LOAD_RECONCILE_TRANSACTIONS;
	payload: {
		transactions: Array<Transaction>;
	};
};

export const loadReconcileTransactionsActionCreator = (
	transactions: Array<Transaction>
): LoadReconcileTransactions => ({
	type: LOAD_RECONCILE_TRANSACTIONS,
	payload: {
		transactions,
	},
});

export const loadReconcileTransactions = (
	accountUUID: Account['uuid'],
	reconcileDate: NaiveDate
): AppThunkAction<Promise<void>> => {
	return (dispatch: AppDispatch) => {
		return TransactionsRepo.getTransactionsToReconcile(accountUUID, reconcileDate).then(
			transactions => {
				dispatch(loadReconcileTransactionsActionCreator(transactions));
			}
		);
	};
};

export const STOP_RECONCILING = 'STOP_RECONCILING';
export type StopReconciling = {
	type: typeof STOP_RECONCILING;
};

export const stopReconcilingActionCreator = (): StopReconciling => ({
	type: STOP_RECONCILING,
});

export const stopReconciling = (): AppThunkAction => {
	return (dispatch: AppDispatch, getState) => {
		const state = getState();
		const activeProfile = requireActiveProfile(state);
		const reconcileAccountUUID = getReconcileAccountUUID(state);
		dispatch(stopReconcilingActionCreator());
		if (reconcileAccountUUID) {
			dispatch(loadTransactions(activeProfile.uuid, reconcileAccountUUID));
		}
	};
};

export const SET_CLEARED_BALANCE = 'SET_CLEARED_BALANCE';
export type SetClearedBalance = {
	type: typeof SET_CLEARED_BALANCE;
	payload: {
		clearedBalance: number;
	};
};

export const setClearedBalance = (clearedBalance: number): SetClearedBalance => ({
	type: SET_CLEARED_BALANCE,
	payload: {
		clearedBalance,
	},
});

export const loadReconcileClearedBalance = (
	accountUUID?: Account['uuid'],
	reconcileDate?: NaiveDate
): AppThunkAction => {
	return async (dispatch: AppDispatch, getState) => {
		const state = getState();
		const reconcileAccountUUID = accountUUID || getReconcileAccountUUID(state);
		const date = reconcileDate || getReconcileDate(state);
		if (!reconcileAccountUUID || !date) {
			return;
		}
		const clearedBalance = await AccountRepo.getClearedBalance(reconcileAccountUUID, date);
		dispatch(setClearedBalance(clearedBalance));
	};
};

export const reconcileClearedTransactions = (): AppThunkAction => {
	return (dispatch: AppDispatch, getState) => {
		const state = getState();
		const reconcileAccountUUID = getReconcileAccountUUID(state);
		if (!reconcileAccountUUID) {
			throw new Error(
				'Cannot reconcile transactions when account is not currently being reconciled'
			);
		}
		const transactionsToReconcile = getAllAccountTransactions(state);
		const reconciledTransactions = reconcileClearedAccountTransactions(
			reconcileAccountUUID,
			transactionsToReconcile
		);
		dispatch(editTransactions(reconciledTransactions));
		dispatch(stopReconciling());
	};
};

export const createAdjustmentTransaction = (): AppThunkAction => {
	return (dispatch: AppDispatch, getState) => {
		const state = getState();
		const activeProfile = requireActiveProfile(state);
		const reconcileAccountUUID = getReconcileAccountUUID(state);
		if (!reconcileAccountUUID) {
			return;
		}
		const account = state.accounts.accounts.get(reconcileAccountUUID)
		if (!account) {
			throw new Error(`Cannot find account reconcile account: ${reconcileAccountUUID}`);
		}
		const statementBalance = getStatementBalance(state) || 0;
		const clearedBalance = getClearedBalance(state) || 0;
		const difference = clearedBalance - statementBalance;
		const adjustmentTansaction = createAdjTrans(
			account,
			activeProfile,
			difference
		);
		return dispatch(addTransaction(adjustmentTansaction));
	};
};
