import {DOLLERO_ACCOUNT_URL} from "../../../config";
import { SignUpErrorResponse, SignUpSuccessResponse } from '../../../../../shared/api/auth';
import logInUser from "../../../domain/auth/logInUser";

class SignUpError extends Error{}

const signUp = async (email: string, password: string) => {
    const response = await fetch(`${DOLLERO_ACCOUNT_URL}api/auth/signup`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            email,
            password
        })
    })

    if (response.status === 422) {
        const { message } = await response.json() as SignUpErrorResponse;
        throw new SignUpError(message)
    }

    const { token, user } = await response.json() as SignUpSuccessResponse;
    await logInUser(user, token);
    return {
        user: user,
        token: token
    }
}

export default signUp;