import { Field, useFormikContext } from 'formik';
import {
    FormValues, isTransferIntoAccount,
} from '../AddEditTransactionForm/AddEditTransactionForm';
import useAccountOptions, { AccountOption } from '../../../store/accounts/hooks/useAccountOptions';
import SelectField, { SIZE_SMALL } from '../../../common/Form/SelectField';
import React, { CSSProperties } from 'react';
import {useParams} from "react-router-dom";
import {Account} from "../../../../domain/accounts/Account";

type Props = {
    style?: CSSProperties;
};

const TransferToAccountColumn = (props: Props) => {
    const { setFieldValue, values } = useFormikContext<FormValues>();
    const { accountUUID } = useParams<{accountUUID: Account['uuid']}>();
    const accountOptions = useAccountOptions();

    if (accountUUID) {
        return (
            <div className={'add-transaction-row__main-row-cell'} style={props.style}>
                <Field
                    name="transferToAccount"
                    as={SelectField}
                    options={accountOptions.filter(opt => opt.value !== accountUUID)}
                    onChange={(newValue: AccountOption) =>
                        setFieldValue('transferToAccount', newValue)
                    }
                    placeholder={'Transfer to Account...'}
                    size={SIZE_SMALL}
                    className={'add-transaction-row__select'}
                    disabled={isTransferIntoAccount(values, accountUUID)}
                />
            </div>
        );
    }

    return (
        <div className={'add-transaction-row__main-row-cell'} style={props.style}>
            <Field
                name="transferToAccount"
                as={SelectField}
                options={accountOptions}
                onChange={(newValue: AccountOption) =>
                    setFieldValue('transferToAccount', newValue)
                }
                size={SIZE_SMALL}
                placeholder={'Transfer to account...'}
                className={'add-transaction-row__select'}
                disabled={false}
            />
        </div>
    );
};

export default TransferToAccountColumn;
