import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
	dangerouslyRound,
	formatCurrencyForInput,
} from '../../../domain/util/currency';
import {
	createMasterCategoryBudgetedSelector,
	createMasterCategoryOutflowSelector,
	createMasterCategoryBalanceSelector,
} from '../../store/budget/selectors/monthBudget';
import { Month } from '../../../domain/util/date';
import { MasterCategory, SubCategory } from '../../../domain/categories';
import { Profile } from '../../../domain/profiles';
import { Link } from 'react-router-dom';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import { DateFilterType } from '../../accounts/TransactionsTable/TableFilters/DateRangeFilter';
import NaiveDate from '../../../domain/util/NaiveDate';
import { getOrderedSubCategoryAssignments } from '../../store/categories/selectors';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import './master-category-totals.less';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';

type Props = {
	month: Month;
	masterCategory: MasterCategory;
	isLoading: boolean;
};

const MasterCategoryTotals = ({ month, masterCategory, isLoading }: Props) => {
	const categoryAssignments = useSelector(getOrderedSubCategoryAssignments);
	const activeProfile = useRequiredActiveProfile();
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const budgetSelector = useMemo(() => {
		return createMasterCategoryBudgetedSelector(month.year, month.month, masterCategory.uuid);
	}, [month.year, month.month, masterCategory.uuid]);
	const budgeted = useSelector(budgetSelector);

	const outflowsSelector = useMemo(() => {
		return createMasterCategoryOutflowSelector(month.year, month.month, masterCategory.uuid);
	}, [month.year, month.month, masterCategory.uuid]);
	const outflows = useSelector(outflowsSelector);

	const balanceSelector = useMemo(() => {
		return createMasterCategoryBalanceSelector(month.year, month.month, masterCategory.uuid);
	}, [month.year, month.month, masterCategory.uuid]);
	const balance = useSelector(balanceSelector);

	return (
		<>
			<div className={'master-category-totals__cell'}>
				{!isLoading && (
					<span className={'master-category-totals__value'}>
						{formatCurrencyForDisplay(budgeted, true)}
					</span>
				)}
			</div>
			<div className={'master-category-totals__cell'}>
				{!isLoading && (
					<span className={'master-category-totals__value'}>
						{getOutflowCell(
							outflows,
							activeProfile,
							month,
							categoryAssignments.get(masterCategory.uuid) || undefined
						)}
					</span>
				)}
			</div>
			<div className={'master-category-totals__cell'}>
				{!isLoading && (
					<span className={'master-category-totals__value'}>
						{formatCurrencyForDisplay(balance, true)}
					</span>
				)}
			</div>
		</>
	);
};

export default MasterCategoryTotals;

const getOutflowCell = (
	outflows: number,
	activeProfile: Profile,
	month: Month,
	categoryUUIDs: SubCategory['uuid'][] | undefined
) => {
	if (dangerouslyRound(outflows) === 0) {
		return formatCurrencyForInput(outflows);
	}

	return (
		<Link
			to={APP_LINKS.accounts(
				activeProfile.uuid,
				DateFilterType.CustomRange,
				NaiveDate.getStartOfMonth(month),
				NaiveDate.getEndOfMonth(month),
				categoryUUIDs
			)}
			className={'master-category-totals__outflows-link'}
		>
			{formatCurrencyForInput(outflows)}
		</Link>
	);
};
