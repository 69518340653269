import {combineReducers, createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';
import categoriesReducers from './categories/reducers'
import budgetReducers from './budget/reducers';
import accountsReducers from './accounts/reducers';
import navReducers from './nav/reducers';
import uiReducers from './ui/reducers';
import profileReducers from './profiles/reducers';
import syncReducers from './sync/reducers';
import authReducers from './auth/reducers';
import appReducers from './app/reducers';
import { pubSubMiddleware } from "../../infrastructure/pub-sub";
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory, History } from 'history';
import { routerMiddleware } from 'connected-react-router'
import registerTransactionListeners from "./accounts/subscribers/transactions";
import registerBudgetListeners from "./budget/subscribers/budgets";
import PubSub from "../../infrastructure/pub-sub";
import { RootState, StoreActions } from './types';

registerTransactionListeners(PubSub);
registerBudgetListeners(PubSub);

export const history = createBrowserHistory()

const createRootReducer = (history: History) => combineReducers({
    profiles: profileReducers,
    accounts: accountsReducers,
    budget: budgetReducers,
    categories: categoriesReducers,
    nav: navReducers,
    router: connectRouter(history),
    ui: uiReducers,
    sync: syncReducers,
    auth: authReducers,
    app: appReducers
})

const composer = composeWithDevTools({serialize: true});

const store = createStore<RootState, StoreActions, {}, {}>(
    createRootReducer(history),
    composer(
        applyMiddleware(
            thunk,
            routerMiddleware(history),
            pubSubMiddleware,
        ),
    )
);

export default store;