import {fetchAccountDevices} from "../../../view/store/sync/actions/devices";
import registerNewDevices from "../registerNewDevices";
import getCurrentSyncDeviceUUID from "../../../domain/sync/getCurrentSyncDeviceUUID";
import { AppDispatch } from '../../../view/store/types';

const newDeviceHandler = async (dispatch: AppDispatch) => {
    const devices = await dispatch(fetchAccountDevices())
    const syncUUID = await getCurrentSyncDeviceUUID()
    if (!syncUUID) {
        console.error(`Could not handle new device because current device has not UUID (is not setup to sync?)`)
        return;
    }
    // if any devices are not yet registered and are online then register them
    await registerNewDevices(devices, syncUUID);
}

export default newDeviceHandler;