import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { formatMoneyForDisplay, LegacyMoney, legacyToMoney } from '../../../domain/util/money';

const useLegacyMoneyFormatter = () => {
	const profile = useRequiredActiveProfile();
	return (amount: LegacyMoney, showZero = true) => {
		if (!showZero) return '';
		const money = legacyToMoney(amount, profile.currency)
		return formatMoneyForDisplay(money)
	}
}

export default useLegacyMoneyFormatter;