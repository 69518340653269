import { useCategoryFilterValue } from '../TableFilters/useCategoryFilters';
import { useSelector } from 'react-redux';
import { getAllAccounts } from '../../../store/accounts/selectors/accounts';
import { useMemo } from 'react';
import isTransfer from '../../../../domain/transactions/util/isTransfer';
import getCategoryTotal, {
	getCategoryInflows,
	getCategoryOutflows,
} from '../../../../domain/transactions/util/getCategoryTotal';
import { Row } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import { Account } from '../../../../domain/accounts/Account';
import { SubCategory } from '../../../../domain/categories';

const useSearchTotal = (
	rows: Row<Transaction>[],
	currentAccountUUID: Account['uuid'] | undefined = undefined
) => {
	const categoryFilterValue = useCategoryFilterValue();
	const accounts = useSelector(getAllAccounts);
	return useMemo(() => {
		const transactions = rows.map(r => r.original);
		return {
			total: sumSearchTotal(transactions, accounts, currentAccountUUID, categoryFilterValue),
			outflows: sumSearchOutflows(transactions, accounts, currentAccountUUID, categoryFilterValue),
			inflows: sumSearchInflows(transactions, accounts, currentAccountUUID, categoryFilterValue),
		}
	}, [rows, currentAccountUUID, categoryFilterValue, accounts]);
};

export default useSearchTotal;

const sumSearchTotal = (
	transactions: Transaction[],
	accounts: Map<Account['uuid'], Account>,
	currentAccountUUID: Account['uuid'] | undefined,
	subCategoryUUIDs: SubCategory['uuid'][] | undefined | null
) => {
	return transactions.reduce((acc, transaction) => {
		if (currentAccountUUID && transaction.transferToAccountUUID === currentAccountUUID) {
			return acc - transaction.inflow + transaction.outflow;
		}

		if (!currentAccountUUID && isTransfer(transaction)) {
			return acc;
		}

		if (!subCategoryUUIDs?.length) {
			return acc + transaction.inflow - transaction.outflow;
		}

		const transactionAccount = accounts.get(transaction.accountUUID);
		if (!transactionAccount) {
			throw new Error(
				`Can't find account for transaction. Account UUID: ${transaction.accountUUID}`
			);
		}
		const categoryFilterSum = subCategoryUUIDs.reduce((sum, subCategoryUUID) => {
			return sum + getCategoryTotal(transaction, subCategoryUUID, transactionAccount);
		}, 0);
		return acc + categoryFilterSum;
	}, 0);
};

const sumSearchOutflows = (
	transactions: Transaction[],
	accounts: Map<Account['uuid'], Account>,
	currentAccountUUID: Account['uuid'] | undefined,
	subCategoryUUIDs: SubCategory['uuid'][] | undefined | null
) => {
	return transactions.reduce((acc, transaction) => {
		if (currentAccountUUID && transaction.accountUUID === currentAccountUUID) {
			return acc + transaction.outflow;
		}

		if (!currentAccountUUID && isTransfer(transaction)) {
			return acc;
		}

		if (!subCategoryUUIDs?.length) {
			return acc + transaction.outflow;
		}

		const transactionAccount = accounts.get(transaction.accountUUID);
		if (!transactionAccount) {
			throw new Error(
				`Can't find account for transaction. Account UUID: ${transaction.accountUUID}`
			);
		}

		const categoryFilterSum = subCategoryUUIDs.reduce((sum, subCategoryUUID) => {
			return sum + getCategoryOutflows(transaction, subCategoryUUID, transactionAccount);
		}, 0);
		return acc + categoryFilterSum;
	}, 0);
};

const sumSearchInflows = (
	transactions: Transaction[],
	accounts: Map<Account['uuid'], Account>,
	currentAccountUUID: Account['uuid'] | undefined,
	subCategoryUUIDs: SubCategory['uuid'][] | undefined | null
) => {
	return transactions.reduce((acc, transaction) => {
		if (currentAccountUUID && transaction.transferToAccountUUID === currentAccountUUID) {
			return acc - transaction.inflow;
		}

		if (!currentAccountUUID && isTransfer(transaction)) {
			return acc;
		}

		if (!subCategoryUUIDs?.length) {
			return acc + transaction.inflow;
		}

		const transactionAccount = accounts.get(transaction.accountUUID);
		if (!transactionAccount) {
			throw new Error(
				`Can't find account for transaction. Account UUID: ${transaction.accountUUID}`
			);
		}
		const categoryFilterSum = subCategoryUUIDs.reduce((sum, subCategoryUUID) => {
			return sum + getCategoryInflows(transaction, subCategoryUUID, transactionAccount);
		}, 0);
		return acc + categoryFilterSum;
	}, 0);
};
