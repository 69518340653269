import {
    ApplyRemoteChangesFunction,
    IPersistedContext,
    ISyncProtocol,
    PollContinuation,
    ReactiveContinuation
} from "dexie-syncable/api";
import { IDatabaseChange } from 'dexie-observable/api';
import Dexie from "dexie";
import getDataChannel from "../sync/getDataChannel";
import SignallingError from '../web-rtc/signalling/SignallingError';
import { SyncDevice } from '../../domain/sync/SyncDevice';
import isDeviceConnected from '../sync/api/getDeviceConnection';
import { RETRY_CONNECTION_MS } from '../sync/constants';

export type OnChangesAccepted = () => void;
export type OnSuccess = (continuation: PollContinuation | ReactiveContinuation) => void;
export type OnError = (error: Error | string, tryAgainMs?: number) => void;

export default class WebRTCSyncProtocol implements ISyncProtocol {
    public partialsThreshold = 250;

    sync(
        context: IPersistedContext,
        url: string,
        options: any,
        baseRevision: number,
        syncedRevision: number,
        changes: IDatabaseChange[],
        partial: boolean,
        applyRemoteChanges: ApplyRemoteChangesFunction,
        onChangesAccepted: OnChangesAccepted,
        onSuccess: OnSuccess,
        onError: OnError
    ): void {
        console.log('ISyncProtocol called', url, changes);
        const filteredChanges = changes.filter(chg => chg.table !== 'monthBudgets');
        const peerDeviceUUID = url.slice(url.indexOf("/")+1);
        // check if other device is connected to dollero websocket server
        // if not call on error and dont try again
        getDataChannel(peerDeviceUUID)
            .then((dataChannel) => {
                dataChannel.beginSync(
                    peerDeviceUUID,
                    context,
                    baseRevision,
                    syncedRevision,
                    filteredChanges,
                    partial,
                    applyRemoteChanges,
                    onChangesAccepted,
                    onSuccess,
                    onError
                )
            }).catch(async (err) => {
                await handlerSyncError(err, peerDeviceUUID, onError)
            })

    }
}

const handlerSyncError = async (err: any, peerDeviceUUID: SyncDevice['uuid'], onError: OnError) => {
    if (err instanceof SignallingError) {
        const isConnected = await isDeviceConnected(peerDeviceUUID);
        const retry = isConnected ? RETRY_CONNECTION_MS : undefined;
        onError(err, retry);
    } else {
        onError(err);
    }
}


export const registerWebRTCSync = () => {
    Dexie.Syncable.registerSyncProtocol('webrtc', new WebRTCSyncProtocol());
};

