import {StoreActions} from "../../types";
import * as SettingsActions from "../actions/settings";
import {SettingsKeys} from "../../../../domain/settings/IAppSettingsRepo";

export type SettingsState = Map<SettingsKeys, any>;
export const settings = (state: SettingsState = new Map(), action: StoreActions) => {
    switch (action.type) {
        case SettingsActions.SET_SETTINGS:
            return action.payload.settingsMap;
        default:
            return state;
    }
};