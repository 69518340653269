import {Label} from "semantic-ui-react";
import React from "react";
import classNames from "classnames";
import './pill.less'

export const THEME_DEFAULT = 'default';
export const THEME_WARNING = 'warning';
export const THEME_SUCCESS = 'success';
export const THEME_MUTED = 'muted';

type PillTheme = typeof THEME_DEFAULT | typeof THEME_WARNING | typeof THEME_SUCCESS | typeof THEME_MUTED;

type Props = {
    value: string,
    theme?: PillTheme
}



const Pill = (props: Props) => {
    const themeClasses = classNames({
        'pill': true,
        'pill--default': props.theme && props.theme === THEME_DEFAULT,
        'pill--warning': props.theme && props.theme === THEME_WARNING,
        'pill--success': props.theme && props.theme === THEME_SUCCESS,
        'pill--muted': props.theme && props.theme === THEME_MUTED,
    });

    return <Label horizontal className={themeClasses}>
        {props.value}
    </Label>
}

export default Pill;