import { Profile } from '../../profiles';
import { AccountRepo } from '../../accounts/IAccountRepo';
import { MonthBudgetsRepo } from '../../budget/IMonthBudgetsRepo';
import { CategoryBudgetsRepo } from '../../categories/ICategoryBudgetsRepo';
import { CategoriesRepo } from '../../categories/ICategoriesRepo';
import { ProfileRepo } from '../../profiles/IProfileRepo';
import { TransactionsRepo } from '../../transactions/ITransactionsRepo';
import { CURRENT_VERSION } from '../../../infrastructure/persistence/indexed-db';
import { Account } from '../../accounts/Account';
import { CategoryBudget, MonthBudget } from '../../budget';
import { MasterCategory, SubCategory } from '../../categories';
import { SerializedTransaction } from '../../transactions/Transaction';
import toJson from '../../transactions/util/toJson';

export type BackupData = {
	dexieVersion: number;
	accounts: Account[];
	monthBudgets: MonthBudget[];
	categories: {
		masterCategories: MasterCategory[];
		subCategories: SubCategory[];
		masterCategoryOrder: MasterCategory['uuid'][];
		subCategoryOrder: [MasterCategory['uuid'], SubCategory['uuid'][]][];
	};
	categoryBudgets: CategoryBudget[];
	profile: Profile;
	transactions: SerializedTransaction[];
};

const collectData = async (profileUUID: Profile['uuid']): Promise<BackupData> => {
	const profile = await ProfileRepo.getByUUID(profileUUID);
	if (!profile) throw new Error(`Cannot find profile for uuid: ${profileUUID}`);

	return {
		dexieVersion: CURRENT_VERSION,
		accounts: await AccountRepo.getAll(profileUUID),
		monthBudgets: await MonthBudgetsRepo.getAllMonthBudgets(profileUUID),
		categories: {
			masterCategories: await CategoriesRepo.getAllMasterCategories(profileUUID),
			subCategories: await CategoriesRepo.getAllSubCategories(profileUUID),
			masterCategoryOrder: await CategoriesRepo.getMasterCategoryOrder(profileUUID),
			subCategoryOrder: Array.from((await CategoriesRepo.getSubCategoryOrder()).entries()),
		},
		categoryBudgets: await CategoryBudgetsRepo.getAll(profileUUID),
		profile: profile,
		transactions: (await TransactionsRepo.getTransactions(profileUUID)).map(toJson),
	};
};

export default collectData;
