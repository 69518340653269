import { MonthBudget } from '../../index';

const getTotalMonthBudgeted = (monthBudget: MonthBudget): number => {
	return Array.from(monthBudget.masterCategoryBudgeted.values()).reduce(
		(acc, masterCatBudgeted) => {
			return (acc += masterCatBudgeted);
		},
		0
	);
};

export default getTotalMonthBudgeted;
