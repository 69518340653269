import React from 'react';
import MonthSheet from '../MonthSheet/MonthSheet';
import { useDispatch, useSelector } from 'react-redux';
import { getMonthsVisible } from '../../store/budget/selectors/monthsVisible';
import useBudgetableCategories from '../../store/categories/hooks/useBudgetableCategories';
import { DragDropContext } from 'react-beautiful-dnd';
import './budget-sheet.less';
import { reOrderSubCategory } from '../../store/categories/actions/subCategories';
import { reOrderMasterCategory } from '../../store/categories/actions/masterCategories';
import BudgetSheetEmptyState from '../BudgetSheetEmptyState/BudgetSheetEmptyState';
import BudgetSheetCategoryRows from '../BudgetSheetCategoryRows/BudgetSheetCategoryRows';
import {hasCreatedAnyCategories} from "../../store/categories/selectors";
import classNames from "classnames";

const BudgetSheet = () => {
	const dispatch = useDispatch();
	const monthsToDisplay = useSelector(getMonthsVisible);
	const budgetableMasterCategories = useBudgetableCategories();
	const hasCreatedCategories = useSelector(hasCreatedAnyCategories)

	const windowClasses = classNames({
		'budget-sheet__window': true,
		'budget-sheet__window--burred': !hasCreatedCategories
	})

	return (
		<div className={'budget-sheet'}>
			{!hasCreatedCategories && <BudgetSheetEmptyState />}
			<div className={windowClasses}>
				<DragDropContext
					onDragEnd={dragResult => {
						console.log(dragResult);
						if (!dragResult.destination) {
							return;
						}

						if (dragResult.type === 'MASTER_CATEGORY') {
							return dispatch(
								reOrderMasterCategory(
									dragResult.draggableId,
									dragResult.source.index,
									dragResult.destination.index
								)
							);
						}

						return dispatch(
							reOrderSubCategory(
								dragResult.draggableId,
								dragResult.source.droppableId,
								dragResult.destination.droppableId,
								dragResult.source.index,
								dragResult.destination.index
							)
						);
					}}
				>
					<BudgetSheetCategoryRows
						budgetableMasterCategories={budgetableMasterCategories}
					/>
					<div className={'budget-sheet__month-sheets'}>
						{monthsToDisplay.map(month => (
							<MonthSheet key={`${month.year}-${month.month}`} month={month} />
						))}
					</div>
				</DragDropContext>
			</div>
		</div>
	);
};

export default BudgetSheet;
