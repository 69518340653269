import { SyncDevice } from '../../../domain/sync/SyncDevice';

class SignallingError extends Error {
	private readonly peerDeviceUUID: SyncDevice['uuid'];

	constructor(message: string, peerDeviceUUID: SyncDevice['uuid']) {
		super(message);
		this.peerDeviceUUID = peerDeviceUUID;
	}

	getPeerDeviceUUID = () => this.peerDeviceUUID;
}

export default SignallingError;