import actions from '../actions';
import { StoreActions } from '../../types';

export type WindowWidthState = number;
export const width = (state: number = window.innerWidth, action: StoreActions) => {
    switch (action.type) {
        case actions.SET_DIMENSIONS:
            return action.payload.width;

        default:
            return state;
    }
};

export type WindowHeightState = number;
export const height = (state: number = window.innerHeight, action: StoreActions) => {
    switch (action.type) {
        case actions.SET_DIMENSIONS:
            return action.payload.height;

        default:
            return state;
    }
};

export type InstallPromptEventState = null|Event;
export const installPromptEvent = (state: InstallPromptEventState = null, action: StoreActions) => {
    switch (action.type) {
        case actions.SET_INSTALL_PROMPT_EVENT:
            return action.payload.installPromptEvent;

        default:
            return state;
    }
};
