import React from 'react';
import classNames from 'classnames';
import {Month, getLastMonth, getNextMonth, getShortMonthYear} from '../../../domain/util/date';
import {
	setTargetViewMonth,
	setViewMonthBack,
	setViewMonthNext,
} from '../../store/budget/actions/budgetMonth';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import './multi-month-selector.less';

type Props = {
	months: Month[];
};

const MultiMonthSelector = (props: Props) => {
	const dispatch = useDispatch();
	const paddedMonths = padMonths(props.months);

	return (
		<nav className={'multi-month-selector'}>
			<Button icon="chevron left" className="multi-month-selector__arrow" onClick={() => dispatch(setViewMonthBack())} />
			<div className="multi-month-selector__list">
				{paddedMonths.map(month => {
					const shortMonth = getShortMonthYear(month);
					const isVisibleMonth = props.months.find(
						propMonth => propMonth.month === month.month
					);
					const isCurrentMonth =
						new Date().getFullYear() === month.year &&
						new Date().getMonth() + 1 === month.month;
					const classes = classNames({
						'multi-month-selector__list-item': true,
						'multi-month-selector__list-item--visible': isVisibleMonth,
						'multi-month-selector__list-item--current-month': isCurrentMonth,
					});
					return (
						<button
							key={`${month.year}-${month.month}`}
							className={classes}
							onClick={() => {
								dispatch(setTargetViewMonth(month));
							}}
						>
							{shortMonth}
						</button>
					);
				})}
			</div>
			<Button icon="chevron right" className="multi-month-selector__arrow" onClick={() => dispatch(setViewMonthNext())} />
		</nav>
	);
};

export default MultiMonthSelector;

const padMonths = (months: Month[]) => {
	if (months.length === 0) {
		return [];
	}
	const paddedMonths = Array.from(months);
	paddedMonths.unshift(getLastMonth(paddedMonths[0]));
	paddedMonths.unshift(getLastMonth(paddedMonths[0]));
	paddedMonths.unshift(getLastMonth(paddedMonths[0]));
	paddedMonths.push(getNextMonth(paddedMonths[paddedMonths.length - 1]));
	paddedMonths.push(getNextMonth(paddedMonths[paddedMonths.length - 1]));
	paddedMonths.push(getNextMonth(paddedMonths[paddedMonths.length - 1]));
	return paddedMonths;
};
