import {Profile} from "../../../../domain/profiles";
import MonthBudgetsRepo from "../../../../infrastructure/persistence/indexed-db/budgets/MonthBudgetsRepo";
import {AppThunkAction} from "../../types";
import {MonthBudget} from "../../../../domain/budget";

export const LOAD_MONTH_BUDGETS = 'LOAD_MONTH_BUDGETS';

export type LoadMonthBudgets = {
    type: typeof LOAD_MONTH_BUDGETS,
    payload: {
        monthBudgets: MonthBudget[]
    }
}

export const loadMonthBudgets = (profileUUID: Profile['uuid']): AppThunkAction => {
    return async (dispatch) => {
        const monthBudgets = await MonthBudgetsRepo.getAllMonthBudgets(profileUUID);
        return dispatch({
            type: LOAD_MONTH_BUDGETS,
            payload: {
                monthBudgets
            }
        });
    }
}