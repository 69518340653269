import {Profile} from "../../profiles";
import {uuid} from "../../util/uuid";
import {BudgetableMasterCategory} from "../../../view/store/categories/hooks/useBudgetableCategories";

const generateStartingCategories = (profile: Profile): BudgetableMasterCategory[] => {
    const monthlyBillsUUID = uuid();
    const everydayExpensesUUID = uuid();
    const debtPaymentsUUID = uuid();
    const savingsGoalsUUID = uuid();

    return [
        {
            uuid: monthlyBillsUUID,
            name: 'Monthly Bills',
            hidden: false,
            profileUUID: profile.uuid,
            subCategories: [
                {
                    uuid: uuid(),
                    name: 'Rent/Mortgage',
                    masterCategoryId: monthlyBillsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Electricity',
                    masterCategoryId: monthlyBillsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Water',
                    masterCategoryId: monthlyBillsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Internet',
                    masterCategoryId: monthlyBillsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
            ]
        },
        {
            uuid:everydayExpensesUUID,
            name: 'Everyday Expenses',
            hidden: false,
            profileUUID: profile.uuid,
            subCategories: [
                {
                    uuid: uuid(),
                    name: 'Groceries',
                    masterCategoryId: everydayExpensesUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Transportation / Fuel',
                    masterCategoryId: everydayExpensesUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Dining Out',
                    masterCategoryId: everydayExpensesUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Clothing',
                    masterCategoryId: everydayExpensesUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Fun Money',
                    masterCategoryId: everydayExpensesUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Fitness',
                    masterCategoryId: everydayExpensesUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
            ]
        },
        {
            uuid: debtPaymentsUUID,
            name: 'Debt Payments',
            hidden: false,
            profileUUID: profile.uuid,
            subCategories: [
                {
                    uuid: uuid(),
                    name: 'Auto Loan',
                    masterCategoryId: debtPaymentsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Student Loan',
                    masterCategoryId: debtPaymentsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
            ]
        },
        {
            uuid: savingsGoalsUUID,
            name: 'Savings Goals',
            hidden: false,
            profileUUID: profile.uuid,
            subCategories: [
                {
                    uuid: uuid(),
                    name: 'Gifts',
                    masterCategoryId: savingsGoalsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Vacation',
                    masterCategoryId: savingsGoalsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
                {
                    uuid: uuid(),
                    name: 'Education',
                    masterCategoryId: savingsGoalsUUID,
                    hidden: false,
                    profileUUID: profile.uuid
                },
            ]
        }
    ]
}

export default generateStartingCategories;