import {GetDevicesResponse} from "../../../../../shared/api/devices";
import {SyncDevice} from "../../../domain/sync/SyncDevice";
import {DOLLERO_ACCOUNT_URL} from "../../../config";
import getAuthorizationHeader from "./auth/getAuthorizationHeader";

const getDevices = async (): Promise<SyncDevice[]> => {
    return fetch(`${DOLLERO_ACCOUNT_URL}api/devices`, {
        headers: {
            Authorization: await getAuthorizationHeader()
        }
    })
        .then(response => response.json())
        .then(results => {
            console.log('Success:', results);
            return mapToDevice(results);
        })
        .catch(error => {
            throw new Error();
        });
}

export default getDevices;

const mapToDevice = (responseData: GetDevicesResponse): SyncDevice[] => {
    // TODO: better type
    return responseData.map((d: any) => {
        return {
            uuid: d.uuid,
            syncUrl: `dollero:device/${d.uuid}`,
            online: d.online,
            name: d.name,
            osName: d.os_name,
            osVersion: d.os_version,
            deviceModel: d.device_model,
            deviceType: d.device_type,
            deviceVendor: d.device_vendor,
            browserName: d.browser_name,
            browserVersion: d.browser_version,
            deletedAt: d.deleted_at,
            updatedAt: d.updated_at,
            createdAt: d.created_at
        }
    })
}