import NaiveDate from './NaiveDate';

export enum DateFormats {
	'yyyy-MM-dd' = 'yyyy-MM-dd',
	'dd/MM/yyyy' = 'dd/MM/yyyy',
	'M/d/yyyy' = 'M/d/yyyy',
}

export const guessDateFormatParser = (dateStrings: string[]) => {
	if (!dateStrings.length) throw new Error('No dates provider');

	switch (true) {
		case allDatesMatchFormat(dateStrings, DateFormats['yyyy-MM-dd']):
			return dateFormatParsers[DateFormats['yyyy-MM-dd']];
		case allDatesMatchFormat(dateStrings, DateFormats['M/d/yyyy']):
			return dateFormatParsers[DateFormats['M/d/yyyy']];
	}

	return dateFormatParsers[DateFormats['dd/MM/yyyy']];
};

const allDatesMatchFormat = (dates: string[], format: DateFormats): boolean => {
	return !dates.find(date => {
		return !dateFormatRegex[format].test(date);
	});
};
export const dateFormatParsers = {
	[DateFormats['yyyy-MM-dd']]: (date: string) => {
		const dateParts = date.split('-');
		return NaiveDate.fromYMD(
			parseInt(dateParts[0]),
			parseInt(dateParts[1]),
			parseInt(dateParts[2])
		);
	},
	[DateFormats['dd/MM/yyyy']]: (date: string) => {
		const dateParts = date.split('/');
		return NaiveDate.fromYMD(
			parseInt(dateParts[2]),
			parseInt(dateParts[1]),
			parseInt(dateParts[0])
		);
	},
	[DateFormats['M/d/yyyy']]: (date: string) => {
		const dateParts = date.split('/');
		return NaiveDate.fromYMD(
			parseInt(dateParts[2]),
			parseInt(dateParts[0]),
			parseInt(dateParts[1])
		);
	},
};

const dateFormatRegex = {
	[DateFormats['yyyy-MM-dd']]: new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/),
	[DateFormats['dd/MM/yyyy']]: new RegExp(
		/^(?:(?:31(\/|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
	),
	[DateFormats['M/d/yyyy']]: new RegExp(
		/^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
	),
};
