import * as filterActions from "../actions/filters";
import {StoreActions} from "../../types";

export type FiltersModalOpenState = boolean;

export const filtersModalOpen = (state = false, action: StoreActions) => {
    switch (action.type) {
        case filterActions.SHOW_TRANSACTION_FILTERS:
            return true;
        case filterActions.HIDE_TRANSACTION_FILTERS:
            return false;
        case filterActions.TOGGLE_TRANSACTION_FILTERS:
            return !state;
        default:
            return state;
    }
}

