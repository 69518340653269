import { Account } from '../../accounts/Account';
import createTransaction from './createTransaction';
import { INCOME_CATEGORY_ID_THIS_MONTH } from '../../categories';
import NaiveDate from '../../util/NaiveDate';
import { STATUS_CLEARED } from '../Transaction';
import { Profile } from '../../profiles';

/**
 * adjustmentAmount is the difference between the statement balance and the cleared balance.
 * - If this amount is negative it means the cleared balance is less than the statement balance
 * - If this amount is positive it means the cleared balance is greater than the statement balance
 */
export const createAdjustmentTransaction = (
	account: Account,
	profile: Profile,
	adjustmentAmount: number
) => {
	return createTransaction({
		accountUUID: account.uuid,
		inflow: adjustmentAmount < 0 ? Math.abs(adjustmentAmount) : 0,
		outflow: adjustmentAmount > 0 ? Math.abs(adjustmentAmount) : 0,
		categoryId: account.isOffBudget ? [] : [INCOME_CATEGORY_ID_THIS_MONTH],
		profileUUID: profile.uuid,
		date: NaiveDate.fromDate(new Date()),
		note: 'Reconciliation Balance Adjustment',
		status: STATUS_CLEARED,
		payee: "Reconciliation Balance Adjustment"
	});
};
