import useTransactionTags from './useTransactionTags';
import { TransactionTag } from '../../../domain/transactions/TransactionTag';

export type TransactionTagOption = {
	value: TransactionTag['uuid'],
	label: TransactionTag['label'],
}

const useTransactionTagOptions = (): TransactionTagOption[] => {
	const tags = useTransactionTags();
	if (!tags) return [];
	return tags?.map(t => ({
		value: t.uuid,
		label: t.label
	}))
}

export default useTransactionTagOptions;