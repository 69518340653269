import AddEditTransactionRow from '../../AddEditTransaction/AddEditTransactionRow/AddEditTransactionRow';
import React from 'react';
import { Transaction } from '../../../../domain/transactions/Transaction';
import { ListChildComponentProps } from 'react-window';
import { useSelector } from 'react-redux';
import { isSmallScreen } from '../../../store/ui/selectors/window';
import { CompactTransactionRow, TransactionRow } from '../../TransactionRow';
import { Row } from 'react-table';

type Props = ListChildComponentProps & {
	editingTransactionUUID: Transaction['uuid'] | null;
	onTransactionRowClick: (uuid: Transaction['uuid'], index: number) => void;
	onEditTransactionSave: () => void;
	onEditTransactionCancel: () => void;
	onRowHeightChange: (height: number) => void;
};

const TableRow = (props: Props) => {
	const smallScreen = useSelector(isSmallScreen);
	const row: Row<Transaction> = props.data.table.rows[props.index];
	props.data.table.prepareRow(row);

	if (!smallScreen && row.original.uuid === props.editingTransactionUUID) {
		return (
			<AddEditTransactionRow
				onCancel={props.onEditTransactionCancel}
				onSave={props.onEditTransactionSave}
				columns={props.data.table.visibleColumns}
				row={row}
				key={`${row.original.uuid}-edit`}
				style={props.style}
				onHeightChange={props.onRowHeightChange}
				isTransfer={row.original.transferToAccountUUID !== null}
			/>
		);
	}

	if (smallScreen) {
		return (
			<CompactTransactionRow
				row={row}
				onEdit={() =>
					props.onTransactionRowClick &&
					props.onTransactionRowClick(row.original.uuid, props.index)
				}
				key={row.original.uuid}
				style={props.style}
				listIndex={props.index}
				showDateHeader={showDateHeader(props.data.table.rows, props.index)}
			/>
		);
	}

	return (
		<TransactionRow
			row={row}
			onEdit={() =>
				props.onTransactionRowClick &&
				props.onTransactionRowClick(row.original.uuid, props.index)
			}
			key={row.original.uuid}
			style={props.style}
			listIndex={props.index}
		/>
	);
};

export const showDateHeader = (tableRows: Row<Transaction>[], currentIndex: number) => {
	const lastIndex = currentIndex === 0 ? null : currentIndex - 1;
	if (!lastIndex) {
		return true;
	}
	const lastRow: Row<Transaction> = tableRows[lastIndex];
	const currentRow: Row<Transaction> = tableRows[currentIndex];
	return lastRow.original.date.toString() !== currentRow.original.date.toString();
};

export default TableRow;
