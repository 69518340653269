import React, { useState } from 'react';
import './transactions-page.less';
import Table from '../../common/Table';
import Label from '../../common/Label/Label';
import Button from '../../common/Button';
import AddTransactionTagModal from '../AddTransactionTagModal/AddTransactionTagModal';
import useTransactionTags from '../../transactions/hooks/useTransactionTags';
import { TransactionTag } from '../../../domain/transactions/TransactionTag';
import Header from '../../common/Header';
import DeleteTransactionTagConfirmModal from '../DeleteTransactionTagConfirmModal/DeleteTransactionTagConfirmModal';
import { Segment } from 'semantic-ui-react';

const TransactionsTagsPage = () => {
	const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
	const [editingTag, setEditingTag] = useState<TransactionTag | undefined>(undefined);
	const [deletingTag, setDeletingTag] = useState<TransactionTag | undefined>(undefined);
	const tags = useTransactionTags();

	return <section className={'transactions-page'}>
		<Header as={'h1'} attached={'top'}>
			Tags
			<Button onClick={() => setIsAddEditModalOpen(true)} floated={'right'}>Add New</Button>
		</Header>
		<Segment attached padded={false}>
			<div className={'transactions-page__table-container'}>
				<Table celled unstackable basic={'very'}>
					<Table.Body>
						{tags.map(t => <Row
							tag={t}
							onDeleteClick={() => setDeletingTag(t)}
							onEditClick={() => {
								setEditingTag(t);
								setIsAddEditModalOpen(true);
							}}
						/>)}
					</Table.Body>
				</Table>
			</div>
		</Segment>
		<AddTransactionTagModal isOpen={isAddEditModalOpen} onClose={() => {
			setIsAddEditModalOpen(false);
			setEditingTag(undefined);
		}} tag={editingTag} />
		<DeleteTransactionTagConfirmModal tagToDelete={deletingTag} onDelete={() => setDeletingTag(undefined)}
										  onCancel={() => setDeletingTag(undefined)} />
	</section>;
};

export default TransactionsTagsPage;

type RowProps = {
	tag: TransactionTag,
	onEditClick: () => any
	onDeleteClick: () => any
}

const Row = ({ tag, onEditClick, onDeleteClick }: RowProps) => {
	return <Table.Row>
		<Table.Cell>
			<Label as='a' tag>
				{tag.label}
			</Label>
		</Table.Cell>
		<Table.Cell>
			<Button onClick={onEditClick}>Edit</Button>
			<Button onClick={onDeleteClick}>Delete</Button>
		</Table.Cell>
	</Table.Row>;
};