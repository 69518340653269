import {
	getLastMonth,
	getNextMonth,
	getNextMonths,
	Month,
} from '../../../../domain/util/date';
import { AppDispatch, AppThunkAction } from '../../types';
import { loadCategoryBudgets } from '../../categories/actions/budgets';
import { recalculateBudget } from './monthBudget';
import { getMonthsVisible } from '../selectors/monthsVisible';
import { getLatestCalculatedMonth } from '../selectors/monthBudget';
import numDisplayableMonths from '../../../../domain/budget/calculations/numDisplayableMonths';
import { width } from '../../ui/selectors/window';

export type BudgetMonthActions = ViewMonths;

export const setTargetViewMonth = (month: Month): AppThunkAction => {
	return (dispatch: AppDispatch, getState) => {
		const numViewableMonths = numDisplayableMonths(width(getState()));
		const monthsToDisplay = getNextMonths(month, numViewableMonths - 1);
		return dispatch(viewMonths([month, ...monthsToDisplay]));
	};
};

export const viewMonths = (months: Array<Month>): AppThunkAction => {
	return async (dispatch: AppDispatch, getState) => {
		if (months.length === 0) {
			throw new Error('Must view at least one month');
		}

		months.forEach(month => {
			return dispatch(loadCategoryBudgets(month));
		});
		dispatch(setViewMonths(months));

		const latestCalculatedMonth = getLatestCalculatedMonth(getState());
		if (!latestCalculatedMonth) {
			return dispatch(recalculateBudget());
		}

		return dispatch(recalculateBudget(months[0]));
	};
};

export const VIEW_MONTHS = 'VIEW_MONTHS';
export type ViewMonths = {
	type: typeof VIEW_MONTHS;
	payload: {
		months: Array<Month>;
	};
};

const setViewMonths = (months: Array<Month>): ViewMonths => ({
	type: VIEW_MONTHS,
	payload: {
		months,
	},
});

export const setViewMonthBack = (): AppThunkAction => {
	return async (dispatch, getState) => {
		const state = getState();
		const monthsVisible = getMonthsVisible(state);
		if (monthsVisible.length === 1) {
			dispatch(viewMonths([getLastMonth(monthsVisible[0])]));
			return;
		}
		monthsVisible.pop();
		const prev = getLastMonth(monthsVisible[0]);
		const newViewMonths = [prev, ...monthsVisible];
		dispatch(viewMonths(newViewMonths));
	};
};

export const setViewMonthNext = (): AppThunkAction => {
	return async (dispatch, getState) => {
		const state = getState();
		const monthsVisible = getMonthsVisible(state);
		if (monthsVisible.length === 1) {
			dispatch(viewMonths([getNextMonth(monthsVisible[0])]));
			return;
		}
		monthsVisible.shift();
		const latestMonth = monthsVisible[monthsVisible.length - 1];
		const next = getNextMonth(latestMonth);
		const newViewMonths = [...monthsVisible, next];
		dispatch(viewMonths(newViewMonths));
	};
};
