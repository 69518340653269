import {RootState} from "../../types";
import {Account} from "../../../../domain/accounts/Account";

export type AccountsMap = Map<Account['uuid'], Account>;
export const getAllAccounts = (state: RootState): AccountsMap => {
    return state.accounts.accounts;
};

export const getAllAccountBalances = (state: RootState) => {
    return state.accounts.accountBalances;
};

export const getActiveAccountBalance = (state: RootState) : number | undefined => {
    const activeAccountUUID = getActiveAccount(state);
    if (!activeAccountUUID) {
        return;
    }
    return state.accounts.accountBalances.get(activeAccountUUID);
};

export const getActiveAccountClearedBalance = (state: RootState) : number | undefined => {
    const activeAccountUUID = getActiveAccount(state);
    if (!activeAccountUUID) {
        return;
    }
    return state.accounts.accountClearedBalances.get(activeAccountUUID);
}

export const getActiveAccountUnclearedBalance = (state: RootState) : number | undefined => {
    const activeAccountUUID = getActiveAccount(state);
    if (!activeAccountUUID) {
        return;
    }
    return state.accounts.accountUnclearedBalances.get(activeAccountUUID);
}

export const getActiveAccount = (state: RootState) => {
    return state.accounts.activeAccount;
}