import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import MonthTotals from '../MonthTotals/MonthTotals';
import { Month, isCurrentMonth, isPastMonth, getMonthName } from '../../../domain/util/date';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import {
	getAvailableToBudget,
	isBudgetCalculatedForMonth,
	isMonthBudgetLoading,
} from '../../store/budget/selectors/monthBudget';
import { RootState } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import './month-header.less';
import { isSmallScreen } from '../../store/ui/selectors/window';
import Button from '../../common/Button';
import { setViewMonthBack, setViewMonthNext } from '../../store/budget/actions/budgetMonth';
import MonthHeaderPopupContent from './MonthHeaderPopupContent';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';

type Props = {
	month: Month;
};

const MonthHeader = ({ month }: Props) => {
	const dispatch = useDispatch();
	const smallScreen = useSelector(isSmallScreen);
	const isLoading = useSelector(
		useCallback(
			(state: RootState) => {
				return isMonthBudgetLoading(state, month);
			},
			[month]
		)
	);
	const hasBudgetCalculated = useSelector(
		useCallback(
			(state: RootState) => {
				return isBudgetCalculatedForMonth(state, month);
			},
			[month]
		)
	);
	const showLoading = isLoading && !hasBudgetCalculated;

	return smallScreen ? (
		<div className={'month-header__month-navigation'}>
			<Button
				className={'month-header__arrow'}
				icon={'arrow left'}
				primary
				onClick={() => dispatch(setViewMonthBack())}
			/>
			<Top month={month} smallScreen={smallScreen} showLoading={showLoading} />
			<Button
				className={'month-header__arrow'}
				icon={'arrow right'}
				primary
				onClick={() => dispatch(setViewMonthNext())}
			/>
		</div>
	) : (
		<header className={componentClasses(smallScreen)}>
			<Top month={month} smallScreen={smallScreen} showLoading={showLoading} />
			{!showLoading && <MonthTotals month={month} />}
		</header>
	);
};

type TopProps = {
	month: Month;
	smallScreen: boolean;
	showLoading: boolean;
};

const Top = (props: TopProps) => {
	const { month, smallScreen, showLoading } = props;

	const availToBudgetSelector = useMemo(() => {
		return (state: RootState) => getAvailableToBudget(state, month.year, month.month);
	}, [month]);
	const availableToBudget = useSelector(availToBudgetSelector);
	const formatMoneyForDisplay = useLegacyMoneyFormatter();

	const topClasses = getTopClasses(month, smallScreen);
	const headingClasses = getHeadingClasses(month, smallScreen);
	const toBudgetClasses = budgetClasses(month, smallScreen, availableToBudget);

	return (
		<div className={topClasses}>
			<h3 className={headingClasses}>{getMonthName(month) + ' ' + month.year}</h3>
			{showLoading && <HeaderLoader />}
			{!showLoading && (
				<>
					<span className={toBudgetClasses}>
						{formatMoneyForDisplay(availableToBudget)}
					</span>
					<span>
						<Popup
							wide={'very'}
							position={'bottom center'}
							content={<MonthHeaderPopupContent month={month} />}
							trigger={<div>
								Available to budget &nbsp;
								<Icon name={'question circle'} />
							</div>}
						/>
					</span>
				</>
			)}
		</div>
	);
};

export default MonthHeader;

const HeaderLoader = () => {
	return (
		<div className={'month-header__loader'}>
			<Loader active={true} inline={'centered'} inverted={true} />
		</div>
	);
};

const componentClasses = (smallScreen: boolean) => {
	return classNames({
		'month-header': true,
		'month-header--small-screen': smallScreen,
	});
};

const budgetClasses = (month: Month, smallScreen: boolean, availableToBudget: number) => {
	return classNames({
		'month-header__avail-to-budget': true,
		'month-header__avail-to-budget--negative': !isPastMonth(month) && availableToBudget < 0,
		'month-header__avail-to-budget--past': !smallScreen && isPastMonth(month),
		'month-header__avail-to-budget--current': smallScreen || isCurrentMonth(month),
		'month-header__avail-to-budget--future':
			!smallScreen && !isPastMonth(month) && !isCurrentMonth(month),
	});
};

const getTopClasses = (month: Month, smallScreen: boolean) => {
	return classNames({
		'month-header__top': true,
		'month-header__top--past': !smallScreen && isPastMonth(month),
		'month-header__top--current': !smallScreen && isCurrentMonth(month),
		'month-header__top--current-mobile': smallScreen,
		'month-header__top--future': !smallScreen && !isPastMonth(month) && !isCurrentMonth(month),
	});
};

const getHeadingClasses = (month: Month, smallScreen: boolean) => {
	return classNames({
		'month-header__month-heading': true,
		'month-header__month-heading--past': !smallScreen && isPastMonth(month),
		'month-header__month-heading--current': smallScreen || isCurrentMonth(month),
		'month-header__month-heading--future':
			!smallScreen && !isPastMonth(month) && !isCurrentMonth(month),
	});
};
