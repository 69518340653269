import { useSelector } from 'react-redux';
import { getSyncDevices } from '../../sync/selectors/devices';
import { SyncDevice } from '../../../../domain/sync/SyncDevice';
import { UrlStatusMap } from '../../sync/actions/devices';
import { connectedDevices } from '../selectors/dataChannels';
import { getSyncUUID } from '../selectors/settings';
import useAccountDevices from '../../sync/hooks/useAccountDevices';

export type SyncDeviceStatus = SyncDevice & {
	syncStatus: string | undefined,
	syncUrl: string | undefined,
	dataChannelConnected: boolean
}

const useSyncDevices = (): SyncDeviceStatus[] => {
	const syncUUID = useSelector(getSyncUUID);
	const syncDevices = useSelector(getSyncDevices);
	const accountDevices = useAccountDevices();
	const dataChannelDevices = useSelector(connectedDevices);

	return Array.from(accountDevices.values())
		.map(device => {
			if (device.uuid === syncUUID) return null;
			const [url, status] = getSyncDevice(device.uuid, syncDevices);
			return Object.assign({}, device, {
				syncStatus: status,
				syncUrl: url,
				dataChannelConnected: dataChannelDevices.has(device.uuid),
			});
		}).filter((d => d)) as SyncDeviceStatus[];
};

export default useSyncDevices;

const getSyncDevice = (syncDeviceUUID: SyncDevice['uuid'], syncDevices: UrlStatusMap) => {
	return Array.from(syncDevices.entries())
		.find(([url, status]) => {
			return url.includes(syncDeviceUUID);
		}) || [];
};