import { Workbox } from 'workbox-window';

export type UpdatePromptActions = ShowUpdatePromptModalAction | CloseUpdatePromptModalAction;

export type ShowUpdatePromptModalAction = {
	type: 'SHOW_UPDATE_PROMPT_MODAL',
	payload: {
		workbox: Workbox
	}
};

export const SHOW_UPDATE_PROMPT_MODAL = 'SHOW_UPDATE_PROMPT_MODAL';
export const showUpdatePromptModal = (workbox: Workbox): ShowUpdatePromptModalAction => ({
	type: SHOW_UPDATE_PROMPT_MODAL,
	payload: {
		workbox: workbox
	}
})

export type CloseUpdatePromptModalAction = {
	type: 'CLOSE_UPDATE_PROMPT_MODAL',
	payload: {}
};

export const CLOSE_UPDATE_PROMPT_MODAL = 'CLOSE_UPDATE_PROMPT_MODAL';
export const closeUpdatePromptModal = (): CloseUpdatePromptModalAction => ({
	type: CLOSE_UPDATE_PROMPT_MODAL,
	payload: {}
})