import * as DeviceActions from '../actions/devices';
import { StoreActions } from '../../types';
import {UrlStatusMap} from "../actions/devices";
import {UUID} from "../../../../domain";
import {SyncDevice} from "../../../../domain/sync/SyncDevice";

export type DevicesState = UrlStatusMap;
export const devices = (state: DevicesState = new Map(), action: StoreActions) => {
    switch (action.type) {
        case DeviceActions.LOAD_SYNC_DEVICES:
            return action.payload.syncDevices;
        case DeviceActions.SYNC_STATUS_CHANGED:
            state.set(action.payload.url, action.payload.status)
            return new Map(state.entries());
        default:
            return state;
    }
};

export type AccountDevicesState = Map<UUID, SyncDevice>;
export const accountDevices = (state: AccountDevicesState = new Map(), action: StoreActions) => {
    switch (action.type) {
        case DeviceActions.LOAD_ACCOUNT_DEVICES:
            return new Map(action.payload.syncDevices.map(d => [d.uuid, d]));
        default:
            return state;
    }
};

export type FirstRoundSyncCompleteState = Set<SyncDevice['uuid']>;
export const firstRoundSyncComplete = (state: FirstRoundSyncCompleteState = new Set(), action: StoreActions) => {
    switch (action.type) {
        case DeviceActions.FIRST_ROUND_SYNC_COMPLETE:
            return (new Set(state.values())).add(action.payload.peerSyncDeviceUUID);
        default:
            return state;
    }
}

export type NumItemsSyncedState = Map<SyncDevice['uuid'], number>;
export const numItemsSynced = (state: NumItemsSyncedState = new Map(), action: StoreActions) => {
    switch (action.type) {
        case DeviceActions.INCREMENT_ITEMS_SYNCED:
            let existingNum = state.get(action.payload.peerSyncDeviceUUID) || 0;
            return (new Map(state.entries()))
                .set(action.payload.peerSyncDeviceUUID, existingNum + action.payload.incrementItemsSynced);
        default:
            return state;
    }
}