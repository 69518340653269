import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './view/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Store from "./view/store";
import {Provider} from "react-redux";
import { injectStore } from './view/store/dispatch';
import handleInstallPrompt from './infrastructure/environment/handleInstallPrompt';
import setupUpdatePrompt from './infrastructure/service-worker/promptForUpdate';
// import whyDidYouRender from "@welldone-software/why-did-you-render";

// whyDidYouRender(React,{
//     trackAllPureComponents: false,
// });

injectStore(Store);
handleInstallPrompt();
setupUpdatePrompt();

ReactDOM.render(<Provider store={Store}><App /></Provider>, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
