import NaiveDate from "../../../../domain/util/NaiveDate";
import {DATE_FILTER_TYPE_OPTIONS, DateFilter, DateFilterType, getDatesFromFilterOption} from "./DateRangeFilter";
import useQueryParams from "../../../common/hooks/useQueryParams";

type DateRangeQueryFilters = {
    filterValue: DateFilter,
    setFilterValue: (filterValue: DateFilter) => void
}

export const DATE_FILTER_QUERY = 'date_filter';
export const DATE_FROM_QUERY = 'date_from';
export const DATE_TO_QUERY = 'date_to';

const useDateRangeQueryFilters = (): DateRangeQueryFilters => {
    const query = useQueryParams();
    const dateFilterQueryParam = query.params.get(DATE_FILTER_QUERY);
    const queryDateFrom = query.params.get(DATE_FROM_QUERY);
    const queryDateTo = query.params.get(DATE_TO_QUERY);


    const initialDateFilter = DATE_FILTER_TYPE_OPTIONS.find(
        opt => opt.value === dateFilterQueryParam
    ) || DATE_FILTER_TYPE_OPTIONS[0];

    const dates = getDatesFromFilterOption(initialDateFilter.value);
    const dateFrom = queryDateFrom ? new NaiveDate(queryDateFrom) : undefined;
    const dateTo = queryDateTo ? new NaiveDate(queryDateTo) : undefined;

    const filerValue: DateFilter = {
        fromDate: initialDateFilter.value === DateFilterType.CustomRange
            ? dateFrom
            : dates[0],
        toDate: initialDateFilter.value === DateFilterType.CustomRange
            ? dateTo
            : dates[1],
        type: initialDateFilter.value
    }

    const setFilterValue = (filterValue: DateFilter) => {
        if (filterValue.type !== DateFilterType.CustomRange) {
            query.removeParam(DATE_FROM_QUERY)
            query.removeParam(DATE_TO_QUERY)
        } else {
            query.setParam(DATE_FROM_QUERY, filterValue.fromDate ? filterValue.fromDate.toString() : '');
            query.setParam(DATE_TO_QUERY, filterValue.toDate ? filterValue.toDate.toString() : '');
        }
        query.setParam(DATE_FILTER_QUERY, filterValue.type);
    }

    return {
        filterValue: filerValue,
        setFilterValue: setFilterValue
    }
}

export default useDateRangeQueryFilters;