import { StoreActions } from '../../types';
import { Transaction } from '../../../../domain/transactions/Transaction';
import { ADD_TRANSACTION } from '../actions/transactions';

export type LastTransactionCreatedState = {
	transaction: Transaction|null,
	timeCreated: Date|null
};

const initialState = {
	transaction: null,
	timeCreated: null
}

export const lastTransactionCreated = (state: LastTransactionCreatedState = initialState, action: StoreActions) => {
	switch (action.type) {
		case ADD_TRANSACTION:
			return {
				transaction: action.payload.transaction,
				timeCreated: new Date()
			}
		default:
			return state;
	}
};
