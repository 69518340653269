import {IDatabaseChange} from "dexie-observable/api";
import {getActiveProfile} from "../../view/store/profiles/selectors";
import {getMonthsVisible} from "../../view/store/budget/selectors/monthsVisible";
import {loadAllProfiles} from "../../view/store/profiles/actions";
import {recalculateBudget} from "../../view/store/budget/actions/monthBudget";
import {loadAccountBalances, loadAccounts} from "../../view/store/accounts/actions";
import {loadCategoryBudgets} from "../../view/store/categories/actions/budgets";
import { getState, dispatch } from '../../view/store/dispatch';

const reactToChanges = async (changes: IDatabaseChange[]) => {
    const state = getState()

    await dispatch(loadAllProfiles());

    const activeProfile = getActiveProfile(state);
    if (!activeProfile) {
        return;
    }

    dispatch(recalculateBudget())
    dispatch(loadAccountBalances())
    dispatch(loadAccounts(activeProfile.uuid))

    const monthsVisible = getMonthsVisible(state);
    monthsVisible.forEach((month) => {
        dispatch(loadCategoryBudgets(month));
    })

}

export default reactToChanges;
