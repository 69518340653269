import appDb from '../persistence/indexed-db';
import AppDB from '../persistence/indexed-db';

const connectToOnlineDevices = async () => {
	console.log('connectToOnlineDevices');
	const syncURLs = await appDb.syncable.list();
	// TODO actually check if these devices are online first?
	syncURLs.forEach(url => {
        AppDB.syncable.connect('webrtc', url, { hi: 'hello' });
	});
};

export default connectToOnlineDevices;
