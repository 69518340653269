import {Profile} from "../../../../domain/profiles";
import {AppThunkAction} from "../../types";
import generateStartingCategories from "../../../../domain/categories/operations/generateStartingCategories";
import {addMasterCategory} from "../../categories/actions/masterCategories";
import {addSubCategory} from "../../categories/actions/subCategories";
import omit from 'lodash/omit';

export const createStartingCategories = (profile: Profile): AppThunkAction => {
    return async (dispatch) => {
        const masterCategories = generateStartingCategories(profile);
        const createMasterCategories = masterCategories.map((masterCat) => {
            return dispatch(addMasterCategory(omit(masterCat, ['subCategories'])));
        })

        await Promise.all(createMasterCategories);

        const createSubCategories = masterCategories.reduce((acc, masterCat) => {
            const subCatPromises = masterCat.subCategories.map((subCat) => {
                return dispatch(addSubCategory(subCat));
            })

            return acc.concat(subCatPromises);
        }, [] as Promise<void>[])

        return Promise.all(createSubCategories);
    }
}
