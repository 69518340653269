import UAParser from 'ua-parser-js';

export type UserAgent = {
    "browser": {
        name: string|undefined,
        version: string|undefined,
        major: string|undefined,
    },
    os: {
        name: string|undefined,
        version: string|undefined,
    },
    device: {
        model: string|undefined,
        type: string|undefined,
        vendor: string|undefined,
    }
}

const getUserAgent = (): UserAgent => {
    const ua = new UAParser();

    return {
        browser: ua.getBrowser(),
        os: ua.getOS(),
        device: ua.getDevice()
    }
}

export default getUserAgent;