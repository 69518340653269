import React, {useEffect, useRef, useState} from 'react';
import { AdvancedModal } from '../../common/Modal';
import { Button } from "../../common/Button/Button";
import {Dimmer, Icon, Loader} from "semantic-ui-react";
import {Form} from "semantic-ui-react";
import useRequiredActiveProfile from "../../store/profiles/hooks/useRequiredActiveProfile";
import readFileFromInput from "../../../domain/settings/import/readFileFromInput";
import {importYnabCSV} from "../../../domain/settings/import/YNAB";
import NoticeMessage, { Type as NoticeType} from '../../common/Message/NoticeMessage';

type Props = {
    open: boolean
    onClose: () => void
};

const YNABImportModal = (props: Props) => {
	const activeProfile = useRequiredActiveProfile();
	const [isImporting, setIsImporting] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string|null>(null);
	const budgetInputRef = useRef<HTMLInputElement>(null);
	const registerInputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
        setErrorMessage(null);
    }, [props.open]);

	const doImport = async () => {
        setErrorMessage(null);
	    if (!budgetInputRef || !budgetInputRef.current || !registerInputRef || !registerInputRef.current) {
	        console.error('File input refs missing');
	        return;
        }

	    setIsImporting(true);

        // @ts-ignore
        const budgetReaderPromise = readFileFromInput(budgetInputRef.current.files[0])
        // @ts-ignore
        const registerReaderPromise = readFileFromInput(registerInputRef.current.files[0])

        try {
            const [budgetCSV, registerCSV] = await Promise.all([budgetReaderPromise, registerReaderPromise])
            await importYnabCSV(budgetCSV, registerCSV, activeProfile)
            window.location.reload()
        } catch (e: any) {
            console.error(e);
            setIsImporting(false);
            setErrorMessage(`An error occurred importing your data: ${e.message}`);
        }

    }

	return (
		<AdvancedModal
            title={'Import from YNAB'}
            open={props.open}
            onClose={props.onClose}
            actions={
                <>
                    <Button onClick={props.onClose}>
                        <Icon name="remove" /> Cancel
                    </Button>
                    <Button type={'success'} onClick={doImport}>
                        <Icon name="download" /> Import
                    </Button>
                </>
            }
        >
                <Dimmer active={isImporting} inverted>
                    <Loader active={isImporting} disabled={!isImporting} inverted>Importing</Loader>
                </Dimmer>

            {
                !isImporting && <Form>
                    { errorMessage && <NoticeMessage type={NoticeType.ERROR}>{errorMessage}</NoticeMessage>}
                    <p>You can import from YNAB (youneedabudget.com) or a YNAB Classic (YNAB4).</p>
                    <Form.Field>
                        <label>{'Budget (.csv)'}</label>
                        <input
                            type="file"
                            id="input"
                            ref={budgetInputRef}
                            accept=".csv, text/csv"
                        />
                    </Form.Field>
                    <Form.Field>
                            <label>{'Register (.csv)'}</label>
                            <input
                                type="file"
                                id="input"
                                ref={registerInputRef}
                                accept=".csv, text/csv"
                            />
                    </Form.Field>
                </Form>
            }

		</AdvancedModal>
	);
};

export default YNABImportModal;
