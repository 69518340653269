import { MasterCategory, SubCategory } from '../index';
import { isSubCategory } from '../util/isSubCategory';

type EditableProperties = Pick<SubCategory|MasterCategory, 'name'>;

const editCategory = (
	existingCategory: SubCategory|MasterCategory,
	newData: EditableProperties
): MasterCategory|SubCategory => {
	const updatedCategory: MasterCategory|SubCategory = {
		uuid: existingCategory.uuid,
		profileUUID: existingCategory.profileUUID,
		hidden: existingCategory.hidden,
		name: existingCategory.name,
	}

	if (isSubCategory(existingCategory)) {
		// @ts-ignore
		updatedCategory.masterCategoryId = existingCategory.masterCategoryId
	}

	return Object.assign({}, updatedCategory, newData);
};

export default editCategory;