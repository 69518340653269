import * as AccountActions from '../actions/accounts';
import { StoreActions } from '../../types';
import { Account } from '../../../../domain/accounts/Account';

export type AccountBalancesState = Map<Account['uuid'], number>;
export const accountBalances = (state: AccountBalancesState = new Map(), action: StoreActions) => {
    switch (action.type) {
        case AccountActions.LOAD_ACCOUNT_BALANCES:
            action.payload.accountBalances.forEach((balance, accountUUID) => {
               state.set(accountUUID, balance);
            });
            return new Map(state.entries());
        default:
            return state;
    }
};
