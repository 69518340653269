import useIsLoggedIn from '../../store/auth/hooks/useIsLoggedIn';
import { Link, Redirect } from 'react-router-dom';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import useSyncDevices, { SyncDeviceStatus } from '../../store/app/hooks/useSyncDevices';
import { Loader, Segment } from 'semantic-ui-react';
import React from 'react';
import getDeviceName from '../../../domain/sync/getDeviceName';
import useFirstRoundCompleteUUIDs from '../../store/sync/hooks/useFirstRoundCompleteUUIDs';
import useNumSyncedItems from '../../sync/hooks/useNumSyncedItems';

const SyncProfilePage = () => {
	const loggedIn = useIsLoggedIn();
	const syncDevices = useSyncDevices();
	const firstRoundSyncsComplete = useFirstRoundCompleteUUIDs();

	if (!loggedIn) return <Redirect to={APP_LINKS.sign_up()} />;
	if (syncDevices.length === 0) return <Redirect to={APP_LINKS.add_profile()} />;

	const onlineDevice = syncDevices.find(s => s.dataChannelConnected);
	const syncComplete = onlineDevice && firstRoundSyncsComplete.has(onlineDevice.uuid);

	return <div className={'add-profile-page'}>
		{!onlineDevice ? <WaitingLoader /> : null}
		{onlineDevice && !syncComplete ? <Syncing connectingDevice={onlineDevice} /> : null}
		{syncComplete ? <SyncingComplete onlineDevice={onlineDevice} /> : null}
		{!syncComplete && <p>
			If you would like to skip this step you can <Link to={APP_LINKS.add_profile()}>create a new budget
			profile</Link>
		</p>}
	</div>;
};

export default SyncProfilePage;

const WaitingLoader = () => {
	return <>
		<p>
			It looks like you've used Dollero on another device. Open Dollero on another device to sync your budget
			profiles to this device.
		</p>
		<Segment>
			<Loader active inline={'centered'}>Waiting for devices...</Loader>
		</Segment>
	</>;
};

const Syncing = ({ connectingDevice }: { connectingDevice: SyncDeviceStatus }) => {
	const syncedItemCounts = useNumSyncedItems(connectingDevice.uuid);

	return <Segment inverted color={'green'}>
		<Loader active inline={'centered'}>
			<p>Syncing with {getDeviceName(connectingDevice)}.</p>
			<p>{syncedItemCounts} items synced.</p>
		</Loader>
	</Segment>;
};

const SyncingComplete = ({ onlineDevice }: { onlineDevice: SyncDeviceStatus }) => {
	return <div>
		<p>
			We have successfully imported your budget profile from {getDeviceName(onlineDevice)}.
		</p>
		<p>
			<Link to={APP_LINKS.profiles()}>Continue</Link>
		</p>
	</div>;
};