import React, {useCallback} from 'react';
import classNames from "classnames";
import MasterCategoryTotals from '../MasterCategoryTotals/MasterCategoryTotals';
import MonthCategoryCells from '../MonthCategoryCells/MonthCategoryCells';
import './month-sheet.less';
import useBudgetableCategories, { BudgetableMasterCategory } from "../../store/categories/hooks/useBudgetableCategories";
import {Month} from "../../../domain/util/date";
import { hasHiddenCategories } from "../../store/categories/selectors";
import {useSelector} from "react-redux";
import HiddenCategoryTotals from "../HiddenCategoryTotals/HiddenCategoryTotals";
import { Dimmer } from 'semantic-ui-react';
import {RootState} from "../../store/types";
import {isBudgetCalculatedForMonth, isMonthBudgetLoading} from "../../store/budget/selectors/monthBudget";

export const MIN_WIDTH = 350;

type Props = {
	month: Month
}

const MonthSheet = ({ month }: Props) => {
	const budgetableMasterCategories = useBudgetableCategories();
	const hasAnyHiddenCategories = useSelector(hasHiddenCategories)
	const isLoading = useSelector(useCallback((state: RootState) => {
		return isMonthBudgetLoading(state, month);
	}, [month]))
	const hasBudgetCalculated = useSelector(useCallback((state: RootState) => {
		return isBudgetCalculatedForMonth(state, month);
	}, [month]))
	const showLoading = isLoading && !hasBudgetCalculated;

	return (

		<div className={'month-sheet'} style={{ minWidth: `${MIN_WIDTH}px` }}>
			<Dimmer.Dimmable>
				<Dimmer active={false} inverted/>
			{budgetableMasterCategories.map((masterCat, index) => {
				if (masterCat.hidden) {
					return null;
				}

				return (
					<div key={`${month.year}-${month.month}-master:${masterCat.uuid}`}>
						<div className={classNames({
							'month-sheet__category-totals': true,
							'month-sheet__category-totals--first': index === 0
						})}>
							<MasterCategoryTotals
								month={month}
								masterCategory={masterCat}
								key={month.month}
								isLoading={showLoading}
							/>
						</div>
						{masterCategoryRows(month, masterCat, showLoading)}
					</div>
				);
			})}
			{ hasAnyHiddenCategories && <>
				<div key={`hidden`}>
					<div className={'month-sheet__row'} >
						<HiddenCategoryTotals month={month}/>
					</div>
				</div>
			</>}
			</Dimmer.Dimmable>

		</div>
	);
};

const masterCategoryRows = (month: Month, masterCategory: BudgetableMasterCategory, isLoading: boolean) => {
	return masterCategory.subCategories.map((subCategory, index) => {
		const isLast = masterCategory.subCategories.length - 1 === index;
		const isFirst = 0 === index;
		if (subCategory.hidden) {
			return null;
		}
		const classes = classNames({
			'month-sheet__row': true,
			'month-sheet__row--last': isLast,
			'month-sheet__row--first': isFirst,
		});
		return (
			<div className={classes} key={`${month.year}-${month.month}-sub:${subCategory.uuid}`}>
				<MonthCategoryCells
					month={month}
					category={subCategory}
					isLoading={isLoading}
				/>
			</div>
		);
	})
}

export default MonthSheet;
