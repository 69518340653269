import React, { ErrorInfo, PropsWithChildren } from 'react';
import NoticeMessage, { Type } from '../Message/NoticeMessage';
import { Button } from '../Button/Button';

type State = {
	hasError: boolean,
	error: Error|null,
	errorInfo: ErrorInfo|null
}

type Props = PropsWithChildren<{
	inline?: boolean
}>

export default class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null
		};
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(error, errorInfo);
		this.setState({ hasError: true, error, errorInfo });
	}

	reset = () => {
		this.setState({
			hasError: false,
			error: null,
			errorInfo: null
		});
	}

	render() {
		if (this.state.hasError) {

			if (this.props.inline) {
				return <div style={{margin: '1rem'}}>
					<NoticeMessage type={Type.ERROR}>
						{`An error occurred: ${this.state.error?.message}`}
					</NoticeMessage>
				</div>
			}
			// You can render any custom fallback UI
			return <div style={{margin: '1rem'}}>
				<NoticeMessage
					type={Type.ERROR}
					header={`An error occurred: ${this.state.error?.message}`}
				>
					<Button onClick={this.reset} type={'warning'}>Reset</Button>
					<ul>
						{this.state.errorInfo?.componentStack.split("\n")
							.filter(line => line!!)
							.map(line => <li>{line}</li>)
						}
					</ul>
				</NoticeMessage>
			</div>
		}

		return this.props.children;
	}
}