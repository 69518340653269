const getDolleroServerURL = () => {
    switch(process.env.NODE_ENV) {
        case 'development':
            return process.env.REACT_APP_DOLLERO_ACCOUNT_URL || "https://account.dollero.local/";
        case 'test':
            return process.env.REACT_APP_DOLLERO_ACCOUNT_URL || "https://127.0.0.1/";
        case 'production':
            return process.env.REACT_APP_DOLLERO_ACCOUNT_URL || "https://account.dollero.app/"
        default:
            return process.env.REACT_APP_DOLLERO_ACCOUNT_URL || "https://account.dollero.local/";
    }
}

export const DOLLERO_ACCOUNT_URL = getDolleroServerURL();