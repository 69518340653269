import * as AddCategoryActions from "../actions/addCategoryModal";
import {StoreActions} from "../../types";

export type AddMasterCategoryModalOpenState = boolean;
export const addMasterCategoryModalOpen = (state: AddMasterCategoryModalOpenState = false, action: StoreActions) => {
    switch (action.type) {
        case AddCategoryActions.SHOW_ADD_MASTER_CATEGORY_MODAL:
            return true;

        case AddCategoryActions.HIDE_ADD_MASTER_CATEGORY_MODAL:
            return false;

        default:
            return state;
    }
};