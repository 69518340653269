import { Transaction } from '../Transaction';
import { Account } from '../../accounts/Account';
import isTransfer from './isTransfer';

const isTransferFromAccount = (transaction: Transaction, accountUUID: Account['uuid']) => {
	if (!isTransfer(transaction)) return false;

	return transaction.accountUUID === accountUUID;
}

export default isTransferFromAccount;