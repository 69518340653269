import React from 'react';
import { Button } from '../../common/Button/Button';
import { useDispatch } from 'react-redux';
import { createStartingCategories } from '../../store/budget/actions/startingCategories';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { showAddMasterCategoryModal } from '../../store/categories/actions/addCategoryModal';
import './budget-sheet-empty-state.less';
import NoticeMessage, { Theme } from "../../common/Message/NoticeMessage";

const BudgetSheetEmptyState = () => {
	const dispatch = useDispatch();
	const profile = useRequiredActiveProfile();
	const primaryClick = async () => {
		await dispatch(createStartingCategories(profile));
	};
	const secondaryClick = () => dispatch(showAddMasterCategoryModal());

	return (
		<div className={'budget-sheet-empty-state'}>
			<NoticeMessage
				header="Get started creating budget categories"
				className={'budget-sheet-empty-state__message'}
				theme={Theme.DARK}
			>
				<MessageActions primaryClick={primaryClick} secondaryClick={secondaryClick} />
			</NoticeMessage>
		</div>
	);
};

type MessageActionsProps = {
	primaryClick: () => void;
	secondaryClick: () => void;
};

const MessageActions = (props: MessageActionsProps) => {
	return (
		<>
			<p>You'll use budget categories to set aside money for different types of spending.</p>
			<Button type={'success'} onClick={props.primaryClick} className={'budget-sheet-empty-state__button'}>
				Create some for me
			</Button>
			<Button onClick={props.secondaryClick} className={'budget-sheet-empty-state__button'}>
				I'll create my own
			</Button>
		</>
	);
};

export default BudgetSheetEmptyState;
