import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { formatCurrencyForInput } from '../../../domain/util/currency';
import './currency-cell.less';

type Props = {
	value: number;
	onBlur?: (newValue: string) => void;
	disabled?: boolean;
	ariaLabel?: string;
};

const CurrencyCell = ({ value, onBlur, disabled, ariaLabel }: Props) => {
	const [inputValue, setInputValue] = useState<string>(formatCurrencyForInput(value));

	useEffect(() => {
		setInputValue(formatCurrencyForInput(value));
	}, [value]);

	/**
	 * Should probably use the newer CurrencyInput component here
	 */
	return (
		<input
			type={'number'}
			value={inputValue}
			onChange={e => {
				setInputValue(e.target.value);
			}}
			onBlur={e => {
				setInputValue(formatCurrencyForInput(Number(e.target.value)));
				onBlur && onBlur(e.target.value);
			}}
			onFocus={e => {
				e.target.select();
			}}
			className={classnames({
				'currency-cell__input': true,
				'currency-cell--disabled': disabled,
			})}
			onKeyPress={e => {
				if (e.charCode === 13) {
					onBlur && onBlur(inputValue);
				}
			}}
			autoComplete={'off'}
			disabled={disabled}
			maxLength={9}
			readOnly={!onBlur}
			aria-label={ariaLabel}
		/>
	);
};

export default CurrencyCell;
