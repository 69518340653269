import React, { CSSProperties } from 'react';
import { useParams } from 'react-router-dom';
import {
	Transaction,
} from '../../../domain/transactions/Transaction';
import { Cell, Row } from 'react-table';
import classNames from 'classnames';
import { ColumnID } from '../TransactionsTable/useTableColumns/useTableColumns';
import './compact-transaction-row.less';
import TransactionStatus from "../TransactionStatus";
import {Account} from "../../../domain/accounts/Account";
import { Icon } from 'semantic-ui-react';
import useShouldHighlightTransaction from '../../store/accounts/hooks/useShouldHighlightTransaction';

type Props = {
	row: Row<Transaction>;
	onEdit: (transactionUUID: Transaction['uuid']) => void;
	style: CSSProperties;
	listIndex: number;
	showDateHeader: boolean
};

const CompactTransactionRow = (props: Props) => {
	const { accountUUID } = useParams<{ accountUUID: Account['uuid']}>();
	const transaction = props.row.original;
	const shouldHighlight = useShouldHighlightTransaction(transaction);

	const rowClasses = classNames({
		'compact-transaction-row': true,
		'compact-transaction-row--striped': props.listIndex % 2,
		'compact-transaction-row--glowing': shouldHighlight,
	});

	const amountClasses = classNames({
		'compact-transaction-row__amount': true,
		'compact-transaction-row__amount--outflow': !isInflow(props.row.original, accountUUID),
		'compact-transaction-row__amount--inflow': isInflow(props.row.original, accountUUID),
	})

	return (
		<div
			{...props.row.getRowProps()}
			style={props.style}
			onClick={() => props.onEdit(transaction.uuid)}
		>
			{ props.showDateHeader && <div className={'compact-transaction-row__date-header'}>
				{props.row.original.date.toString()}
			</div>}
			<div className={rowClasses}>
				<div className={'compact-transaction-row__upper'}>
				<span className={'compact-transaction-row__payee'}>
					{getCell(ColumnID.COL_ID_PAYEE, props.row).render('Cell')}
				</span>
				{ !!props.row.original.tags.length && <span>
					<Icon name={'tag'} className={'compact-transaction-row__tag'}/>
				</span>}
				<span className={amountClasses}>
					{props.row.original.outflow !== 0 && getCell(ColumnID.COL_ID_OUTFLOW, props.row).render('Cell')}
						{props.row.original.inflow !== 0 && getCell(ColumnID.COL_ID_INFLOW, props.row).render('Cell')}
				</span>
				</div>
				<div className={'compact-transaction-row__lower'}>
				<span className={'compact-transaction-row__notes'}>
					<TransactionStatus transactionStatus={props.row.original.status} />
					{!accountUUID && getCell(ColumnID.COL_ID_ACCOUNT, props.row).render('Cell')}
					{accountUUID && getCell(ColumnID.COL_ID_NOTE, props.row).render('Cell')}
				</span>
					<span className={'compact-transaction-row__category'}>
					{getCell(ColumnID.COL_ID_CATEGORY, props.row).render('Cell')}
				</span>
				</div>
			</div>
		</div>
	);
};

const getCell = (colId: ColumnID, row: Row<Transaction>): Cell<Transaction> => {
	const cell = row.cells.find(c => c.column.id === colId);
	if (!cell) {
		throw new Error(`Cannot find column by id: ${colId}`);
	}

	return cell;
};

const isInflow = (transaction: Transaction, currentAccountUUID?: Account['uuid']) => {
	if (!currentAccountUUID) return !!transaction.inflow;
	if (transaction.transferToAccountUUID === currentAccountUUID) return true;
	return !!transaction.inflow;
}

export default React.memo(CompactTransactionRow);
