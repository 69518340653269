import { Month } from '../../../domain/util/date';
import { SubCategory } from '../../../domain/categories';
import { useDispatch } from 'react-redux';
import useCategoryBudget from '../../store/budget/hooks/useCategoryBudget';
import useCategoryBalance from '../../store/budget/hooks/useCategoryBalance';
import React, { useState } from 'react';
import classNames from 'classnames';
import * as categoryBudgetActions from '../../store/categories/actions/budgets';
import setAmount from '../../../domain/categories/operations/setAmount';
import CurrencyInput from '../../common/Form/CurrencyInput';
import { Icon, Progress } from 'semantic-ui-react';
import useCategoryOutflows from '../../store/budget/hooks/useCategoryOutflows';
import { MonthBudget } from '../../../domain/budget';
import BalancePill from './BalancePill';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import { DateFilterType } from '../../accounts/TransactionsTable/TableFilters/DateRangeFilter';
import NaiveDate from '../../../domain/util/NaiveDate';
import { Link } from 'react-router-dom';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import './sub-category-card.less';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';
import EditCategoryModal from '../EditCategoryButton/EditCategoryModal';
import Button from '../../common/Button';

type Props = {
	month: Month;
	subCategory: SubCategory,
	monthBudget: MonthBudget,
	lastMonthBudget: MonthBudget|undefined,
}

const SubCategoryCard = (props: Props) => {
	const { month, subCategory } = props;
	const dispatch = useDispatch();

	const activeProfile = useRequiredActiveProfile();
	const categoryBudget = useCategoryBudget(month, subCategory.uuid);
	const balance = useCategoryBalance(month, subCategory.uuid);
	const outflows = useCategoryOutflows(month, subCategory.uuid);
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const [expanded, setExpanded] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const lastMonthBalance = props.lastMonthBudget?.categoryBalances.get(subCategory.uuid);
	const lastMonthAvailable =  lastMonthBalance && (lastMonthBalance.isCarriedOver || lastMonthBalance.balance > 0) ? lastMonthBalance.balance : 0;
	const availableThisMonth = categoryBudget.amount + lastMonthAvailable;

	const progress = Math.round((Math.abs(outflows) / availableThisMonth) * 100);
	const progressClasses = classNames({
		"sub-category-card__progress-bar": true,
		"sub-category-card__progress-bar--success": balance >= 0,
		"sub-category-card__progress-bar--warning": balance < 0,
	})

	const [focusedInput, setIsFocusedInput] = useState(false);

	const onBlur = (newValue: number) => {
		setIsFocusedInput(false);

		// value hasn't changed
		if (newValue === categoryBudget.amount) {
			return;
		}

		dispatch(
			categoryBudgetActions.updateCategoryBudget(setAmount(categoryBudget, newValue)),
		);
	};

	const topClassNames = classNames({
		'sub-category-card__top': true,
		'sub-category-card__top--expanded': expanded,
	})

	const cellsClassNames = classNames({
		'sub-category-card__cells': true,
		'sub-category-card__cells--expanded': expanded,
	})

	const headerIconClassNames = classNames({
		'sub-category-card__header-icon': true,
		'sub-category-card__header-icon--down': expanded,
	})

	return <div className={'sub-category-card'}>
		<div className={topClassNames} onClick={() => setExpanded(!expanded)}>
			<div className={'sub-category-card__header'}>
				<h4 className={'sub-category-card__title'}>
					<Icon name={'angle right'} className={headerIconClassNames}/> {props.subCategory.name}
				</h4>
				<span className={'sub-category-card__balance'}><BalancePill numericValue={balance} /></span>
			</div>
			<Progress percent={progress} size='tiny' className={progressClasses}/>
		</div>
		<div className={cellsClassNames}>
			<div className={'sub-category-card__cell'}>
				{formatCurrencyForDisplay(lastMonthAvailable)}
				<span className={'sub-category-card__label'}>Balance Last Month</span>
			</div>
			<div className={'sub-category-card__cell'} onClick={() => !focusedInput && setIsFocusedInput(!focusedInput)}>
				{focusedInput && (
					<CurrencyInput
						autoFocus={true}
						value={categoryBudget.amount}
						name={'budgetValue'}
						className={'sub-category-card__input'}
						onBlur={onBlur}
					/>
				)}
				{!focusedInput && formatCurrencyForDisplay(categoryBudget.amount)}
				{!focusedInput && <span className={'sub-category-card__label'}><Icon name={'edit'}/>Budgeted</span>}
			</div>
			<div className={'sub-category-card__cell'}>
				<Link
					to={APP_LINKS.accounts(
						activeProfile.uuid,
						DateFilterType.CustomRange,
						NaiveDate.getStartOfMonth(month),
						NaiveDate.getEndOfMonth(month),
						[subCategory.uuid]
					)}
					className={'sub-category-card__outflows-link'}
				>
					{formatCurrencyForDisplay(outflows)}
					<span className={'sub-category-card__label'}>
						Outflows
						<Icon name={'angle right'} className={'sub-category-card__outflows-icon'}/>
					</span>
				</Link>

			</div>
		</div>
		{ expanded && <div className={'sub-category-card__edit'}>
			<Button onClick={() => setIsEditModalOpen(true)} compact><Icon name={'edit'}/> Edit Category</Button>
		</div>}
		<EditCategoryModal
			category={subCategory}
			isModalOpen={isEditModalOpen}
			onClose={() => setIsEditModalOpen(false)}
		/>
	</div>;
}

export default SubCategoryCard;