import {Profile} from "../index";
import {ProfileRepo} from "../IProfileRepo";

const getProfile = async (profileUUID?: Profile['uuid']) => {
    const profiles = (await ProfileRepo.getAllProfiles()).filter(p => !p.deleted);
    if (profiles.length === 0) {
        throw new Error("No profiles exist")
    }

    if (!profileUUID) {
        return profiles[0];
    }

    const foundProfile = profiles.find(p => p.uuid === profileUUID);

    if (!foundProfile) {
        throw new Error(`Can't find profile with uuid ${profileUUID}`);
    }

    return foundProfile;
}

export default getProfile;