import {
    ICECandidateEventType,
    StartICEEventType
} from "./SignallingServerSession";
import DolleroWebSocket from "../../web-socket/DolleroWebSocket";

export default abstract class SignallingPeer {
    protected peerConnection: RTCPeerConnection;
    protected localICECandidates: RTCIceCandidate[] = [];
    protected iceStarted = false;
    protected socket = DolleroWebSocket;
    protected cleanup: () => void;

    constructor(iceServers: RTCConfiguration['iceServers']) {
        this.peerConnection = new RTCPeerConnection({ iceServers });

        const removeLocalICEListener = this.listenForLocalICECandidates();
        const removeRemoteICEListener = this.listenForRemoteICECandidates();
        const removeICEStartListener = this.listenForICEStart();

        // Listen for connectionstatechange on the local RTCPeerConnection
        this.peerConnection.addEventListener('connectionstatechange', this.connectionstatechangeListener);
        this.cleanup = () => {
            removeLocalICEListener();
            removeRemoteICEListener();
            removeICEStartListener();
            //this.peerConnection.close();
            this.peerConnection.removeEventListener('connectionstatechange', this.connectionstatechangeListener);
        }
    }

    public destroy() {
        console.log('Destroy SignallingPeer')
        this.cleanup();
        this.afterCleanup();
    }

    protected afterCleanup() {

    }

    protected connectionstatechangeListener = (event: Event) => {
        console.log(`connectionstatechange: ${this.peerConnection.connectionState}`, event)
        if (this.peerConnection.connectionState === 'failed') {
            this.socket.send({
                // @ts-ignore
                type: 'signallingComplete',
            });
            this.peerConnection.restartIce();
            throw new Error('SignallingPeer connection failed');
        }
    }

    protected sendLocalICECandidates() {
        console.log(`Sending ${this.localICECandidates.length} ICE candidates`);
        this.localICECandidates.forEach((candidate) => {
            this.socket.send({
                // @ts-ignore
                type: ICECandidateEventType,
                payload: candidate
            })
        })
        this.localICECandidates = [];
    }

    protected listenForICEStart() {
        return this.socket.onMessage(async message => {
            this.iceStarted = true;
            // @ts-ignore
            if (message.type === StartICEEventType) {
                this.sendLocalICECandidates();
            }
        });
    }

    private listenForLocalICECandidates() {
        const icecandidateListener = (event: RTCPeerConnectionIceEvent) => {
            if (event.candidate) {
                console.log('Local icecandidate event')
                this.addIceCandidate(event.candidate);
            }
        }
        this.peerConnection.addEventListener('icecandidate', icecandidateListener);
        return () => this.peerConnection.removeEventListener('icecandidate', icecandidateListener);
    }

    private addIceCandidate(candidate: RTCIceCandidate) {
        this.localICECandidates.push(candidate);
        if (this.iceStarted) {
            this.sendLocalICECandidates();
        }
    }

    private listenForRemoteICECandidates() {
        return this.socket.onMessage(async message => {
            // @ts-ignore
            if (message.type === ICECandidateEventType) {
                try {
                    // @ts-ignore
                    await this.peerConnection.addIceCandidate(message.payload);
                } catch (e) {
                    console.error('Error adding received ice candidate', e);
                }
            }
        });
    }
}