import { SyncDevice } from '../../../domain/sync/SyncDevice';
import { AppDispatch } from '../../../view/store/types';
import { fetchAccountDevices } from '../../../view/store/sync/actions/devices';
import DataChannelManager from '../../web-rtc/data-channel/DataChannelManager';

const deviceDisconnectedHandler = async (dispatch: AppDispatch, deviceUUID: SyncDevice['uuid']) => {
	console.log('deviceDisconnectedHandler', deviceUUID);
	DataChannelManager.close(deviceUUID)
	await dispatch(fetchAccountDevices());
};

export default deviceDisconnectedHandler;