import React from 'react';
import { Grid } from 'semantic-ui-react';
import { SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic';

type Props = {
	children: React.ReactNode;
	columns: SemanticWIDTHS;
	className?: string;
};

const Width = (props: Props) => {
	const propClassName = props.className || 'width';
	return (
		<Grid.Column width={props.columns} className={propClassName}>
			{props.children}
		</Grid.Column>
	);
};

export const WIDTH_FULL = 'WIDTH_FULL';
export const WIDTH_HALF = 'WIDTH_HALF';

export default Width;
