import {ListenerData, PubSubInterface} from "../../../../infrastructure/pub-sub";
import {
    ADD_TRANSACTION,
    DELETE_TRANSACTIONS,
    DeleteTransactions,
    AddTransaction,
    EDIT_TRANSACTION,
    EDIT_TRANSACTIONS,
    EditTransaction,
    EditTransactions, loadTransactions,
} from '../actions/transactions';
import {recalculateBudget} from "../../budget/actions/monthBudget";
import {isReconciling} from "../selectors/reconciling";
import {
    getClearedAccountBalance,
    getUnclearedAccountBalance,
    loadAccountBalances,
    loadReconcileClearedBalance
} from "../actions";
import {getActiveAccount} from "../selectors/accounts";
import {Month} from "../../../../domain/util/date";
import {Transaction} from "../../../../domain/transactions/Transaction";
import getEarliestMonth from "../../../../domain/transactions/util/getEarliestMonth";
import notifyCategoryBalanceChange from '../../../../domain/budget/notifyCategoryBalanceChange';
import { SubCategory } from '../../../../domain/categories';
import { getActiveProfile } from '../../profiles/selectors';

type TransactionMutations = EditTransactions|EditTransaction|DeleteTransactions|AddTransaction;

export const editedTransactionListener = async (actionType: TransactionMutations['type'], data: ListenerData<TransactionMutations>) => {
    const { dispatch, state, action } = data;
    dispatch(loadAccountBalances())
    const recalc = async () => await dispatch(recalculateBudget(getMonthFromAction(action)));
    const actionMonth = getMonthFromAction(action);
    if (actionMonth) await notifyCategoryBalanceChange(recalc, actionMonth, getCategoriesFromAction(action))

    if (isReconciling(state)) {
        dispatch(loadReconcileClearedBalance());
    }

    const activeAccountUUID = getActiveAccount(state);
    if (activeAccountUUID) {
        const activeProfile = getActiveProfile(state);
        dispatch(getClearedAccountBalance(activeAccountUUID))
        dispatch(getUnclearedAccountBalance(activeAccountUUID))
        activeProfile && await dispatch(loadTransactions(activeProfile.uuid, activeAccountUUID))
    }
}

const registerListeners = (pubSub: PubSubInterface) => {
    pubSub.subscribe<TransactionMutations>(DELETE_TRANSACTIONS, editedTransactionListener);
    pubSub.subscribe<TransactionMutations>(EDIT_TRANSACTIONS, editedTransactionListener);
    pubSub.subscribe<TransactionMutations>(EDIT_TRANSACTION, editedTransactionListener);
    pubSub.subscribe<TransactionMutations>(ADD_TRANSACTION, editedTransactionListener);
}

export default registerListeners;

export const getMonthFromAction = (action: TransactionMutations): Month|null => {
    switch (action.type) {
        case ADD_TRANSACTION:
            return action.payload.transaction.date.toMonth();

        case EDIT_TRANSACTION:
            return getEarliestActionTransactionMonth([
                action.payload.transaction,
                action.payload.oldTransaction
            ])

        case EDIT_TRANSACTIONS:
            return getEarliestActionTransactionMonth(action.payload.transactions);

        case DELETE_TRANSACTIONS:
            return getEarliestActionTransactionMonth(action.payload.deletedTransactions);

        default:
            return null;
    }
}

const getEarliestActionTransactionMonth = (transactions: Array<Transaction|null>): Month|null => {
    const filteredTransactions = transactions.filter((t): t is Transaction => t !== null);
    if (filteredTransactions.length === 0) {
        return null;
    }
    return getEarliestMonth(filteredTransactions);
}

export const getCategoriesFromAction = (action: TransactionMutations): Array<SubCategory['uuid']> => {
    switch (action.type) {
        case ADD_TRANSACTION:
            return action.payload.transaction.categoryId;

        case EDIT_TRANSACTION:
            return action.payload.transaction.categoryId.concat(action.payload.oldTransaction?.categoryId || [])

        case EDIT_TRANSACTIONS:
            return action.payload.transactions.map(t => t.categoryId).flat()

        case DELETE_TRANSACTIONS:
            return action.payload.deletedTransactions.map(t => t.categoryId).flat()

        default:
            return [];
    }
}