import { useSelector } from 'react-redux';
import { getAllAccounts } from '../../selectors/accounts';
import { getReconcileAccountUUID } from '../../selectors/reconciling';

const useAccountBeingReconciled = () => {
	const accountUUID = useSelector(getReconcileAccountUUID);
	const allAccounts =useSelector(getAllAccounts);
	if (!accountUUID) return null;
	return allAccounts.get(accountUUID);
}

export default useAccountBeingReconciled;