import React from "react";
import classNames from "classnames";
import './simple-button.less';

type Props = {
    inverted?: boolean
    children: React.ReactNode,
    onClick?: () => void,
    className?: string
}

const SimpleButton = (props: Props) => {
    const classes = classNames({
        'simple-button': true,
        'simple-button--inverted': props.inverted,
        [props.className || '']: Boolean(props.className),

    });

    return <button
        onClick={() => props.onClick && props.onClick()}
        className={classes}
    >
        { props.children }
    </button>
}

export default SimpleButton;