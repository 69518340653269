import { Month } from '../../../../domain/util/date';
import { useMemo } from 'react';
import { creatMonthBalanceSelector } from '../selectors/monthBudget';
import { useSelector } from 'react-redux';

const useMonthTotalBalance = (month: Month) => {
	const balanceSelector = useMemo(() => {
		return creatMonthBalanceSelector(month.year, month.month);
	}, [month.year, month.month]);
	return useSelector(balanceSelector);
}

export default useMonthTotalBalance;