import {MasterCategory, SubCategory} from "../../../categories";
import {AllCategoryTotals} from "../../index";

const getAllMasterCategoryBalances = (
    subCategoryAssignments: Map<MasterCategory['uuid'], Array<SubCategory['uuid']>>,
    categoryTotals: AllCategoryTotals
) => {
    return Array.from(subCategoryAssignments.entries()).reduce((acc, assignments) => {
        const [masterCatId, subCatIds] = assignments;
        acc.set(masterCatId, getMasterCategoryBalance(subCatIds, categoryTotals));
        return acc;
    }, new Map());
};

export default getAllMasterCategoryBalances;

const getMasterCategoryBalance = (
    subCatIds: Array<SubCategory['uuid']>,
    categoryTotals: AllCategoryTotals
) => {
    return subCatIds.reduce((acc, subCatId) => {
        const categoryBalance = categoryTotals.categoryBalances.get(subCatId);
        const balance = categoryBalance ? categoryBalance.balance : 0;
        return acc + balance;
    }, 0);
};