import Card from '../../common/Card';
import React from 'react';
import getDeviceName from '../../../domain/sync/getDeviceName';
import useCurrentSyncDevice from '../hooks/useCurrentSyncDevice';
import SyncDeviceIcon from '../SyncDeviceIcon';
import Pill from '../../common/Pill/Pill';
import { useSelector } from 'react-redux';
import { getSocketState } from '../../store/app/selectors/socket';
import './current-sync-device-card.less';

const CurrentSyncDeviceCard = () => {
	const currentDevice = useCurrentSyncDevice();
	const socketState = useSelector(getSocketState);
	if (!currentDevice) return null;

	return <Card className={'current-sync-device-card'}>
		<Card.Content>
			<Card.Header>
				<SyncDeviceIcon syncDevice={currentDevice} />
				{getDeviceName(currentDevice)}
				<div className={'sync-device-card__dollero-status'}>
					{getDolleroStatusPill(socketState)}
				</div>
			</Card.Header>
			<Card.Meta>uuid: {currentDevice.uuid}</Card.Meta>
		</Card.Content>
	</Card>
}

export default CurrentSyncDeviceCard;

const getDolleroStatusPill = (socketState: number|null) => {
	if (!socketState) return <Pill value={'offline'} theme={'muted'}/>
	if (WebSocket.CLOSED === socketState) return <Pill value={'offline'} theme={'muted'}/>
	if (WebSocket.OPEN === socketState) return <Pill value={'online'} theme={'success'}/>
	return null;
}