import { Month } from '../../../../domain/util/date';
import { SubCategory } from '../../../../domain/categories';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createCategoryBalanceSelector } from '../selectors/monthBudget';

const useCategoryBalance = (month: Month, uuid: SubCategory['uuid']) => {
	const balanceSelector = useMemo(() => {
		return createCategoryBalanceSelector(month.year, month.month, uuid);
	}, [month.year, month.month, uuid]);
	return useSelector(balanceSelector);
};

export default useCategoryBalance;