import React, { useState } from 'react';
import classnames from 'classnames';
import AddCategoryButton from '../AddCategoryBudgetButton/AddCategoryButton';
import { MasterCategory } from '../../../domain/categories';
import EditCategoryModal from '../EditCategoryButton/EditCategoryModal';
import './category-label.less';
import { Icon } from 'semantic-ui-react';

type Props = {
	masterCategory: MasterCategory;
};
const MasterCategoryLabel = ({ masterCategory }: Props) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return (
		<div
			className={classnames({
				'category-label': true,
				'category-label--master-category': true,
			})}
		>
			<div className={'category-label__drag-handle'}>
				<Icon name={'bars'} />
			</div>
			<span
				className={classnames({
					'category-label__text': true,
					'category-label__text--master-category': true,
				})}
				onClick={() => setIsEditModalOpen(true)}
			>
				{masterCategory.name + ' '}
				<Icon name={'edit'} className={'category-label__edit-icon'} />
			</span>
			<EditCategoryModal
				category={masterCategory}
				isModalOpen={isEditModalOpen}
				onClose={() => setIsEditModalOpen(false)}
			/>
			<div className={'category-label__add-button'}>
				<AddCategoryButton masterCategoryUUID={masterCategory.uuid} />
			</div>
		</div>
	);
};

export default MasterCategoryLabel;
