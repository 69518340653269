import { useSelector } from 'react-redux';
import {
	getAllUserCategories,
	getAllMasterCategories,
	getMasterCategoryOrder,
	getOrderedSubCategoryAssignments, hasCreatedAnyCategories
} from '../selectors';
import { MasterCategory, SubCategory } from '../../../../domain/categories';
import generateStartingCategories from "../../../../domain/categories/operations/generateStartingCategories";
import useRequiredActiveProfile from "../../profiles/hooks/useRequiredActiveProfile";

export type BudgetableMasterCategory = MasterCategory & {
	subCategories: SubCategory[];
};

const useBudgetableCategories = (): BudgetableMasterCategory[] => {
	const profile = useRequiredActiveProfile();
	const allCategories = useSelector(getAllUserCategories);
	const allMasterCategories = useSelector(getAllMasterCategories);
	const assignments = useSelector(getOrderedSubCategoryAssignments);
	const orderedMasterCategories = useSelector(getMasterCategoryOrder);
	const hasCreatedCategories = useSelector(hasCreatedAnyCategories)
	if (!hasCreatedCategories) {
		return generateStartingCategories(profile);
	}

	return orderedMasterCategories.map(masterCategoryUUID => {
		const masterCategory = allMasterCategories.get(masterCategoryUUID);
		if (!masterCategory) {
			throw new Error(`Can't find master category uuid: ${masterCategoryUUID} in categories order`)
		}
		const subCategoryIds = assignments.get(masterCategory.uuid);
        if (!subCategoryIds) {
			return {
				subCategories: [],
				...masterCategory,
			};
        }
		const subCategories = subCategoryIds.map(subCategoryId => {
			const subCategory = allCategories.get(subCategoryId);
			if (!subCategory) {
			    throw new Error(`Cannot find sub category with id; ${subCategoryId}`);
            }
			return subCategory;
		});
		return {
			...masterCategory,
			subCategories,
		};
	});
};

export default useBudgetableCategories;
