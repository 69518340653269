import { getLastMonth, getShortMonthName, Month } from '../../../domain/util/date';
import React, { useCallback } from 'react';
import { getMonthBudget } from '../../store/budget/selectors/monthBudget';
import { MonthBudget } from '../../../domain/budget';
import { useSelector } from 'react-redux';
import getTotalMonthBudgeted from '../../../domain/budget/calculations/month/getTotalMonthBudgeted';
import './month-header-popup-content.less';
import { RootState } from '../../store/types';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';

type Props = {
	month: Month;
};

const MonthHeaderPopupContent = (props: Props) => {
	const lastMonth = getLastMonth(props.month);
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();
	const lastMonthBudgetSelector = useCallback(
		(state: RootState) => getMonthBudget(state, lastMonth),
		[lastMonth]
	);
	const thisMonthBudgetSelector = useCallback(
		(state: RootState) => getMonthBudget(state, props.month),
		[props.month]
	);
	const lastMonthBudget: MonthBudget | undefined = useSelector(lastMonthBudgetSelector);
	const thisMonthBudget: MonthBudget | undefined = useSelector(thisMonthBudgetSelector);
	const budgetedThisMonth = thisMonthBudget ? getTotalMonthBudgeted(thisMonthBudget) : 0;
	return (
		<table className={'month-header-popup-content'}>
			<tbody>
				<tr>
					<td className={'month-header-popup-content__value'}>
						{formatCurrencyForDisplay(lastMonthBudget?.availableToBudget || 0)}
					</td>
					<td className={'month-header-popup-content__title'}>
						Not budgeted in {getShortMonthName(lastMonth)}
					</td>
				</tr>
				<tr>
					<td className={'month-header-popup-content__value'}>
						{formatCurrencyForDisplay(lastMonthBudget?.monthBalance || 0)}
					</td>
					<td className={'month-header-popup-content__title'}>
						Overspent in {getShortMonthName(lastMonth)}
					</td>
				</tr>
				<tr>
					<td className={'month-header-popup-content__value'}>
						+{formatCurrencyForDisplay(thisMonthBudget?.totalIncome || 0)}
					</td>
					<td className={'month-header-popup-content__title'}>
						Income for {getShortMonthName(props.month)}
					</td>
				</tr>
				<tr>
					<td className={'month-header-popup-content__value'}>
						{formatCurrencyForDisplay(budgetedThisMonth * -1)}
					</td>
					<td className={'month-header-popup-content__title'}>
						Budgeted in {getShortMonthName(props.month)}
					</td>
				</tr>
				<tr className={'month-header-popup-content__totals-row'}>
					<td
						className={
							'month-header-popup-content__value month-header-popup-content__value--total'
						}
					>
						{formatCurrencyForDisplay(thisMonthBudget?.availableToBudget || 0)}
					</td>
					<td
						className={
							'month-header-popup-content__title month-header-popup-content__title--total'
						}
					>
						Available in {getShortMonthName(props.month)}
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default MonthHeaderPopupContent;
