import { Month } from "./date";

export type SerializedNaiveDate = {
    year: number,
    month: number,
    date: number,
}

export default class NaiveDate {
    private readonly dateString: string;
    private readonly _year: number;
    private readonly _month: number;
    private readonly _day: number;

    constructor(dateString: string) {
        const validation = new RegExp("^[\\d]{4}[-][\\d]{2}[-][\\d]{2}$");
        if (!validation.test(dateString)) {
            throw new Error('Date must be in Y-m-d format')
        }
        const [year, month, day] = dateString.split('-');

        this.dateString = dateString;
        this._year = Number(year);
        this._month = Number(month);
        this._day = Number(day);
    }

    getYear() {
        return this._year;
    }

    getMonth() {
        return this._month;
    }

    getDay() {
        return this._day;
    }

    getYearMonthDate() {
        return [this._year, this._month, this._day];
    }

    valueOf(): string {
        return this.dateString;
    }

    toString(): string {
        return this.valueOf()
    }

    toJSDate(): Date {
        return new Date(this._year, this._month - 1, this._day);
    }

    toMonth(): Month {
        return {
            year: this._year,
            month: this._month,
        }
    }

    toJson(): SerializedNaiveDate {
        return {
            year: this._year,
            month: this._month,
            date: this._day,
        }
    }

    isEqual(comparison: NaiveDate) {
        return this._year === comparison.getYear()
            && this._month === comparison.getMonth()
            && this._month === comparison.getMonth();
    }

    static fromDate(date: Date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth()+1)).slice(-2);
        const day = ('0' + (date.getDate())).slice(-2);
        return new NaiveDate(`${year}-${month}-${day}`);
    }

    static fromYMD(year: number, month: number, day: number) {
        const monthS = (('0' + String(month)).slice(-2));
        const dayS = (('0' + String(day)).slice(-2));
        return new NaiveDate(`${year}-${monthS}-${dayS}`);
    }

    static getStartOfMonth(month: Month) {
        return NaiveDate.fromYMD(month.year, month.month, 1);
    }

    static getEndOfMonth(month: Month) {
        return NaiveDate.fromDate(new Date(month.year, month.month, 0));
    }
}