import * as dinero from 'dinero.js';
import * as dineroCurrencies from '@dinero.js/currencies';
import { dangerouslyRound } from './currency';
import { toSnapshot } from 'dinero.js';

export type Money = dinero.Dinero<number>;
export type Currency = dinero.Currency<number>;
export type LegacyMoney = number; // A plain number. Ex. $1,293.05 is 1293.05

const subtract = dinero.subtract;
const toUnits = dinero.toUnits;
const add = dinero.add;
const equal = dinero.equal;
const isZero = dinero.isZero
const multiply = dinero.multiply
const greaterThanZero = (money: Money) => dinero.greaterThan(money, zero(getCurrency(money).code))
const lessThanZero = (money: Money) => dinero.lessThan(money, zero(getCurrency(money).code))
const getCurrency = (money: Money) => dinero.toSnapshot(money).currency;

export { add, subtract, equal, isZero, greaterThanZero, lessThanZero, multiply };

export const zero = (currencyCode: Currency['code']) => newMoney(0, currencyCode);
export const negate = (money: Money) => {
	const snapshot = toSnapshot(money);
	return newMoney(-snapshot.amount, snapshot.currency.code);
}

export const newMoney = (amountInCents: number, currencyCode: Currency['code'] = "USD") => {
	const currency = getDineroCurrencyFromCode(currencyCode);
	return dinero.dinero({ amount: amountInCents, currency })
}

export const legacyToMoney = (
	amount: number,
	currencyCode: Currency['code'] = "USD"
): Money => {
	if (Number.isNaN(amount)) {
		console.error(`Amount is NaN`);
		return zero(currencyCode);
	}
	const currency = getDineroCurrencyFromCode(currencyCode);
	const amountInCents = dangerouslyRound(amount * Math.pow(currency.base, currency.exponent));
	const wholeNumberAmountInCents = Number(amountInCents.toFixed());
	try {
		return dinero.dinero({ amount: wholeNumberAmountInCents, currency });
	} catch (e) {
		console.error(`Failed to convert ${amount} to money`);
		throw e;
	}
};

export const toLegacy = (money: Money) => {
	const [dollars, cents] = toUnits(money);
	return dollars + (cents/100);
}

export const formatMoneyForDisplay = (money: Money, locale: string = navigator.language, options = {}) => {
	function transformer({ value, currency }: { value: any, currency: Currency}) {
		return Number(value).toLocaleString(locale, {
			...options,
			style: 'currency',
			currency: currency.code,
			currencyDisplay: "narrowSymbol"
		});
	}

	return dinero.toDecimal(money, transformer);
}

export const stringToMoney = (stringAmount: string, currencyCode: Currency['code'] = "USD") => {
	const number = Number(stringAmount);
	const currency = getDineroCurrencyFromCode(currencyCode);
	const roundedNumber = Number(number.toFixed(currency.exponent));
	return legacyToMoney(roundedNumber, currencyCode)
}

const getDineroCurrencyFromCode = (currencyCode: Currency['code']) => {
	// @ts-ignore
	return dineroCurrencies[currencyCode];
}