import React from 'react';
import { Account, ACCOUNT_CATEGORY_OPTIONS } from '../../../domain/accounts/Account';
import { AdvancedModal } from '../../common/Modal';
import { Icon } from 'semantic-ui-react';
import { Button } from '../../common/Button/Button';
import TextField from '../../common/Form/TextField';
import { Field, Formik } from 'formik';
import SelectField, { SingleOption } from '../../common/Form/SelectField';
import './edit-account-modal.less';
import editAccount from '../../../domain/accounts/operations/editAccount';
import { useDispatch } from 'react-redux';
import { updateAccount } from '../../store/accounts/actions';
import { AppDispatch } from '../../store/types';
import validationSchema from './validationSchema';
import CheckboxField from "../../common/Form/CheckboxField/CheckboxField";

type FormValues = {
	name: Account['name'];
	category: SingleOption<Account['category']>;
	isOffBudget: boolean|undefined;
};

type Props = {
	account: Account;
	open: boolean;
	onClose: () => void;
};

const EditAccountModal = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	return (
		<Formik<FormValues>
			enableReinitialize={true}
			validateOnMount={true}
			validationSchema={validationSchema}
			initialValues={getInitialValues(props.account)}
			onSubmit={(values, formik) => {
				const updatedAccount = editAccount(props.account, {
					name: values.name,
					category: values.category.value,
					isOffBudget: !!values.isOffBudget
				});
				console.log(values, updatedAccount)
				dispatch(updateAccount(updatedAccount)).then(() => {
					props.onClose();
				});
				formik.resetForm();
			}}
		>
			{({ setFieldValue, handleSubmit, resetForm, isValid }) => (
				<AdvancedModal
					open={props.open}
					actions={
						<>
							<Button onClick={() => props.onClose()}>
								<Icon name="remove" /> Cancel
							</Button>
							<Button type={'success'} onClick={handleSubmit} disabled={!isValid}>
								<Icon name="checkmark" /> Save
							</Button>
						</>
					}
					title={'Edit Account'}
					onClose={() => {
						resetForm();
						props.onClose();
					}}
				>
					<Field name="name" as={TextField} label={'Account Name'} />
					<Field
						name="category"
						className={'edit-account-modal__select-field'}
						as={SelectField}
						label={'Account type'}
						onChange={(newVal: SingleOption<string> | null) => {
							if (newVal) {
								setFieldValue('category', newVal);
							}
						}}
						options={ACCOUNT_CATEGORY_OPTIONS}
					/>
					<Field
						name="isOffBudget"
						as={CheckboxField}
						label={'Off Budget'}
						onChange={(checked: boolean) => setFieldValue('isOffBudget', checked)}
					/>
				</AdvancedModal>
			)}
		</Formik>
	);
};

export default EditAccountModal;

const getInitialValues = (account: Account): FormValues => {
	const initialCategory = ACCOUNT_CATEGORY_OPTIONS.find(opt => opt.value === account.category);

	if (!initialCategory) {
		throw new Error(`Account has invalid category of ${account.category}`);
	}

	return {
		name: account.name,
		category: initialCategory,
		isOffBudget: account.isOffBudget
	};
};
