import {Account, ACCOUNT_CATEGORY_TYPES} from "../Account";

const editAccount = (account: Account, updatedProperties: Partial<Account>): Account => {
    // Account category being updated, update type as well
    if ("category" in updatedProperties) {
        const updatedType = ACCOUNT_CATEGORY_TYPES.get(account.category);
        if (!updatedType) {
            throw new Error(`Cannot find account type for category: ${updatedProperties.category}`);
        }
        updatedProperties.type = updatedType;
    }

    return {
        ...account,
        ...updatedProperties
    }
}

export default editAccount;