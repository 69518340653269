import React, { useState } from 'react';
import classnames from 'classnames';
import { SubCategory } from '../../../domain/categories';
import './category-label.less';
import EditCategoryModal from '../EditCategoryButton/EditCategoryModal';
import { Icon } from 'semantic-ui-react';
import { Draggable } from 'react-beautiful-dnd';

type Props = {
	category: SubCategory;
	index: number;
};
const CategoryLabel = ({ category, index }: Props) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	return (
		<Draggable draggableId={category.uuid} index={index}>
			{dndProvided => (
				<div
					className={'category-label'}
					{...dndProvided.draggableProps}
					ref={dndProvided.innerRef}
				>
					<div {...dndProvided.dragHandleProps} className={'category-label__drag-handle'}>
						<Icon name={'bars'} />
					</div>
					<span
						className={classnames({
							'category-label__text': true,
						})}
						onClick={() => setIsEditModalOpen(true)}
					>
						{` ${category.name} `}
						<Icon name={'edit'} className={'category-label__edit-icon'} />
					</span>
					<EditCategoryModal
						category={category}
						isModalOpen={isEditModalOpen}
						onClose={() => setIsEditModalOpen(false)}
					/>
				</div>
			)}
		</Draggable>
	);
};

export default CategoryLabel;
