import { AppDispatch, AppThunkAction } from '../../types';
import { Account } from '../../../../domain/accounts/Account';
import AccountRepo from '../../../../infrastructure/persistence/indexed-db/accounts/AccountRepo';
import { getAllAccounts } from '../selectors/accounts';
import { Transaction } from '../../../../domain/transactions/Transaction';
import TransactionsRepo from '../../../../infrastructure/persistence/indexed-db/transactions/TransactionsRepo';
import { recalculateBudget } from '../../budget/actions/monthBudget';
import { addTransaction } from './transactions';
import { Profile } from '../../../../domain/profiles';

export type AccountsActions =
	| LoadAccounts
	| LoadAccountBalances
	| CreateAccount
	| UpdateAccount
	| SetActiveAccount
	| LoadClearedAccountBalance
	| LoadUnclearedAccountBalance;

export const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS';
export type LoadAccounts = {
	type: typeof LOAD_ACCOUNTS;
	payload: {
		accounts: Array<Account>;
	};
};

export const loadAccountsActionCreator = (accounts: Array<Account>): LoadAccounts => ({
	type: LOAD_ACCOUNTS,
	payload: {
		accounts,
	},
});

export const loadAccounts = (profileUUID: Profile['uuid']): AppThunkAction => {
	return (dispatch, getState) => {
		return AccountRepo.getAll(profileUUID).then(accounts => {
			dispatch(loadAccountsActionCreator(accounts));
			dispatch(loadAccountBalances());
		});
	};
};

export const LOAD_ACCOUNT_BALANCES = 'LOAD_ACCOUNT_BALANCES';
export type LoadAccountBalances = {
	type: typeof LOAD_ACCOUNT_BALANCES;
	payload: {
		accountBalances: Map<Account['uuid'], number>;
	};
};

export const loadAccountBalancesActionCreator = (
	accountBalances: Map<Account['uuid'], number>
): LoadAccountBalances => ({
	type: LOAD_ACCOUNT_BALANCES,
	payload: {
		accountBalances,
	},
});

export const loadAccountBalances = (accountUUID?: Account['uuid']): AppThunkAction => {
	return (dispatch, getState) => {
		const allAccountUUIDs: Array<Account['uuid']> = Array.from(
			getAllAccounts(getState()).values()
		).map(a => a.uuid);
		const accounts = accountUUID ? [accountUUID] : allAccountUUIDs;
		const accountPromises = accounts.map(aUUID => {
			return AccountRepo.getAccountBalance(aUUID).then((accountBalance: number) => {
				return [aUUID, accountBalance];
			});
		});
		return Promise.all(accountPromises).then(entries => {
			// @ts-ignore
			const balances = new Map(entries) as Map<Account['uuid'], number>;
			dispatch(loadAccountBalancesActionCreator(balances));
		});
	};
};

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export type CreateAccount = {
	type: typeof CREATE_ACCOUNT;
	payload: {
		account: Account;
		initialTransaction: Transaction;
	};
};

export const createAccountActionCreator = (
	account: Account,
	initialTransaction: Transaction
): CreateAccount => ({
	type: CREATE_ACCOUNT,
	payload: {
		account,
		initialTransaction,
	},
});

export const createAccount = (
	account: Account,
	initialTransaction: Transaction
): AppThunkAction<Promise<void>> => {
	return (dispatch, getState) => {
		return AccountRepo.put(account)
			.then(() => {
				return TransactionsRepo.put(initialTransaction);
			})
			.then(() => {
				dispatch(createAccountActionCreator(account, initialTransaction));
				return Promise.all([
					dispatch(loadAccounts(account.profileUUID)),
					dispatch(recalculateBudget(initialTransaction.date.toMonth()))
				]).then(
					() => {
						return;
					}
				);
			});
	};
};

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export type UpdateAccount = {
	type: typeof UPDATE_ACCOUNT;
	payload: {
		account: Account;
	};
};

export const updateAccountActionCreator = (account: Account): UpdateAccount => ({
	type: UPDATE_ACCOUNT,
	payload: {
		account,
	},
});

export const updateAccount = (account: Account): AppThunkAction<Promise<void>> => {
	return (dispatch: AppDispatch) => {
		return AccountRepo.put(account).then(() => {
			dispatch(updateAccountActionCreator(account));
		});
	};
};

export const closeAccount = (
	account: Account,
	closingTransaction: Transaction | null = null
): AppThunkAction => {
	return dispatch => {
		account.closed = true;
		dispatch(updateAccount(account));
		if (closingTransaction) {
			dispatch(addTransaction(closingTransaction));
		}
	};
};

export const reOpenAccount = (account: Account): AppThunkAction => {
	return dispatch => {
		account.closed = false;
		dispatch(updateAccount(account));
	};
};

export const SET_ACTIVE_ACCOUNT = 'SET_ACTIVE_ACCOUNT';
export type SetActiveAccount = {
	type: typeof SET_ACTIVE_ACCOUNT;
	payload: {
		accountUUID: Account['uuid'];
	};
};

export const setActiveAccount = (accountUUID: Account['uuid']): SetActiveAccount => ({
	type: SET_ACTIVE_ACCOUNT,
	payload: {
		accountUUID,
	},
});

export const LOAD_CLEARED_ACCOUNT_BALANCE = 'LOAD_CLEARED_ACCOUNT_BALANCE';
export type LoadClearedAccountBalance = {
	type: typeof LOAD_CLEARED_ACCOUNT_BALANCE;
	payload: {
		accountUUID: Account['uuid'];
		clearedBalance: number;
	};
};

export const loadClearedAccountBalance = (
	accountUUID: Account['uuid'],
	clearedBalance: number
): LoadClearedAccountBalance => ({
	type: LOAD_CLEARED_ACCOUNT_BALANCE,
	payload: {
		accountUUID,
		clearedBalance,
	},
});

export const getClearedAccountBalance = (accountUUID: Account['uuid']): AppThunkAction => {
	return async dispatch => {
		const clearedBalance = await AccountRepo.getClearedBalance(accountUUID);
		dispatch(loadClearedAccountBalance(accountUUID, clearedBalance));
	};
};

export const LOAD_UNCLEARED_ACCOUNT_BALANCE = 'LOAD_UNCLEARED_ACCOUNT_BALANCE';
export type LoadUnclearedAccountBalance = {
	type: typeof LOAD_UNCLEARED_ACCOUNT_BALANCE;
	payload: {
		accountUUID: Account['uuid'];
		unclearedBalance: number;
	};
};

export const loadUnclearedAccountBalance = (
	accountUUID: Account['uuid'],
	unclearedBalance: number
): LoadUnclearedAccountBalance => ({
	type: LOAD_UNCLEARED_ACCOUNT_BALANCE,
	payload: {
		accountUUID,
		unclearedBalance,
	},
});

export const getUnclearedAccountBalance = (accountUUID: Account['uuid']): AppThunkAction => {
	return async dispatch => {
		const unclearedBalance = await AccountRepo.getUnclearedBalance(accountUUID);
		dispatch(loadUnclearedAccountBalance(accountUUID, unclearedBalance));
	};
};
