import {AppThunkAction} from "../../types";
import {AppSettingsRepo, SettingsKeys} from "../../../../domain/settings/IAppSettingsRepo";
import logOutUser from "../../../../domain/auth/logOutUser";

export type AuthActions = LoginSuccess | LoggedIn | LoggedOut | OpenLoginModal | CloseLoginModal;

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LoginSuccess = {
    type: typeof LOGIN_SUCCESS;
    payload: {
        email: string,
        token: string
    };
};

export const loginSuccess = (email:string, token: string): LoginSuccess => ({
    type: LOGIN_SUCCESS,
    payload: {
        email,
        token
    }
})

export const LOGGED_IN = 'LOGGED_IN';
export type LoggedIn = {
    type: typeof LOGGED_IN;
    payload: {};
};

export const loggedIn = (): LoggedIn => ({
    type: LOGGED_IN,
    payload: {}
})

export const LOGGED_OUT = 'LOGGED_OUT';
export type LoggedOut = {
    type: typeof LOGGED_OUT;
    payload: {};
};

export const loggedOut = (): LoggedOut => ({
    type: LOGGED_OUT,
    payload: {}
})

export const loadLoggedIn = (): AppThunkAction => {
    return async (dispatch, getState) => {
        const authToken = await AppSettingsRepo.get(SettingsKeys.authToken);
        if (authToken) dispatch(loggedIn());
    };
}

export const logOut = (): AppThunkAction => {
    return async (dispatch, getState) => {
        await logOutUser();
        dispatch(loggedOut())
    };
}

export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export type OpenLoginModal = {
    type: typeof OPEN_LOGIN_MODAL;
    payload: {};
};

export const openLoginModal = (): OpenLoginModal => ({
    type: OPEN_LOGIN_MODAL,
    payload: {}
})

export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export type CloseLoginModal = {
    type: typeof CLOSE_LOGIN_MODAL;
    payload: {};
};

export const closeLoginModal = (): CloseLoginModal => ({
    type: CLOSE_LOGIN_MODAL,
    payload: {}
})