import { useLocation, useHistory } from 'react-router-dom';

const useQueryParams = () => {
    const location = useLocation();
    const history = useHistory();

    return {
        params: (new URLSearchParams(location.search)),
        setParam: (param: string, value: string) => {
            const urlQueryParams = new URLSearchParams(window.location.search);
            urlQueryParams.set(param, value);
            history.push({
                pathname: location.pathname,
                search: urlQueryParams.toString()
            })
        },
        removeParam: (param:string ) => {
            const urlQueryParams = new URLSearchParams(window.location.search);
            urlQueryParams.delete(param);
            history.push({
                pathname: location.pathname,
                search: urlQueryParams.toString()
            })
        }
    }
}

export default useQueryParams;