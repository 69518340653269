import { Field, useFormikContext } from 'formik';
import NativeDatePicker from '../../../common/Form/NativeDatePicker';
import NaiveDate from '../../../../domain/util/NaiveDate';
import React, { CSSProperties } from 'react';
import { FormValues } from '../AddEditTransactionForm/AddEditTransactionForm';

type Props = {
	style?: CSSProperties;
};

const DateColumn = (props: Props) => {
	const { setFieldValue } = useFormikContext<FormValues>();

	return (
		<div className={'add-transaction-row__main-row-cell'} style={props.style}>
			<Field
				type="text"
				name="date"
				as={NativeDatePicker}
				className={'add-transaction-row__date-picker'}
				onChange={(newValue: NaiveDate) => setFieldValue('date', newValue)}
			/>
		</div>
	);
};

export default DateColumn;
