import React, { useState } from 'react';
import { Checkbox, Segment } from 'semantic-ui-react';
import Header from '../../common/Header';
import './settings-page.less';
import Button from '../../common/Button';
import collectData from '../../../domain/settings/backup/collectData';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import exportJson from '../../../domain/settings/backup/exportJson';
import YNABImportModal from '../YNABClassicImportModal/YNABImportModal';
import DolleroImportModal from "../DolleroImportModal/DolleroImportModal";
import appData from '../../../infrastructure/appData';
import RecalculateBudgetButton from './RecalculateBudgetButton/RecalculateBudgetButton';
import useStunOnlySetting from '../../sync/hooks/useStunOnlySetting';
import ContactSection from './ContactSection';

const SettingsPage = () => {
	const currentProfile = useRequiredActiveProfile();
	const [YNABModalOpen, setYNABModalOpen] = useState(false);
	const [dolleroModalOpen, setDolleroModalOpen] = useState(false);
	const [stunOnly, toggleStunOnly] = useStunOnlySetting();

	return (
		<div className={'settings-page'}>
			<section className={'settings-page__section'}>
				<Header>Import</Header>
				<Segment>
					<Header as='h4'>YNAB</Header>
					<p>Import your budget and register data from a YNAB export.</p>
					<YNABImportModal
						open={YNABModalOpen}
						onClose={() => setYNABModalOpen(false)}
					/>
					<Button onClick={() => setYNABModalOpen(true)}>Import</Button>

					<Header as='h4'>Dollero</Header>
					<p>Import your profile from a Dollero backup.</p>
					<DolleroImportModal open={dolleroModalOpen} onClose={() => setDolleroModalOpen(false)} />
					<Button onClick={() => setDolleroModalOpen(true)}>Import</Button>
				</Segment>
			</section>
			<section className={'settings-page__section'}>
				<Header>Backup</Header>
				<Segment>
					<p>Download a copy of your profile and all it's associated data.</p>
					<Button
						onClick={async () => {
							const data = await collectData(currentProfile.uuid);
							exportJson(data);
						}}
					>
						Download
					</Button>
				</Segment>
			</section>
			<section className={'settings-page__section'}>
				<Header>Sync</Header>
				<Segment>
					<p>Sync between device using STUN only. No TURN servers.</p>
					<div>
						<Checkbox toggle onChange={() => toggleStunOnly()} checked={stunOnly} label={'STUN Only'} />
					</div>
				</Segment>
			</section>
			<section className={'settings-page__section'}>
				<Header>Advanced</Header>
				<Segment>
					<p>Current version: {appData.version}</p>
				</Segment>
				<Segment>
					<p>Recalculate this profile's entire budget.</p>
					<RecalculateBudgetButton/>
				</Segment>
			</section>
			<ContactSection/>
		</div>
	);
};

export default SettingsPage;
