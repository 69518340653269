import { dispatch } from '../../view/store/dispatch';
import { setInstallPromptEvent } from '../../view/store/ui/actions/window';

const handleInstallPrompt = () => {
	window.addEventListener("beforeinstallprompt", (e) => {
		e.preventDefault();
		dispatch(setInstallPromptEvent(e))
	});
}

export default handleInstallPrompt;
