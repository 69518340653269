import {UUID} from "../index";
import {Profile} from "../profiles";

export interface Category {
    uuid: UUID,
    name: string,
    profileUUID: Profile['uuid']
    hidden: boolean,
}

export type SubCategory = {
    masterCategoryId: UUID,
} & Category;

export type MasterCategory = Category;

export type CategoryAssignments = Map<MasterCategory['uuid'], Array<SubCategory['uuid']>>;

// Transfers
/** @deprecated AFAIK category id is no longer used to determine whether a transaction is a transfer so this has no purpose **/
export const TRANSFER_MASTER_CATEGORY_ID: MasterCategory['uuid'] = 'jsjdd92';
/** @deprecated AFAIK category id is no longer used to determine whether a transaction is a transfer so this has no purpose **/
export const TRANSFER_CATEGORY_ID = 'sdhds829k';
export const TRANSFER_MASTER_CATEGORY: MasterCategory = {
    uuid: TRANSFER_MASTER_CATEGORY_ID,
    name: 'Transfer',
    hidden: false,
    profileUUID: ''
}
export const TRANSFER_SUB_CATEGORY: SubCategory = {
    uuid: TRANSFER_CATEGORY_ID,
    name: "Transfer",
    masterCategoryId: TRANSFER_MASTER_CATEGORY_ID,
    hidden: true,
    profileUUID: ''
};


// Initial balance TODO: Looks like this is unused and can be removed?
export const INITIAL_BALANCE_MASTER_CATEGORY_ID = 'sdlksdljs99';
export const INITIAL_BALANCE_ASSET_CATEGORY_ID = 'nssdj3265';
export const INITIAL_BALANCE_LIABILITY_CATEGORY_ID = 'bsbd29jks';
export const INITIAL_BALANCE_SUB_CATEGORY: SubCategory = {
    uuid: INITIAL_BALANCE_ASSET_CATEGORY_ID,
    name: "Initial Account Balance",
    masterCategoryId: INITIAL_BALANCE_MASTER_CATEGORY_ID,
    hidden: true,
    profileUUID: ''
};

// Income
export const INCOME_MASTER_CATEGORY_ID = '38dbsk0';
export const INCOME_CATEGORY_ID_THIS_MONTH = 'asvcba7863js';
export const INCOME_CATEGORY_ID_NEXT_MONTH = 'abnasmnbdf98';
export const INCOME_CATEGORY_IDS = [
    INCOME_CATEGORY_ID_THIS_MONTH,
    INCOME_CATEGORY_ID_NEXT_MONTH,
    INITIAL_BALANCE_ASSET_CATEGORY_ID
];
export const INCOME_SUB_CATEGORY: SubCategory = {
    uuid: INCOME_CATEGORY_ID_THIS_MONTH,
    name: "Income this month",
    masterCategoryId: INCOME_MASTER_CATEGORY_ID,
    hidden: false,
    profileUUID: ''
};

export const INCOME_NEXT_MONTH_SUB_CATEGORY: SubCategory = {
    uuid: INCOME_CATEGORY_ID_NEXT_MONTH,
    name: "Income next month",
    masterCategoryId: INCOME_MASTER_CATEGORY_ID,
    hidden: false,
    profileUUID: ''
};

//All built in categories
export const BUILT_IN_CATEGORIES = [
    INCOME_SUB_CATEGORY,
    INCOME_NEXT_MONTH_SUB_CATEGORY,
    TRANSFER_CATEGORY_ID
];