import { PAGE_PADDING } from '../../../view/budget/BudgetPage/BudgetPage';
import { MIN_WIDTH as BUDGET_CATEGORIES_MIN_WIDTH } from '../../../view/budget/BudgetTools/BudgetTools';
import { MIN_WIDTH as MONTH_SHEET_MIN_WIDTH } from '../../../view/budget/MonthSheet/MonthSheet';

const numDisplayableMonths = (windowWidthPx: number) => {
	const sideNavWidth = windowWidthPx * 0.18;
	const widthForMonths =
		windowWidthPx - PAGE_PADDING * 2 - sideNavWidth - BUDGET_CATEGORIES_MIN_WIDTH;
	const numMonths = Math.floor(widthForMonths / MONTH_SHEET_MIN_WIDTH);
	return numMonths > 0 ? numMonths : 1;
}

export default numDisplayableMonths;