import {
    Account,
    ACCOUNT_CASH,
    ACCOUNT_CHEQUING,
    ACCOUNT_CREDIT_CARD,
    ACCOUNT_SAVINGS
} from "../../../domain/accounts/Account";
import {Icon} from "semantic-ui-react";
import React from "react";

type Props = {
    account: Account
}
const AccountIcon = ({ account }: Props) => {
    switch (account.category) {
        case ACCOUNT_CREDIT_CARD:
            return <Icon name={'credit card'} />;
        case ACCOUNT_CHEQUING:
        case ACCOUNT_SAVINGS:
            return <Icon name={'university'} />;
        case ACCOUNT_CASH:
            return <Icon name={'money bill alternate outline'} />;
        default:
            return <Icon name={'dollar sign'} />;
    }
}

export default AccountIcon;