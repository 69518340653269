import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTransactionFilters } from '../../store/accounts/actions/filters';
import { useParams } from 'react-router';
import './accounts-top-nav-tools.less';
import { getAllAccounts } from '../../store/accounts/selectors/accounts';
import EditAccountModal from '../EditAccountModal/EditAccountModal';
import AccountTools from "../AccountTools/AccountTools";
import {Account} from "../../../domain/accounts/Account";
import useIsFiltering from '../TransactionsTable/TableFilters/useIsFiltering';
import classNames from 'classnames';

const AccountsTopNavTools = () => {
	const dispatch = useDispatch();
	const { accountUUID } = useParams<{accountUUID: Account['uuid']}>();
	const accounts = useSelector(getAllAccounts);
	const account = accountUUID ? accounts.get(accountUUID) : null;
	const [isEditingModal, setIsEditingModal] = useState(false);
	const isFiltering = useIsFiltering();

	return (
		<div className={'accounts-top-nav-tools'}>
			<h1 className={'accounts-top-nav-tools__heading'}>
				{account ? account.name : 'Transactions'}
			</h1>
			<div className={'accounts-top-nav-tools__filter-btn'}>
				<span className={classNames({
					"accounts-top-nav-tools__filter-badge": true,
					"accounts-top-nav-tools__filter-badge--filtering": isFiltering,
				})}></span>
				<Button
					icon={'filter'}
					primary
					size={'big'}
					onClick={() => dispatch(toggleTransactionFilters())}
				/>
			</div>
			{account && (
				<AccountTools account={account} />
			)}
			{isEditingModal && account && (
				<EditAccountModal
					account={account}
					open={isEditingModal}
					onClose={() => setIsEditingModal(false)}
				/>
			)}
		</div>
	);
};

export default AccountsTopNavTools;
