import React, { useEffect, useState } from 'react';
import BudgetTools, {
} from '../BudgetTools/BudgetTools';
import { useDispatch, useSelector } from 'react-redux';
import {
	viewMonths,
} from '../../store/budget/actions/budgetMonth';
import {getMonthsVisible} from '../../store/budget/selectors/monthsVisible';
import { AppDispatch } from '../../store/types';
import MultiMonthSelector from '../MultiMonthSelector/MultiMonthSelector';
import MonthHeader from '../MonthHeader/MonthHeader';
import BudgetSheet from '../BudgetSheet/BudgetSheet';
import './budget-page.less';
import {Dimmer, Loader} from "semantic-ui-react";
import {isSmallScreen} from "../../store/ui/selectors/window";
import {isLoadingProfile} from "../../store/profiles/selectors";
import BudgetCardList from '../BudgetCardList/BudgetCardList';
import AddEditTransactionButton
	from '../../accounts/AddEditTransaction/AddEditTransactionButton/AddEditTransactionButton';

export const PAGE_PADDING = 20;

const BudgetPage = () => {
	const dispatch = useDispatch<AppDispatch>();
	const monthsToDisplay = useSelector(getMonthsVisible);
	const [loadingMonths, setLoadingMonths] = useState(monthsToDisplay.length === 0);
	const smallScreen = useSelector(isSmallScreen);
	const loadingProfile = useSelector(isLoadingProfile);

	useEffect(() => {
		if (monthsToDisplay.length !== 0 && !loadingProfile) {
			dispatch(viewMonths(monthsToDisplay))
			setLoadingMonths(false);
		}
	}, [monthsToDisplay, dispatch, loadingProfile]);

	if (loadingMonths || loadingProfile) {
		return (
			<div className={'budget-page__dimmer'}>
				<Dimmer active inverted>
					<Loader size='huge'>Loading</Loader>
				</Dimmer>
			</div>

		);
	}

	if (smallScreen) {
		const month = monthsToDisplay[0];
		if (!month) {
			console.error('No month to display')
			return null;
		}
		return <div className={'budget-page budget-page--small-screen'}>
			<div className={'budget-page__list'}>
				<MonthHeader month={month}/>
				<BudgetCardList month={month}/>
			</div>
			<div className={'budget-page__add-transaction-btn'}><AddEditTransactionButton/></div>
		</div>;
	}

	return (
		<div className={'budget-page'}>
			<div className={'budget-page__content'}>
				<MultiMonthSelector months={monthsToDisplay} />
				<div className={'budget-page__header'}>
					<div className={'budget-page__category-tools'}>
						<BudgetTools />
					</div>
					<div className={'budget-page__sheet-header'}>

						<div className={'budget-page__month-headers'}>
							{monthsToDisplay.map(month => (
								<MonthHeader month={month} key={`${month.year}-${month.month}`}/>
							))}
						</div>
					</div>

				</div>
				<BudgetSheet />
			</div>
		</div>
	);
};

export default BudgetPage;
