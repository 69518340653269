import { MasterCategory, SubCategory } from '../../../categories';
import { AllCategoryTotals } from '../../index';

const getAllMasterCategoryOutflows = (
	subCategoryAssignments: Map<MasterCategory['uuid'], Array<SubCategory['uuid']>>,
	categoryTotals: AllCategoryTotals
) => {
	return Array.from(subCategoryAssignments.entries()).reduce((acc, assignments) => {
		const [masterCatId, subCatIds] = assignments;
		acc.set(masterCatId, getMasterCategoryOutflows(subCatIds, categoryTotals));
		return acc;
	}, new Map());
};

export default getAllMasterCategoryOutflows;

const getMasterCategoryOutflows = (
	subCatIds: Array<SubCategory['uuid']>,
	categoryTotals: AllCategoryTotals
) => {
	return subCatIds.reduce((acc, subCatId) => {
		return acc + (categoryTotals.categoryOutflows.get(subCatId) || 0);
	}, 0);
};
