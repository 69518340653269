import {StoreActions} from "../../types";
import * as DataChannelActions from "../actions/dataChannels";
import {SyncDevice} from "../../../../domain/sync/SyncDevice";

export type DataChannelState = Set<SyncDevice['uuid']>;
export const dataChannels = (state: DataChannelState = new Set(), action: StoreActions) => {
    switch (action.type) {
        case DataChannelActions.DATA_CHANNEL_CONNECTED:
            state.add(action.payload.deviceUUID);
            return new Set(state.values());

        case DataChannelActions.DATA_CHANNEL_DISCONNECTED:
            state.delete(action.payload.deviceUUID);
            return new Set(state.values());
        default:
            return state;
    }
};