import * as AccountActions from '../actions';
import { StoreActions } from '../../types';
import { Account } from '../../../../domain/accounts/Account';
import NaiveDate from "../../../../domain/util/NaiveDate";

type Reconciling = {
    accountUUID: Account['uuid'];
    reconcileDate: NaiveDate,
    reconcileBalance: number
}

export type ReconcilingState = Reconciling | null;
export const reconciling = (state: ReconcilingState = null, action: StoreActions) => {
    switch (action.type) {
        case AccountActions.START_RECONCILING:
            return action.payload;
        case AccountActions.STOP_RECONCILING:
            return null;
        default:
            return state;
    }
};
