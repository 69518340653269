import { useSelector } from 'react-redux';
import {getActiveProfile} from "../selectors";

const useRequiredActiveProfile = () => {
	const profile = useSelector(getActiveProfile)
	if (!profile) {
		throw new Error('No profile is currently active');
	}

	return profile;
};

export default useRequiredActiveProfile;
