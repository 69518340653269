import React from 'react';
import { ColumnInstance } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import { COL_ID_CATEGORY, COL_ID_NOTE, COL_ID_PAYEE, ColumnID } from '../useTableColumns/useTableColumns';
import useCategoryFilters from '../TableFilters/useCategoryFilters';
import usePayeeFilters from '../TableFilters/PayeeFilter/usePayeeFilter';
import useNotesFilters from '../TableFilters/NotesFilter/useNotesFilter';
import './all-filters.less';
import useTransactionTagFilters from '../TableFilters/useTransactionTagFilters';

type Props = {
	columns: ColumnInstance<Transaction>[];
	showAll?: boolean;
};

const AllFilters = (props: Props) => {
	const categoryCol = props.columns.find(col => col.id === COL_ID_CATEGORY);
	const payeeCol = props.columns.find(col => col.id === COL_ID_PAYEE);
	const notesCol = props.columns.find(col => col.id === COL_ID_NOTE);
	const tagsCol = props.columns.find(col => col.id === ColumnID.COL_ID_TAGS);

	if (!payeeCol || !notesCol || !tagsCol) {
		throw new Error('Cannot find table column for filters');
	}

	const categoryFilters = useCategoryFilters(categoryCol);
	const payeeFilters = usePayeeFilters(payeeCol);
	const notesFilters = useNotesFilters(notesCol);
	const tagFilters = useTransactionTagFilters(tagsCol);

	const getFilterForCol = (col: ColumnInstance<Transaction>) => {
		// @ts-ignore
		if (!Object.values(ColumnID).includes(col.id)) {
			return null;
		}

		if (col.canFilter && props.showAll && col.id !== ColumnID.COL_ID_DATE) {
			return col.render('Filter');
		}

		if (
			col.id === ColumnID.COL_ID_CATEGORY &&
			categoryFilters.isShown
		) {
			return col.render('Filter');
		}

		if (col.id === ColumnID.COL_ID_PAYEE && payeeFilters.filterValue !== undefined) {
			return col.render('Filter');
		}

		if (col.id === ColumnID.COL_ID_NOTE && notesFilters.filterValue !== undefined) {
			return col.render('Filter');
		}

		if (col.id === ColumnID.COL_ID_TAGS && tagFilters.isShown) {
			return col.render('Filter');
		}

		if (col.id === ColumnID.COL_ID_DATE) {
			return null;
		}

		// TO maybe be enabled for other filters in the future
		// return col.canFilter ? col.render('Filter') : null;
		return null;
	};

	return (
		<>
			{props.columns.map(col => {
				return <div className={'all-filters__each-filter'}>{getFilterForCol(col)}</div>;
			})}
		</>
	);
};

export default AllFilters;
