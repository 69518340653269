import React from 'react';
import { AdvancedModal, SIZE_TINY } from '../../common/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/types';
import { closeReconcileModal, startReconciling } from '../../store/accounts/actions';
import { Field, Formik } from 'formik';
import NaiveDate from '../../../domain/util/NaiveDate';
import { Account } from '../../../domain/accounts/Account';
import {
	getReconcileDate,
	getStatementBalance,
	isReconcileModalOpen,
} from '../../store/accounts/selectors/reconciling';
import NativeDatePicker from '../../common/Form/NativeDatePicker';
import validationSchema from './validationSchema';
import CurrencyInputField from '../../common/Form/CurrencyInputField';
import { Button } from '../../common/Button/Button';
import { Icon } from 'semantic-ui-react';
import { getAllAccountBalances, getAllAccounts } from '../../store/accounts/selectors/accounts';
import adjustedReconciliationBalance from '../../../domain/accounts/util/adjustedReconciliationBalance';
import { legacyToMoney, toLegacy } from '../../../domain/util/money';

type Props = {
	accountUUID: Account['uuid'];
};

const ReconcileModal = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const isOpen = useSelector(isReconcileModalOpen);
	const statementBalance = useSelector(getStatementBalance) || 0;
	const reconcileDate = useSelector(getReconcileDate);
	const account = useSelector(getAllAccounts).get(props.accountUUID);
	const accountBalance = useSelector(getAllAccountBalances).get(props.accountUUID);
	if (!account || accountBalance === undefined) {
		throw new Error('Cannot find account being reconciled');
	}

	return (
		<>
			<Formik
				initialValues={getInitialValues(account, statementBalance, reconcileDate)}
				validationSchema={validationSchema}
				onSubmit={(values, formikHelpers) => {
					const adjustedStatementBalance = adjustedReconciliationBalance(account, legacyToMoney(values.reconcileBalance));
					dispatch(
						startReconciling(
							props.accountUUID,
							values.reconcileDate,
							toLegacy(adjustedStatementBalance)
						),
					);
					dispatch(closeReconcileModal());
					formikHelpers.resetForm();
				}}
			>
				{({ setFieldValue, handleSubmit, isSubmitting, isValid, resetForm }) => (
					<AdvancedModal
						title={'Reconcile Account'}
						open={isOpen}
						size={SIZE_TINY}
						onClose={() => dispatch(closeReconcileModal())}
						actions={
							<>
								<Button onClick={() => dispatch(closeReconcileModal())}>
									<Icon name='remove' /> Cancel
								</Button>
								<Button
									type={'success'}
									onClick={handleSubmit}
									disabled={isSubmitting || !isValid}
								>
									<Icon name='checkmark' /> Begin Reconciling
								</Button>
							</>
						}
					>
						<Field
							label={'Statement Date'}
							type='text'
							name='reconcileDate'
							as={NativeDatePicker}
							className={''}
							onChange={(newValue: NaiveDate) =>
								setFieldValue('reconcileDate', newValue)
							}
						/>
						<Field
							name='reconcileBalance'
							as={CurrencyInputField}
							label={'Statement Balance'}
						/>
					</AdvancedModal>
				)}
			</Formik>
		</>
	);
};

const getInitialValues = (account: Account, reconcileBalance: number, reconcileDate: NaiveDate | null) => {
	const adjustedStatementBalance = adjustedReconciliationBalance(account, legacyToMoney(reconcileBalance));
	return {
		reconcileDate: reconcileDate ? reconcileDate : NaiveDate.fromDate(new Date()),
		reconcileBalance: toLegacy(adjustedStatementBalance)
	};
};

export default ReconcileModal;
