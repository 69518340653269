import { TransactionTag } from '../TransactionTag';
import { getUUID } from '../../index';
import { Profile } from '../../profiles';

const createNewTransactionTag = (label: string, profileUUID: Profile['uuid']): TransactionTag => {
	return {
		uuid: getUUID(),
		label: label,
		profileUUID: profileUUID,
		createdAt: new Date(),
		updatedAt: new Date(),
	}
}

export default createNewTransactionTag;