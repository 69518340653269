import {APP_LINKS} from "../../store/router/hooks/useAppRoutes";
import {NavLink} from "react-router-dom";
import React from "react";
import {Account} from "../../../domain/accounts/Account";
import useRequiredActiveProfile from "../../store/profiles/hooks/useRequiredActiveProfile";
import AccountIcon from "./AccountIcon";
import BalancePill from "./BalancePill";
import {useSelector} from "react-redux";
import {getAllAccountBalances} from "../../store/accounts/selectors/accounts";
import "./account-link.less";
import useSideNav from '../../store/nav/hooks/useSideNav';

type Props = {
    account: Account
}

const AccountLink = ({ account }: Props) => {
    const activeProfile = useRequiredActiveProfile();
    const accountBalances = useSelector(getAllAccountBalances);
    const { hideSideNav } = useSideNav();

    return <NavLink
        to={APP_LINKS.account(activeProfile.uuid, account.uuid)}
        className={'account-link'}
        activeClassName={'account-link--active'}
        onClick={() => hideSideNav()}
    >
        <AccountIcon account={account}/>
        <span className={'account-link__name'}>{account.name}</span>
        <BalancePill balance={accountBalances.get(account.uuid) || 0} />
    </NavLink>
}

export default AccountLink;