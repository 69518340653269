import React from 'react';
import TextField from "../../common/Form/TextField";
import { ErrorMessage, Field, Formik } from 'formik';
import login from "../../../infrastructure/auth/api/login";
import { useDispatch, useStore } from 'react-redux';
import {loginSuccess} from "../../store/auth/actions";
import setup from "../../../infrastructure/sync/setup";
import { RootState } from '../../store/types';
import NoticeMessage, { Type as NoticeType } from '../../common/Message/NoticeMessage';
import './log-in-form.less';

type Props = {
    onLoginSuccess?: () => void
}

type FormValues = {
    email: string,
    password: string
}

const LoginForm = ({ onLoginSuccess }: Props) => {
    const dispatch = useDispatch();
    const store = useStore<RootState>();

    return <Formik<FormValues>
        initialValues={{
            email: '',
            password: ''
        }}
        onSubmit={async (values, formikHelpers) => {
            console.log(values);
            login(values)
                .then(async (loginDetails) => {
                    await setup(dispatch, store.getState)
                    dispatch(loginSuccess(loginDetails.user.email, loginDetails.token))
                    onLoginSuccess && onLoginSuccess()
                })
                .catch(e => {
                  formikHelpers.setErrors({
                      email: e.message
                  })
                })
        }}
    >
        {({ setFieldValue, handleSubmit, resetForm, isValid, errors }) => (
            <form id={'login'} onSubmit={handleSubmit}>
                <ErrorMessage name="email" >
                    {msg => <NoticeMessage type={NoticeType.ERROR}>{msg}</NoticeMessage>}
                </ErrorMessage>
                <Field name="email" as={TextField} label={'Email'} className={'log-in-form__field'} />
                <Field name="password" as={TextField} label={'Password'} type={'password'} className={'log-in-form__field'}/>
            </form>
        )}
    </Formik>
}

export default LoginForm;