import React, {useState} from 'react';
import { AdvancedModal } from '../../../common/Modal';
import { Button } from '../../../common/Button/Button';
import AddEditTransactionForm, {
	FormValues,
	hasSplits,
	isTransfer, isTransferIntoAccount, isTransferOutOfAccount,
	splitTransaction,
} from '../AddEditTransactionForm/AddEditTransactionForm';
import {
	STATUS_CLEARED,
	STATUS_UNCLEARED,
	Transaction,
} from '../../../../domain/transactions/Transaction';
import { Field, Form, FormikProps, useFormikContext } from 'formik';
import NativeDatePicker from '../../../common/Form/NativeDatePicker';
import NaiveDate from '../../../../domain/util/NaiveDate';
import SelectField, { SIZE_SMALL } from '../../../common/Form/SelectField';
import useAccountOptions, { AccountOption } from '../../../store/accounts/hooks/useAccountOptions';
import PayeeField from '../../AddTransactionRow/fields/PayeeField';
import useCategoryOptions, {
	CategoryOption,
} from '../../../store/categories/hooks/useCategoryOptions';
import TransactionStatus from '../../TransactionStatus';
import { useParams } from 'react-router-dom';
import useRequiredActiveProfile from '../../../store/profiles/hooks/useRequiredActiveProfile';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAccounts } from '../../../store/accounts/selectors/accounts';
import OutflowInflowField, { AmountType } from '../OutflowInflowFIeld/OutflowInflowField';
import './add-edit-transactions-modal.less';
import { TextInput } from '../../../common/Form/Text';
import { deleteTransactions } from '../../../store/accounts/actions/transactions';
import SplitsModal from './SplitsModal/SplitsModal';
import {Checkbox, Icon} from 'semantic-ui-react';
import classNames from 'classnames';
import {Account} from "../../../../domain/accounts/Account";
import TagField from '../../AddTransactionRow/fields/TagField';
import { TransactionTag } from '../../../../domain/transactions/TransactionTag';
import isCategoryFieldDisabled from '../AddEditTransactionForm/isCategoryFieldDisabled';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onEdit?: () => void;
	transaction?: Transaction;
};

const AddEditTransactionModal = (props: Props) => {
	const dispatch = useDispatch();

	const deleteButton = props.transaction ? (
		<Button
			onClick={() => {
				if (!props.transaction) return;
				dispatch(deleteTransactions([props.transaction.uuid]));
				props.onClose();
			}}
			label={'Delete'}
			type={'warning'}
		/>
	) : null;

	return (
		<AddEditTransactionForm
			onSave={() => {
				if (props.onEdit) props.onEdit();
				props.onClose();
			}}
			onCancel={props.onClose}
			transaction={props.transaction}
		>
			{/* @ts-ignore */}
			{(formProps: FormikProps<FormValues>) => (
				<AdvancedModal
					open={props.isOpen}
					contentPadding={false}
					actions={
						<>
							{deleteButton}
							<Button onClick={() => {
								formProps.resetForm();
								props.onClose();
							}} label={'Cancel'} />
							<Button
								onClick={formProps.handleSubmit}
								label={'Save'}
								type={'success'}
							/>
						</>
					}
					onClose={props.onClose}
					title={props.transaction ? 'Edit Transaction' : 'Add Transaction'}
				>
					<ModalForm />
				</AdvancedModal>
			)}
		</AddEditTransactionForm>
	);
};

export default AddEditTransactionModal;

const transferOption = {
	label: (
		<span>
				<Icon name={'exchange'} />
				Transfer
			</span>
	),
	value: '_transfer',
}

const actionOptions: CategoryOption[] = [
	{
		label: (
			<span>
				<Icon name={'copy'} />
				Split Categories
			</span>
		),
		value: '_split',
	},
	transferOption,
];

const ModalForm = () => {
	const { values, setFieldValue, errors } = useFormikContext<FormValues>();
	const categoryOptions = useCategoryOptions(actionOptions);
	const accountOptions = useAccountOptions();
	const activeProfile = useRequiredActiveProfile();
	const accounts = useSelector(getAllAccounts);
	const [isShowingSplitModal, setIsShowingSplitModal] = useState(false);
	const { accountUUID } = useParams<{accountUUID: Account['uuid']}>();
	const account = accountUUID ? accounts.get(accountUUID) : null;
	const showCategoryCol = !accountUUID || !account?.isOffBudget;
	// const showCategoryField = !accounts.get(row?.values.accountUUID)?.isOffBudget;
	const showCategoryField = true;

	const categoryFieldClasses = classNames({
		'add-edit-transactions-modal__field': true,
		'add-edit-transactions-modal__field--error': !!errors.category || !!errors.splits,
	});

	const accountFieldClasses = classNames({
		'add-edit-transactions-modal__field': true,
		'add-edit-transactions-modal__field--error': !!errors.account
	});

	const type = isTransferIntoAccount(values, accountUUID) || values.inflow !== 0 ? AmountType.INFLOW : AmountType.OUTFLOW;

	return (
		<Form>
			<OutflowInflowField
				initialType={type}
				initialAmount={values.outflow || values.inflow}
				canChangeType={!values.isTransfer && !isTransfer(values)}
				onChange={(newAmount, type) => {
					console.log(newAmount, type);
					if (type === AmountType.OUTFLOW) {
						setFieldValue('outflow', newAmount);
						setFieldValue('inflow', 0);
						return;
					}

					setFieldValue('outflow', 0);
					setFieldValue('inflow', newAmount);
				}}
			/>
			{showCategoryCol && showCategoryField && (
				<div className={categoryFieldClasses}>
					{hasSplits(values) && (
						<button
							className={'add-edit-transactions-modal__split-button'}
							onClick={e => {
								e.preventDefault();
								setIsShowingSplitModal(true);
							}}
						>
							<Icon name={'copy'} />
							Split Categories
							<Icon name={'chevron right'} />
						</button>
					)}
					{!hasSplits(values) && (
						<Field
							name="category"
							as={SelectField}
							options={categoryOptions}
							value={values.isTransfer ? transferOption : values.category}
							onChange={(newValue: CategoryOption) => {
								if (newValue.value === '_split') {
									setFieldValue('isTransfer', false);
									setFieldValue('transferToAccount', null);
									setIsShowingSplitModal(true);
									if (values.splits.length === 0) {
										splitTransaction(values, setFieldValue);
									}
								} else if (newValue.value === '_transfer') {
									setFieldValue('isTransfer', true);
								} else {
									setFieldValue('isTransfer', false);
									setFieldValue('transferToAccount', null);
									setFieldValue('splits', []);
									setFieldValue('category', newValue, true);
								}
							}}
							size={SIZE_SMALL}
							placeholder={'Choose a category...'}
						/>
					)}
				</div>
			)}
			<Field
				type="text"
				name="date"
				as={NativeDatePicker}
				onChange={(newValue: NaiveDate) => setFieldValue('date', newValue)}
				// label={'Date'}
			/>
			{!accountUUID && (
				<div className={accountFieldClasses}>
					<Field
						name="account"
						as={SelectField}
						options={accountOptions}
						onChange={(newValue: AccountOption) => setFieldValue('account', newValue, true)}
						size={SIZE_SMALL}
						placeholder={'Choose an account...'}
					/>
				</div>
			)}
			{!isTransfer(values) && (
				<div className={'add-edit-transactions-modal__field'}>
					<Field
						as={PayeeField}
						name="payee"
						placeholder={'Payee...'}
						profileUUID={activeProfile.uuid}
						onChange={(newValue: Transaction['payee']) =>
							setFieldValue('payee', newValue)
						}
						// label={'Payee'}
					/>
				</div>
			)}
			{isTransfer(values) && (!accountUUID || isTransferOutOfAccount(values, accountUUID)) && (
				<div className={'add-edit-transactions-modal__field'}>
					<Field
						name="transferToAccount"
						as={SelectField}
						options={accountOptions
							.filter(opt => opt.value !== accountUUID)}
						onChange={(newValue: AccountOption) =>
							setFieldValue('transferToAccount', newValue)
						}
						size={SIZE_SMALL}
						placeholder={'Transfer to account...'}
					/>
				</div>
			)}
			{ !isCategoryFieldDisabled(values, accounts) && values.isTransfer &&
				<div className={categoryFieldClasses}>
					<Field
						type="number"
						name="category"
						as={SelectField}
						placeholder={values.splits.length > 1 ? 'Split...' : 'Choose a category...'}
						options={categoryOptions}
						onChange={(newValue: CategoryOption) => setFieldValue('category', newValue)}
						size={SIZE_SMALL}
					/>
				</div>}
			{isTransfer(values) && accountUUID && isTransferIntoAccount(values, accountUUID) && (
				<div className={'add-edit-transactions-modal__field'}>
					<Field
						name="account"
						as={SelectField}
						options={accountOptions
							.filter(opt => opt.value !== accountUUID)}
						onChange={(newValue: AccountOption) =>
							setFieldValue('account', newValue)
						}
						size={SIZE_SMALL}
						placeholder={'Transfer from account...'}
					/>
				</div>
			)}
			<div className={'add-edit-transactions-modal__field'}>
				<Field
					name="tags"
					as={TagField}
					onChange={(newValues: Array<TransactionTag['uuid']>) =>
						setFieldValue('tags', newValues)
					}
				/>
			</div>
			<div className={'add-edit-transactions-modal__field'}>
				<Field type="text" name="note" placeholder={'Notes...'} as={TextInput} />
			</div>
			<div
				className={'add-edit-transactions-modal__field'}
				onClick={() => {
					if (values.status === STATUS_UNCLEARED) {
						setFieldValue('status', STATUS_CLEARED);
					}
					if (values.status === STATUS_CLEARED) {
						setFieldValue('status', STATUS_UNCLEARED);
					}
				}}
			>
				<TransactionStatus transactionStatus={values.status} />
				<Checkbox toggle label={'Cleared'} checked={values.status === STATUS_CLEARED}/>

			</div>
			<SplitsModal
				isOpen={isShowingSplitModal}
				onClose={() => setIsShowingSplitModal(false)}
			/>
		</Form>
	);
};
