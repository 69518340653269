import NaiveDate from "../../util/NaiveDate";

let file: string|null = null;

const exportJson = (data: Object) => {
    const blob = new Blob([JSON.stringify(data)], {type: 'application/json'});

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (file !== null) {
        window.URL.revokeObjectURL(file);
    }

    file = window.URL.createObjectURL(blob);

    const now = NaiveDate.fromDate(new Date())
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = file;
    link.download = `backup-${now.toString()}`;

    // It needs to be added to the DOM so it can be clicked
    document.body.appendChild(link);
    link.click();

    // To make this work on Firefox we need to wait
    // a little while before removing it.
    setTimeout(() => {
        URL.revokeObjectURL(link.href);
        // @ts-ignore
        link.parentNode.removeChild(link);
    }, 0);
}

export default exportJson;