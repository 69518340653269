import DolleroWebSocket from "../../web-socket/DolleroWebSocket";
import Initiator from "../signalling/Initiator";
import Receiver from "../signalling/Receiver";
import IncomingSyncDataChannel from "../../sync/IncomingSyncDataChannel";
import {SyncDevice} from "../../../domain/sync/SyncDevice";
import getIceServers from '../stun-turn/getIceServers';

declare interface DataChannelManager extends EventTarget {
   addEventListener(type: string, listener: (event: Event) => void): this;
   addEventListener(type: "data-channel-connected", listener: (event: DataChannelConnected) => void): this;
   addEventListener(type: "data-channel-disconnected", listener: (event: DataChannelDisconnected) => void): this;
}

class DataChannelManager extends EventTarget {

    private peerConnectionsInProgress = new Map<SyncDevice['uuid'], Promise<RTCDataChannel>>();
    private activeDataChannels = new Map<SyncDevice['uuid'], RTCDataChannel>();
    private receiverRoles = new Map<SyncDevice['uuid'], Receiver>();

    constructor(private dolleroWebSocket: typeof DolleroWebSocket) {
        super();

        this.dolleroWebSocket.onMessage((message) => {
            console.log(`socket message`, message)
            // Should add check for being logged in before signalling session is started
            // @ts-ignore TODO: Add these types
            if (message.type === 'role' && message.payload.role === 'receiver') {
                console.log(`received request to start signalling as receiver` , message);
                // @ts-ignore
                if (this.receiverRoles.has(message.payload.peerUUID)) {
                    // @ts-ignore
                    console.log(`Already in a receiving signalling session with ${message.payload.peerUUID}`);
                    return;
                }
                // @ts-ignore
                this.receiveDataChannel(message.payload.peerUUID);
            }
        })
    }

    public hasActiveDataChannel(peerDeviceUUID: string): boolean {
        return this.activeDataChannels.has(peerDeviceUUID);
    }

    public hasConnectionInProgress(peerDeviceUUID: string): undefined|Promise<RTCDataChannel> {
        return this.peerConnectionsInProgress.get(peerDeviceUUID);
    }

    // To be called by initiator
    public async startDataChannel(peerDeviceUUID: string) {
        if (this.hasActiveDataChannel(peerDeviceUUID)) {
            throw new Error(`Active data channel with ${peerDeviceUUID} already exists`);
        }

        const inProgress = this.hasConnectionInProgress(peerDeviceUUID);
        if (inProgress) {
            console.log(`Data connection connection already in progress for ${peerDeviceUUID}`);
            return await inProgress;
        }

        const iceServers = await getIceServers()
        console.log(`Starting signalling with peer: ${peerDeviceUUID}`)
        let role: Initiator|null = new Initiator(iceServers);
        try {
            const dataChannelPromise = role.beginSignalling(peerDeviceUUID);
            this.peerConnectionsInProgress.set(peerDeviceUUID, dataChannelPromise);
            dataChannelPromise.catch(() => this.peerConnectionsInProgress.delete(peerDeviceUUID))
            const dataChannel = await dataChannelPromise;
            role.destroy();
            role = null;
            return this.addDataChannel(peerDeviceUUID, dataChannel);
        } catch (e) {
            role?.destroy();
            role = null;
            throw e;
        }
    }

    public getDataChannel(peerDeviceUUID: string) {
        const channel = this.activeDataChannels.get(peerDeviceUUID);
        if (channel && channel.readyState !== 'open') {
            console.log(`Existing data channel not in 'open' ready state.`);
            this.activeDataChannels.delete(peerDeviceUUID);
            this.dispatchEvent(new DataChannelDisconnected(peerDeviceUUID));
            return null;
        }
        return channel;
    }

    public closeAll() {
        Array.from(this.activeDataChannels.values()).forEach((dataChannel) => {
            dataChannel.close();
        })
    }

    public close(peerDeviceUUID: string) {
        const activeDataChannel = this.activeDataChannels.get(peerDeviceUUID);
        if (!activeDataChannel) {
            console.log(`Data channel for ${peerDeviceUUID} cannot be closed because it is not active.`);
            return
        }
        activeDataChannel.close();
    }

    private async receiveDataChannel(peerDeviceUUID: string) {
        const iceServers = await getIceServers();
        console.log(`Receiver: New incoming signalling request from peer: ${peerDeviceUUID}`)
        let receiver: Receiver|null = new Receiver(iceServers);
        try {
            this.receiverRoles.set(peerDeviceUUID, receiver);
            const dataChannel = await receiver.getDataChannel();
            console.log('Receiver has data channel');
            this.receiverRoles.delete(peerDeviceUUID);
            receiver.destroy();
            receiver = null;
            return this.addDataChannel(peerDeviceUUID, dataChannel);
        } catch (e) {
            this.receiverRoles.delete(peerDeviceUUID);
            receiver?.destroy();
            receiver = null;
            throw e;
        }
    }

    private addDataChannel(peerDeviceUUID: string, dataChannel: RTCDataChannel) {
        this.activeDataChannels.set(peerDeviceUUID, dataChannel);
        this.peerConnectionsInProgress.delete(peerDeviceUUID);

        dataChannel.addEventListener("closing", () => {
            console.log(`Begin closing data channel with peer: ${peerDeviceUUID}.`)
        })

        dataChannel.addEventListener("error", (e) => {
            console.log(`Data channel error with peer: ${peerDeviceUUID}.`, e)
        })

        dataChannel.addEventListener("close", () => {
            console.log(`Data channel with peer: ${peerDeviceUUID} was closed.`)
            this.activeDataChannels.delete(peerDeviceUUID);
            this.dispatchEvent(new DataChannelDisconnected(peerDeviceUUID));
        })
        new IncomingSyncDataChannel(dataChannel, peerDeviceUUID); // TODO: Move this somewhere else
        this.dispatchEvent(new DataChannelConnected(peerDeviceUUID));
        return dataChannel;
    }
}

export default new DataChannelManager(DolleroWebSocket);

export { DataChannelManager };

class DataChannelConnected extends Event {
    public deviceUUID: SyncDevice['uuid'];

    constructor(deviceUUID: SyncDevice['uuid']) {
        super('data-channel-connected');
        this.deviceUUID = deviceUUID;
    }
}

class DataChannelDisconnected extends Event {
    public deviceUUID: SyncDevice['uuid'];

    constructor(deviceUUID: SyncDevice['uuid']) {
        super('data-channel-disconnected');
        this.deviceUUID = deviceUUID;
    }
}