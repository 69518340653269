import * as AccountActions from '../actions/accounts';
import { StoreActions } from '../../types';
import { Account } from '../../../../domain/accounts/Account';

export type AccountsState = Map<Account['uuid'], Account>;
export const accounts = (state: AccountsState = new Map(), action: StoreActions) => {
	switch (action.type) {
		case AccountActions.LOAD_ACCOUNTS:
			const entries = action.payload.accounts.map((a) => [a.uuid, a]) as Array<[Account['uuid'], Account]>;
			return new Map(entries);

		case AccountActions.UPDATE_ACCOUNT:
			state.set(action.payload.account.uuid, action.payload.account);
			return new Map(state.entries());

		default:
			return state;
	}
};
