import { Account } from '../../../../domain/accounts/Account';
import { useSelector } from 'react-redux';
import { getAllAccountBalances } from '../selectors/accounts';
import { legacyToMoney, Money, newMoney } from '../../../../domain/util/money';
import useRequiredActiveProfile from '../../profiles/hooks/useRequiredActiveProfile';

const useAccountBalance = (accountUUID: Account['uuid']): Money => {
	const accountBalances = useSelector(getAllAccountBalances);
	const profile = useRequiredActiveProfile();
	const balance = accountBalances.get(accountUUID);
	if (balance === undefined) {
		return newMoney(0, profile.currency);
	}

	return legacyToMoney(balance, profile.currency);
};

export default useAccountBalance;
