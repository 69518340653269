import {AllCategoryTotals} from "../../index";

const getMonthBalance = (monthCategoryBalances: AllCategoryTotals): number => {
    return Array.from(monthCategoryBalances.categoryBalances.values()).reduce(
        (acc, balance) => {
            if (balance.isCarriedOver) {
                return acc;
            }

            if (balance.balance < 0) {
                acc += balance.balance;
            }
            return acc;
        },
        0
    );
}

export default getMonthBalance;