import { useCategoryFilterValue } from './useCategoryFilters';
import { useNotesFilterValue } from './NotesFilter/useNotesFilter';
import { usePayeeFilterValue } from './PayeeFilter/usePayeeFilter';
import { useTagFilterValue } from './useTransactionTagFilters';

const useIsFiltering = () => {
	const categoryFilter = useCategoryFilterValue();
	const notesFilter = useNotesFilterValue();
	const payeeFilter = usePayeeFilterValue();
	const tagFilter = useTagFilterValue();

	return !!categoryFilter || !!notesFilter || !!payeeFilter || !!tagFilter
}

export default useIsFiltering;