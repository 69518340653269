import NaiveDate from '../../../util/NaiveDate';
import { Profile } from '../../../profiles';
import checkYNABVersion, { YnabVersion } from './checkYNABVersion';
import parseBudgetRows from '../YNABClassic/parseBudgetRows';
import parseRegisterRows from '../YNABClassic/parseRegisterRows';
import newParseBudgetRows from '../YNABNew/parseBudgetRows';
import newParseRegisterRows from '../YNABNew/parseRegisterRows';
import { importBudget } from './importBudget';
import { importRegister } from './importRegister';
import { MasterCategory, SubCategory } from '../../../categories';
import CategoriesRepo from '../../../../infrastructure/persistence/indexed-db/categories/CategoriesRepo';
import CategoryBudgetsRepo from '../../../../infrastructure/persistence/indexed-db/categories/CategoryBudgetsRepo';
import AccountRepo from '../../../../infrastructure/persistence/indexed-db/accounts/AccountRepo';
import TransactionsRepo from '../../../../infrastructure/persistence/indexed-db/transactions/TransactionsRepo';

export type BudgetRow = {
	month: string;
	category: string;
	masterCategory: string;
	subCategory: string;
	budgeted: number;
	outflows: number;
	categoryBalance: number;
};

export type RegisterRow = {
	account: string;
	flag: string;
	checkNumber: string;
	date: NaiveDate;
	payee: string;
	category: string;
	masterCategory: string;
	subCategory: string;
	memo: string;
	outflow: number;
	inflow: number;
	cleared: string;
	runningBalance: string;
};

export const importYnabCSV = async (
	budgetCsvText: string,
	registerCsvText: string,
	profile: Profile
) => {
	if (budgetCsvText === '') {
		throw new Error('Cannot import empty budget CSV');
	}

	if (registerCsvText === '') {
		throw new Error('Cannot import empty register CSV');
	}

	const version = checkYNABVersion(budgetCsvText, registerCsvText);
	let budgetRows: BudgetRow[] = [];
	let registerRows: RegisterRow[] = [];
	switch (version) {
		case YnabVersion.VERSION_YNAB_CLASSIC:
			budgetRows = parseBudgetRows(budgetCsvText);
			registerRows = parseRegisterRows(registerCsvText);
			break;
		case YnabVersion.VERSION_YNAB_NEW:
			budgetRows = newParseBudgetRows(budgetCsvText);
			registerRows = newParseRegisterRows(registerCsvText);
			break;
		default:
			throw new Error('Unknown budget or register format');
	}

	const [categoryBudgets, subCategoriesMap, masterCategoriesMap] = importBudget(
		budgetRows,
		profile
	);
	const [accountsMap, transactions] = importRegister(registerRows, subCategoriesMap, profile);
	const masterCategoryOrder = Array.from(masterCategoriesMap.values()).map(mcat => mcat.uuid);

	const subCategoryOrder = Array.from(subCategoriesMap.values()).reduce((acc, subcat) => {
		const subCategoryUUIDs = acc.get(subcat.masterCategoryId) || [];
		subCategoryUUIDs.push(subcat.uuid);
		acc.set(subcat.masterCategoryId, subCategoryUUIDs);
		return acc;
	}, new Map<MasterCategory['uuid'], SubCategory['uuid'][]>());
	const subCategoryOrders: Array<Promise<SubCategory['uuid'][]>> = Array.from(
		subCategoryOrder.entries()
	).map(([masterCatUUID, subCategoryUUIDs]) => {
		return CategoriesRepo.setSubCategoryOrder(masterCatUUID, subCategoryUUIDs);
	});

	await Promise.all(subCategoryOrders);

	return Promise.all([
		CategoryBudgetsRepo.putMany(categoryBudgets),
		CategoriesRepo.putManySubCategories(Array.from(subCategoriesMap.values())),
		CategoriesRepo.putManyMasterCategories(Array.from(masterCategoriesMap.values())),
		CategoriesRepo.setMasterCategoryOrder(masterCategoryOrder),
		AccountRepo.putMany(Array.from(accountsMap.values())),
		TransactionsRepo.putMany(transactions),
	]).catch(results => {
		console.error('Import failed', results);
		throw new Error('Import failed');
	});
};
