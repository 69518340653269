import {RootState, StoreActions} from "../types";
import {Reducer} from "redux";
import {LOAD_ACTIVE_PROFILE} from "./actions";

const withProfileReset = (reducer: Reducer) => {
    return (state: RootState, action: StoreActions) => {
        if (action.type === LOAD_ACTIVE_PROFILE) {
            return reducer(undefined, action);
        }

        return reducer(state, action);
    }
}

export default withProfileReset;