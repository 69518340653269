import React from 'react';
import Select, { GroupBase, StylesConfig, OptionsOrGroups } from 'react-select';

export type SingleOption<T> = { label: string; value: T };
export type SelectOptions<T> = OptionsOrGroups<SingleOption<T>, GroupBase<SingleOption<T>>>;
export type StylesConfigFunction<T> = StylesConfig<SingleOption<T>>;

export const SIZE_SMALL = 'small';
export const SIZE_MEDIUM = 'medium';
type SIZES = typeof SIZE_SMALL | typeof SIZE_MEDIUM;

type Props<T> = {
	value: SingleOption<T>;
	name: string;
	id?: string;
	onChange?: (option: SingleOption<T> | null) => void;
	disabled?: boolean;
	label?: string;
	options: SelectOptions<T>;
	size?: SIZES;
	className?: string;
	clearable?: boolean;
	placeholder?: string;
	defaultValue?: SingleOption<T>; // TODO: implement if needed,
	valueStylesFn?: StylesConfig<SingleOption<T>>['singleValue'];
	optionsStylesFn?: StylesConfig<SingleOption<T>>['option'];
};

function SelectField<T = string>(props: Props<T>) {
	const htmlFor = props.id ? props.id : '';
	const size = props.size ? props.size : SIZE_MEDIUM;
	const propClass = props.className || '';
	const defaultStylesFn = (provided: any) => {
		return { ...provided };
	};
	const styles = getStyles(
		size,
		props.valueStylesFn || defaultStylesFn,
		props.optionsStylesFn || defaultStylesFn
	);

	return (
		<div style={{width: '100%'}}>
			{props.label && (
				<label className="" htmlFor={htmlFor}>
					{props.label}
				</label>
			)}
			<Select
				id={props.id}
				name="category"
				options={props.options}
				isDisabled={Boolean(props.disabled)}
				styles={styles}
				menuPlacement={'auto'}
				className={propClass}
				onChange={value => {
					if (value === null || value === undefined) {
						props.onChange && props.onChange(null);
						return;
					}

					if (value.hasOwnProperty('value')) {
						const refinedValue = value as SingleOption<T>;
						props.onChange && props.onChange(refinedValue);
						return;
					}

					console.error(`Could not handler select value changing to ${value}`);
					throw Error(`Could not handler select value changing to ${value}`);
				}}
				placeholder={props.placeholder}
				value={props.value}
				isClearable={Boolean(props.clearable)}
				menuPortalTarget={document.body}
			/>
		</div>
	);
}

export default SelectField;

const getStyles = (
	size: SIZES,
	valueStylesFn: any,
	optionsStylesFn: any
): StylesConfig => {
	return Object.assign(
		{},
		{
			container: (provided: {}) => ({
				...provided,
				height: '3rem',
		    }),
			control: (provided: {}) => ({
				...provided,
				height: '100%',
				borderRadius: 0,
				borderColor: '#e5e5e5',
				minHeight: 0,
				border: 0,
				background: 'none',
				boxShadow: 'none',
			}),
			dropdownIndicator: (provided: {}) => ({
				...provided,
				padding: '0 0.8rem',
			}),
			indicatorSeparator: (provided: {}) => ({
				...provided,
				marginTop: '2px',
				marginBottom: '2px',
		    }),
			clearIndicator: (provided: {}) => ({
				...provided,
				padding: '5px',
			}),
			singleValue: valueStylesFn,
			option: optionsStylesFn,
		},
		{ menuPortal: (base: {}) => ({ ...base, zIndex: 9999 }) }, //fixed hidden dropdown menu
		getSizeStyles(size)
	);
};

const getSizeStyles = (size: SIZES) => {
	switch (size) {
		case SIZE_SMALL:
			return SMALL_STYLES;
		default:
			return {};
	}
};

const SMALL_STYLES = {
	container: (provided: {}) => ({
		...provided,
		height: 'auto',
	}),
	valueContainer: (provided: {}) => ({
		...provided,
		padding: '0 4px',
	}),
	dropdownIndicator: (provided: {}) => ({
		...provided,
		padding: '5px 7px',
	}),
};
