import {
	isIgnoredCategory,
	MASTER_CATEGORY_HIDDEN,
	MasterCategoryNameMap,
} from './masterCategories';
import { MasterCategory, SubCategory } from '../../../categories';
import { getUUID } from '../../../index';
import {Profile} from "../../../profiles";
import {BudgetRow} from "./index";
import { getCategoryFromBudgetRow } from './util';

export type SubCategoryNameMap = Map<SubCategory['name'], SubCategory>;

export const getSubCategoriesMap = (
	budgetRows: BudgetRow[],
	masterCategoryMap: MasterCategoryNameMap,
	profile: Profile
): SubCategoryNameMap => {
	return budgetRows.reduce((acc: SubCategoryNameMap, row) => {
		if (isIgnoredCategory(row)) {
			return acc;
		}
		const [masterCategoryName, subCategoryName] = getCategoryFromBudgetRow(row);
		const subCategory: SubCategory = {
			uuid: getUUID(),
			name: subCategoryName,
			hidden: isSubCategoryHidden(row),
			masterCategoryId: getMasterCategoryUUID(masterCategoryName, masterCategoryMap),
			profileUUID: profile.uuid,
		};
		acc.set(subCategoryName, subCategory);
		return acc;
	}, new Map());
};

const getMasterCategoryUUID = (
	masterCategoryName: string,
	masterCategoryMap: MasterCategoryNameMap
): MasterCategory['uuid'] => {
	const masterCat = masterCategoryMap.get(masterCategoryName);
	if (!masterCat) {
		throw new Error('Subcategory without a master category found');
	}

	return masterCat.uuid;
};

const isSubCategoryHidden = (row: BudgetRow) => {
	return row.masterCategory === MASTER_CATEGORY_HIDDEN;
};
