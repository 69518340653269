import Button from '../../../common/Button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import recalculateEntireBudget from '../../../../domain/budget/commands/recalculateEntireBudget';
import useRequiredActiveProfile from '../../../store/profiles/hooks/useRequiredActiveProfile';

const RecalculateBudgetButton = () => {
	const dispatch = useDispatch();
	const profile = useRequiredActiveProfile();
	const [isRecalculating, setIsRecalculating] = useState(false)

	return <Button
		onClick={() =>{
			setIsRecalculating(true);
			recalculateEntireBudget(profile.uuid, dispatch).then(() => {
				setIsRecalculating(false)
			})
		}}
		loading={isRecalculating}
	>
		Recalculate
	</Button>
}

export default RecalculateBudgetButton;