import React, { useState } from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import './reconcile-summary.less';
import { useDispatch, useSelector } from 'react-redux';
import {
	getReconcileDate,
	isReconciling,
} from '../../store/accounts/selectors/reconciling';
import {
	openReconcileModal,
	reconcileClearedTransactions,
	stopReconciling,
} from '../../store/accounts/actions';
import AdjustmentTransactionModal from '../AdjustmentTransactionModal/AdjustmentTransactionModal';
import Button from '../../common/Button';
import { isSmallScreen } from '../../store/ui/selectors/window';
import useStatementBalance from '../../store/accounts/hooks/reconciliation/useStatementBalance';
import { formatMoneyForDisplay, isZero, subtract } from '../../../domain/util/money';
import useClearedBalance from '../../store/accounts/hooks/reconciliation/useClearedBalance';

const ReconcileSummary = () => {
	const dispatch = useDispatch();
	const smallScreen = useSelector(isSmallScreen);
	const [adjustmentModalOpen, setAdjustmentModalOpen] = useState<boolean>(false);
	const statementBalance = useStatementBalance();
	const clearedBalance = useClearedBalance();
	const reconcileDate = useSelector(getReconcileDate);
	const activeReconciling = useSelector(isReconciling);
	const difference = subtract(statementBalance, clearedBalance);

	if (!activeReconciling) {
		return null;
	}

	return (
		<div className={'reconcile-summary'}>
			{ smallScreen && <div className={'reconcile-summary__bar'}>{`Reconciling to ${reconcileDate?.toString()}`}</div>}
			<div className={'account-summary'}>
				<div className={'account-summary__third'}>
					<span className={'account-summary__value'}>
						{formatMoneyForDisplay(clearedBalance)}
					</span>
					<span className={'account-summary__label'}>Cleared</span>
				</div>
				<div className={'account-summary__third'}>
					<span className={'account-summary__value'}>
						{formatMoneyForDisplay(statementBalance)}
					</span>
					<span className={'account-summary__label'}>
						Statement Balance
					</span>
				</div>
				<div className={'account-summary__third'}>
					<span className={'account-summary__value'}>
						{formatMoneyForDisplay(difference)}
					</span>
					<span className={'account-summary__label'}>
						Difference
					</span>
				</div>
			</div>

			{smallScreen && (
				<Button.Group>
					<Button icon="check" content="Finish" onClick={() => {
						if (isZero(difference)) {
							dispatch(reconcileClearedTransactions());
						} else {
							setAdjustmentModalOpen(true);
						}
					}} />
					<Button icon="edit" content="Adjust" onClick={() => dispatch(openReconcileModal())} />
					<Button icon="close" content="Cancel" onClick={() => dispatch(stopReconciling())} />
				</Button.Group>
			)}

			{!smallScreen && (
				<Menu compact icon="labeled">
					<Menu.Item
						name="Finish Reconciling"
						onClick={() => {
							if (isZero(difference)) {
								dispatch(reconcileClearedTransactions());
							} else {
								setAdjustmentModalOpen(true);
							}
						}}
					>
						<Icon name="check" />
						Finish Reconciling
					</Menu.Item>
					<Menu.Item
						name="Adjust Statement"
						onClick={() => dispatch(openReconcileModal())}
					>
						<Icon name="edit" />
						Adjust Statement
					</Menu.Item>
					<Menu.Item name="Stop Reconciling" onClick={() => dispatch(stopReconciling())}>
						<Icon name="close" />
						Stop Reconciling
					</Menu.Item>
				</Menu>
			)}
			<AdjustmentTransactionModal
				isOpen={adjustmentModalOpen}
				onClose={() => setAdjustmentModalOpen(false)}
			/>
		</div>
	);
};

export default ReconcileSummary;
