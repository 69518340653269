

export type WindowActions =
    | SetDimensions | SetInstallPromptEvent;

export const SET_DIMENSIONS = 'window/SET_DIMENSIONS';
export type SetDimensions = {
    type: typeof SET_DIMENSIONS;
    payload: {
        width: number;
        height: number;
    }
};

export const setDimensions = (width: number, height: number): SetDimensions => ({
    type: 'window/SET_DIMENSIONS',
    payload: {
        width,
        height
    }
});

export const SET_INSTALL_PROMPT_EVENT = 'window/SET_INSTALL_PROMPT_EVENT';
export type SetInstallPromptEvent = {
    type: typeof SET_INSTALL_PROMPT_EVENT;
    payload: {
        installPromptEvent: Event
    }
};
export const setInstallPromptEvent = (e: Event): SetInstallPromptEvent => ({
    type: SET_INSTALL_PROMPT_EVENT,
    payload: {
        installPromptEvent: e
    }
});