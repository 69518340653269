import { toast, ToastOptions, Id as ToastID } from 'react-toastify';
import React from 'react';

type Options = Pick<ToastOptions, 'icon' | 'autoClose' | 'toastId' | 'type'>

export const TYPES = toast.TYPE;

const DEFAULT_POSITION = toast.POSITION.BOTTOM_RIGHT;
const DEFAULT_AUTO_CLOSE_MS = 5000;

class ToastManager {
	static newToast(message: React.ReactNode, options: Options = {}) {
		toast(message, getOptions(options));
	}

	static updateToast(id: ToastID, message: React.ReactNode, options: Options = {}) {
		if (!toast.isActive(id)) return this.newToast(message, options);
		toast.update(id, Object.assign(getOptions(options), { render: message }))
	}
}

export default ToastManager;

const getOptions = (options: Options) => {
	const toastOptions: ToastOptions = {
		position: DEFAULT_POSITION,
		toastId: options.toastId,
		type: options.type,
		autoClose: options.autoClose === undefined ? DEFAULT_AUTO_CLOSE_MS : options.autoClose,
		icon: options.icon
	};

	return toastOptions;
}