import { TransactionTag } from '../TransactionTag';
import { TransactionsRepo } from '../ITransactionsRepo';
import unTagTransaction from './unTagTransaction';

const unTagAll = async (tagUUID: TransactionTag['uuid']) => {
	const taggedTransactions = await TransactionsRepo.getTagged(tagUUID);
	const untaggedTransactions = taggedTransactions.map((transaction) => unTagTransaction(transaction, tagUUID))
	return TransactionsRepo.putMany(untaggedTransactions);
}

export default unTagAll;