import { BudgetRow } from '../YNAB';
import papaparse from 'papaparse';
import { NewBudgetRowRaw } from './index';
import camelCase from 'lodash/camelCase';
import { getCurrencyAmount } from '../YNAB/util';

const parseConfig = {
	header: true,
	skipEmptyLines: true,
	transformHeader: camelCase,
};

const parseBudgetRows = (budgetCsvText: string): BudgetRow[] => {
	const parsedBudgetData = papaparse.parse<NewBudgetRowRaw>(budgetCsvText, parseConfig);
	return parsedBudgetData.data.map(row => {
		return {
			month: row.month,
			category: row.categoryGroupCategory,
			masterCategory: row.categoryGroup,
			subCategory: row.category,
			budgeted: getCurrencyAmount(row.budgeted),
			outflows: getCurrencyAmount(row.activity) * -1,
			categoryBalance: getCurrencyAmount(row.available),
		};
	});
};

export default parseBudgetRows;
