import { combineReducers } from 'redux';
import { allAccountTransactions, AllAccountTransactionsState } from './transactions';
import { accounts, AccountsState as AllAccountsState } from './accounts';
import { accountBalances, AccountBalancesState } from "./accountBalances";
import { reconciling, ReconcilingState } from "./reconciling";
import { reconcileModalOpen, ReconcileModalState } from "./reconcileModalOpen";
import { reconcilingBalance, ReconcilingBalance } from "./reconcilingBalance";
import { activeAccount, ActiveAccountState } from "./activeAccount";
import { accountClearedBalances, AccountClearedBalancesState } from "./accountClearedBalances";
import { accountUnclearedBalances, AccountUnclearedBalancesState } from "./accountUnclearedBalances";
import { filtersModalOpen, FiltersModalOpenState } from "./filters";
import { lastTransactionCreated, LastTransactionCreatedState } from './lastTransactionCreated';

export type AccountsState = {
	allAccountTransactions: AllAccountTransactionsState;
	accounts: AllAccountsState;
	accountBalances: AccountBalancesState;
	accountClearedBalances: AccountClearedBalancesState;
	accountUnclearedBalances: AccountUnclearedBalancesState;
	reconciling: ReconcilingState;
	reconcileModalOpen: ReconcileModalState;
	reconcilingBalance: ReconcilingBalance;
	activeAccount: ActiveAccountState;
	filtersModalOpen: FiltersModalOpenState;
	lastTransactionCreated: LastTransactionCreatedState;
};
export default combineReducers({
	allAccountTransactions,
	accounts,
	accountBalances,
	accountClearedBalances,
	accountUnclearedBalances,
	reconciling,
	reconcileModalOpen,
	reconcilingBalance,
	activeAccount,
	filtersModalOpen,
	lastTransactionCreated
});
