import React from "react";
import {STATUS_CLEARED, STATUS_UNCLEARED, Transaction} from "../../../../domain/transactions/Transaction";
import Dropdown from "../../../common/Dropdown";
import { deleteTransactions, editTransactions } from '../../../store/accounts/actions/transactions';
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store/types";
import useCategoryOptions from "../../../store/categories/hooks/useCategoryOptions";
import assignCategory from "../../../../domain/transactions/operations/assignCategory";
import useAccountOptions from "../../../store/accounts/hooks/useAccountOptions";
import assignAccount from "../../../../domain/transactions/operations/assignAccount";
import setStatus from "../../../../domain/transactions/operations/setStatus";
import { useParams } from 'react-router-dom';
import { Account } from '../../../../domain/accounts/Account';

type Props = {
    selectedTransactions: Transaction[];
}

const EditTransactionsMenu = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const categoryOptions = useCategoryOptions();
    const accountOptions = useAccountOptions();
    const { accountUUID } = useParams<{ accountUUID: Account['uuid']}>();

    const numEditedTransactions = props.selectedTransactions.length ? `(${props.selectedTransactions.length})` : '';
    const transactionUUIDs = props.selectedTransactions.map((t) => t.uuid)
    const editLabel = `Edit ${numEditedTransactions}`;

    return <Dropdown
        text={editLabel}
        icon='edit'
        labeled
        button
        className='icon'
        disabled={!props.selectedTransactions.length}
    >
        <Dropdown.Menu>
            <Dropdown.Item text='Delete' onClick={() => dispatch(deleteTransactions(transactionUUIDs))} />
            <Dropdown.Item>
                <Dropdown text='Categorize as...'>
                    <Dropdown.Menu>
                        {categoryOptions.map((masterCat) => {
                            return <Dropdown.Item key={masterCat.label}>
                                <Dropdown text={masterCat.label}>
                                    <Dropdown.Menu>
                                        {masterCat.options.map((subCat) => {
                                            return <Dropdown.Item
                                                key={subCat.value}
                                                onClick={() => {
                                                    const editedTransactions = assignCategory(props.selectedTransactions, subCat.value);
                                                    return dispatch(editTransactions(editedTransactions));
                                                }}>
                                                {subCat.label}
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Dropdown.Item>
            <Dropdown.Item>
                <Dropdown text='Move to account...'>
                    <Dropdown.Menu>
                        {accountOptions.map((account) => {
                            return <Dropdown.Item
                                key={account.value}
                                onClick={async () => {
                                    const editedTransactions = assignAccount(props.selectedTransactions, account.value);
                                    await dispatch(editTransactions(editedTransactions));
                                }}>
                                {account.label}
                            </Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </Dropdown.Item>
            <Dropdown.Item>
                <Dropdown text='Mark as...'>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => {
                            const editedTransactions = setStatus(props.selectedTransactions, STATUS_CLEARED, accountUUID);
                            return dispatch(editTransactions(editedTransactions));
                        }}>
                            Cleared
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => {
                            const editedTransactions = setStatus(props.selectedTransactions, STATUS_UNCLEARED, accountUUID);
                            return dispatch(editTransactions(editedTransactions));
                        }}>
                            Uncleared
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}

export default EditTransactionsMenu;