import { Field, Formik, ErrorMessage, Form } from 'formik';
import React from 'react';
import TextField from '../../common/Form/TextField';
import { Button } from '../../common/Button/Button';
import signUp from '../../../infrastructure/auth/api/signUp';
import validationSchema from './validationSchema';
import { loginSuccess } from '../../store/auth/actions';
import { useDispatch } from 'react-redux';
import './sign-up-form.less';

export type Props = {
	onSignUpSuccess?: () => void
}

const SignUpForm = (props: Props) => {
	const dispatch = useDispatch();
	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
				password_confirm: '',
			}}
			validationSchema={validationSchema}
			onSubmit={async (values, formik) => {
				console.log(values);
				try {
					const { user, token } = await signUp(values.email, values.password);
					dispatch(loginSuccess(user.email, token))
					props.onSignUpSuccess && props.onSignUpSuccess();
				} catch (e: any) {
					formik.setErrors({ email: e?.message})
				}
			}}
		>
			{({ handleSubmit, isValid, errors, touched, isSubmitting }) => {
				// @ts-ignore
				return <Form className={'sign-up-form'}>
					<Field name="email" as={TextField} label={'Email'} id={'email'}/>
					<span className={'sign-up-form__error'}>
						<ErrorMessage name="email"/>
					</span>
					<Field name="password" as={TextField} type={'password'} label={'Password'} id={'password'}/>
					<span className={'sign-up-form__error'}>
						<ErrorMessage name="password"/>
					</span>
					<Field
						name="password_confirm"
						as={TextField}
						type={'password'}
						label={'Confirm Password'}
						id={'confirm-password'}
					/>
					<span className={'sign-up-form__error'}>
						<ErrorMessage name="password_confirm"/>
					</span>
					<div>
						<p>
							<small>By singing up below, I agree to Dollero's <a href={'https://dollero.app/privacy-policy.html'} target={'_blank'} rel="noreferrer">privacy policy</a> and <a href={'https://dollero.app/terms-of-use.html'} target={'_blank'} rel="noreferrer">terms of use</a>.</small>
						</p>
						<Button type={'success'} onClick={handleSubmit} disabled={!isValid} loading={isSubmitting}>
							Sign Up
						</Button>
					</div>
				</Form>
			}}
		</Formik>
	);
};

export default SignUpForm;
