import {useSelector} from "react-redux";
import {getProfilesByUUID} from "../selectors";
import {useMemo} from "react";

const useAllProfiles = () => {
    const profilesMap = useSelector(getProfilesByUUID);
    return useMemo(() => {
        return Array.from(profilesMap.values());
    }, [profilesMap]);
}

export default useAllProfiles;