import React from "react";
import {InputField} from "../../../common/Form/InputField";
import './text-filter.less';
import { PayeeQueryFilters } from './PayeeFilter/usePayeeFilter';

type Props = {
    label: string,
    filter: PayeeQueryFilters
};

const TextFilter = (props: Props) => {
    return (
        <div className={'text-filter'}>
            <label className={'text-filter__label'}>{props.label}</label>
            <InputField
                value={props.filter.filterValue || ''}
                onChange={newValue => {
                    props.filter.setFilterValue(newValue || undefined) // Set undefined to remove the filter entirely
                }}
                className={'text-filter__input'}
            />
        </div>
    )
};

export default TextFilter;