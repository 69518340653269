import {SerializedTransaction, Transaction} from "../Transaction";

const toJson = (transaction: Transaction): SerializedTransaction => {
    return {
        ...transaction,
        date: transaction.date.toJson()
    }
}

export default toJson;

