import React from 'react';
import { Field, Formik } from 'formik';
import NaiveDate from '../../../domain/util/NaiveDate';
import TextField from '../../common/Form/TextField';
import Form from '../../common/Form/Form';
import Grid from '../../common/Grid/Grid';
import Width from '../../common/Grid/Width';
import SelectField, { SingleOption } from '../../common/Form/SelectField';
import {
	Account,
	ACCOUNT_CATEGORY_OPTIONS,
	ACCOUNT_CATEGORY_TYPES, AccountCategory,
	ASSET_ACCOUNT,
	LIABILITY_ACCOUNT,
} from '../../../domain/accounts/Account';
import { getUUID } from '../../../domain';
import { AdvancedModal } from '../../common/Modal';
import { STATUS_CLEARED, Transaction } from '../../../domain/transactions/Transaction';
import {INCOME_CATEGORY_ID_THIS_MONTH} from '../../../domain/categories';
import { useDispatch } from 'react-redux';
import { createAccount } from '../../store/accounts/actions/accounts';
import { AppDispatch } from '../../store/types';
import createTransaction from '../../../domain/transactions/operations/createTransaction';
import NativeDatePicker from '../../common/Form/NativeDatePicker';
import './add-account-form.less';
import { Profile } from '../../../domain/profiles';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { Button } from '../../common/Button/Button';
import validationSchema from './validationSchema';

type Option = SingleOption<AccountCategory>;

type FormValues = {
	accountName: string;
	accountBalance: number;
	accountBalanceDate: NaiveDate;
	accountCategory: Option|undefined;
	profileUUID: Profile['uuid'];
};

type Props = {
	modalOpen: boolean;
	onModalClose: () => void;
};

const AddAccountFormModal = (props: Props) => {
	const activeProfile = useRequiredActiveProfile();
	const initialValues: FormValues = {
		accountName: '',
		accountBalance: 0,
		accountBalanceDate: NaiveDate.fromDate(new Date()),
		accountCategory: undefined,
		profileUUID: activeProfile.uuid,
	};
	const dispatch = useDispatch<AppDispatch>();

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(values, formik) => {
				const account = getAccountFromForm(values, activeProfile);
				const transaction = getInitialTransactionFromForm(account, values);
				return dispatch(createAccount(account, transaction)).then(() => {
					formik.resetForm();
					props.onModalClose();
				});
			}}
			validateOnMount={true}
			validationSchema={validationSchema}
			enableReinitialize={true}
		>
			{({ setFieldValue, handleSubmit, isValid }) => (
				<AdvancedModal
					actions={
						<>
							<Button onClick={props.onModalClose} label={'Cancel'} />
							<Button
								onClick={handleSubmit}
								label={'Save'}
								type={'success'}
								disabled={!isValid}
							/>
						</>
					}
					title={'Add Account'}
					open={props.modalOpen}
					onClose={props.onModalClose}
				>
					<Form name={'add-account-form'}>
						<Grid>
							<Width columns={16}>
								<Field
									type="text"
									name="accountName"
									as={TextField}
									label={'Account name'}
									required={true}
								/>
							</Width>
							<Width columns={16}>
								<Field
									className={'add-account-form__select'}
									type="text"
									name="accountCategory"
									as={SelectField}
									label={'Account type'}
									placeholder={'Choose an account type...'}
									options={ACCOUNT_CATEGORY_OPTIONS}
									onChange={(newOption: Option) =>
										setFieldValue('accountCategory', newOption)
									}
								/>
							</Width>
							<Width columns={8}>
								<Field
									type="number"
									name="accountBalance"
									as={TextField}
									label={'Account balance'}
								/>
							</Width>
							<Width columns={8}>
								<Field
									type="text"
									name="accountBalanceDate"
									as={NativeDatePicker}
									label={'Account balance date'}
									onChange={(newDate: NaiveDate) =>
										setFieldValue('accountBalanceDate', newDate)
									}
								/>
							</Width>
						</Grid>
					</Form>
				</AdvancedModal>
			)}
		</Formik>
	);
};

export default AddAccountFormModal;

const getAccountFromForm = (values: FormValues, activeProfile: Profile): Account => {
	if (!values.accountCategory) {
		throw new Error("No category selected");
	}

	const type = ACCOUNT_CATEGORY_TYPES.get(values.accountCategory.value);
	if (!type) {
		throw new Error('Account category does not match a valid account type');
	}

	return {
		uuid: getUUID(),
		profileUUID: activeProfile.uuid,
		name: values.accountName,
		type: type,
		category: values.accountCategory.value,
		closed: false,
		isOffBudget: false,
	};
};

const getInitialTransactionFromForm = (account: Account, values: FormValues): Transaction => {
	return createTransaction({
		date: values.accountBalanceDate,
		accountUUID: account.uuid,
		profileUUID: values.profileUUID,
		categoryId: [INCOME_CATEGORY_ID_THIS_MONTH],
		inflow: account.type === ASSET_ACCOUNT ? values.accountBalance : 0,
		outflow: account.type === LIABILITY_ACCOUNT ? values.accountBalance : 0,
		note: 'Initial account balance',
		status: STATUS_CLEARED,
	});
};
