import { Transaction } from '../Transaction';
import { Account } from '../../accounts/Account';
import isTransfer from './isTransfer';
import isTransferFromAccount from './isTransferFromAccount';

const getStatusInAccount = (transaction: Transaction, accountUUID: Account['uuid']|null = null) => {
	if (!accountUUID) {
		return transaction.status;
	}

	if (!isTransfer(transaction)  || isTransferFromAccount(transaction, accountUUID)) {
		return transaction.status;
	}

	// is a transfer to accountUUID
	return transaction.transferToAccountStatus
}

export default getStatusInAccount;