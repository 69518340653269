import {useFilters, useRowSelect, useSortBy, useTable} from "react-table";
import {useMemo} from "react";
import {COL_ID_DATE, useTableColumns} from "view/accounts/TransactionsTable/useTableColumns/useTableColumns";
import {Transaction} from "domain/transactions/Transaction";
import {Account} from "domain/accounts/Account";
import useDefaultTableFilters from "view/accounts/TransactionsTable/useDefaultTableFilters";

const useTransactionsTable = (transactions: Transaction[], accountUUID: Account['uuid']) => {
    const columns = useTableColumns(accountUUID);
    const defaultSort = useMemo(() => [{ id: COL_ID_DATE, desc: false }], []);
    const initialDateFilter = useDefaultTableFilters();
    const initialState = useMemo(
        () => ({
            sortBy: defaultSort,
            filters: initialDateFilter,
        }),
        [defaultSort, initialDateFilter]
    );

    return useTable(
        {
            columns,
            data: transactions,
            autoResetSortBy: false,
            initialState: initialState,
            autoResetFilters: false
        },
        useFilters,
        useSortBy,
        useRowSelect
    );
}

export default useTransactionsTable;