import {
	DataChannelProvider,
	PeerAnswerEventType,
	PeerOfferEventType,
} from './SignallingServerSession';
import SignallingPeer from './SignallingPeer';

export default class Receiver extends SignallingPeer implements DataChannelProvider {
	private removeOfferListener: (() => void | undefined) | undefined;

	public async getDataChannel(): Promise<RTCDataChannel> {
		this.listenForOffer();
		return new Promise<RTCDataChannel>((resolve, reject) => {
			this.peerConnection.ondatachannel = dataChannelEvent => {
				dataChannelEvent.channel.addEventListener('open', event => {
					console.log('Receiver has data channel', dataChannelEvent.channel);
					resolve(dataChannelEvent.channel);
					this.socket.send({
						// @ts-ignore
						type: 'signallingComplete',
					});
				});
			};
		});
	}

	protected afterCleanup() {
		this.peerConnection.ondatachannel = null;
		if (this.removeOfferListener) this.removeOfferListener();
	}

	private async listenForOffer() {
		this.removeOfferListener = this.socket.onMessage(async message => {
			// @ts-ignore
			if (message.type === PeerOfferEventType) {
				console.log('Receiver offer from initiator.');
				await this.peerConnection.setRemoteDescription(
					// @ts-ignore
					new RTCSessionDescription(message.payload)
				);
				const answer = await this.peerConnection.createAnswer();
				await this.peerConnection.setLocalDescription(answer);
				this.socket.send({
					// @ts-ignore
					type: PeerAnswerEventType,
					payload: answer,
				});
				this.sendLocalICECandidates();
			}
		});
	}
}
