import {CategoryBudget} from "../../budget";

const setAmount = (categoryBudget: CategoryBudget, amount: number) => {
    if (Number.isNaN(amount)) {
        throw new Error("Cannot set category budget to NaN");
    }
    return {
        ...categoryBudget,
        amount
    }
}

export default setAmount;