import {MasterCategory} from "../../../../domain/categories";
import {useMemo} from "react";
import {createMasterCategoryBalanceSelector} from "../selectors/monthBudget";
import {useSelector} from "react-redux";
import {Month} from "../../../../domain/util/date";

const useMasterCategoryBalance = (month: Month, uuid: MasterCategory['uuid']) => {
    const balanceSelector = useMemo(() => {
        return createMasterCategoryBalanceSelector(month.year, month.month, uuid);
    }, [month.year, month.month, uuid]);
    return useSelector(balanceSelector);
}

export default useMasterCategoryBalance;