import {MasterCategory} from "../../../../domain/categories";
import {useMemo} from "react";
import {
	createMasterCategoryOutflowSelector,
} from '../selectors/monthBudget';
import {useSelector} from "react-redux";
import {Month} from "../../../../domain/util/date";

const useMasterCategoryOutflows = (month: Month, uuid: MasterCategory['uuid']) => {
	const outflowsSelector = useMemo(() => {
		return createMasterCategoryOutflowSelector(month.year, month.month, uuid);
	}, [month.year, month.month, uuid]);
	return useSelector(outflowsSelector);
}

export default useMasterCategoryOutflows;