import isLoggedIn from "../../domain/auth/isLoggedIn";
import DolleroWebSocket from "./DolleroWebSocket";
import getCurrentSyncDeviceUUID from "../../domain/sync/getCurrentSyncDeviceUUID";

const backOffTimeStart = 5000;
const maxConnectionAttemptInterval = 1000 * 60 * 20;
const maxRetries = 8;

let keepAliveInProgress = false;

const webSocketKeepAlive = async (retries = 0, backOffTime = backOffTimeStart) => {
    console.log(`Websocket keep alive. Retry:${retries} BackOffTime:${backOffTime}`)
    const loggedIn = await isLoggedIn();
    const syncUUID = await getCurrentSyncDeviceUUID()

    if (retries >= maxRetries) return cancelKeepAlive();
    if (!loggedIn) return cancelKeepAlive();
    if (!syncUUID) return cancelKeepAlive();
    if (DolleroWebSocket.isConnected()) return cancelKeepAlive();
    DolleroWebSocket.onError((errorEvent) => {
        if (errorEvent.errorType === 'unauthorized') cancelKeepAlive();
    })

    keepAliveInProgress = true;
    retries ++;
    DolleroWebSocket.connect(syncUUID)

    backOffTime = backOffTime * 2.5;
    if (backOffTime > maxConnectionAttemptInterval) {
        setTimeout(webSocketKeepAlive.bind(null, retries, backOffTime), maxConnectionAttemptInterval)
    } else {
        setTimeout(webSocketKeepAlive.bind(null, retries, backOffTime), backOffTime)
    }
}

export const canStartKeepAliveProcess = () => keepAliveInProgress === false;

export default webSocketKeepAlive;

const cancelKeepAlive = () => {
    keepAliveInProgress = false;
}