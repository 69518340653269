import { addMasterCategory } from '../../store/categories/actions/masterCategories';
import React, { useState } from 'react';
import { uuid } from '../../../domain/util/uuid';
import { useDispatch } from 'react-redux';
import { AdvancedModal, SIZE_TINY } from '../../common/Modal';
import Width from '../../common/Grid/Width';
import TextField from '../../common/Form/TextField';
import Grid from '../../common/Grid/Grid';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import { Button } from '../../common/Button/Button';
import { Icon } from 'semantic-ui-react';

type Props = {
	modalOpen: boolean;
	onModalClose: () => void;
};

export const AddMasterCategoryModal = (props: Props) => {
	const [newMasterCategoryName, setNewMasterCategoryName] = useState('');
	const dispatch = useDispatch();
	const activeProfile = useRequiredActiveProfile();

	const onPrimaryClick = () => {
		dispatch(
			addMasterCategory({
				uuid: uuid(),
				name: newMasterCategoryName,
				hidden: false,
				profileUUID: activeProfile.uuid,
			})
		);
		setNewMasterCategoryName('');
		props.onModalClose();
	};

	return (
		<AdvancedModal
			open={props.modalOpen}
			onClose={props.onModalClose}
			size={SIZE_TINY}
			title={'Add Master Category'}
			actions={
				<>
					<>
						<Button onClick={props.onModalClose}>
							<Icon name="remove" /> Cancel
						</Button>
						<Button
							type={'success'}
							onClick={onPrimaryClick}
							disabled={newMasterCategoryName === ''}
						>
							Save
						</Button>
					</>
				</>
			}
		>
			<Grid>
				<Width columns={16}>
					<TextField
						type="text"
						name="master-category-name"
						placeholder={'Master category name'}
						value={newMasterCategoryName}
						onChange={e => {
							setNewMasterCategoryName(e.target.value);
						}}
					/>
				</Width>
			</Grid>
		</AdvancedModal>
	);
};

export default AddMasterCategoryModal;
