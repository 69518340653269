import { Month } from '../../../../domain/util/date';
import { useMemo } from 'react';
import { createMonthTotalOutflowsSelector } from '../selectors/monthBudget';
import { useSelector } from 'react-redux';

const useMonthTotalOutflows = (month: Month) => {
	const outflowsSelector = useMemo(() => {
		return createMonthTotalOutflowsSelector(month.year, month.month);
	}, [month.year, month.month]);
	return useSelector(outflowsSelector);
}

export default useMonthTotalOutflows;