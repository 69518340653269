import { Transaction } from '../Transaction';
import { SubCategory } from '../../categories';
import { Account } from '../../accounts/Account';
import isTransfer from "./isTransfer";

const getCategoryTotal = (
	transaction: Transaction,
	subCategoryUUID: SubCategory['uuid'],
	transactionAccount: Account
) => {
	if (transaction.accountUUID !== transactionAccount.uuid) {
		throw new Error("Invalid transactionAccount given.")
	}

	if (!transaction.categoryId.includes(subCategoryUUID)) {
		return 0;
	}

	if (isTransfer(transaction) && transactionAccount?.isOffBudget) {
		return transaction.outflow;
	}

	if (transaction.splits.length === 0) {
		return transaction.inflow - transaction.outflow;
	}

	return transaction.splits.reduce((acc, split) => {
		if (split.categoryUUID === subCategoryUUID) {
			return acc + split.inflow - split.outflow;
		}
		return acc;
	}, 0);
};

export default getCategoryTotal;

export const getCategoriesOutflows = (
	transaction: Transaction,
	subCategoryUUIDs: Array<SubCategory['uuid']>,
	transactionAccount: Account
) => {
	return subCategoryUUIDs.reduce((acc, uuid) => {
		return acc + getCategoryOutflows(transaction, uuid, transactionAccount)
	}, 0)
}

export const getCategoryOutflows = (
	transaction: Transaction,
	subCategoryUUID: SubCategory['uuid'],
	transactionAccount: Account
) => {
	if (transaction.accountUUID !== transactionAccount.uuid) {
		throw new Error("Invalid transactionAccount given.")
	}

	if (!transaction.categoryId.includes(subCategoryUUID)) {
		return 0;
	}

	if (isTransfer(transaction) && transactionAccount?.isOffBudget) {
		return transaction.outflow;
	}

	if (transaction.splits.length === 0) {
		return transaction.outflow;
	}

	return transaction.splits.reduce((acc, split) => {
		if (split.categoryUUID === subCategoryUUID) {
			return acc + split.outflow;
		}
		return acc;
	}, 0);
}

export const getCategoriesInflows = (
	transaction: Transaction,
	subCategoryUUIDs: Array<SubCategory['uuid']>,
	transactionAccount: Account
) => {
	return subCategoryUUIDs.reduce((acc, uuid) => {
		return acc + getCategoryInflows(transaction, uuid, transactionAccount)
	}, 0)
}

export const getCategoryInflows = (
	transaction: Transaction,
	subCategoryUUID: SubCategory['uuid'],
	transactionAccount: Account
) => {
	if (transaction.accountUUID !== transactionAccount.uuid) {
		throw new Error("Invalid transactionAccount given.")
	}

	if (!transaction.categoryId.includes(subCategoryUUID)) {
		return 0;
	}

	if (isTransfer(transaction) && transactionAccount?.isOffBudget) {
		return transaction.outflow;
	}

	if (transaction.splits.length === 0) {
		return transaction.inflow;
	}

	return transaction.splits.reduce((acc, split) => {
		if (split.categoryUUID === subCategoryUUID) {
			return acc + split.inflow;
		}
		return acc;
	}, 0);
};