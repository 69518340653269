import { Account, ASSET_ACCOUNT, LIABILITY_ACCOUNT } from '../../../domain/accounts/Account';
import useAccountOptions, { AccountOption, getOptionByAccount } from '../../store/accounts/hooks/useAccountOptions';
import { useDispatch } from 'react-redux';
import useRequiredActiveProfile from '../../store/profiles/hooks/useRequiredActiveProfile';
import useAccountBalance from '../../store/accounts/hooks/useAccountBalance';
import { Field, Formik } from 'formik';
import { closeAccount } from '../../store/accounts/actions';
import Modal from '../../common/Modal';
import SelectField from '../../common/Form/SelectField';
import Grid from '../../common/Grid/Grid';
import Width from '../../common/Grid/Width';
import { Profile } from '../../../domain/profiles';
import createTransaction from '../../../domain/transactions/operations/createTransaction';
import NaiveDate from '../../../domain/util/NaiveDate';
import { TRANSFER_CATEGORY_ID } from '../../../domain/categories';
import { STATUS_UNCLEARED } from '../../../domain/transactions/Transaction';
import React from 'react';
import {
	formatMoneyForDisplay,
	greaterThanZero,
	isZero, lessThanZero,
	Money,
	toLegacy,
} from '../../../domain/util/money';

type ModalProps = {
	isOpen: boolean;
	account: Account;
	onClose: () => void;
};

type CloseModalFormValues = {
	accountBeingClosed: AccountOption;
	transferAccount: AccountOption;
};

const CloseAccountModal = (props: ModalProps) => {
	const dispatch = useDispatch();
	const activeProfile = useRequiredActiveProfile();
	const accountBalance = useAccountBalance(props.account.uuid);
	const accountOptions = useAccountOptions(true);
	const defaultOption = getOptionByAccount(props.account.uuid, accountOptions);

	return (
		<Formik
			initialValues={{
				accountBeingClosed: defaultOption,
				transferAccount: { label: 'Choose an account...', value: '0' },
			}}
			enableReinitialize={true}
			onSubmit={(values: CloseModalFormValues, formikHelpers) => {
				const transaction =
					!isZero(accountBalance) ? getTransaction(values, props.account, accountBalance, activeProfile) : null;
				console.log(values, transaction);
				dispatch(closeAccount(props.account, transaction));
				formikHelpers.resetForm();
				props.onClose();
			}}
		>
			{({
				  values,
				  errors,
				  touched,
				  setFieldValue,
				  handleBlur,
				  handleSubmit,
				  resetForm,
			  }) => (
				<Modal
					title={`Close ${props.account.name}`}
					open={props.isOpen}
					onClose={() => {
						props.onClose();
						resetForm();
					}}
					onPrimaryClick={handleSubmit}
				>
					<CloseAccountTransactionForm
						account={props.account}
						accountBalance={accountBalance}
						setFieldValue={setFieldValue}
					/>
				</Modal>
			)}
		</Formik>
	);
};

export default CloseAccountModal;

type CloseAccountTransactionFormProps = {
	account: Account;
	accountBalance: Money;
	setFieldValue: (fieldName: string, value: AccountOption) => void;
};

const CloseAccountTransactionForm = (props: CloseAccountTransactionFormProps) => {
	const accountOptions = useAccountOptions();
	const sourceAccountField = (
		<Field
			type="text"
			name="accountBeingClosed"
			as={SelectField}
			label={'Account to close'}
			options={accountOptions}
			onChange={(newOption: AccountOption) =>
				props.setFieldValue('accountBeingClosed', newOption)
			}
			disabled={true}
		/>
	);

	const targetAccountField = (
		<Field
			type="text"
			name="transferAccount"
			as={SelectField}
			label={`Transfer ${props.account.type === ASSET_ACCOUNT ? `to` : `from`} account`}
			options={accountOptions.filter(opt => opt.value !== props.account.uuid)}
			onChange={(newOption: AccountOption) =>
				props.setFieldValue('transferAccount', newOption)
			}
			disabled={false}
		/>
	);

	const message = getCloseAccountMessage(props.account, props.accountBalance);

	return (
		<>
			{message}
			{ !isZero(props.accountBalance) && <Grid>
				<Width columns={8}>
					{greaterThanZero(props.accountBalance) &&
						props.account.type === ASSET_ACCOUNT &&
						sourceAccountField}
					{lessThanZero(props.accountBalance) &&
						props.account.type === LIABILITY_ACCOUNT &&
						targetAccountField}
				</Width>
				<Width columns={8}>
					{greaterThanZero(props.accountBalance) &&
						props.account.type === ASSET_ACCOUNT &&
						targetAccountField}
					{lessThanZero(props.accountBalance) &&
						props.account.type === LIABILITY_ACCOUNT &&
						sourceAccountField}
				</Width>
			</Grid>}

		</>
	);
};

const getTransaction = (
	formValues: CloseModalFormValues,
	account: Account,
	accountBalance: Money,
	profile: Profile
) => {
	if (account.type === ASSET_ACCOUNT) {
		return createTransaction({
			date: NaiveDate.fromDate(new Date()),
			categoryId: [TRANSFER_CATEGORY_ID],
			accountUUID: formValues.accountBeingClosed.value,
			transferToAccountUUID: formValues.transferAccount.value,
			transferToAccountStatus: STATUS_UNCLEARED,
			profileUUID: profile.uuid,
			inflow: 0,
			outflow: toLegacy(accountBalance),
		})
	}

	return createTransaction({
		date: NaiveDate.fromDate(new Date()),
		categoryId: [TRANSFER_CATEGORY_ID],
		accountUUID: formValues.transferAccount.value,
		transferToAccountUUID: formValues.accountBeingClosed.value,
		transferToAccountStatus: STATUS_UNCLEARED,
		profileUUID: profile.uuid,
		inflow: 0,
		outflow: toLegacy(accountBalance) * -1,
	})
};

const getCloseAccountMessage = (account: Account, accountBalance: Money) => {
	if (isZero(accountBalance)) {
		return `Are you sure you want to close this account?`
	}

	return account.type === ASSET_ACCOUNT
		? `You must transfer the account balance of ${formatMoneyForDisplay(
			accountBalance
		)} to another account`
		: `You must cover the account's balance of ${formatMoneyForDisplay(
			accountBalance
		)} from another account`;
}