import {StoreActions} from "../../types";
import * as AuthActions from '../actions';

export const loginModalOpen = (state: boolean = false, action: StoreActions) => {
    switch (action.type) {
        case AuthActions.OPEN_LOGIN_MODAL:
            return true;
        case AuthActions.CLOSE_LOGIN_MODAL:
            return false;
        default:
            return state;
    }
}