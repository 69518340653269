import getStunOnlySetting from './getStunOnlySetting';
import getDeviceIceServers from '../api/getDeviceIceServers';
import getCurrentSyncDeviceUUID from '../../../domain/sync/getCurrentSyncDeviceUUID';

const getIceServers = async () => {
	const stunOnly = await getStunOnlySetting();
	if (stunOnly) {
		console.log('WebRTC with STUN servers ONLY.')
		return [
			{'urls': 'stun:stun.l.google.com:19302'},
		]
	}
	const deviceUUID = await getCurrentSyncDeviceUUID();
	if (!deviceUUID) throw new Error('Unable to retrieve current device UUID');

	return await getDeviceIceServers(deviceUUID)
}

export default getIceServers;