import { SIZE_TINY } from '../../common/Modal';
import { Button } from '../../common/Button/Button';
import { Icon } from 'semantic-ui-react';
import React, { useState } from 'react';
import AdvancedModal from '../../common/Modal/AdvancedModal';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkboxInstance, isUpdatePromptModalOpen } from '../../store/app/selectors/updatePrompt';
import { closeUpdatePromptModal } from '../../store/app/actions/updatePrompt';

const PromptForUpdateModal = () => {
	const dispatch = useDispatch();
	const modalOpen = useSelector(isUpdatePromptModalOpen);
	const onClose = () => dispatch(closeUpdatePromptModal());
	const [updating, setUpdating] = useState(false)
	const workboxInstance = useSelector(getWorkboxInstance);
	if (!workboxInstance) {
		return null;
	}

	const onUpdate = async () => {
		setUpdating(true);
		const keys = await caches.keys()
		await Promise.all(keys.map(cacheName => caches.delete(cacheName)));
		workboxInstance.messageSkipWaiting();
	}

	return <AdvancedModal
		title={'A new version of Dollero is available'}
		open={modalOpen}
		size={SIZE_TINY}
		onClose={() => {}}
		actions={
			<>
				<Button onClick={onClose}>
					<Icon name="remove" /> Cancel
				</Button>
				<Button
					type={'success'}
					onClick={onUpdate}
					loading={updating}
				>
					<Icon name="checkmark" /> Update now
				</Button>
			</>
		}
	>
		Updating now will refresh Dollero
	</AdvancedModal>
}

export default PromptForUpdateModal;