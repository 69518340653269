import {DOLLERO_ACCOUNT_URL} from "../../../config";
import getAuthorizationHeader from "./auth/getAuthorizationHeader";
import {SyncDevice} from "../../../domain/sync/SyncDevice";

const deleteDevice = async (deviceUUID: SyncDevice['uuid']) => {
    return fetch(`${DOLLERO_ACCOUNT_URL}api/devices/${deviceUUID}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: await getAuthorizationHeader()
        },
        credentials: "include"
    })
        .then(response => response.json())
        .then(result => {
            console.log('Success:', result);
            return result;
        })
        .catch(error => {
            console.error('Error:', error);
            return false;
        });
}

export default deleteDevice;