import React from 'react';
import NaiveDate from '../../../domain/util/NaiveDate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './date-picker.less';

type Props = {
	name?: string,
	onChange?: (date: NaiveDate | undefined) => void
	value?: NaiveDate,
	className?: string,
	label?: string,
	disabled?: boolean,
};

const DatePickerField = (props: Props) => {
	return (
		<div>
			{props.label && <label>{props.label}</label>}
			<div className="date-picker">
				<DatePicker
					name={props.name}
					value={props.value ? props.value.toString() : undefined}
					disabled={props.disabled}
					onChange={(newDate: Date | Date[] | null) => {
						if (newDate === null) return (props.onChange && props.onChange(undefined));
					    const jsDate = Array.isArray(newDate) ? newDate[0] : newDate;
						const naiveDate = NaiveDate.fromDate(jsDate);
                        (props.onChange && props.onChange(naiveDate))
					}}
					className={`date-picker__input`}
				/>
			</div>
		</div>
	);
};

export default DatePickerField;
