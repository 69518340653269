import { BudgetRow } from '../YNAB';
import papaparse from 'papaparse';
import { BudgetRowRaw } from './index';
import camelCase from 'lodash/camelCase';
import { getCurrencyAmount } from '../YNAB/util';

const parseConfig = {
	header: true,
	skipEmptyLines: true,
	transformHeader: camelCase,
};

const parseBudgetRows = (budgetCsvText: string): BudgetRow[] => {
	const parsedBudgetData = papaparse.parse<BudgetRowRaw>(budgetCsvText, parseConfig);
	return parsedBudgetData.data.map(row => {
		return {
			month: row.month,
			category: row.category,
			masterCategory: row.masterCategory,
			subCategory: row.subCategory,
			budgeted: getCurrencyAmount(row.budgeted),
			outflows: getCurrencyAmount(row.outflows),
			categoryBalance: getCurrencyAmount(row.categoryBalance),
		};
	});
};

export default parseBudgetRows;
