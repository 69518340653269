import { useSelector } from 'react-redux';
import { getAllAccounts } from '../selectors/accounts';
import { Account } from '../../../../domain/accounts/Account';

export type AccountOption = {
	label: string;
	value: Account['uuid'];
};

export const DEFAULT_CHOOSE_ACCOUNT = { label: 'Choose an account...', value: '0' };

const useAccountOptions = (includeClosed = false): Array<AccountOption> => {
	const allAccounts = Array.from(useSelector(getAllAccounts).values());
	return allAccounts
		.filter(a => includeClosed ? true : !a.closed)
		.sort((a, b) => a.name < b.name ? -1 : 1)
		.map(a => {
		return {
			label: a.closed ? `${a.name} (closed)` : a.name,
			value: a.uuid,
		};
	});
};

export default useAccountOptions;

export const getOptionByAccount = (
	accountUUID: Account['uuid'],
	options: Array<AccountOption>
): AccountOption => {
	const option = options.find((ao: AccountOption) => ao.value === accountUUID);
	if (!option) {
		throw new Error(`Cannot find options for account uuid: ${accountUUID}`);
	}

	return option;
};

