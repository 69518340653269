import SyncDataChannel from "./SyncDataChannel";
import {AppSettingsRepo, SettingsKeys} from "../../domain/settings/IAppSettingsRepo";
import isDeviceConnected from "./api/getDeviceConnection";
import {SyncDevice} from "../../domain/sync/SyncDevice";
import DataChannelManager from "../web-rtc/data-channel/DataChannelManager";

//TODO: move this to its own file
class SyncChannelManager {

    private syncDataChannels: Map<SyncDevice['uuid'], SyncDataChannel>

    constructor() {
        this.syncDataChannels = new Map();
    }

    public async getSyncChannel(peerDeviceUUID: SyncDevice['uuid']) {
        const existingSyncChannel = this.syncDataChannels.get(peerDeviceUUID);
        if (existingSyncChannel) {
            if (!existingSyncChannel.isClosed()) return existingSyncChannel;

            this.syncDataChannels.delete(peerDeviceUUID);
        }

        const existingDataChannel = DataChannelManager.getDataChannel(peerDeviceUUID);
        if (existingDataChannel) {
            return this.createSyncChannel(peerDeviceUUID, existingDataChannel);
        }

        const dataChannel = await DataChannelManager.startDataChannel(peerDeviceUUID);
        return this.createSyncChannel(peerDeviceUUID, dataChannel);
    }

    private createSyncChannel(peerDeviceUUID: SyncDevice['uuid'], dataChannel: RTCDataChannel) {
        const syncDataChannel =  new SyncDataChannel(dataChannel);
        this.syncDataChannels.set(peerDeviceUUID, syncDataChannel)
        return syncDataChannel;
    }
}

const SyncChannelManagerSingleton = new SyncChannelManager();

const getDataChannel = async (peerDeviceUUID: SyncDevice['uuid']) => {
    console.log(`getDataChannel for connection to ${peerDeviceUUID}`)
    const syncUUID = await AppSettingsRepo.get<string>(SettingsKeys.syncUUID);
    if (!syncUUID) {
        throw new Error(`This device is not registered to sync and has no device UUID`);
    }
    const isPeerConnected = await isDeviceConnected(peerDeviceUUID)
    if (!isPeerConnected) {
        throw new Error('Cannot sync right now, peer is not connected');
    }

    return await SyncChannelManagerSingleton.getSyncChannel(peerDeviceUUID);
}

export default getDataChannel;
