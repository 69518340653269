import { Profile } from '../index';

const editProfile = (
	existingProfile: Profile,
	newData: Partial<Exclude<Profile, 'uuid'>>
): Profile => {
	return Object.assign({}, existingProfile, newData);
};

export default editProfile;
