import appDb from "../persistence/indexed-db";
import zip from 'lodash/zip';
import Dexie from "dexie";
import {UrlStatusMap} from "../../view/store/sync/actions/devices";

const getSyncStatuses = async () => {
    // @ts-ignore
    const syncURLs = await appDb.syncable.list()
    const deviceStatusPromises = syncURLs.map(url => {
        return appDb.syncable.getStatus(url).then(statusCode => Dexie.Syncable.StatusTexts[statusCode])
    })
    const deviceStatuses = await Promise.all(deviceStatusPromises);

    return new Map(zip(syncURLs, deviceStatuses)) as UrlStatusMap;
}

export default getSyncStatuses;