import {Accordion} from "semantic-ui-react";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getAllAccounts} from "../../store/accounts/selectors/accounts";
import {Account} from "../../../domain/accounts/Account";
import AccountLink from "./AccountLink";

const ClosedAccountsList = () => {
    const accounts = useSelector(getAllAccounts);
    const [showClosedAccounts, setShowClosedAccounts] = useState<boolean>(false);

    const closedAccounts = Array.from(accounts.values()).filter(ac => ac.closed);
    if (closedAccounts.length === 0) {
        return null;
    }

    const closedAccountsList = closedAccounts.map((account: Account) => (
        <li className={'accounts-nav__accounts-list-item'} key={account.uuid}>
            <AccountLink account={account}/>
        </li>
    ));

    return <Accordion
        as={'nav'}
        className={'accounts-nav__section accounts-nav__closed-accounts'}
    >
        <Accordion.Title
            active={showClosedAccounts}
            index={0}
            onClick={() => setShowClosedAccounts(!showClosedAccounts)}
            content={'Closed Accounts'}
            className={'accounts-nav__heading ui'}
            as={'h3'}
        />
        <Accordion.Content
            active={showClosedAccounts}
            content={closedAccountsList}
            as={'ul'}
        />
    </Accordion>
}

export default ClosedAccountsList;