import { Account, AccountType } from '../Account';
import { Money, negate } from '../../util/money';

/**
 * Dollero tracks balances of liability accounts like credit cards as a negative number.
 * However users typically see their credit card balances as a positive number on their statement.
 * We use this function to negate the balance for display in some scenarios (like reconciling).
 */
const adjustedReconciliationBalance = (account: Account, balance: Money) => {
	if (account.type === AccountType.LIABILITY_ACCOUNT) {
		return negate(balance)
	}
	return balance;
}

export default adjustedReconciliationBalance;