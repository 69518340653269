import TransactionsRepo from '../../../../infrastructure/persistence/indexed-db/transactions/TransactionsRepo';
import CategoryBudgetsRepo from '../../../../infrastructure/persistence/indexed-db/categories/CategoryBudgetsRepo';
import { Month } from '../../../util/date';
import { SubCategory } from '../../../categories';
import { CategoryBalances, CategoryMonthTotals } from '../../index';
import {Profile} from "../../../profiles";
import {Account} from "../../../accounts/Account";

const calculateCategoryMonthTotals = async (
	profileUUID: Profile['uuid'],
	subCategory: SubCategory,
	month: Month,
	accounts: Map<Account['uuid'], Account>,
	lastMonthsCategoryBalances?: CategoryBalances
) => {
	const categoryOutflowsAtMonth = TransactionsRepo.getCategoryOutflow(month, profileUUID, accounts, subCategory.uuid);

	const categoryBudgetForMonth = CategoryBudgetsRepo.getCategoryBudgetsForMonth(
		month,
		profileUUID,
		[subCategory.uuid]
	).then(categoryBudgets => {
		if (categoryBudgets.length === 1) {
			return categoryBudgets[0];
		}
	});

	const [outflowsAtMonth, categoryBudget] = await Promise.all([categoryOutflowsAtMonth, categoryBudgetForMonth]);
	const budgeted = categoryBudget ? categoryBudget.amount : 0;
	const outflows = outflowsAtMonth ? outflowsAtMonth : 0;
	const {
		isCarriedOver: lastMonthBalanceCarriesOver,
		balance: lastMonthsBalance,
	} = getLastMonthsBalance(subCategory, lastMonthsCategoryBalances);

	// category balance
	const categoryBalance =
		lastMonthsBalance > 0 || lastMonthBalanceCarriesOver
			? lastMonthsBalance + budgeted + outflows
			: budgeted + outflows;

	return {
		id: subCategory.uuid,
		balance: categoryBalance,
		outflows: outflows,
		budgeted: budgeted,
		balanceCarriesOver: categoryBudget ? categoryBudget.carryover : false,
	} as CategoryMonthTotals;
};

export default calculateCategoryMonthTotals;

const getLastMonthsBalance = (
	subCategory: SubCategory,
	lastMonthsCategoryBalances?: CategoryBalances
) => {
    if (!lastMonthsCategoryBalances) {
        return { balance: 0, isCarriedOver: false };
    }

	const lastMonthsBalanceForCategory = lastMonthsCategoryBalances.get(subCategory.uuid);
	if (!lastMonthsBalanceForCategory) {
		return { balance: 0, isCarriedOver: false };
	}

	return lastMonthsBalanceForCategory;
};
