import CategoryBudgetsRepo from '../../../../infrastructure/persistence/indexed-db/categories/CategoryBudgetsRepo';
import { MasterCategory } from '../../../../domain/categories';
import { AppThunkAction } from '../../types';
import { CategoryBudget } from '../../../../domain/budget';
import { requireActiveProfile } from '../../profiles/selectors';
import { Month } from '../../../../domain/util/date';

export type CategoryBudgetActions =
	| LoadCategoryBudgets
	| SetCategoryBudget
	| SetMasterCategoryBudgetTotal;

export const SET_CATEGORY_BUDGET = 'SET_CATEGORY_BUDGET';
export type SetCategoryBudget = {
	type: typeof SET_CATEGORY_BUDGET;
	payload: {
		categoryBudget: CategoryBudget;
	};
};

const setCategoryBudget = (categoryBudget: CategoryBudget): SetCategoryBudget => ({
	type: SET_CATEGORY_BUDGET,
	payload: {
		categoryBudget,
	},
});

export const updateCategoryBudget = (categoryBudget: CategoryBudget): AppThunkAction => {
	return (dispatch, getState) => {
		CategoryBudgetsRepo.put(categoryBudget).then(() => {
			dispatch(setCategoryBudget(categoryBudget));
		});
	};
};

export const LOAD_CATEGORY_BUDGETS = 'LOAD_CATEGORY_BUDGETS';
export type LoadCategoryBudgets = {
	type: typeof LOAD_CATEGORY_BUDGETS;
	payload: {
		categoryBudgets: Array<CategoryBudget>;
	};
};

export const loadCategoryBudgetsCreator = (
	categoryBudgets: Array<CategoryBudget>
): LoadCategoryBudgets => ({
	type: LOAD_CATEGORY_BUDGETS,
	payload: {
		categoryBudgets,
	},
});

export const SET_MASTER_CATEGORY_BUDGET_TOTAL = 'SET_MASTER_CATEGORY_BUDGET_TOTAL';
export type SetMasterCategoryBudgetTotal = {
	type: typeof SET_MASTER_CATEGORY_BUDGET_TOTAL;
	payload: {
		masterCategoryUUID: MasterCategory['uuid'];
		year: number;
		month: number;
		budgetAmount: number;
	};
};

export const loadCategoryBudgets = (month: Month): AppThunkAction => {
	return (dispatch, getState) => {
		const activeProfile = requireActiveProfile(getState());
		return CategoryBudgetsRepo.getCategoryBudgetsForMonth(month, activeProfile.uuid).then(
			categoryBudgets => {
				dispatch(loadCategoryBudgetsCreator(categoryBudgets));
			}
		);
	};
};
