import * as budgetActions from '../actions/budgets';
import { StoreActions } from '../../types';
import { CategoryBudget } from '../../../../domain/budget';

export type CategoryBudgetsState = Map<string, CategoryBudget>;
export const categoryBudgets = (state: CategoryBudgetsState = new Map(), action: StoreActions) => {
	switch (action.type) {
		case budgetActions.SET_CATEGORY_BUDGET:
			state.set(
				`${action.payload.categoryBudget.year}-${action.payload.categoryBudget.month}:${action.payload.categoryBudget.categoryId}`,
				action.payload.categoryBudget
			);
			return new Map(state.entries());
		case budgetActions.LOAD_CATEGORY_BUDGETS:
			action.payload.categoryBudgets.forEach(categoryBudget => {
				state.set(
					`${categoryBudget.year}-${categoryBudget.month}:${categoryBudget.categoryId}`,
					categoryBudget
				);
			});
			return new Map(state.entries());
		default:
			return state;
	}
};
