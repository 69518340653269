import {SyncDevice} from "./SyncDevice";

export const isChrome = (syncDevice: SyncDevice) => {
    const normalized = normalizeBrowserName(syncDevice);
    return normalized.includes('chrome') || normalized.includes('chromiurm');
}

export const isFirefox = (syncDevice: SyncDevice) => {
    const normalized = normalizeBrowserName(syncDevice);
    return normalized.includes('firefox') || normalized.includes('mozilla');
}

export const isSafari = (syncDevice: SyncDevice) => {
    return normalizeBrowserName(syncDevice).includes('safari');
}

export const isIE = (syncDevice: SyncDevice) => {
    return normalizeBrowserName(syncDevice).includes('is');
}

export const isEdge = (syncDevice: SyncDevice) => {
    return normalizeBrowserName(syncDevice).includes('edge');
}

const normalizeBrowserName = (syncDevice: SyncDevice) => {
    if (!syncDevice.browserName) return '';
    return  syncDevice.browserName.toLowerCase();
}