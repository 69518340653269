import useQueryParams from '../../../common/hooks/useQueryParams';
import { SubCategory } from '../../../../domain/categories';
import { useCallback, useMemo } from 'react';
import { ColumnInstance } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';

export const CATEGORY_UUID_QUERY = 'category_uuid';

export type CategoryFilterValue = SubCategory['uuid'][] | null | undefined;

type CategoryQueryFilters = {
	filterValue: CategoryFilterValue;
	setFilterValue: (subCategoryUUIDs: SubCategory['uuid'][] | undefined | null) => void;
	isShown: boolean;
	show: () => void;
	hide: () => void;
};

const useCategoryFilters = (categoryColumn: ColumnInstance<Transaction>|undefined): CategoryQueryFilters => {
	const query = useQueryParams();
	const filterValue = useCategoryFilterValue();

	const setFilterValue = useCallback(
		(subCategoryUUIDs: SubCategory['uuid'][] | undefined | null = undefined) => {
			if (!categoryColumn) {
				return;
			}
			categoryColumn.setFilter(subCategoryUUIDs);
			if (subCategoryUUIDs === undefined) {
				query.removeParam(CATEGORY_UUID_QUERY);
			} else {
				query.setParam(CATEGORY_UUID_QUERY, subCategoryUUIDs?.join(',') || '');
			}
		},
		[categoryColumn, query]
	);

	return useMemo(
		() => ({
			isShown: ( categoryColumn !== undefined) && (filterValue !== undefined),
			filterValue: filterValue,
			setFilterValue: setFilterValue,
			show: () => {
				if (filterValue === undefined) setFilterValue(null)
			},
			hide: () => {
				setFilterValue(undefined)
			}
		}),
		[setFilterValue, filterValue, categoryColumn]
	);
};

export const useCategoryFilterValue = () => {
	const query = useQueryParams();
	const categoryUUID = query.params.get(CATEGORY_UUID_QUERY);
	return useMemo(() => {
		if (categoryUUID === '') {
			return null;
		}
		return categoryUUID?.split(',') || undefined
	}, [categoryUUID]);
}

export default useCategoryFilters;
