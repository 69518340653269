import {
	MasterCategoryActions,
} from './masterCategories';
import {
	SubCategoryActions,
} from './subCategories';
import { CategoryBudgetActions } from './budgets';
import CategoriesRepo from '../../../../infrastructure/persistence/indexed-db/categories/CategoriesRepo';
import { AppThunkAction } from '../../types';
import { Profile } from '../../../../domain/profiles';
import { AddCategoryModalActions } from './addCategoryModal';
import { MasterCategory, SubCategory } from '../../../../domain/categories';

export type CategoryActions =
	| MasterCategoryActions
	| SubCategoryActions
	| CategoryBudgetActions
	| AddCategoryModalActions
	| ResetAllCategories;

export const loadAllCategories = (profileUUID: Profile['uuid']): AppThunkAction => {
	return async (dispatch, getState) => {
		const masterCatOrder = await CategoriesRepo.getMasterCategoryOrder(profileUUID);
		const subCatOrders = await CategoriesRepo.getSubCategoryOrder();
		const masterCats = await CategoriesRepo.getAllMasterCategories(profileUUID);
		const subCats = await CategoriesRepo.getAllSubCategories(profileUUID);
		dispatch(resetAllCategoryState(masterCatOrder, masterCats, subCatOrders, subCats));
	};
};

export const RESET_ALL_CATEGORIES = 'RESET_ALL_CATEGORIES';

type ResetAllCategories = {
	type: typeof RESET_ALL_CATEGORIES;
	payload: {
		masterCategoryOrder: MasterCategory['uuid'][],
		masterCategories: MasterCategory[],
		subCategoryOrder: Map<MasterCategory['uuid'], SubCategory['uuid'][]>,
		subCategories: SubCategory[]
	};
};

export const resetAllCategoryState = (
	masterCategoryOrder: MasterCategory['uuid'][],
	masterCategories: MasterCategory[],
	subCategoryOrder: Map<MasterCategory['uuid'], SubCategory['uuid'][]>,
	subCategories: SubCategory[]
): ResetAllCategories => ({
	type: RESET_ALL_CATEGORIES,
	payload: {
		masterCategoryOrder,
		masterCategories,
		subCategoryOrder,
		subCategories
	},
});
