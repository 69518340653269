import React from 'react';
import { FIELD_WIDTHS } from './index';
import './text-field.less';

type Props = {
	value: string;
	name: string;
	id?: string;
	type?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	placeholder?: string;
	label?: string;
	width?: FIELD_WIDTHS;
	required?: boolean;
	className?: string ;
};

const TextField = (props: Props) => {
	const htmlFor = props.id ? props.id : '';
	return (
		<div className={props.className}>
			{props.label && (
				<label className="text-field__label" htmlFor={htmlFor}>
					{props.label}
				</label>
			)}
			<input
				id={props.id}
				className={'text-field__input'}
				type={props.type || 'text'}
				name={props.name}
				placeholder={props.placeholder}
				value={props.value}
				onChange={e => props.onChange && props.onChange(e)}
				onBlur={e => props.onBlur && props.onBlur(e)}
				disabled={props.disabled}
				autoComplete={'off'}
				required={props.required}
			/>
		</div>
	);
};

export default TextField;
