import { FilterProps, Row, UseFiltersColumnProps } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import React, { useState } from 'react';
import NaiveDate from '../../../../domain/util/NaiveDate';
import NativeDatePicker from '../../../common/Form/NativeDatePicker';
import { COL_ID_DATE } from '../useTableColumns/useTableColumns';
import SelectField, { SingleOption } from '../../../common/Form/SelectField';
import { getLastMonth, getPastMonths } from '../../../../domain/util/date';
import './date-range-filter.less';
import useDateRangeQueryFilters from "./useDateRangeQueryFilters";

export enum DateFilterType {
	AllDates = 'ALL_DATES',
	ThisMonth = 'THIS_MONTH',
	LastMonth = 'LAST_MONTH',
	LastThreeMonths = 'LAST_THREE_MONTHS',
	LastYear = 'LAST_YEAR',
	CustomRange = 'CUSTOM_RANGE',
}

export const DATE_FILTER_TYPE_OPTIONS: SingleOption<DateFilterType>[] = [
	{ label: 'All Dates', value: DateFilterType.AllDates },
	{ label: 'This Month', value: DateFilterType.ThisMonth },
	{ label: 'Last Month', value: DateFilterType.LastMonth },
	{ label: 'Last 3 Month', value: DateFilterType.LastThreeMonths },
	{ label: 'Last Year', value: DateFilterType.LastYear },
	{ label: 'Custom Range', value: DateFilterType.CustomRange },
];

export type DateFilter = {
	fromDate: NaiveDate | undefined;
	toDate: NaiveDate | undefined;
	type: DateFilterType;
};

const DateRangeFilter = (props: FilterProps<Transaction> & UseFiltersColumnProps<Transaction>) => {
	const dateRangeQueryFilters = useDateRangeQueryFilters();
	const defaultFilterOption = DATE_FILTER_TYPE_OPTIONS
		.find(opt => opt.value === props.column.filterValue?.type) || DATE_FILTER_TYPE_OPTIONS[0];

	const [dateFilterType, setDateFilterType] = useState<SingleOption<string>>(defaultFilterOption);
	const dateFrom = props.column.filterValue?.fromDate;
	const dateTo = props.column.filterValue?.toDate;
	return (
		<div className={'date-range-filter'}>
			<div className={'date-range-filter__type'}>
				<label
					className={'date-range-filter__label date-range-filter__label--from'}
					htmlFor={'dateFilterOptions'}
				>
					Date
				</label>
				<div className={'date-range-filter__select'}>
					<SelectField
						value={dateFilterType}
						onChange={newValue => {
							if (!newValue) {
								return;
							}

							if (newValue.value !== DateFilterType.CustomRange) {
								const newDateRange = getDatesFromFilterOption(newValue.value);
								console.log(newDateRange);
								props.column.setFilter({
									fromDate: newDateRange[0],
									toDate: newDateRange[1],
									type: newValue.value,
								});
								dateRangeQueryFilters.setFilterValue({
									fromDate: newDateRange[0],
									toDate: newDateRange[1],
									// @ts-ignore
									type: newValue.value,
								});
							}

							dateRangeQueryFilters.setFilterValue({
								fromDate: dateRangeQueryFilters.filterValue.fromDate,
								toDate: dateRangeQueryFilters.filterValue.toDate,
								// @ts-ignore
								type: newValue.value,
							});
							setDateFilterType(newValue);
						}}
						name={'dateFilterOptions'}
						options={DATE_FILTER_TYPE_OPTIONS}
					/>
				</div>
			</div>
			{dateFilterType.value === 'CUSTOM_RANGE' && (
				<div className={'date-range-filter__custom'}>
					{/*<label*/}
					{/*	className={'date-range-filter__label date-range-filter__label--from'}*/}
					{/*	htmlFor={'date_from'}*/}
					{/*>*/}
					{/*	From*/}
					{/*</label>*/}
					<NativeDatePicker
						name={'date_from'}
						value={dateFrom}
						onChange={newDateFrom => {
							const newFilterValue = {
								fromDate: newDateFrom,
								toDate: dateTo,
								type: DateFilterType.CustomRange,
							};
							props.column.setFilter(newFilterValue);
							dateRangeQueryFilters.setFilterValue(newFilterValue);
						}}
						className={'date-range-filter__input date-range-filter__input--from'}
					/>
					<label
						className={'date-range-filter__label date-range-filter__label--to'}
						htmlFor={'date_to'}
					>
						to
					</label>
					<NativeDatePicker
						name={'date_to'}
						value={dateTo}
						onChange={newDateTo => {
							const newFilterValue = {
								fromDate: dateFrom,
								toDate: newDateTo,
								type: DateFilterType.CustomRange,
							};
							props.column.setFilter(newFilterValue);
							dateRangeQueryFilters.setFilterValue(newFilterValue);
						}}
						className={'date-range-filter__input date-range-filter__input--to'}
					/>
				</div>
			)}
		</div>
	);
};

export default DateRangeFilter;

export const getDatesFromFilterOption = (
	filterOption: string,
	fromDate: NaiveDate | undefined = undefined,
	toDate: NaiveDate | undefined = undefined
): Array<NaiveDate | undefined> => {
	const now = new Date();
	const currentMonth = { year: now.getFullYear(), month: now.getMonth() + 1 };
	switch (filterOption) {
		case DateFilterType.AllDates:
			return [undefined, undefined];

		case DateFilterType.ThisMonth:
			return [
				NaiveDate.fromYMD(now.getFullYear(), currentMonth.month, 1),
				NaiveDate.fromDate(new Date(now.getFullYear(), currentMonth.month, 0)),
			];

		case DateFilterType.LastMonth:
			return [
				NaiveDate.fromYMD(
					getLastMonth(currentMonth).year,
					getLastMonth(currentMonth).month,
					1
				),
				NaiveDate.fromDate(
					new Date(getLastMonth(currentMonth).year, getLastMonth(currentMonth).month, 0)
				),
			];

		case DateFilterType.LastThreeMonths:
			let lastThreeMonths = getPastMonths(currentMonth, 3);
			return [
				NaiveDate.fromYMD(lastThreeMonths[0].year, lastThreeMonths[0].month, 1),
				NaiveDate.fromDate(
					new Date(getLastMonth(currentMonth).year, getLastMonth(currentMonth).month, 0)
				),
			];

		case DateFilterType.LastYear:
			return [
				NaiveDate.fromYMD(now.getFullYear() - 1, 1, 1),
				NaiveDate.fromYMD(now.getFullYear() - 1, 12, 31),
			];

		case DateFilterType.CustomRange:
			return [
				fromDate,
				toDate
			];
		default:
			throw new Error('Unknown date filter option type');
	}
};

export const dateRangeFilterFn = (
	rows: Row<Transaction>[],
	id: string,
	filterValue: DateFilter
) => {
	return rows.filter(row => {
		const startFilter =
			!filterValue.fromDate || row.values[COL_ID_DATE] >= filterValue.fromDate.toJSDate();
		const endFilter =
			!filterValue.toDate || row.values[COL_ID_DATE] <= filterValue.toDate.toJSDate();
		return startFilter && endFilter;
	});
};
