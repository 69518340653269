import {SyncDevice} from "./SyncDevice";
import deleteAllSyncDevices from "../../infrastructure/sync/deleteAllSyncDevices";
import unregisterDevice from "./unregisterDevice";
import logOutUser from "../auth/logOutUser";
import disconnectSync from "../../infrastructure/sync/disconnectSync";
import deleteSyncDevice from "../../infrastructure/sync/deleteSyncDevice";
import getCurrentSyncDeviceUUID from "./getCurrentSyncDeviceUUID";

const handleDeletedDevice = async (deletedDeviceUUID: SyncDevice['uuid']) => {
    if (await deviceToDeleteIsSelf(deletedDeviceUUID)) {
        await deleteAllSyncDevices()
        await unregisterDevice()
        await logOutUser();
    } else {
        await disconnectSync(deletedDeviceUUID)
        deleteSyncDevice(deletedDeviceUUID);
    }
}

export default handleDeletedDevice;

const deviceToDeleteIsSelf = async (deviceUUIDToDelete: SyncDevice['uuid']) => {
    return await getCurrentSyncDeviceUUID() === deviceUUIDToDelete;
}

