import { IMonthBudgetsRepo } from '../../../../domain/budget/IMonthBudgetsRepo';
import { MonthBudget } from '../../../../domain/budget';
import AppDB from '../index';
import { Profile } from '../../../../domain/profiles';
import { Month } from '../../../../domain/util/date';
import Dexie from 'dexie';

class MonthBudgetsRepo implements IMonthBudgetsRepo {
	put(monthBudget: MonthBudget) {
		return AppDB.monthBudgets.put(monthBudget);
	}

	putMany(monthBudgets: MonthBudget[]) {
		return AppDB.monthBudgets.bulkPut(monthBudgets);
	}

	getMonthBudget(profileUUID: Profile['uuid'], month: Month) {
		return AppDB.monthBudgets
			.where('[profileUUID+month.year+month.month]')
			.equals([profileUUID, month.year, month.month])
			.first();
	}

	getAllMonthBudgets(profileUUID: Profile['uuid']) {
		return AppDB.monthBudgets
			.where('[profileUUID+month.year+month.month]')
			.between(
				[profileUUID, Dexie.minKey, Dexie.minKey],
				[profileUUID, Dexie.maxKey, Dexie.maxKey]
			)
			.toArray();
	}

	deleteAll(profileUUID: Profile['uuid']) {
		return AppDB.monthBudgets
			.where('[profileUUID+month.year+month.month]')
			.between(
				[profileUUID, Dexie.minKey, Dexie.minKey],
				[profileUUID, Dexie.maxKey, Dexie.maxKey]
			)
			.delete();
	}
}

export default new MonthBudgetsRepo();
