import {Month} from "../../../../domain/util/date";

export const CALCULATING_MONTH_BUDGETS = 'CALCULATING_MONTH_BUDGETS';

export type CalculatingMonthBudgets = {
    type: typeof CALCULATING_MONTH_BUDGETS,
    payload: {
        months: Month[]
    }
}

const calculatingMonthBudgets = (months: Month[]): CalculatingMonthBudgets => {
    return {
        type: CALCULATING_MONTH_BUDGETS,
        payload: {
            months
        }
    };
}

export default calculatingMonthBudgets;