import {SyncDevice} from "../../domain/sync/SyncDevice";
import AppDB from "../persistence/indexed-db";
import registerNewDevice from "./registerNewDevice";

const registerNewDevices = async (syncDevices: SyncDevice[], syncUUID: SyncDevice['uuid']) => {
    const syncables: SyncDevice['uuid'][] = await AppDB.syncable.list()
    const syncDevicesToRegister = syncDevices.filter((syncDevice) => {
        const notYetRegistered = !syncables.includes(syncDevice.uuid) && syncUUID !== syncDevice.uuid;
        return notYetRegistered && !syncDevice.deletedAt;
    })
    syncDevicesToRegister.forEach((syncDevice) => registerNewDevice(syncDevice))
}

export default registerNewDevices;