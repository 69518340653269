import React from 'react';
import { TransactionTag } from '../../../domain/transactions/TransactionTag';
import Dropdown from '../../common/Dropdown';
import useTransactionTags from '../hooks/useTransactionTags';
import { Transaction } from '../../../domain/transactions/Transaction';
import { editTransaction } from '../../store/accounts/actions/transactions';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import './tags-cell.less';
import { Icon } from 'semantic-ui-react';

type Props = {
	transaction: Transaction,
}

const TagsCell = (props: Props) => {
	const dispatch = useDispatch();
	const allTags = useTransactionTags();
	const options = allTags.map((t) => ({
		key: t.uuid,
		value: t.uuid,
		text: props.transaction.tags.includes(t.uuid) ? <Icon name={'check'}/> : "",
		label: { tag: true, content: t.label }
	}))

	const updateTransactionTags = (newTags: TransactionTag['uuid'][]) => {
		const updatedTransaction = Object.assign({}, props.transaction, { tags: newTags });
		dispatch(editTransaction(updatedTransaction, props.transaction))
	}

	const iconClasses = classNames({
		'tags-cell__icon': true,
		'tags-cell__icon--none': props.transaction.tags.length === 0,
	});

	const tagIcon = props.transaction.tags.length > 1 ? 'tags' : 'tag';

	return <Dropdown
		inline
		multiple
		icon={{name: tagIcon, className: iconClasses }}
		options={options}
		>
		<Dropdown.Menu>
			<Dropdown.Header content='Tags' />
			<Dropdown.Divider />
			{options.map((option) => (
				<Dropdown.Item {...option} selected={props.transaction.tags.includes(option.value)} onClick={() => {
					if (props.transaction.tags.includes(option.value)) {
						const newTags = props.transaction.tags.filter(t => t !== option.value)
						updateTransactionTags(newTags)
					} else {
						const newTags = [...props.transaction.tags, option.value]
						updateTransactionTags(newTags)
					}
				}}/>
			))}
		</Dropdown.Menu>
	</Dropdown>
}

export default TagsCell;