import {RootState} from "../../types";

export const getProfilesByUUID = (state: RootState) => {
    return state.profiles.byUUID;
}

export const getActiveProfile = (state: RootState) => {
    if (!state.profiles.activeProfile) {
        return null;
    }

    const profile = state.profiles.byUUID.get(state.profiles.activeProfile)
    if (!profile) {
        throw new Error(`Cannot find profile for active profile uuid: ${state.profiles.activeProfile}`)
    }

    return profile;
}

export const requireActiveProfile = (state: RootState) => {
    const profile = getActiveProfile(state);
    if (!profile) {
        throw new Error('No active profile');
    }
    return profile;
}

export const isLoadingProfile = (state: RootState) => state.profiles.isLoadingProfile;