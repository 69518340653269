import useSyncDevices, { SyncDeviceStatus } from '../../store/app/hooks/useSyncDevices';
import SyncDeviceCard from '../../sync/SyncDeviceCard/SyncDeviceCard';
import React from 'react';
import './sync-page.less';
import useIsLoggedIn from '../../store/auth/hooks/useIsLoggedIn';
import CurrentSyncDeviceCard from '../../sync/CurrentSyncDevice/CurrentSyncDeviceCard';
import useHasInternetConnection from '../../common/hooks/useHasInternetConnection';
import SyncDevicesEmptyState from './SyncDevicesEmptyState';

const SyncPage = () => {
	const isLoggedIn = useIsLoggedIn();
	const syncDeviceStatuses = useSyncDevices();
	const deletedDevices = grabDeleted(syncDeviceStatuses);
	const sortedDevices = grabSorted(syncDeviceStatuses);
	const hasInternet = useHasInternetConnection();

	if (!hasInternet) {
		return <div className={'sync-page__empty-state'}>
			<h4>Connect to the internet to view you sync devices</h4>
		</div>
	}

	if (!isLoggedIn) {
		return <div className={'sync-page__empty-state'}>
			<h4>Log in to view you sync devices</h4>
		</div>
	}

	if (sortedDevices.length === 0 && deletedDevices.length === 0) {
		return <SyncDevicesEmptyState/>
	}

	return (
		<div className={'sync-page'}>
			<h3>This Device </h3>
			<CurrentSyncDeviceCard/>
			<h3>Account Devices </h3>
			<div className={'sync-page__device-container'}>
				{sortedDevices.map(accountDevice => {
					return <SyncDeviceCard syncDevice={accountDevice} />
				})}
			</div>

			{ !!deletedDevices.length && <h3>Deleted Devices </h3> }
			{deletedDevices.map(accountDevice => {
				return <SyncDeviceCard syncDevice={accountDevice} />
			})}
		</div>
	);
};

const grabDeleted = (devices: SyncDeviceStatus[]) => {
	return devices.filter((d) => d.deletedAt);
}

const grabSorted = (devices: SyncDeviceStatus[]) => {
	return devices.filter((d) => !d.deletedAt)
		.sort((a, b) => {
			return a.syncStatus === 'ONLINE' ? -1 : 0;
		})
}

export default SyncPage;