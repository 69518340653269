import * as monthBudgetActions from "../actions/monthBudget";
import {StoreActions} from "../../types";
import { MonthBudget } from "../../../../domain/budget";
import {LOAD_MONTH_BUDGETS} from "../actions/loadMonthBudgets";

export type MonthBudgetState = Map<string, MonthBudget>;
export const monthBudget = (state: MonthBudgetState = new Map(), action: StoreActions) => {
    switch (action.type) {
        case monthBudgetActions.SET_MONTH_BUDGET:
            const { monthBudget } = action.payload;
            state.set(`${monthBudget.month.year}-${monthBudget.month.month}`, monthBudget);
            return new Map(state.entries());

        case LOAD_MONTH_BUDGETS:
            action.payload.monthBudgets.forEach((b) => {
                state.set(`${b.month.year}-${b.month.month}`, b);
            })
            return new Map(state.entries());

        default:
            return state;
    }
};