import {StoreActions} from "../../types";
import * as AuthActions from '../actions';

export const isLoggedIn = (state: boolean = false, action: StoreActions) => {
    switch (action.type) {
        case AuthActions.LOGIN_SUCCESS:
            return true;
        case AuthActions.LOGGED_IN:
            return true;
        case AuthActions.LOGGED_OUT:
            return false;
        default:
            return state;
    }
}