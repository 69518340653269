import { COL_ID_CATEGORY, COL_ID_DATE } from './useTableColumns/useTableColumns';
import useDateRangeQueryFilters from './TableFilters/useDateRangeQueryFilters';
import { useCategoryFilterValue } from './TableFilters/useCategoryFilters';
import { Filters } from 'react-table';
import { Transaction } from '../../../domain/transactions/Transaction';
import useOnce from "../../common/useOnce";

const useDefaultTableFilters = (): Filters<Transaction> => {
	const dateRangeQueryFilters = useDateRangeQueryFilters();
	const categoryFilterValue = useCategoryFilterValue();

	const defaults: Filters<Transaction> = [
		{
			id: COL_ID_DATE,
			value: dateRangeQueryFilters.filterValue,
		},
	];

	if (categoryFilterValue) {
		defaults.push({
			id: COL_ID_CATEGORY,
			value: categoryFilterValue,
		});
	}

	return useOnce(defaults);
};

export default useDefaultTableFilters;
