import React from "react";
import Card from "../../common/Card";
import {SyncDeviceStatus} from "../../store/app/hooks/useSyncDevices";
import SyncDeviceCardFooter from "./SyncDeviceCardFooter";
import SyncDeviceCardHeader from "./SyncDeviceCardHeader";
import './sync-device-card.less';
import SyncDeviceContent from "./SyncDeviceCardContent";

type Props = {
    syncDevice: SyncDeviceStatus
}

const SyncDeviceCard = ({ syncDevice }: Props) => {
    return <Card className={'sync-device-card'}>
        <SyncDeviceCardHeader syncDevice={syncDevice}/>
        <SyncDeviceContent syncDevice={syncDevice} />
        <SyncDeviceCardFooter syncDevice={syncDevice}/>
    </Card>
}

export default SyncDeviceCard;