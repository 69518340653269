import React from 'react';
import './profiles-list-page.less';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadActiveProfile } from '../../store/profiles/actions';
import { APP_LINKS } from '../../store/router/hooks/useAppRoutes';
import './profiles-list-page.less';
import { AppDispatch } from '../../store/types';
import useAllProfiles from '../../store/profiles/hooks/useAllPofiles';

const ProfilesListPage = () => {
	const profiles = useAllProfiles();
	const dispatch = useDispatch<AppDispatch>();
	const history = useHistory();

	return (
		<ul className={'profiles-list-page__list'}>
			<li>
				<Link to={APP_LINKS.add_profile()} className={'profiles-list-page__add-profile'}>
					+ Add a profile
				</Link>
			</li>
			{profiles.map(profile => {
				if (profile?.deleted === true) {
					return null;
				}

				return <li className={'profiles-list-page__list-item'} key={profile.uuid}>
					<a
						className={'profiles-list-page__list-link'}
						href={APP_LINKS.budget(profile.uuid)}
						onClick={e => {
							e.preventDefault();
							dispatch(loadActiveProfile(profile)).then(() => {
								history.push(APP_LINKS.budget(profile.uuid));
							});
						}}
					>
						{profile.name}
					</a>
				</li>
			})}
		</ul>
	);
};

export default ProfilesListPage;
