import {DOLLERO_ACCOUNT_URL} from "../../../config";
import logInUser from "../../../domain/auth/logInUser";

type LoginData = {
    email: string,
    password: string,
}

class LoginError extends Error{}

const login = async (loginData: LoginData) => {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(loginData.email + ":" + loginData.password));
    const response = await fetch(`${DOLLERO_ACCOUNT_URL}api/auth/login`, {
        method: 'POST',
        headers
    })

    if (response.status === 401) {
        throw new LoginError("Wrong email and password")
    }

    const { token, user } = await response.json();
    await logInUser(user, token);
    return {
        user: user,
        token: token
    }
}

export default login;