import {SyncDevice} from "../../../../domain/sync/SyncDevice";
import {AppDispatch, AppThunkAction} from "../../types";
import getDevices from "../../../../infrastructure/sync/api/getDevices";
import getSyncStatuses from "../../../../infrastructure/sync/getSyncStatuses";

export type SyncDeviceActions = LoadSyncDevices | SyncStatusChanged | LoadAccountDevices | FirstRoundSyncComplete  | IncrementItemsSynced;

export type UrlStatusMap = Map<SyncDevice['syncUrl'], string>;

export const LOAD_SYNC_DEVICES = 'LOAD_SYNC_DEVICES';
export type LoadSyncDevices = {
    type: typeof LOAD_SYNC_DEVICES;
    payload: {
        syncDevices: UrlStatusMap
    };
};

export const loadSyncDevices = (deviceURLs: UrlStatusMap): LoadSyncDevices => ({
    type: 'LOAD_SYNC_DEVICES',
    payload: {
        syncDevices: deviceURLs
    }
})

export const fetchSyncDevices = (): AppThunkAction => {
    return async (dispatch: AppDispatch) => {
        const syncStatus = await getSyncStatuses();
        dispatch(loadSyncDevices(syncStatus));
    }
}

export const SYNC_STATUS_CHANGED = 'SYNC_STATUS_CHANGED';
export type SyncStatusChanged = {
    type: typeof SYNC_STATUS_CHANGED;
    payload: {
        url: SyncDevice['syncUrl'],
        status: string,
    };
};

export const syncStatusChanged = (url: SyncDevice['syncUrl'], status: string): SyncStatusChanged => ({
    type: 'SYNC_STATUS_CHANGED',
    payload: {
        url,
        status
    }
})

export const LOAD_ACCOUNT_DEVICES = 'LOAD_ACCOUNT_DEVICES';
export type LoadAccountDevices = {
    type: typeof LOAD_ACCOUNT_DEVICES;
    payload: {
        syncDevices: SyncDevice[]
    };
};

export const loadAccountDevices = (syncDevices: SyncDevice[]): LoadAccountDevices => ({
    type: 'LOAD_ACCOUNT_DEVICES',
    payload: {
        syncDevices
    }
})

export const fetchAccountDevices = (): AppThunkAction<Promise<SyncDevice[]>> => {
    return async (dispatch: AppDispatch) => {
        const devices = await getDevices();
        dispatch(loadAccountDevices(devices));
        return devices;
    }
}

export const FIRST_ROUND_SYNC_COMPLETE = 'FIRST_ROUND_SYNC_COMPLETE';
export type FirstRoundSyncComplete = {
    type: typeof FIRST_ROUND_SYNC_COMPLETE;
    payload: {
        peerSyncDeviceUUID: SyncDevice['uuid']
    };
};

export const firstRoundSyncComplete = (peerSyncDeviceUUID: SyncDevice['uuid']): FirstRoundSyncComplete => ({
    type: 'FIRST_ROUND_SYNC_COMPLETE',
    payload: {
        peerSyncDeviceUUID
    }
})

export const INCREMENT_ITEMS_SYNCED = 'INCREMENT_ITEMS_SYNCED';
export type IncrementItemsSynced = {
    type: typeof INCREMENT_ITEMS_SYNCED;
    payload: {
        peerSyncDeviceUUID: SyncDevice['uuid'],
        incrementItemsSynced: number
    };
};

export const incrementItemsSynced = (peerSyncDeviceUUID: SyncDevice['uuid'], incrementItemsSynced: number): IncrementItemsSynced => ({
    type: 'INCREMENT_ITEMS_SYNCED',
    payload: {
        peerSyncDeviceUUID,
        incrementItemsSynced
    }
})