import useQueryParams from '../../../common/hooks/useQueryParams';
import { useCallback, useMemo } from 'react';
import { ColumnInstance } from 'react-table';
import { Transaction } from '../../../../domain/transactions/Transaction';
import { TransactionTag } from '../../../../domain/transactions/TransactionTag';

export const TAG_UUID_QUERY = 'tag_uuid';

export type FilterValue = TransactionTag['uuid'][] | null | undefined;

type TransactionTagFilters = {
	filterValue: FilterValue;
	setFilterValue: (tagUUIDs: TransactionTag['uuid'][] | undefined | null) => void;
	isShown: boolean;
	show: () => void;
	hide: () => void;
};

const useTransactionTagFilters = (tagColumn: ColumnInstance<Transaction>): TransactionTagFilters => {
	const query = useQueryParams();
	const filterValue = useTagFilterValue();

	const setFilterValue = useCallback(
		(tagUUIDs: TransactionTag['uuid'][] | undefined | null = undefined) => {
			if (!tagColumn) {
				return;
			}
			tagColumn.setFilter(tagUUIDs);
			if (tagUUIDs === undefined) {
				query.removeParam(TAG_UUID_QUERY);
			} else {
				query.setParam(TAG_UUID_QUERY, tagUUIDs?.join(',') || '');
			}
		},
		[tagColumn, query]
	);

	return useMemo(
		() => ({
			isShown: filterValue !== undefined,
			filterValue: filterValue,
			setFilterValue: setFilterValue,
			show: () => {
				if (filterValue === undefined) setFilterValue(null)
			},
			hide: () => {
				setFilterValue(undefined)
			}
		}),
		[setFilterValue, filterValue]
	);
};

export const useTagFilterValue = () => {
	const query = useQueryParams();
	const tagUUID = query.params.get(TAG_UUID_QUERY);
	return useMemo(() => {
		if (tagUUID === '') {
			return null;
		}
		return tagUUID?.split(',') || undefined
	}, [tagUUID]);
}

export default useTransactionTagFilters;
