import {SyncDevice} from "./SyncDevice";

export const isWindows = (syncDevice: SyncDevice) => {
    return normalizeOsName(syncDevice).includes('windows');
}

export const isMac = (syncDevice: SyncDevice) => {
    return normalizeOsName(syncDevice).includes('mac');
}

export const isLinux = (syncDevice: SyncDevice) => {
    const normalized = normalizeOsName(syncDevice);
    return normalized.includes('fedora')
        || normalized.includes('debian')
        || normalized.includes('ubuntu')
        || normalized.includes('linux')
        || normalized.includes('suse');
}

export const isAndroid = (syncDevice: SyncDevice) => {
    return normalizeOsName(syncDevice).includes('Android');
}

export const isIOS = (syncDevice: SyncDevice) => {
    return normalizeOsName(syncDevice).includes('ios');
}

const normalizeOsName = (syncDevice: SyncDevice) => {
    if (!syncDevice.osName) return '';
    return  syncDevice.osName.toLowerCase();
}