import { UnauthorizedError } from './errors';
import logOutUser from '../../domain/auth/logOutUser';
import ToastManager, { TYPES } from '../../view/common/Toast';

const handleUnauthorized = async (response: Response) => {
	if (response.status === 401) {
		await logOutUser();
		ToastManager.newToast('Your session has ended, please log in again.', { type: TYPES.INFO })
		throw new UnauthorizedError("Unauthorized")
	}
	return response;
}

export default handleUnauthorized;