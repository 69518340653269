import {AppSettingsRepo, SettingsKeys} from "../settings/IAppSettingsRepo";
import disconnectSync from "../../infrastructure/sync/disconnectSync";
import DolleroWebSocket from "../../infrastructure/web-socket/DolleroWebSocket";
import { dispatch } from '../../view/store/dispatch';
import { loggedOut } from '../../view/store/auth/actions';

const logOutUser = async () => {
    // Probably close the dollero web socket?
    await AppSettingsRepo.del(SettingsKeys.authToken);
    await AppSettingsRepo.del(SettingsKeys.authUser);
    await disconnectSync();
    DolleroWebSocket.disconnect();
    dispatch(loggedOut());
}

export default logOutUser;