import { Transaction } from '../../../../domain/transactions/Transaction';
import useLastTransactionCreated from './useLastTransactionCreated';

/**
 * Determines whether a transaction was created recently
 */
const useShouldHighlightTransaction = (currentTransaction: Transaction|null) => {
	const lastTransactionCreated = useLastTransactionCreated();
	const twoSecondAgo = Date.now() - 2000;
	const lastTransactionCreatedTime = lastTransactionCreated.timeCreated?.getTime() || 0;
	return lastTransactionCreated.transaction?.uuid === currentTransaction?.uuid && lastTransactionCreatedTime > twoSecondAgo
}

export default useShouldHighlightTransaction;