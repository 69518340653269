import { SingleOption } from '../../view/common/Form/SelectField';
import { Profile } from '../profiles';

export type Account = {
	uuid: string;
	profileUUID: Profile['uuid'];
	name: string;
	type: AccountType;
	category: AccountCategory;
	closed: boolean;
	isOffBudget: boolean;
};

// export type AccountType = ASSET_ACCOUNT | LIABILITY_ACCOUNT;

export const ASSET_ACCOUNT = 'ASSET_ACCOUNT';
export const LIABILITY_ACCOUNT = 'LIABILITY_ACCOUNT';

export enum AccountType {
	ASSET_ACCOUNT = 'ASSET_ACCOUNT',
	LIABILITY_ACCOUNT = 'LIABILITY_ACCOUNT',
}

export type AccountCategory =
	| typeof ACCOUNT_CASH
	| typeof ACCOUNT_CREDIT_CARD
	| typeof ACCOUNT_CHEQUING
	| typeof ACCOUNT_SAVINGS
	| typeof ACCOUNT_LOAN;

export const ACCOUNT_CASH = 'ACCOUNT_CASH';
export const ACCOUNT_CREDIT_CARD = 'ACCOUNT_CREDIT_CARD';
export const ACCOUNT_CHEQUING = 'ACCOUNT_CHEQUING';
export const ACCOUNT_SAVINGS = 'ACCOUNT_SAVINGS';
export const ACCOUNT_LOAN = 'ACCOUNT_LOAN';

type AccountCategoryTypes = Map<AccountCategory, AccountType>;
export const ACCOUNT_CATEGORY_TYPES = new Map([
	[ACCOUNT_CASH, ASSET_ACCOUNT],
	[ACCOUNT_CHEQUING, ASSET_ACCOUNT],
	[ACCOUNT_SAVINGS, ASSET_ACCOUNT],
	[ACCOUNT_LOAN, LIABILITY_ACCOUNT],
	[ACCOUNT_CREDIT_CARD, LIABILITY_ACCOUNT],
]) as AccountCategoryTypes;

export const ACCOUNT_CATEGORY_NAMES = {
	[ACCOUNT_CASH]: 'Cash',
	[ACCOUNT_CHEQUING]: 'Chequing',
	[ACCOUNT_SAVINGS]: 'Savings',
	[ACCOUNT_LOAN]: 'Loan',
	[ACCOUNT_CREDIT_CARD]: 'Credit Card',
};

export const ACCOUNT_CATEGORY_OPTIONS: SingleOption<AccountCategory>[] = [
	{ label: ACCOUNT_CATEGORY_NAMES[ACCOUNT_CASH], value: ACCOUNT_CASH },
	{ label: ACCOUNT_CATEGORY_NAMES[ACCOUNT_CHEQUING], value: ACCOUNT_CHEQUING },
	{ label: ACCOUNT_CATEGORY_NAMES[ACCOUNT_SAVINGS], value: ACCOUNT_SAVINGS },
	{ label: ACCOUNT_CATEGORY_NAMES[ACCOUNT_LOAN], value: ACCOUNT_LOAN },
	{ label: ACCOUNT_CATEGORY_NAMES[ACCOUNT_CREDIT_CARD], value: ACCOUNT_CREDIT_CARD },
];
