import React from 'react';
import SideBar from '../../nav/SideBar/SideBar';
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary';
import './app-frame.less';
import InstallPrompt from '../InstallPrompt/InstallPrompt';

type AppFrameProps = React.PropsWithChildren;

const AppFrame = (props: AppFrameProps) => {
	return <main className={'app-frame'}>
		<div className={'app-frame__content-area'}>
			<SideBar />
			<section className={'app-frame__content-width'}>
				<ErrorBoundary>
					<InstallPrompt/>
					{props.children}
				</ErrorBoundary>
			</section>
		</div>
	</main>
}
export default AppFrame;