import { DOLLERO_ACCOUNT_URL } from '../../../config';
import getAuthorizationHeader from '../../sync/api/auth/getAuthorizationHeader';
import { SyncDevice } from '../../../domain/sync/SyncDevice';

const getDeviceIceServers = async (uuid: SyncDevice['uuid']) => {
	return fetch(`${DOLLERO_ACCOUNT_URL}api/devices/${uuid}/ice-servers`, {
		headers: {
			Authorization: await getAuthorizationHeader()
		}
	})
		.then(response => response.json())
		.then(result => {
			console.log('Success:', result);
			return result.iceServers;
		})
		.catch(error => {
			throw new Error(error);
		});
}

export default getDeviceIceServers;