import React from 'react';
import { ColumnInstance } from 'react-table';
import { Transaction } from '../../../domain/transactions/Transaction';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { isSmallScreen } from '../../store/ui/selectors/window';

type Props = {
	headers: ColumnInstance<Transaction>[];
};

const TableHeaders = (props: Props) => {
	const smallScreen = useSelector(isSmallScreen);
	if (smallScreen) {
		return null;
	}

	return (
		<div className={'transactions-table__header-row'}>
			{props.headers.map(col => {
				const props = col.getHeaderProps(
					// @ts-ignore
					col.getSortByToggleProps()
				);
				props.style = Object.assign({}, props.style, {
					width: col.width,
					maxWidth: col.maxWidth,
					minWidth: col.minWidth,
				});
				return (
					<div className={'transactions-table__header-column'} {...props} key={col.id}>
						{col.render('Header')}
						{// @ts-ignore
						col.isSorted ? (
							col.isSortedDesc ? (
								<Icon name="triangle down" />
							) : (
								<Icon name="triangle up" />
							)
						) : (
							''
						)}
					</div>
				);
			})}
		</div>
	);
};

export default TableHeaders;
