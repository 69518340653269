import {
    STATUS_CLEARED,
    STATUS_RECONCILED,
    STATUS_UNCLEARED,
    TransactionStatus
} from "../../../transactions/Transaction";
import { BudgetRow, RegisterRow } from './index';

const CURRENCY_AMOUNT_REGEX = RegExp(/\(([\d.]*)\)/);

export const getCurrencyAmount = (amount: string) => {
    const stringAmount = amount.replace('$', '')
        .replace('€', '')
        .replace(',', '')

    const negativeAmount = stringAmount.match(CURRENCY_AMOUNT_REGEX)

    if (negativeAmount && negativeAmount.length > 0) {
        return Number(negativeAmount[1]) * -1;
    }

    return Number(stringAmount);
}

export const getStatusFromRow = (row: RegisterRow): TransactionStatus => {
    switch (row.cleared) {
        case 'U':
            return STATUS_UNCLEARED;
        case 'C':
            return STATUS_CLEARED;
        case 'R':
            return STATUS_RECONCILED;
        case 'Uncleared':
            return STATUS_UNCLEARED;
        case 'Cleared':
            return STATUS_CLEARED;
        case 'Reconciled':
            return STATUS_RECONCILED;
        default:
            throw new Error(`Unknown transactions cleared value: ${row.cleared}`);
    }
}

export const getCategoryFromBudgetRow = (row: BudgetRow) => {
    const [masterCategoryName, subCategoryName] = row.category.split(/:(.*)/s);
    return [masterCategoryName?.trim(), subCategoryName?.trim()]
}