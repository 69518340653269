import { AppDispatch } from '../../../view/store/types';
import DolleroWebSocket from '../../web-socket/DolleroWebSocket';
import { setSocketState } from '../../../view/store/app/actions/socket';
import webSocketKeepAlive, { canStartKeepAliveProcess } from '../../web-socket/webSocketKeepAlive';
import handleSocketMessage from '../handleSocketMessage';
import { SyncDevice } from '../../../domain/sync/SyncDevice';

const setupWebSocket = (syncUUID: SyncDevice['uuid'], dispatch: AppDispatch) => {
	DolleroWebSocket
		.connect(syncUUID)
		.onOpen(function() {
			dispatch(setSocketState(WebSocket.OPEN));
		})
		.onClose(() => {
			dispatch(setSocketState(WebSocket.CLOSED))
			if (canStartKeepAliveProcess()) webSocketKeepAlive();
		})
		.onMessage(handleSocketMessage(dispatch))
}

export default setupWebSocket;