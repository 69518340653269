import React from 'react';
import './accounts-summary.less';
import { useSelector } from 'react-redux';
import {
	getActiveAccountBalance,
	getActiveAccountClearedBalance,
	getActiveAccountUnclearedBalance,
} from '../../store/accounts/selectors/accounts';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';

const AccountSummary = () => {
	const balance = useSelector(getActiveAccountBalance) || 0;
	const clearedBalance = useSelector(getActiveAccountClearedBalance) || 0;
	const unclearedBalance = useSelector(getActiveAccountUnclearedBalance) || 0;
	const formatCurrencyForDisplay = useLegacyMoneyFormatter();

	return (
		<div className={'account-summary'}>
			<div className={'account-summary__third'}>
				<span className={'account-summary__value'}>
					{formatCurrencyForDisplay(clearedBalance, true)}
				</span>
				<span className={'account-summary__label'}>Cleared</span>
			</div>
			<div className={'account-summary__third'}>
				<span className={'account-summary__value'}>
					{formatCurrencyForDisplay(unclearedBalance, true)}
				</span>
				<span className={'account-summary__label'}>Uncleared</span>

			</div>
			<div className={'account-summary__third'}>
				<span className={'account-summary__value'}>
					{formatCurrencyForDisplay(balance, true)}
				</span>
				<span className={'account-summary__label'}>Balance</span>
			</div>
		</div>
	);
};

export default AccountSummary;
