import * as BudgetMonthActions from "../actions/budgetMonth";
import * as UiWindowActions from "../../ui/actions/window";
import {StoreActions} from "../../types";
import { getCurrentMonths, getNextMonths, Month } from '../../../../domain/util/date';
import numDisplayableMonths from '../../../../domain/budget/calculations/numDisplayableMonths';

export type MonthsVisibleState = Array<Month>;
export const monthsVisible = (state: Array<Month> = defaultState(), action: StoreActions) => {
    switch (action.type) {
        case BudgetMonthActions.VIEW_MONTHS:
            return action.payload.months;
        case UiWindowActions.SET_DIMENSIONS:
            return calculateMonthsVisible(state, numDisplayableMonths(action.payload.width))
        default:
            return state;
    }
};

const defaultState = (): MonthsVisibleState => calculateMonthsVisible([], numDisplayableMonths(window.innerWidth))

const calculateMonthsVisible = (currentViewMonths: MonthsVisibleState, maxDisplayableMonths: number) => {
    if (currentViewMonths.length === 0) {
        return getCurrentMonths(maxDisplayableMonths);
    }

    if (maxDisplayableMonths === 1) return [currentViewMonths[0]];

    return [currentViewMonths[0], ...getNextMonths(currentViewMonths[0], maxDisplayableMonths - 1)]
}