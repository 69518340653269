import PubSub from 'pubsub-js';
import { Middleware } from 'redux';
import { AppDispatch, RootState, StoreActions } from '../../view/store/types';

export interface PubSubInterface {
	subscribe: <Action extends StoreActions>(
		actionType: Action['type'],
		listener: PubSubListener<Action>
	) => void;
	publish: <Action extends StoreActions>(
		actionType: Action['type'],
		data: ListenerData<Action>
	) => void;
}

export type ListenerData<Action extends StoreActions> = {
	action: Action;
	state: RootState;
	dispatch: AppDispatch;
};

export interface PubSubListener<Action extends StoreActions> {
	(actionType: Action['type'], data: ListenerData<Action>): void;
}

const AppPubSub: PubSubInterface = {
	subscribe: <Action extends StoreActions>(
		actionType: Action['type'],
		listener: PubSubListener<Action>
	) => {
		// @ts-ignore
		PubSub.subscribe(actionType, listener);
	},
	publish: <Action extends StoreActions>(
		actionType: Action['type'],
		data: ListenerData<Action>
	) => {
		PubSub.publish(actionType, data);
	},
};

export const pubSubMiddleware: Middleware<{}, RootState, AppDispatch> = store => next => (
	action: StoreActions
) => {
	let result = next(action);
	AppPubSub.publish(action.type, { action, state: store.getState(), dispatch: store.dispatch });
	return result;
};

export default AppPubSub;
