import {STATUS_UNCLEARED, Transaction} from "../Transaction";
import {getUUID} from "../../index";

type RequiredTransactionProperties = Pick<Transaction, 'profileUUID' | 'accountUUID' | 'categoryId' | 'date' | 'inflow' | 'outflow'>
type PartialTransaction = Partial<Transaction> & RequiredTransactionProperties;

const createTransaction = (partialTransaction: PartialTransaction): Transaction => {
    const defaultTransaction = {
        uuid: getUUID(),
        transferToAccountUUID: null,
        transferToAccountStatus: null,
        note: '',
        payee: '',
        splits: [],
        status: STATUS_UNCLEARED,
        tags: [],
    }
    return Object.assign(
        {},
        defaultTransaction,
        partialTransaction
    )
}

export default createTransaction;