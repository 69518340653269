import { combineReducers } from 'redux';
import { categoryBudgets, CategoryBudgetsState } from './budgets';
import {
	subCategories,
	orderedSubCategoryAssignments,
	SubCategoriesState,
	OrderedSubCategoriesAssignmentsState,
} from './subCategories';
import {
	masterCategories,
	MasterCategoriesState,
	orderedMasterCategories,
	OrderedMasterCategoriesState,
} from './masterCategories';
import { addMasterCategoryModalOpen, AddMasterCategoryModalOpenState } from "./addCategoryModal";

export default combineReducers({
	addMasterCategoryModalOpen,
	subCategories,
	masterCategories,
	orderedSubCategoryAssignments,
	categoryBudgets,
	orderedMasterCategories,
});

export type CategoriesState = {
	addMasterCategoryModalOpen: AddMasterCategoryModalOpenState,
	subCategories: SubCategoriesState;
	masterCategories: MasterCategoriesState;
	orderedSubCategoryAssignments: OrderedSubCategoriesAssignmentsState;
	categoryBudgets: CategoryBudgetsState;
	orderedMasterCategories: OrderedMasterCategoriesState;
};
