import React from 'react';
import classNames from 'classnames';
import useLegacyMoneyFormatter from '../../common/hooks/useLegacyMoneyFormatter';
import './cell-value.less';

type Props = {
	children?: React.ReactNode;
	numericValue: number;
};

const CellValue = (props: Props) => {
	const formatMoneyForDisplay = useLegacyMoneyFormatter();
	const classes = classNames({
		'cell-value': true,
		'cell-value--negative': props.numericValue < 0,
	});

	return (
		<span className={classes}>
			{formatMoneyForDisplay(props.numericValue)}
			{props.children}
		</span>
	);
};

export default CellValue;
