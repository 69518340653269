import { combineReducers } from 'redux';
import {
	width,
	height,
	WindowHeightState,
	WindowWidthState,
	installPromptEvent,
	InstallPromptEventState,
} from './window';

export type UIState = {
	windowWidth: WindowWidthState
	windowHeight: WindowHeightState
	installPromptEvent: InstallPromptEventState
}
export default combineReducers({
	windowWidth: width,
	windowHeight: height,
	installPromptEvent,
});
