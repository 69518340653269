import { SyncDevice } from '../../../domain/sync/SyncDevice';
import AppDB from '../../persistence/indexed-db';
import { AppDispatch } from '../../../view/store/types';
import { fetchAccountDevices } from '../../../view/store/sync/actions/devices';
import getDeviceName from '../../../domain/sync/getDeviceName';
import ToastManager, { TYPES } from '../../../view/common/Toast';

const deviceConnectedHandler = async (dispatch: AppDispatch, deviceUUID: SyncDevice['uuid']) => {
	console.log('deviceConnectedHandler', deviceUUID);
	AppDB.syncable.connect('webrtc', deviceUUID);
	const devices = await dispatch(fetchAccountDevices());
	const connectedDevice = devices.find(d => d.uuid === deviceUUID);
	if (!connectedDevice) return;
	ToastManager.newToast(`${getDeviceName(connectedDevice)} is online`, {
		toastId: deviceUUID,
		type: TYPES.INFO
	});
};

export default deviceConnectedHandler;