import getUserAgent from "../../infrastructure/environment/getUserAgent";
import createDevice from "../../infrastructure/sync/api/createDevice";
import {AppSettingsRepo, SettingsKeys} from "../settings/IAppSettingsRepo";
import {SyncDevice} from "./SyncDevice";

const registerNewDevice = async (): Promise<SyncDevice['uuid']> => {
    const ua = getUserAgent();
    const device = await createDevice(ua);
    await AppSettingsRepo.set(SettingsKeys.syncUUID, device.uuid);
    await AppSettingsRepo.set(SettingsKeys.syncDevice, device);
    return device.uuid;
}

export default registerNewDevice;