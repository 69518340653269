import AppDB from '../index';
import { IAccountRepo } from '../../../../domain/accounts/IAccountRepo';
import { Account } from '../../../../domain/accounts/Account';
import NaiveDate from "../../../../domain/util/NaiveDate";
import {STATUS_CLEARED, STATUS_RECONCILED, STATUS_UNCLEARED} from "../../../../domain/transactions/Transaction";
import {IDBTransaction} from "../transactions/types";
import {Profile} from "../../../../domain/profiles";
import { legacyToMoney, add, subtract, toLegacy } from '../../../../domain/util/money';

class AccountRepo implements IAccountRepo {
	put(account: Account) {
		return AppDB.accounts.put(account);
	}

	putMany(accounts: Account[]) {
		return AppDB.accounts.bulkPut(accounts);
	}

	getAll(profileUUID: Profile['uuid']) {
		return AppDB.accounts.where('profileUUID').equals(profileUUID).toArray();
	}

	getAccountBalance(accountUUID: Account['uuid']) {
		let balance = legacyToMoney(0);
		return AppDB.transactions
			.where('accountUUID').equals(accountUUID)
			.or('transferToAccountUUID').equals(accountUUID)
			.each(transaction => {
				if (transaction.accountUUID === accountUUID) {
					const inflow = legacyToMoney(transaction.inflow)
					const outflow = legacyToMoney(transaction.outflow)
					balance = subtract(add(balance, inflow), outflow)
				}

				if (transaction.transferToAccountUUID === accountUUID) {
					const transferOutflow = legacyToMoney(transaction.outflow)
					balance = add(balance, transferOutflow);
				}
			})
			.then(() => {
				return toLegacy(balance);
			});
	}

	getClearedBalance(accountUUID: Account['uuid'], date: NaiveDate | null = null) {
		const query = AppDB.transactions
			.where('[accountUUID+status]')
			// @ts-ignore
			.anyOf([
				[accountUUID, STATUS_RECONCILED],
				[accountUUID, STATUS_CLEARED],
			])
			.or('[transferToAccountUUID+transferToAccountStatus]')
			.anyOf([
				[accountUUID, STATUS_RECONCILED],
				[accountUUID, STATUS_CLEARED],
			])

		if (date) {
			query.and((t: IDBTransaction) => {
				return NaiveDate.fromYMD(t.date.year, t.date.month, t.date.date).toString() <=
					date.toString();
			})
		}

		return query.toArray()
			.then((transactions: IDBTransaction[]) => {
				return transactions.reduce((acc, t) => {
					if (t.transferToAccountUUID === accountUUID) {
						return acc + t.outflow;
					}

					return acc + t.inflow - t.outflow;
				}, 0)
			})
	}

	getUnclearedBalance(accountUUID: Account['uuid'], date: NaiveDate | null = null) {
		const query = AppDB.transactions
			.where('[accountUUID+status]')
			// @ts-ignore
			.anyOf([
				[accountUUID, STATUS_UNCLEARED],
			])
			.or('[transferToAccountUUID+transferToAccountStatus]')
			.anyOf([
				[accountUUID, STATUS_UNCLEARED],
			])

		if (date) {
			query.and((t: IDBTransaction) => {
				return NaiveDate.fromYMD(t.date.year, t.date.month, t.date.date).toString() <=
					date.toString();
			})
		}

		return query.toArray()
			.then((transactions: IDBTransaction[]) => {
				return transactions.reduce((acc, t) => {
					if (t.transferToAccountUUID === accountUUID) {
						return acc + t.outflow;
					}

					return acc + t.inflow - t.outflow;
				}, 0)
			})
	}
}

export default new AccountRepo();
