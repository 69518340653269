import * as transActions from '../actions/transactions';
import * as accountsActions from '../actions';
import { StoreActions } from '../../types';
import {Transaction} from "../../../../domain/transactions/Transaction";

export type AllAccountTransactionsState = Array<Transaction>;
export const allAccountTransactions = (
	state: AllAccountTransactionsState = [],
	action: StoreActions
) => {
	switch (action.type) {
		case transActions.ADD_TRANSACTION:
			return [action.payload.transaction, ...state];
		case transActions.EDIT_TRANSACTION:
			const otherTransactions = state.filter((t) => t.uuid !== action.payload.transaction.uuid);
			return [...otherTransactions, action.payload.transaction];

		case transActions.LOAD_TRANSACTIONS:
		case accountsActions.LOAD_RECONCILE_TRANSACTIONS:
			return [...action.payload.transactions];

		case transActions.DELETE_TRANSACTIONS:
			return state.filter((t) => !action.payload.UUIDs.includes(t.uuid))

		case transActions.EDIT_TRANSACTIONS:
			const editUUIDs = action.payload.transactions.map(t => t.uuid);
			const notEditedTransactions = state.filter((t) => !editUUIDs.includes(t.uuid));
			return [...notEditedTransactions, ...action.payload.transactions];

		default:
			return state;
	}
};
