import Select from 'react-select';
import { FilterProps } from 'react-table';
import { Transaction } from '../../../../../domain/transactions/Transaction';
import useTransactionTagFilters from '../useTransactionTagFilters';
import useTransactionTagOptions, {
	TransactionTagOption,
} from '../../../../transactions/hooks/useTransactionTagOptions';
import React from 'react';

const TransactionTagFilter = (props: FilterProps<Transaction>) => {
	const filter = useTransactionTagFilters(props.column);
	const options = useTransactionTagOptions();

	return <div className={'select-filter'}>
		<label className={'select-filter__label'}>Tags</label>
		<Select<TransactionTagOption, true>
			// TODO: Fix default value not set on reload
			defaultValue={options.filter(opt => filter.filterValue?.includes(opt.value))}
			isMulti
			options={options}
			isClearable={true}
			onChange={newValue => {
				const newTagUUIDs = newValue?.map(opt => opt.value) || null;
				filter.setFilterValue(newTagUUIDs);
			}}
			className={'select-filter__input'}
			styles={getStyles()}
			placeholder={''}
		/>
	</div>
}

export default TransactionTagFilter;

const getStyles = () => {
	return Object.assign(
		{},
		{
			container: (provided: {}) => ({
				...provided,
				height: '3rem',
			}),
			control: (provided: {}) => ({
				...provided,
				height: '100%',
				borderRadius: 0,
				borderColor: '#e5e5e5',
				minHeight: 0,
				border: 0,
				background: 'none',
				boxShadow: 'none',
			}),
			dropdownIndicator: (provided: {}) => ({
				...provided,
				padding: '0 0.8rem',
			}),
			indicatorSeparator: (provided: {}) => ({
				...provided,
				marginTop: '2px',
				marginBottom: '2px',
			}),
			clearIndicator: (provided: {}) => ({
				...provided,
				padding: '5px',
			}),
		}
	);
};