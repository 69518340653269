import { MasterCategoryGroup } from '../../../store/categories/hooks/useCategoryOptions';
import { AccountOption } from '../../../store/accounts/hooks/useAccountOptions';
import { Profile } from '../../../../domain/profiles';
import { STATUS_UNCLEARED, Transaction } from '../../../../domain/transactions/Transaction';
import { Account } from '../../../../domain/accounts/Account';
import NaiveDate from '../../../../domain/util/NaiveDate';
import { FormValues, getSelectedAccount, getSelectedCategory } from './AddEditTransactionForm';

const getInitialValues = (
	categories: Array<MasterCategoryGroup>,
	accounts: Array<AccountOption>,
	profileUUID: Profile['uuid'],
	existingTransaction?: Transaction,
	accountUUID?: Account['uuid'] | undefined,
	isTransfer?: boolean
): FormValues => {
	if (!existingTransaction) {
		const acctName = accounts.find(a => a.value === accountUUID)
		return {
			uuid: null,
			date: NaiveDate.fromDate(new Date()),
			category: null,
			account: accountUUID ? { label: acctName?.label || '', value: accountUUID } : null,
			transferToAccount: null,
			profileUUID,
			note: '',
			inflow: 0,
			outflow: 0,
			payee: '',
			splits: [],
			status: STATUS_UNCLEARED,
			isTransfer: isTransfer || false,
			tags: [],
		};
	}

	return {
		uuid: existingTransaction.uuid,
		date: existingTransaction.date,
		category: getSelectedCategory(categories, existingTransaction.categoryId),
		account: getSelectedAccount(accounts, existingTransaction.accountUUID),
		transferToAccount: getSelectedAccount(accounts, existingTransaction.transferToAccountUUID),
		profileUUID,
		note: existingTransaction.note,
		inflow: existingTransaction.inflow,
		outflow: existingTransaction.outflow,
		payee: existingTransaction.payee,
		status: existingTransaction.status,
		splits: existingTransaction.splits.map(split => {
			return {
				category: getSelectedCategory(categories, [split.categoryUUID]),
				note: split.note,
				inflow: split.inflow,
				outflow: split.outflow,
			};
		}),
		isTransfer: !!existingTransaction.transferToAccountUUID,
		tags: existingTransaction.tags,
	};
};

export default getInitialValues;