import * as subCategoryActions from '../actions/subCategories';
import { StoreActions } from '../../types';
import {CategoryAssignments, SubCategory} from '../../../../domain/categories';
import { LOAD_SUB_CATEGORY } from '../actions/subCategories';
import { RESET_ALL_CATEGORIES } from '../actions';

export type SubCategoriesState = Map<SubCategory['uuid'], SubCategory>;
export const subCategories = (state: SubCategoriesState = new Map(), action: StoreActions) => {
	switch (action.type) {
		case RESET_ALL_CATEGORIES:
			const all: Array<[SubCategory['uuid'], SubCategory]> = action.payload.subCategories.map(cat => [cat.uuid, cat])
			return new Map(all);

		case LOAD_SUB_CATEGORY:
			state.set(action.payload.subCategory.uuid, action.payload.subCategory);
			return new Map(state.entries());

		case subCategoryActions.ADD_SUB_CATEGORY:
			const subCategory = action.payload.subCategory;
			if (state.get(subCategory.uuid) !== undefined) {
				throw new Error(`Sub category with id:${subCategory.uuid} already exists`);
			}
			state.set(subCategory.uuid, subCategory);
			return new Map(state.entries());

		case subCategoryActions.UPDATE_SUB_CATEGORY:
			state.set(action.payload.subCategory.uuid, action.payload.subCategory);
			return new Map(state.entries());

		default:
			return state;
	}
};

export type OrderedSubCategoriesAssignmentsState = CategoryAssignments;
export const orderedSubCategoryAssignments = (
	state: OrderedSubCategoriesAssignmentsState = new Map(),
	action: StoreActions
) => {
	switch (action.type) {
		case RESET_ALL_CATEGORIES:
			return action.payload.subCategoryOrder;

		case subCategoryActions.SET_CATEGORY_ASSIGNMENTS:
			Array.from(action.payload.assignmentsToUpdate.keys()).forEach(masterCatUUID => {
				const newAssignments = action.payload.assignmentsToUpdate.get(masterCatUUID);
				if (newAssignments) {
					state.set(masterCatUUID, newAssignments);
				}
			});
			return new Map(state.entries());
		default:
			return state;
	}
};
