import React, { CSSProperties } from 'react';
import { Field, useFormikContext } from 'formik';
import {
	FormValues,
} from '../AddEditTransactionForm/AddEditTransactionForm';
import PayeeField from '../../AddTransactionRow/fields/PayeeField';
import useRequiredActiveProfile from '../../../store/profiles/hooks/useRequiredActiveProfile';
import { Transaction } from '../../../../domain/transactions/Transaction';

type Props = {
	style?: CSSProperties;
	isTransfer: boolean;
};

const PayeeColumn = (props: Props) => {
	const activeProfile = useRequiredActiveProfile();
	const { setFieldValue } = useFormikContext<FormValues>();

	return (
		<div className={'add-transaction-row__main-row-cell'} style={props.style}>
			<Field
				as={PayeeField}
				name="payee"
				className={'add-transaction-row__select'}
				placeholder={'Payee...'}
				profileUUID={activeProfile.uuid}
				onChange={(newValue: Transaction['payee']) => setFieldValue('payee', newValue)}
			/>
		</div>
	);
};

export default PayeeColumn;
