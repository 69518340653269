import { RootState } from '../../types';
import { Month } from '../../../../domain/util/date';

export const getMonthsVisible = (state: RootState) => {
	return state.budget.monthsVisible;
};

export const getLatestVisibleMonth = (state: RootState): Month | undefined => {
	const visibleMonths = getMonthsVisible(state);
	if (visibleMonths.length === 0) {
		return;
	}

	return visibleMonths.reduce((acc, month) => {
		if (month.year > acc.year || (acc.year === month.year && month.month > acc.month)) {
			acc = month;
		}
		return acc;
	}, visibleMonths[0]);
};
