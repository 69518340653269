import useQueryParams from '../../../../common/hooks/useQueryParams';
import { useCallback, useMemo } from 'react';
import { ColumnInstance } from 'react-table';
import { Transaction } from '../../../../../domain/transactions/Transaction';

export const PAYEE_QUERY = 'payee';

export type PayeeFilterValue = Transaction['payee'] | null | undefined;

export type PayeeQueryFilters = {
    filterValue: PayeeFilterValue;
    setFilterValue: (payee: Transaction['payee'] | undefined | null) => void;
    isShown: boolean;
    show: () => void;
    hide: () => void;
};

const usePayeeFilters = (payeeColumn: ColumnInstance<Transaction>): PayeeQueryFilters => {
    const query = useQueryParams();
    const filterValue = usePayeeFilterValue();

    const setFilterValue = useCallback(
        (payee: Transaction['payee'] | undefined | null = undefined) => {
            if (!payeeColumn) {
                return;
            }
            payeeColumn.setFilter(payee);
            if (payee === undefined) {
                query.removeParam(PAYEE_QUERY);
            } else {
                query.setParam(PAYEE_QUERY, payee || '');
            }
        },
        [payeeColumn, query]
    );

    return useMemo(
        () => ({
            filterValue: filterValue,
            setFilterValue: setFilterValue,
            isShown: filterValue !== undefined,
            show: () => {
                if (filterValue === undefined) setFilterValue(null)
            },
            hide: () => {
                setFilterValue(undefined)
            }
        }),
        [setFilterValue, filterValue]
    );
};

export const usePayeeFilterValue = () => {
    const query = useQueryParams();
    const payee = query.params.get(PAYEE_QUERY);
    const payeeSet = query.params.has(PAYEE_QUERY);
    return useMemo(() => {
        if (!payeeSet) {
            return undefined;
        }

        if (payee === '') {
            return null;
        }
        return payee
    }, [payee, payeeSet]);
}

export default usePayeeFilters;
