import Button from "../../common/Button";
import {AdvancedModal, SIZE_MINI} from "../../common/Modal";
import React from "react";
import LoginForm from "../LoginForm/LoginForm";
import {useDispatch, useSelector} from "react-redux";
import {isLoginModalOpen} from "../../store/auth/selectors";
import {closeLoginModal} from "../../store/auth/actions";
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../store/router/hooks/useAppRoutes';

const LoginModal = () => {
    const dispatch = useDispatch();
    const open = useSelector(isLoginModalOpen)
    const close = () => dispatch(closeLoginModal())
    const onLoginSuccess = () => dispatch(closeLoginModal())

    return <AdvancedModal
        title={'Login'}
        open={open}
        onClose={close}
        size={SIZE_MINI}
        actions={
            <>
                <Link to={APP_ROUTES.sign_up} onClick={close}>Sign Up</Link>
                <Button form={'login'} type={'submit'} >Log In</Button>
                <Button onClick={close}>Close</Button>
            </>
        }
    >
        <LoginForm onLoginSuccess={onLoginSuccess}/>
    </AdvancedModal>
}

export default LoginModal;