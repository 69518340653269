import { CategoryAssignments, MasterCategory, SubCategory } from '../../../../domain/categories';
import { AppThunkAction } from '../../types';
import CategoriesRepo from '../../../../infrastructure/persistence/indexed-db/categories/CategoriesRepo';
import {
	getAllUserCategories,
	getAllMasterCategories,
	getOrderedSubCategoryAssignments,
} from '../selectors';
import { unHideMasterCategory } from './masterCategories';

export type SubCategoryActions =
	| LoadSubCategory
	| AddSubCategory
	| UpdateSubCategory
	| SetCategoryAssignments;

export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export type AddSubCategory = {
	type: typeof ADD_SUB_CATEGORY;
	payload: {
		subCategory: SubCategory;
	};
};
export const addSubCategoryCreator = (subCategory: SubCategory): AddSubCategory => {
	return {
		type: ADD_SUB_CATEGORY,
		payload: {
			subCategory,
		},
	};
};

export const addSubCategory = (category: SubCategory): AppThunkAction<Promise<void>> => {
	return (dispatch, getState) => {
		return CategoriesRepo.putSubCategory(category).then(() => {
			dispatch(addSubCategoryCreator(category));
			const assignments =
				getOrderedSubCategoryAssignments(getState()).get(category.masterCategoryId) || [];
			if (!assignments.includes(category.uuid)) {
				dispatch(
					setCategoryAssignments(
						new Map([[category.masterCategoryId, [...assignments, category.uuid]]])
					)
				);
			}
		});
	};
};

export const LOAD_SUB_CATEGORY = 'LOAD_SUB_CATEGORY';
export type LoadSubCategory = {
	type: typeof LOAD_SUB_CATEGORY;
	payload: {
		subCategory: SubCategory;
	};
};
export const loadSubCategoryCreator = (subCategory: SubCategory): LoadSubCategory => {
	return {
		type: LOAD_SUB_CATEGORY,
		payload: {
			subCategory,
		},
	};
};

export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
export type UpdateSubCategory = {
	type: typeof UPDATE_SUB_CATEGORY;
	payload: {
		subCategory: SubCategory;
	};
};
export const updateSubCategoryCreator = (subCategory: SubCategory): UpdateSubCategory => {
	return {
		type: UPDATE_SUB_CATEGORY,
		payload: {
			subCategory,
		},
	};
};

export const updateSubCategory = (category: SubCategory): AppThunkAction => {
	return (dispatch, getState) => {
		return CategoriesRepo.putSubCategory(category).then(() => {
			dispatch(updateSubCategoryCreator(category));
		});
	};
};

export const hideCategory = (categoryUUID: SubCategory['uuid']): AppThunkAction => {
	return (dispatch, getState) => {
		const state = getState();
		const category = getAllUserCategories(state).get(categoryUUID);
		if (!category) {
			throw new Error(`Can't find category id: ${categoryUUID}`);
		}

		category.hidden = true;
		return dispatch(updateSubCategory(category));
	};
};

export const unHideCategory = (categoryUUID: SubCategory['uuid']): AppThunkAction => {
	return (dispatch, getState) => {
		const state = getState();
		const category = getAllUserCategories(state).get(categoryUUID);
		if (!category) {
			throw new Error(`Can't find category id: ${categoryUUID}`);
		}
		const masterCategory = getAllMasterCategories(state).get(category.masterCategoryId);
		if (!masterCategory) {
			throw new Error(`Can't find master category id: ${category.masterCategoryId}`);
		}

		if (masterCategory.hidden) {
			dispatch(unHideMasterCategory(masterCategory.uuid));
		}

		category.hidden = false;
		return dispatch(updateSubCategory(category));
	};
};

export const hideAllSubCategories = (
	masterCategoryUUID: MasterCategory['uuid']
): AppThunkAction => {
	return (dispatch, getState) => {
		const state = getState();
		const assignments = getOrderedSubCategoryAssignments(state).get(masterCategoryUUID);
		const categories = getAllUserCategories(state);
		if (!assignments) {
			return;
		}

		return Promise.all(
			assignments.map(subCategoryUUID => {
				const subCategory = categories.get(subCategoryUUID);
				if (!subCategory) {
					throw new Error(`Cant category with id: ${subCategoryUUID}`);
				}
				subCategory.hidden = true;
				return dispatch(updateSubCategory(subCategory));
			})
		);
	};
};

export const SET_CATEGORY_ASSIGNMENTS = 'SET_CATEGORY_ASSIGNMENTS';
export type SetCategoryAssignments = {
	type: typeof SET_CATEGORY_ASSIGNMENTS;
	payload: {
		assignmentsToUpdate: CategoryAssignments;
	};
};

export const setCategoryAssignments = (assignments: CategoryAssignments): AppThunkAction => {
	return dispatch => {
		dispatch({
			type: SET_CATEGORY_ASSIGNMENTS,
			payload: {
				assignmentsToUpdate: assignments,
			},
		});
		return Promise.all(
			Array.from(assignments.keys()).map(masterCategoryUUID => {
				const newAssignmentOrder = assignments.get(masterCategoryUUID);
				if (!newAssignmentOrder) {
					throw new Error(
						`Cannot find sub category assignments for master category: ${masterCategoryUUID}`
					);
				}
				return CategoriesRepo.setSubCategoryOrder(masterCategoryUUID, newAssignmentOrder);
			})
		);
	};
};

export const reOrderSubCategory = (
	subCategoryUUID: SubCategory['uuid'],
	oldMasterCategoryUUID: MasterCategory['uuid'],
	newMasterCategoryUUID: MasterCategory['uuid'],
	oldIndex: number,
	newIndex: number
): AppThunkAction => {
	return (dispatch, getState) => {
		const state = getState();
		const currentAssignments = getOrderedSubCategoryAssignments(state);
		const oldMasterCatAssignments = currentAssignments.get(oldMasterCategoryUUID);
		const newMasterCatAssignments =
			oldMasterCategoryUUID === newMasterCategoryUUID
				? oldMasterCatAssignments
				: currentAssignments.get(newMasterCategoryUUID);

		if (!oldMasterCatAssignments || !newMasterCatAssignments) {
			throw new Error(
				`Could not find category assignments for master category uuid: ${oldMasterCategoryUUID} or ${newMasterCategoryUUID}`
			);
		}

		oldMasterCatAssignments.splice(oldIndex, 1);
		newMasterCatAssignments.splice(newIndex, 0, subCategoryUUID);

		const assignmentsToUpdate: CategoryAssignments = new Map();
		assignmentsToUpdate.set(
			oldMasterCategoryUUID,
			Array.from(oldMasterCatAssignments.values())
		);
		assignmentsToUpdate.set(
			newMasterCategoryUUID,
			Array.from(newMasterCatAssignments.values())
		);
		dispatch(setCategoryAssignments(assignmentsToUpdate));
	};
};
