import { ITransactionTagsRepo } from '../../../../domain/transactions/ITransactionTagsRepo';
import AppDB from '../index';
import { TransactionTag } from '../../../../domain/transactions/TransactionTag';
import { Profile } from '../../../../domain/profiles';

class TransactionTagsRepo implements ITransactionTagsRepo {
	put(transactionTag: TransactionTag) {
		return AppDB.transactionTags.put(transactionTag);
	}

	getAll(profileUUID: Profile['uuid']) {
		return AppDB.transactionTags.where('profileUUID')
			.equals(profileUUID)
			.toArray()
	}

	delete(tagUUID: TransactionTag['uuid']) {
		return AppDB.transactionTags.delete(tagUUID)
	}

	getTransactionCount(tagUUID: TransactionTag['uuid']) {
		return AppDB.transactions.where('tags')
			.equals(tagUUID)
			.count()
	}
}

export default new TransactionTagsRepo();