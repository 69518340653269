import { RootState } from '../../types';
import {MasterCategory, SubCategory} from '../../../../domain/categories';
import {getMonthFromKey, Month} from "../../../../domain/util/date";
import isEqual from "lodash/isEqual";
import {alpha} from "../../../../domain/util/sort";

export const createAvailableToBudgetedSelector = (year: number, month: number) => {
	return (state: RootState) => {
		return getAvailableToBudget(state, year, month);
	};
};

export const getAvailableToBudget = (state: RootState, year: number, month: number) => {
	const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
	return monthBudget ? monthBudget.availableToBudget : 0;
};

export const hasBudgetsCalculatedForMonths =  (state: RootState, months: Month[]) => {
	const budgets = months.map((month) => {
		return state.budget.monthBudget.get(`${month.year}-${month.month}`);
	})

	return !budgets.includes(undefined);
}

export const isBudgetCalculatedForMonth = (state: RootState, month: Month) => {
	return Boolean(state.budget.monthBudget.get(`${month.year}-${month.month}`));
}

export const getMonthsRequiringCalculation = (state: RootState, months: Month[]) => {
	return months.filter((m) => {
		return !isBudgetCalculatedForMonth(state, m);
	})
}

export const getLatestCalculatedMonth = (state: RootState): Month|null => {
	const calculatedMonths = Array.from(state.budget.monthBudget.keys())
		.sort(alpha());
	if (calculatedMonths.length === 0) {
		return null;
	}

	return getMonthFromKey(calculatedMonths[calculatedMonths.length - 1])
}

export const createCategoryBalanceSelector = (
	year: number,
	month: number,
	categoryId: SubCategory['uuid']
) => {
	return (state: RootState) => {
		return getCategoryBalance(state, year, month, categoryId);
	};
};

export const getCategoryBalance = (
	state: RootState,
	year: number,
	month: number,
	categoryId: SubCategory['uuid']
): number => {
	const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
	if (!monthBudget) {
		return 0;
	}

	const categoryBalance = monthBudget.categoryBalances.get(categoryId);
	return categoryBalance ? categoryBalance.balance : 0;
};

export const createCategoryOutflowSelector = (
	year: number,
	month: number,
	categoryId: SubCategory['uuid']
) => {
	return (state: RootState) => {
		return getCategoryOutflow(state, year, month, categoryId);
	};
};

export const getCategoryOutflow = (
	state: RootState,
	year: number,
	month: number,
	categoryId: SubCategory['uuid']
) => {
	const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
	if (!monthBudget) {
		return 0;
	}

	return monthBudget.categoryOutflows.get(categoryId) || 0;
};


export const creatMonthBalanceSelector = (year: number, month: number) => {
	return (state: RootState) => {
		return getMonthBalance(state, year, month);
	};
};

export const getMonthBalance = (state: RootState, year: number, month: number) => {
	const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
	if (!monthBudget) {
		return 0;
	}

	return Array.from(monthBudget.masterCategoryBalances.values()).reduce(
		(acc: number, balance: number) => acc + balance,
		0
	);
};

export const createMonthTotalBudgetedSelector = (year: number, month: number) => {
	return (state: RootState) => {
		const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
		if (!monthBudget) {
			return 0;
		}

		return Array.from(monthBudget.categoryBudgeted.values()).reduce((acc, categoryBudget) => {
			return acc + categoryBudget;
		}, 0);
	};
};

export const createMonthTotalOutflowsSelector = (year: number, month: number) => {
	return (state: RootState) => {
		const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
		if (!monthBudget) {
			return 0;
		}
		return Array.from(monthBudget.masterCategoryOutflows.values()).reduce((acc, categoryBudget) => {
			return acc + categoryBudget;
		}, 0);
	};
};

export const createMasterCategoryBudgetedSelector = (year: number, month: number, masterCategoryId: MasterCategory['uuid']) => {
	return (state: RootState) => {
		const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
		if (!monthBudget) {
			return 0;
		}
		return monthBudget.masterCategoryBudgeted.get(masterCategoryId) || 0;
	}
};

export const createMasterCategoryOutflowSelector = (year: number, month: number, masterCategoryId: MasterCategory['uuid']) => {
	return (state: RootState) => {
		const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
		if (!monthBudget) {
			return 0;
		}
		return monthBudget.masterCategoryOutflows.get(masterCategoryId) || 0;
	}
};

export const createMasterCategoryBalanceSelector = (year: number, month: number, masterCategoryId: MasterCategory['uuid']) => {
	return (state: RootState) => {
		const monthBudget = state.budget.monthBudget.get(`${year}-${month}`);
		if (!monthBudget) {
			return 0;
		}
		return monthBudget.masterCategoryBalances.get(masterCategoryId) || 0;
	}
};

export const createHiddenCategoryBudgetedSelector = (month: Month) => {
	return (state: RootState) => {
		const monthBudget = state.budget.monthBudget.get(`${month.year}-${month.month}`);
		if (!monthBudget) {
			return 0;
		}
		return monthBudget.hiddenCategoryTotals.budgeted || 0;
	}
};

export const createHiddenCategoryOutflowSelector = (month: Month) => {
	return (state: RootState) => {
		const monthBudget = state.budget.monthBudget.get(`${month.year}-${month.month}`);
		if (!monthBudget) {
			return 0;
		}
		return monthBudget.hiddenCategoryTotals.outflows || 0;
	}
};

export const createHiddenCategoryBalanceSelector = (month: Month) => {
	return (state: RootState) => {
		const monthBudget = state.budget.monthBudget.get(`${month.year}-${month.month}`);
		if (!monthBudget) {
			return 0;
		}
		return monthBudget.hiddenCategoryTotals.balance || 0;
	}
};

export const createMonthBudgetSelector = (month: Month) => {
	return (state: RootState) => {
		return state.budget.monthBudget.get(`${month.year}-${month.month}`);
	}
};

export const getMonthBudget = (state: RootState, month: Month) => {
	return state.budget.monthBudget.get(`${month.year}-${month.month}`);
}

export const isMonthBudgetLoading = (state: RootState, month: Month): boolean=> {
	return Boolean(state.budget.monthsBudgetsLoading.find((m) => isEqual(m, month)))
}