import React, { useState, KeyboardEvent, useEffect } from 'react';
import './outflow-inflow-field.less';
import { Button } from 'semantic-ui-react';
import classNames from 'classnames';
import {
	formatCurrencyForInput,
	numberStringToCurrencyAmount,
} from '../../../../domain/util/currency';

export enum AmountType {
	OUTFLOW = 'outflow',
	INFLOW = 'inflow',
}

export enum Size {
	LARGE = 'large',
	SMALL = 'small',
}

type Props = {
	onChange: (newAmount: number, type: AmountType) => void;
	initialAmount?: number;
	initialType?: AmountType;
	size?: Size;
	canChangeType?: boolean;
};


const OutflowInflowField = (props: Props) => {
	const initialAmt = formatCurrencyForInput(props.initialAmount || 0).replace('.', '');
	const [type, setType] = useState<AmountType>(props.initialType || AmountType.OUTFLOW);
	const [amountStr, setAmountStr] = useState(initialAmt);
	const [amountStrFormatted, setAmountStrFormatted] = useState(
		numberStringToCurrencyAmount(initialAmt)
	);
	const canChangeType = props.canChangeType !== false;

	useEffect(() => {
		setType(props.initialType || AmountType.OUTFLOW)
	}, [props.initialType])

	useEffect(() => {
		const initialAmt = formatCurrencyForInput(props.initialAmount || 0).replace('.', '');
		setAmountStr(initialAmt);
		setAmountStrFormatted(numberStringToCurrencyAmount(initialAmt))
	}, [props.initialAmount])

	const onChange = (e: KeyboardEvent<HTMLInputElement>) => {
		console.log(e.key, amountStr, amountStrFormatted);
		let newAmountStr = amountStr;
		if (e.key.toLowerCase() === 'backspace') {
			newAmountStr = amountStr.slice(0, -1);
		}

		if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 0].includes(Number(e.key))) {
			newAmountStr = amountStr + e.key;
		}

		setAmountStr(newAmountStr);
		const newFormattedAmount = numberStringToCurrencyAmount(newAmountStr);
		setAmountStrFormatted(newFormattedAmount);
		props.onChange(Number(newFormattedAmount), type);
	};

	const classes = classNames({
		'outflow-inflow-field': true,
		'outflow-inflow-field--small': props.size === Size.SMALL,
	});

	const inputClasses = classNames({
		'outflow-inflow-field__input': true,
		'outflow-inflow-field__input--small': props.size === Size.SMALL,
	});

	return (
		<div className={classes}>
			<div className={'outflow-inflow-field__input-group'}>
				{props.size === Size.SMALL && (
					<Button
						color={type === AmountType.OUTFLOW ? 'red' : 'green'}
						icon={type === AmountType.OUTFLOW ? 'minus' : 'plus'}
						onClick={() => {
							const newType =
								AmountType.OUTFLOW === type
									? AmountType.INFLOW
									: AmountType.OUTFLOW;
							canChangeType && setType(newType);
							props.onChange(Number(amountStrFormatted), newType);
						}}
					/>
				)}
				<input
					className={inputClasses}
					type={'number'}
					onKeyUp={onChange}
					value={amountStrFormatted}
					onClick={e => {
						e.preventDefault();
					}}
				/>
			</div>
			{props.size !== Size.SMALL && (
				<Button.Group className={'outflow-inflow-field__type-selector'}>
					{(canChangeType ||
						type === AmountType.OUTFLOW) && (
							<Button
								color={'red'}
								onClick={e => {
									e.preventDefault();
									canChangeType && setType(AmountType.OUTFLOW);
									props.onChange(Number(amountStrFormatted), AmountType.OUTFLOW);
								}}
								className={classNames({
									'outflow-inflow-field__button': true,
									'outflow-inflow-field__button--faded':
										type !== AmountType.OUTFLOW,
								})}
							>
								Outflow
							</Button>
						)}
					{canChangeType && <Button.Or />}
					{(canChangeType ||
						type === AmountType.INFLOW) && (
							<Button
								color={'green'}
								onClick={e => {
									e.preventDefault();
									canChangeType && setType(AmountType.INFLOW);
									props.onChange(Number(amountStrFormatted), AmountType.INFLOW);
								}}
								className={classNames({
									'outflow-inflow-field__button': true,
									'outflow-inflow-field__button--faded':
										type !== AmountType.INFLOW,
								})}
							>
								Inflow
							</Button>
						)}
				</Button.Group>
			)}
		</div>
	);
};

export default OutflowInflowField;
