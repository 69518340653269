import React from 'react';
import Button from '../../common/Button';
import LoginModal from '../LoginModal/LoginModal';
import { Icon } from 'semantic-ui-react';
import './login-button.less';
import useIsLoggedIn from '../../store/auth/hooks/useIsLoggedIn';
import useLogOut from '../../store/auth/hooks/useLogOut';
import {useDispatch} from "react-redux";
import {openLoginModal} from "../../store/auth/actions";

const LoginButton = () => {
	const loggedIn = useIsLoggedIn();
	const logOut = useLogOut();
	const dispatch = useDispatch();


	const onClick = () => {
		if (loggedIn) {
			logOut();
		} else {
			dispatch(openLoginModal())
		}
	};

	return (
		<>
			<Button size={'medium'} onClick={onClick} className={'login-button'} icon primary>
				<Icon name={'user circle'} /> {loggedIn ? 'Log Out' : 'Log In'}
			</Button>
			<LoginModal />
		</>
	);
};

export default LoginButton;
