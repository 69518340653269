import AppSettingsRepo from "../../infrastructure/persistence/indexed-db/settings/AppSettingsRepo";

export interface IAppSettingsRepo {
    get: <T>(settingKey: SettingsKeys) => Promise<T|undefined>;
    set: <T>(settingKey: SettingsKeys, value: any) => Promise<T>;
    del: (settingKey: SettingsKeys) => Promise<void>;
}

export enum SettingsKeys {
    syncUUID = "syncUUID",
    syncDevice = "syncDevice",
    authToken = "authToken",
    authUser = "authUser",
    stunOnly = "STUNOnly",
    lastInstallPromptDate = "lastInstallPromptDate"
}

export { AppSettingsRepo }

