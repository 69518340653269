import React, { CSSProperties } from 'react';
import { Field, useFormikContext } from 'formik';
import {
	FormValues,
} from '../AddEditTransactionForm/AddEditTransactionForm';
import SelectField, { SIZE_SMALL } from '../../../common/Form/SelectField';
import useCategoryOptions, {
	CategoryOption,
} from '../../../store/categories/hooks/useCategoryOptions';
import {useSelector} from "react-redux";
import {getAllAccounts} from "../../../store/accounts/selectors/accounts";
import classNames from 'classnames';
import isCategoryFieldDisabled from '../AddEditTransactionForm/isCategoryFieldDisabled';

type Props = {
	style?: CSSProperties;
	className?: string
};

const CategoryColumn = (props: Props) => {
	const { setFieldValue, values, errors } = useFormikContext<FormValues>();
	const categoryOptions = useCategoryOptions();
	const accounts = useSelector(getAllAccounts);
	const isDisabled = isCategoryFieldDisabled(values, accounts);
	const selectClasses = classNames({
		'add-transaction-row__select': true,
		'add-transaction-row__select--disabled': isDisabled
	});

	// TODO: Determine if category field needs to be hidden for off budget account
	console.log(errors)
	return (
		<div className={props.className} style={props.style}>
			<Field
				type="number"
				name="category"
				as={SelectField}
				placeholder={placeholderText(isDisabled, values.splits.length)}
				options={categoryOptions}
				onChange={(newValue: CategoryOption) => setFieldValue('category', newValue, true)}
				disabled={isDisabled}
				size={SIZE_SMALL}
				className={selectClasses}
			/>
			{!!errors.category && <small className={'add-transaction-row__error-message'}>Category is required</small>}
		</div>
	);
};

export default CategoryColumn;

const placeholderText = (fieldDisabled: boolean, numSplits: number) => {
	if (numSplits > 1) {
		return 'Split (Multiple categories)';
	}

	if (fieldDisabled) {
		return '(Category not needed)';
	}

	return 'Choose a category...';
}
