import React from "react";
import { useLocation, useHistory } from 'react-router';
import useAllProfiles from "../store/profiles/hooks/useAllPofiles";
import {APP_LINKS} from "../store/router/hooks/useAppRoutes";
import SignUpPage from "../auth/SignUpPage/SignUpPage";

const HomePage = () => {
    const location = useLocation();
    const history = useHistory();
    const profiles = useAllProfiles().filter(p => !p.deleted);
    if (location.pathname === "/" && profiles.length) {
        history.push(APP_LINKS.budget(profiles[0].uuid))
        return null;
    }

    return <SignUpPage/>
}

export default HomePage;