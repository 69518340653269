import {ICreateChange, IDatabaseChange, IDeleteChange, IUpdateChange} from "dexie-observable/api";
import AppDb from '../persistence/indexed-db/index';
import Dexie, {Table} from "dexie";
import {SyncDevice} from "../../domain/sync/SyncDevice";

export const enum DatabaseChangeType {
    Create = 1,
    Update = 2,
    Delete = 3,
}

type CollectedChanges = {
    [tableName: string]: {
        [changeType: number]: IDatabaseChange[]
    }
}

const persistRemoteChanges = async (changes: IDatabaseChange[], peerDeviceUUID: SyncDevice['uuid']) => {
    let collectedChanges: CollectedChanges = {};
    changes.forEach((change) => {
        if (!collectedChanges.hasOwnProperty(change.table)) {
            collectedChanges[change.table] = { [DatabaseChangeType.Create]: [], [DatabaseChangeType.Delete]: [], [DatabaseChangeType.Update]: [] };
        }
        collectedChanges[change.table][change.type].push(change);
    });
    let table_names = Object.keys(collectedChanges);
    let tables = table_names.map((table) => AppDb.table(table));

    if (tables.length === 0) {
        return Promise.resolve();
    }

    /**
     * Here we get the local dexie sync node id and set it on trans.source below.
     * This tricks dexie-syncable into not syncing this change back to the remote peer.
     * I figured this out by peering at the source code here: https://github.com/dexie/Dexie.js/blob/16eb2b5a369960fa5a9197b238ceccb12f49b22c/addons/Dexie.Syncable/src/finally-commit-all-changes.js#L21
     * This pokes into the internals of dexie-syncable but its the only way to solve this.
     */
    const peerNode = await AppDb._syncNodes.where({url: peerDeviceUUID}).first();

    return AppDb.transaction("rw", tables, (trans) => {
        // @ts-ignore trans.source can be set, see: https://dexie.org/docs/Observable/Dexie.Observable.DatabaseChange
        trans.source = peerNode?.id;
        table_names.forEach((table_name) => {
            const table = AppDb.table(table_name);
            const specifyKeys = !table.schema.primKey.keyPath;
            const createChangesToApply = collectedChanges[table_name][DatabaseChangeType.Create] as ICreateChange[];
            const deleteChangesToApply = collectedChanges[table_name][DatabaseChangeType.Delete] as IDeleteChange[];
            const updateChangesToApply = collectedChanges[table_name][DatabaseChangeType.Update] as IUpdateChange[];
            if (createChangesToApply.length > 0)
                table.bulkPut(createChangesToApply.map(c => c.obj), specifyKeys ?
                    createChangesToApply.map(c => c.key) : undefined);
            if (updateChangesToApply.length > 0)
                bulkUpdate(table, updateChangesToApply);
            if (deleteChangesToApply.length > 0)
                table.bulkDelete(deleteChangesToApply.map(c => c.key));
        });
    });
}

const bulkUpdate = (table: Table, changes: IUpdateChange[]) => {
    let keys = changes.map(c => c.key);
    let map: {[key: string]: {}} = {};
    // Retrieve current object of each change to update and map each
    // found object's primary key to the existing object:
    return table.where(':id').anyOf(keys).raw().each((obj, cursor) => {
        map[cursor.primaryKey+''] = obj;
    }).then(() => {
        // Filter away changes whose key wasn't found in the local database
        // (we can't update them if we do not know the existing values)
        let updatesThatApply = changes.filter(c => map.hasOwnProperty(c.key+''));
        // Apply modifications onto each existing object (in memory)
        // and generate array of resulting objects to put using bulkPut():
        let objsToPut = updatesThatApply.map (c => {
            let curr = map[c.key+''];
            Object.keys(c.mods).forEach(keyPath => {
                Dexie.setByKeyPath(curr, keyPath, c.mods[keyPath]);
            });
            return curr;
        });
        return table.bulkPut(objsToPut);
    });
}

export default persistRemoteChanges;